import { useCallback, useState, type FC } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import classNames from 'classnames/bind';
import { isEmail } from 'validator';
import {
  PlayerRecordsTab,
  PlayerParticipationTab,
  PlayerPaymentTab,
  PlayerWaiversTab,
} from './index';
import styles from './player-details.module.scss';
import {
  EMAIL_IS_VALID,
  PHONE_NUMBER_IS_VALID,
  UPDATE_USER_PROFILE,
} from './PLAYER_DETAILS_MUTATIONS';
import { USER_BY_INFO_QUERY, type GetUserByInfoData } from './PLAYER_DETAILS_QUERIES';
import { Loading, TabNav } from '../../components';
import useActionStates from '../../hooks/useActionStates';
import PlayerInfo from './PlayerInfo';
import { GenderNameEnum } from '../../shared/gender-enum';
import UserShiftLogsTab from './UserShiftLogsTab/UserShiftLogsTab';

const cx = classNames.bind(styles);

type PlayerDetailsProps = {
  userId?: string;
};

const PlayerDetails: FC<PlayerDetailsProps> = props => {
  const { userId = '' } = props;
  const { setUpdating, setSuccess, setError } = useActionStates({ withAlerts: true });

  const [updatingInfo, setUpdatingInfo] = useState(false);
  const [userDetails, setUserDetails] = useState<GetUserByInfoData['getUserByInfo']>({
    _id: '',
    isVoloPassMember: false,
    membershipExpires: '',
    firstName: '',
    lastName: '',
    gender: undefined,
    email: '',
    phone_number: '',
    countryCode: '',
    picture: '',
    _created: '',
    birthday: '',
    status: '',
    username: '',
    deactivated: false,
    is_host: false,
  });

  const updateUserDetails = (update: Partial<GetUserByInfoData['getUserByInfo']>) =>
    setUserDetails({ ...userDetails, ...update });

  const [emailIsValid] = useMutation(EMAIL_IS_VALID);
  const [phoneNumberIsValid] = useMutation(PHONE_NUMBER_IS_VALID);
  const [updateUserMutation] = useMutation(UPDATE_USER_PROFILE);

  const { data, loading, error, refetch } = useQuery(USER_BY_INFO_QUERY, {
    skip: !userId,
    fetchPolicy: 'network-only',
    variables: { input: { search: userId, which: '_id' }, userId },
    onCompleted: ({ getUserByInfo }) => {
      setUserDetails({
        ...getUserByInfo,
        // format for PhoneInput
        phone_number: `+1${getUserByInfo?.phone_number}`,
      });
    },
  });

  const MemoPayment = useCallback(
    () => (
      <PlayerPaymentTab
        userId={userId}
        setUpdating={setUpdating}
        setSuccess={setSuccess}
        setError={setError}
      />
    ),
    [setError, setSuccess, setUpdating, userId]
  );
  const MemoRecords = useCallback(() => <PlayerRecordsTab userId={userId} />, [userId]);
  const MemoParticipation = useCallback(
    () => <PlayerParticipationTab userId={userId} userDetails={userDetails} />,
    [userId, userDetails]
  );
  const MemoWaivers = useCallback(
    () => <PlayerWaiversTab userId={userId} userDetails={userDetails} />,
    [userId, userDetails]
  );

  const MemoShiftLogs = useCallback(() => <UserShiftLogsTab userId={userId} />, [userId]);

  if (loading) return <Loading />;
  if (error || !userId) return <div>Error! {JSON.stringify(error || 'Missing userId')}</div>;

  const { firstName, lastName, email, countryCode, phone_number, gender, birthday } = userDetails;

  const updateUserInfo = async () => {
    try {
      if (
        !data?.getUserByInfo ||
        !firstName ||
        !lastName ||
        !email ||
        !phone_number ||
        !gender ||
        !birthday
      ) {
        throw new Error('Missing information to save profile.');
      }
      setUpdating(true);
      const user = data.getUserByInfo;

      // check email
      if (email !== user.email) {
        if (!isEmail(email)) throw new Error('Email is not valid.');
        const {
          data: {
            emailIsValid: { isValid, message },
          },
        } = await emailIsValid({
          variables: { input: { email } },
        });
        if (!isValid) throw new Error(message);
      }

      // check phone
      if (
        phone_number?.replace(/\D/g, '') !== `1${(user?.phone_number ?? '').replace(/\D/g, '')}`
      ) {
        const {
          data: {
            phoneNumberIsValid: { isValid, message },
          },
        } = await phoneNumberIsValid({
          variables: { input: { phoneNumber: phone_number, countryCode } },
        });
        if (!isValid) throw new Error(message);
      }

      // update input
      await updateUserMutation({
        variables: {
          input: {
            firstName,
            lastName,
            email,
            phoneNumber: phone_number,
            countryCode,
            gender,
            birthday,
            userId,
          },
        },
      });
      refetch();
      setUpdatingInfo(false);
      setSuccess('Updated user info.');
    } catch (e) {
      setError(e);
    } finally {
      setUpdating(false);
    }
  };

  const genderOptions = Object.keys(GenderNameEnum).map(value => ({
    // @ts-expect-error TS doens't like that this is coerced to a string
    label: GenderNameEnum[value],
    value,
  }));

  return (
    <div className={cx('playerDetails')}>
      <div className="row">
        <PlayerInfo
          {...{
            genderOptions,
            setUpdatingInfo,
            updateUserDetails,
            updateUserInfo,
            updatingInfo,
            userDetails,
            activeMembershipForUser: data?.activeMembershipForUser,
            ...props,
          }}
        />
        <div className="col-12 col-md-10">
          <TabNav
            initialTab={1}
            allTabs={[
              { label: 'Payment', component: MemoPayment },
              { label: 'Participation', component: MemoParticipation },
              { label: 'Records', component: MemoRecords },
              { label: 'Waivers', component: MemoWaivers },
              ...(userDetails.is_host ? [{ label: 'Shift Logs', component: MemoShiftLogs }] : []),
            ]}
          />
        </div>
      </div>
      <small className="float-right">userId: {userId}</small>
    </div>
  );
};

export default PlayerDetails;
