export const SearchUserQueryEnum = Object.freeze({
  EMAIL: 'email',
  USERNAME: 'username',
});

export const SearchUserQueryTypes = {
  [SearchUserQueryEnum.EMAIL]: {
    value: SearchUserQueryEnum.EMAIL,
    label: 'Email',
  },
  [SearchUserQueryEnum.USERNAME]: {
    value: SearchUserQueryEnum.USERNAME,
    label: 'Username',
  },
};

export default SearchUserQueryEnum;
