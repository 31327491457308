import { type FC } from 'react';
import { Text, View, Button, Link } from '@rivallapp/volosports-components';

import { type VoloPassWaitlistMessageProps } from './types';
import { StaticUrls } from '../../../../../../shared/static-urls-enum';
import { Loading } from '../../../../../../components';

const VoloPassWaitlistMessage: FC<VoloPassWaitlistMessageProps> = ({
  cityName,
  loading = false,
}) => {
  if (loading)
    return (
      <View alignItems="center" marginTop={3}>
        <Loading />
      </View>
    );

  return (
    <View>
      <Text marginY={3}>
        Membership is not yet available in {cityName}, but join our waitlist to get realtime updates
        on when we launch in your city!
      </Text>
      <View alignItems="center" marginY={4}>
        <Button type="secondary" width={175} borderRadius={50}>
          <Link
            href={StaticUrls.VOLO_PASS_WAITLIST}
            isExternal
            _text={{
              textDecoration: 'none',
              color: 'white.600',
              fontSize: 18,
              fontWeight: 700,
            }}
          >
            Join Waitlist
          </Link>
        </Button>
      </View>
    </View>
  );
};

export default VoloPassWaitlistMessage;
