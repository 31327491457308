/* eslint react/jsx-filename-extension: 0 */
import * as _ from 'lodash-es';
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';

// HOC modified from github.com/JedWatson/react-input-autosize

const sizerStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  visibility: 'hidden',
  height: 0,
  overflow: 'scroll',
  whiteSpace: 'pre',
};

const blacklistedInputProps = [
  'style',
  'className',
  'onAutosize',
  'inputClassName',
  'inputStyle',
  'extraWidth',
  'minWidth',
  'placeholderIsMinWidth',
];

// predicts the shown value to autosize for. useful for select elements.
const shownValueFunc = props => {
  if (!props.getLabel && !props.labelKey) return ({ value }) => value;
  return ({ getLabel, labelKey, options, valueKey = 'value', value }) => {
    const selectedOption = options.find(op => op[valueKey] === value) || {};
    return getLabel ? getLabel(selectedOption) : selectedOption[labelKey];
  };
};

const withAutosize = Field => {
  class AutosizeInput extends Component {
    static propTypes = {
      className: PropTypes.string, // className for the outer element
      extraWidth: PropTypes.oneOfType([
        // additional width for input element
        PropTypes.number,
        PropTypes.string,
      ]),
      inputClassName: PropTypes.string, // className for the input element
      inputStyle: PropTypes.shape({}), // css styles for the input element
      minWidth: PropTypes.oneOfType([
        // minimum width for input element
        PropTypes.number,
        PropTypes.string,
      ]),
      onChange: PropTypes.func,
      placeholder: PropTypes.string,
      placeholderIsMinWidth: PropTypes.bool, // don't collapse size to less than the placeholder
      style: PropTypes.shape({}), // styles for the outer element
      value: PropTypes.any.isRequired, // eslint-disable-line
    };

    static defaultProps = {
      className: null,
      extraWidth: null,
      inputClassName: '',
      inputStyle: {},
      minWidth: 10,
      onChange: () => {},
      placeholder: null,
      placeholderIsMinWidth: null,
      style: {},
    };

    constructor(props) {
      super(props);
      this.inputRef = createRef();
      this.placeholderSizerRef = createRef();
      this.sizerRef = createRef();
      // add extraWidth to the detected width.
      // for number types, this defaults to 16 to allow for the stepper UI.
      this.extraWidth =
        props.type === 'number' && props.extraWidth === undefined
          ? 16
          : parseInt(props.extraWidth, 0) || 0;
      this.getShownValue = shownValueFunc(props);
      this.state = {
        inputWidth: props.minWidth,
      };
    }

    componentDidMount() {
      this.mounted = true;
      this.updateInputWidth();
      // update again after fonts have a chance to load
      setTimeout(() => {
        this.updateInputWidth();
      }, 500);
    }

    componentDidUpdate({ value: prevValue }) {
      const { value } = this.props;
      if (value !== prevValue) {
        this.updateInputWidth();
      }
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    updateInputWidth() {
      const sizer = this.sizerRef ? this.sizerRef.current : null;
      const placeholderSizer = this.placeholderSizerRef ? this.placeholderSizerRef.current : null;
      if (!this.mounted || !sizer || typeof sizer.scrollWidth === 'undefined') {
        return;
      }
      const { placeholder, placeholderIsMinWidth, minWidth, value } = this.props;
      const usePlaceholderWidth =
        placeholder && (!value || placeholderIsMinWidth) && placeholderSizer;
      let newInputWidth =
        (usePlaceholderWidth
          ? Math.max(sizer.scrollWidth, placeholderSizer.scrollWidth)
          : sizer.scrollWidth) +
        this.extraWidth +
        2;
      newInputWidth = Math.max(newInputWidth, minWidth);

      const { inputWidth: currentInputWidth } = this.state;
      if (newInputWidth !== currentInputWidth) {
        this.setState({
          inputWidth: newInputWidth,
        });
      }
    }

    render() {
      const { style, className, placeholder, inputStyle, inputClassName } = this.props;

      const { inputWidth } = this.state;

      const wrapperStyle = {
        display: 'inline-block',
        ...style,
      };

      const inputProps = {
        ..._.omit(this.props, blacklistedInputProps),
        className: inputClassName,
        style: {
          boxSizing: 'content-box',
          width: `${inputWidth}px`,
          ...inputStyle,
        },
      };

      return (
        <div className={className} style={wrapperStyle}>
          <Field {...inputProps} inputRef={this.inputRef} />
          <div ref={this.sizerRef} style={sizerStyle}>
            {this.getShownValue(this.props)}
          </div>
          {placeholder && (
            <div ref={this.placeholderSizerRef} style={sizerStyle}>
              {placeholder}
            </div>
          )}
        </div>
      );
    }
  }
  return AutosizeInput;
};

export default withAutosize;
