import { isProduction } from '../environment';

export const FOUNDATION_PRODUCT_IDS = isProduction
  ? ['prod_OoSRHPd4LyWxYR']
  : ['prod_OoRgCI8AqgxvMe'];

export const productIdIsRecurringDonation = (productId: string) =>
  FOUNDATION_PRODUCT_IDS.includes(productId);

export enum DonationText {
  SHARE_A_SMILE = 'Share a Smile',
  GIVE_A_HIGH_FILE = 'Give a High Five',
  DONATE_EQUIPMENT = 'Donate Equipment',
  SPONSOR_PLAY_FOR_A_DAY = 'Sponsor Play for a Day',
  SPONSOR_PLAY_FOR_A_SEASON = 'Sponsor Play for a Season',
}

/**
 * Monthly prices, text, and plan ids for foundation recurring donations.
 * Prices are in cents!
 */
export const monthlyPriceEnum = Object.freeze([
  {
    price: 100,
    text: DonationText.SHARE_A_SMILE,
    planId: isProduction ? 'price_1O0pWHCyzbeSckFn934g7X2N' : 'price_1O0onKCyzbeSckFnpRP1hX2d',
  },
  {
    price: 500,
    text: DonationText.GIVE_A_HIGH_FILE,
    planId: isProduction ? 'price_1O0pWHCyzbeSckFnOxv2V1DE' : 'price_1O0onKCyzbeSckFnISPtfm4D',
  },
  {
    price: 2000,
    text: DonationText.DONATE_EQUIPMENT,
    planId: isProduction ? 'price_1O0pWHCyzbeSckFnxv2wrD8R' : 'price_1O0onKCyzbeSckFnE4mqjueq',
  },
  {
    price: 5000,
    text: DonationText.SPONSOR_PLAY_FOR_A_DAY,
    planId: isProduction ? 'price_1O0pWHCyzbeSckFn5qVnlYVI' : 'price_1O0onKCyzbeSckFneoOsfn2y',
  },
  {
    price: 17500,
    text: DonationText.SPONSOR_PLAY_FOR_A_SEASON,
    planId: isProduction ? 'price_1O0pWHCyzbeSckFnrqdOsDjA' : 'price_1O0onKCyzbeSckFnQDEAeIPG',
  },
] as const);
