import { gql } from '@apollo/client';
import { DiscoverProgramsFragment } from '../../../contexts/DiscoverContext/hooks/graphql/GET_PROGRAMS';

const LEAGUE_QUERY = gql`
  query league($leagueId: ID!) {
    league(_id: $leagueId) {
      ...discoverProgramsFragment
    }
  }
  ${DiscoverProgramsFragment}
`;

export default LEAGUE_QUERY;
