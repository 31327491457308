import * as _ from 'lodash-es';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames/bind';
import styles from './fancy-forms.module.scss';

const cx = classNames.bind(styles);

class FancyField extends Component {
  constructor(props) {
    super(props);

    this.showLabel = this.showLabel.bind(this);
    this.hideLabel = this.hideLabel.bind(this);
    this.onFancyChange = this.onFancyChange.bind(this);
    this.getNewState = this.getNewState.bind(this);
    this.showDisabledText = this.showDisabledText.bind(this);
    this.renderUnitComponent = this.renderUnitComponent.bind(this);
    this.onPhoneChange = this.onPhoneChange.bind(this);

    this.state = {
      isFocused: props.initFocus,
      isChanged: false,
      showingDisabledText: false,
    };
  }

  componentDidMount() {
    if (this.state.isFocused) {
      this.fancyInput.focus();
    }
  }

  componentWillReceiveProps(props) {
    if (props.value == this.props.initialValue) {
      this.setState({ isChanged: false });
    }
  }

  areaCode(n) {
    return !n || n.length < 3 ? n : `(${n.slice(0, 3)})-`;
  }

  first3(n) {
    return !n || n.length > 3 ? n.slice(3, 6) : '';
  }

  last4(n) {
    return !n || n.length > 6 ? `-${n.slice(6, 10)}` : '';
  }

  formatPhoneNum(val) {
    return this.areaCode(val) + this.first3(val) + this.last4(val);
  }

  render() {
    const { showingDisabledText, isFocused } = this.state;
    const {
      disabled,
      disabledClickText,
      errors,
      focusText,
      infoText,
      initFocus,
      warnings,
      label,
      persistLabel,
      placeholder,
      property,
      unit,
      phone_number,
      inputStyle,
      large,
      updateChange,
      validIndicator,
      value,
      otherClasses,
      className,
      style,
      textarea,
      ...other
    } = this.props;

    const fanciness = cx('fancy-field', property, otherClasses, className, {
      textarea,
      error: errors,
      warning: warnings,
      populated: value,
      disabled,
      focused: isFocused,
    });

    const peekaboo = cx('magic-label', {
      populated: value || persistLabel,
      focused: isFocused,
    });

    const backupName = property ? property[0].toUpperCase() + property.slice(1) : null;

    const defaultProps = {
      style: inputStyle,
      id: property,
      onFocus: this.showLabel,
      onBlur: this.hideLabel,
      onChange: this.onFancyChange,
      ref: input => {
        this.fancyInput = input;
      },
      placeholder: placeholder || label || backupName,
      value,
      disabled,
    };

    let phoneProps;

    if (this.props.phone_number) {
      phoneProps = {
        value: this.formatPhoneNum(value || ''),
        onChange: this.onPhoneChange,
      };
    }

    let peekabooLabel = label || backupName;
    if (this.props.maxLength && isFocused) {
      const charsLeft = value
        ? parseInt(this.props.maxLength) - value.length
        : this.props.maxLength;
      peekabooLabel = `${peekabooLabel} (${charsLeft} chars remaining)`;
    }

    return (
      <div className={fanciness} style={style}>
        <div className={cx('wrap')} onClick={this.showDisabledText}>
          <p className={peekaboo}>{peekabooLabel}</p>

          {textarea ? (
            <textarea {...defaultProps} {...other} className={cx({ large })} />
          ) : this.props.phone_number ? (
            <input {...defaultProps} {...phoneProps} {...other} />
          ) : (
            <input {...defaultProps} {...other} />
          )}

          {unit ? this.renderUnitComponent() : null}
          {validIndicator}

          {infoText ? (
            <FancyMessage classes="focus-text" message={infoText} shown={isFocused && !value} />
          ) : null}

          {focusText ? (
            <FancyMessage classes="focus-text" message={focusText} shown={isFocused} />
          ) : null}

          {disabledClickText ? (
            <FancyMessage
              classes="disabled-text"
              message={disabledClickText}
              shown={showingDisabledText}
            />
          ) : null}

          <FancyMessage classes="error-message" message={errors} shown={errors && !isFocused} />

          <FancyMessage
            classes="warning-message"
            isWarning
            message={warnings}
            shown={warnings && isFocused}
          />
        </div>
      </div>
    );
  }

  showLabel() {
    this.setState({ isFocused: true });
  }

  hideLabel() {
    this.setState({ isFocused: false });
  }

  showDisabledText() {
    if (!this.props.disabledClickText || !this.props.disabled) return;

    this.setState({ showingDisabledText: true });
    setTimeout(() => {
      this.setState({ showingDisabledText: false });
    }, 2100);
  }

  onPhoneChange(e) {
    const val = e.target.value.replace(/[^0-9]/g, '');
    const newState = this.getNewState(val);
    this.props.updateChange(newState);
  }

  onFancyChange(e) {
    if (this.props.updateChange) {
      const newState = this.getNewState(e.target.value);
      this.props.updateChange(newState);
    }
  }

  getNewState(newVal) {
    return {
      value: newVal,
      property: this.props.property,
    };
  }

  renderUnitComponent() {
    return (
      <p className={cx('unit', { shown: this.props.value || this.state.isFocused })}>
        {this.props.unit}
      </p>
    );
  }
}

FancyField.propTypes = {
  property: PropTypes.string,
  updateChange: PropTypes.func,
  value: PropTypes.any,
  label: PropTypes.string,
  large: PropTypes.bool,
  unit: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  initFocus: PropTypes.bool,
  persistLabel: PropTypes.bool,
  errors: PropTypes.any,
  warnings: PropTypes.any,
  disabledClickText: PropTypes.string,
  focusText: PropTypes.any,
};

FancyField.defaultProps = {
  property: 'fancy',
};

const FancyMessage = ({ message, classes, shown, isWarning }) => {
  if (!message || (!message.length && _.isEmpty(message))) return <noscript />;

  const messageClasses = cx('message-container', classes, { shown, warning: isWarning });

  const messageToShow = Array.isArray(message) ? _.values(message).join(' ') : message;

  return (
    <div className={messageClasses}>
      <div className={cx('carat')} />
      <div className={cx('message')}>{messageToShow}</div>
    </div>
  );
};

export default FancyField;
