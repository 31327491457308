import React from 'react';
import Button from '../../../components/Button';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

export default () => (
  <div className={cx('anon-nav')}>
    <Button default link small to="/login" className={cx('login')}>
      Log In
    </Button>{' '}
    &nbsp;
    <Button primary small to="/signup/app/personal" className={cx('signup')}>
      Sign Up
    </Button>
  </div>
);
