import React from 'react';
import * as _ from 'lodash-es';
import PropTypes from 'prop-types';
import { Button } from '../../components';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);
const CollapsedMenuPresenter = props => {
  const { itemList, clicked, setClicked, menuRef } = props;

  return (
    <div className={cx('collapsedMenu')} ref={menuRef}>
      <div className={cx('addMenu', clicked && 'clicked')}>
        {_.map(itemList, ({ label, clickAction }, idx) => (
          <div
            role="button"
            tabIndex={0}
            key={`menuItem-${idx}`}
            className={cx('addMenuItem')}
            onClick={() => {
              clickAction();
              setClicked(false);
            }}
          >
            {label}
          </div>
        ))}
      </div>
      <Button
        primary
        square
        className={cx('addButton')}
        onClick={() => (clicked ? setClicked(false) : setClicked(true))}
      >
        +
      </Button>
    </div>
  );
};

CollapsedMenuPresenter.propTypes = {
  itemList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  clicked: PropTypes.bool.isRequired,
  setClicked: PropTypes.func.isRequired,
  menuRef: PropTypes.shape({}).isRequired,
};

export default CollapsedMenuPresenter;
