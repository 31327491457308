import PropTypes from 'prop-types';
import { CYCLIC_PROP } from '../common-props';

const RegistrantProps = PropTypes.shape({
  _id: PropTypes.string,
  player: CYCLIC_PROP,
  playerId: PropTypes.string,
  gender: PropTypes.string,
  birthday: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  username: PropTypes.string,
  email: PropTypes.string,
  picture: PropTypes.string,
  phone_number: PropTypes.string,
  kind: PropTypes.string,
  payment_status: PropTypes.string,
  charge_data: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    isLegacyCharge: PropTypes.bool,
    programPriceCents: PropTypes.number,
    promoDiscountCents: PropTypes.number,
    afterDiscountCents: PropTypes.number,
    processingFeeCents: PropTypes.number,
    flatFeeCents: PropTypes.number,
    totalFeeCents: PropTypes.number,
    donationCents: PropTypes.number,
    creditCents: PropTypes.number,
    totalTransferCents: PropTypes.number,
    totalChargeCents: PropTypes.number,
    feePercentage: PropTypes.number,
    refundCents: PropTypes.number,
    orgNetCents: PropTypes.number,
    promoCodeId: PropTypes.string,
    promoCodeStr: PropTypes.string,
    amount: PropTypes.number,
    before_fees: PropTypes.number,
    promo_code: PropTypes.string,
    promo_discount: PropTypes.number,
    amount_refunded: PropTypes.number,
    org_total: PropTypes.number,
  }),
  division_selection: PropTypes.string,
  why_you_play_selection: PropTypes.string,
  group: CYCLIC_PROP,
  fullStripeCharge: PropTypes.shape({
    id: PropTypes.string,
    amount: PropTypes.number,
    amount_refunded: PropTypes.number,
    description: PropTypes.string,
    source: PropTypes.shape({
      id: PropTypes.string,
      object: PropTypes.string,
      brand: PropTypes.string,
      exp_month: PropTypes.number,
      exp_year: PropTypes.number,
      last4: PropTypes.string,
    }),
    donationAmount: PropTypes.number,
    creditAmount: PropTypes.number,
    metadata: PropTypes.string,
  }),
  isWaitlisted: PropTypes.bool,
  league: CYCLIC_PROP,
  activityName: PropTypes.string,
  seasonName: PropTypes.string,
  leagueId: PropTypes.string,
  age: PropTypes.number,
  stagedTeam: CYCLIC_PROP,
  team: CYCLIC_PROP,
  credits: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      organizationId: PropTypes.string,
      createdBy: PropTypes.string,
      creditAmount: PropTypes.number,
      creditNote: PropTypes.string,
      reasonType: PropTypes.string,
      date: PropTypes.string,
    })
  ),
  availableCredits: PropTypes.arrayOf(
    PropTypes.shape({
      organizationId: PropTypes.string,
      amount: PropTypes.number,
      used: PropTypes.number,
      organizationName: PropTypes.string,
    })
  ),
  teamRole: PropTypes.string,
  _created: PropTypes.string,

  notes: PropTypes.string,
  customResponsesList: PropTypes.arrayOf(
    PropTypes.shape({
      questionTitle: PropTypes.string,
      response: PropTypes.string,
    })
  ),

  isDropin: PropTypes.bool,
  dropInBegins: PropTypes.string,
  dropInName: PropTypes.string,
  dropInGameId: PropTypes.string,
  dropInSlotId: PropTypes.string,

  isCurrentMember: PropTypes.bool,
});

export default RegistrantProps;
