import PropTypes from 'prop-types';
import { CYCLIC_PROP, EmailProps, NameUrlProps } from '../common-props';

const DefaultSportTypesProps = PropTypes.shape({
  name: PropTypes.string,
  level: PropTypes.string,
  image: NameUrlProps,
  description: PropTypes.string,
});

const TotalsTypeProps = PropTypes.shape({
  total: PropTypes.number,
  male: PropTypes.number,
  female: PropTypes.number,
});

export const ProgramTypesProps = PropTypes.shape({
  description: PropTypes.shape({
    isCustom: PropTypes.bool,
    text: PropTypes.string,
  }),
  hide: PropTypes.bool,
  name: PropTypes.string,
  programImage: NameUrlProps,
});

export const SportDetailsProps = PropTypes.shape({
  sportName: PropTypes.string,
  notes: PropTypes.string,
  stylesOfPlay: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      image: NameUrlProps,
    })
  ),
  hideSport: PropTypes.bool,
  faq: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
    })
  ),
  hero: PropTypes.shape({
    heroText: PropTypes.string,
    heroSubText: PropTypes.string,
    heroImage: PropTypes.shape({
      image: NameUrlProps,
    }),
    heroCta: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
      newTab: PropTypes.bool,
    }),
  }),
  formats: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      format: PropTypes.string,
      formatImage: NameUrlProps,
      gender: PropTypes.string,
      maxPlayersOnField: TotalsTypeProps,
      minPlayersPerGame: TotalsTypeProps,
      minTeamSize: TotalsTypeProps,
    })
  ),
  programTypes: PropTypes.arrayOf(ProgramTypesProps),
  competitionLevels: PropTypes.arrayOf(
    PropTypes.shape({
      hidden: PropTypes.bool,
      level: PropTypes.string,
      description: PropTypes.string,
    })
  ),
});

const OrgInfoProps = PropTypes.shape({
  website_url: PropTypes.string,
  html: PropTypes.string,
  prefer: PropTypes.string,
});

const OrganizationProps = PropTypes.shape({
  _id: PropTypes.string,
  name: PropTypes.string,
  logo: PropTypes.string,
  icon: PropTypes.string,
  website_url: PropTypes.string,
  city: PropTypes.string,
  seasons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      signupGoa: PropTypes.number,
      revenueGoal: PropTypes.number,
    })
  ),
  contact_email: PropTypes.string,
  contact_phone_number: PropTypes.string,
  stripe_connected: PropTypes.bool,
  brand: PropTypes.shape({
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    headerHideName: PropTypes.bool,
    headerHideContact: PropTypes.bool,
  }),
  more_information: PropTypes.shape({
    weather: OrgInfoProps,
    jerseys: OrgInfoProps,
    refunds: OrgInfoProps,
    forfeits: OrgInfoProps,
    faq: OrgInfoProps,
    about: OrgInfoProps,
    charity: OrgInfoProps,
  }),
  waiver: PropTypes.string,
  hasStripeExpressAccount: PropTypes.bool,
  hosts: PropTypes.arrayOf(CYCLIC_PROP),
  hostAdmins: PropTypes.arrayOf(CYCLIC_PROP),
  adminUserIds: PropTypes.arrayOf(PropTypes.string),
  tier: PropTypes.string,
  pendingEmails: PropTypes.arrayOf(EmailProps),
  savedEmailPictures: PropTypes.arrayOf(PropTypes.string),
  staff: PropTypes.arrayOf(PropTypes.string),
  approvedVenues: PropTypes.arrayOf(CYCLIC_PROP),
  location: PropTypes.shape({
    formatted_address: PropTypes.string,
  }),
  homepageDetails: PropTypes.shape({
    sportDefaults: PropTypes.shape({
      programTypes: PropTypes.arrayOf(DefaultSportTypesProps),
      competitionLevels: PropTypes.arrayOf(DefaultSportTypesProps),
    }),
    activeSports: PropTypes.arrayOf(SportDetailsProps),
    sportDetails: PropTypes.arrayOf(SportDetailsProps),
  }),
  heroSlides: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      slideImage: PropTypes.string,
      slideText: PropTypes.string,
      slideSubText: PropTypes.string,
      slideLink: PropTypes.string,
      slideButtonText: PropTypes.string,
      newTab: PropTypes.bool,
      secondarySlideButtonText: PropTypes.string,
      secondarySlideLink: PropTypes.string,
      secondaryNewTab: PropTypes.bool,
    })
  ),
  slideDuration: PropTypes.number,
  sponsorLogos: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      sponsorLogo: PropTypes.string,
      sponsorName: PropTypes.string,
      sponsorLink: PropTypes.string,
    })
  ),
});

export default OrganizationProps;
