/* eslint-disable react/no-array-index-key */
import classNames from 'classnames/bind';
import * as _ from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './list-styles.module.scss';

const cx = classNames.bind(styles);

const ListContainer = props => {
  const { columnTitles, columnData, numOfCol, emptyText, onClick } = props;

  if (columnTitles.length !== numOfCol) {
    return <div>Error: wrong number of column data</div>;
  }

  if (_.isEmpty(columnData)) {
    return (
      <div className="row py-3">
        <div className="col-12">{emptyText}</div>
      </div>
    );
  }

  return (
    <div className={cx('equalRows')}>
      <div className={cx('listHeader', 'row')}>
        {columnTitles.map((title, i) => (
          <div className="col-auto" key={i}>
            {title}
          </div>
        ))}
      </div>
      {columnData.map((column, j) => (
        <div
          className={cx('listRow', 'row', onClick ? 'clickable' : '')}
          onClick={() => onClick && onClick(column)}
          role="button"
          tabIndex={0}
          key={j}
        >
          {/* filter out column item that is the key (for onClick use) */}
          {column
            .filter(col => !col?.key)
            .map((item, i) => (
              <div className="col-auto my-auto" key={i}>
                {item}
              </div>
            ))}
        </div>
      ))}
      {_.isEmpty(columnData) && (
        <div className={cx('listRow', 'row')}>
          <div className="col-12">{emptyText}</div>
        </div>
      )}
    </div>
  );
};

ListContainer.propTypes = {
  columnTitles: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]))
    .isRequired,
  columnData: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]))
  ).isRequired,
  numOfCol: PropTypes.number.isRequired,
  emptyText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

ListContainer.defaultProps = {
  onClick: null,
};

export default ListContainer;
