import { type FC, useState } from 'react';
import { View, Heading, TextInput, Button, Text } from '@rivallapp/volosports-components';

import { type VoloPassPromoCodeProps } from './types';

const VoloPassPromoCode: FC<VoloPassPromoCodeProps> = ({
  updatePlanDetails,
  planDetails,
  validatePromo,
  getPromoText,
}) => {
  const [promoInput, setPromoInput] = useState('');
  const { promoData, enteringPromo } = planDetails;
  const promoText = getPromoText();

  return (
    <View marginY={4}>
      <Heading fontSize={16} color="primary.600">
        Promo Code
      </Heading>
      {enteringPromo ? (
        <View flexDirection="row" marginTop={2}>
          <TextInput
            placeholder="Enter code here"
            value={promoInput}
            onChangeText={value => setPromoInput(value)}
            marginRight={8}
            height={10}
          />
          <Button
            type="secondary"
            backgroundColor="secondary.800"
            width={120}
            height={10}
            borderRadius={3}
            isDisabled={!promoInput}
            onPress={() =>
              promoData?.valid
                ? updatePlanDetails({ promoData: null, promo: '' })
                : validatePromo(promoInput)
            }
          >
            {promoData?.valid ? 'REMOVE' : 'VERIFY'}
          </Button>
        </View>
      ) : (
        <Text fontSize={14} isLink onPress={() => updatePlanDetails({ enteringPromo: true })}>
          Enter Code
        </Text>
      )}
      {promoData?.valid && <Text>{promoText}</Text>}
      {promoData && !promoData.valid && <Text>Invalid promo code. Please try again.</Text>}
    </View>
  );
};

export default VoloPassPromoCode;
