import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { SEARCH_USER_QUERY } from './SEARCH_USERS_QUERY';
import styles from './styles.module.scss';
import { Button, Error, Loading, Popup } from '..';
import { FancyField, Radio } from '../fancyFieldsV2';
import { useActionStates } from '../../hooks';
import SearchUserQueryEnum, { SearchUserQueryTypes } from '../../shared/search-user-query-enum';
import useDebounce from '../../hooks/useDebounce';

const cx = classNames.bind(styles);

/** @deprecated Use the TS version if possible in src/shared-components/feature-components */
const SearchAndAddUsers = props => {
  const {
    noButton,
    showSearchPopup,
    buttonProps,
    popupButtonText,
    headerText,
    descriptionText,
    userButtonPressText,
    toggleSearchPopup,
    addUser,
  } = props;

  const [showPopup, setShowPopup] = useState(false);
  const [value, setValue] = useState('');
  const [which, setWhich] = useState(SearchUserQueryEnum.EMAIL);

  const { updating, setUpdating, setError } = useActionStates({ withAlerts: true });

  const search = useDebounce(value);

  const {
    data: userData,
    loading,
    error,
  } = useQuery(SEARCH_USER_QUERY, {
    skip: search === '',
    variables: {
      input: {
        search,
        which,
      },
    },
  });

  const user = userData?.getUserByInfo || {};

  const { firstName, lastName, email, gender } = user || {};

  const noUserFound = Object.keys(user).length === 0;

  const handleChange = e => {
    setValue(e);
  };

  const handleClickAction = async () => {
    try {
      setUpdating(true);
      await addUser(user._id);
    } catch (err) {
      setError(err);
    } finally {
      setUpdating(false);
    }
  };

  const renderSearchResult = () => {
    if (loading) return <Loading />;
    if (error) return <Error error={error} />;

    return noUserFound ? (
      <p>No users matching criteria.</p>
    ) : (
      <div className={cx('user-found', 'row justify-content-center')}>
        <div className="col-auto my-auto">
          {lastName}, {firstName} <span className="text-muted">({gender})</span>
        </div>
        <div className="col-auto my-auto">
          <a href={`mailto:${email}`}>{email}</a>
        </div>
        <div className="col-auto my-auto">
          <Button action onClick={handleClickAction} disabled={updating}>
            {updating ? 'Updating...' : userButtonPressText}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      {!noButton && (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Button primary {...buttonProps} onClick={() => setShowPopup(true)}>
          {popupButtonText}
        </Button>
      )}
      <Popup
        hidden={noButton ? !showSearchPopup : !showPopup}
        close={() => (noButton ? toggleSearchPopup() : setShowPopup(false))}
        className={cx('wide')}
      >
        <div className="container">
          <div className={cx('search-users-container')}>
            <h3>{headerText}</h3>
            <p className="m-3">{descriptionText}</p>
            <div className={cx('sort-by')}>
              <span className={cx('label')}>Search by:</span>
              <Radio
                className="mx-2"
                value={which === SearchUserQueryEnum.EMAIL}
                label={SearchUserQueryTypes[SearchUserQueryEnum.EMAIL].label}
                onChange={() => setWhich(SearchUserQueryEnum.EMAIL)}
              />
              <Radio
                small
                value={which === SearchUserQueryEnum.USERNAME}
                label={SearchUserQueryTypes[SearchUserQueryEnum.USERNAME].label}
                onChange={() => setWhich(SearchUserQueryEnum.USERNAME)}
              />
            </div>
            <FancyField
              key={which}
              className={cx('search-input')}
              grey
              noLabel
              placeholder={SearchUserQueryTypes[which].label}
              value={value}
              onChange={handleChange}
            />

            {renderSearchResult()}
          </div>
        </div>
      </Popup>
    </>
  );
};

SearchAndAddUsers.propTypes = {
  noButton: PropTypes.bool,
  showSearchPopup: PropTypes.bool,
  buttonProps: PropTypes.shape({}),
  popupButtonText: PropTypes.string,
  headerText: PropTypes.string,
  descriptionText: PropTypes.string,
  userButtonPressText: PropTypes.string,
  toggleSearchPopup: PropTypes.func,
  addUser: PropTypes.func,
};

SearchAndAddUsers.defaultProps = {
  noButton: false,
  buttonProps: {},
  popupButtonText: '',
  headerText: '',
  descriptionText: '',
  userButtonPressText: '',
  toggleSearchPopup: () => true,
  showSearchPopup: false,
  addUser: null,
};

export default SearchAndAddUsers;
