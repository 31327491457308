import { gql, type TypedDocumentNode } from '@apollo/client';
import type { DowShortEnum_TS } from '../../../../shared/days-of-week-enum';
import type { ShiftLogSectionStatus } from '../../../../shared/shift-log-enums';
import type { PaginationInput } from '../../../../shared/query-enums';

type GetUserShiftLogsInput = {
  id: string;
  pagination?: PaginationInput;
};

type GetUserShiftLogsResponse = {
  user: {
    _id: string;
    shiftLogs: {
      hasMore: boolean;
      totalCount: number;
      shiftLogs: {
        _id: string;
        created_at: string;
        created_by: {
          _id: string;
          fullName?: string;
        };
        day_of_week: DowShortEnum_TS;
        venue: {
          _id: string;
          shorthandName: string;
        };
        sports: string[];
        pulse_check_rating: number;
        content_capture_resolution_status?: ShiftLogSectionStatus;
        daily_programming_resolution_status?: ShiftLogSectionStatus;
        equipment_resolution_status?: ShiftLogSectionStatus;
        hosts_resolution_status?: ShiftLogSectionStatus;
        miscellaneous_resolution_status?: ShiftLogSectionStatus;
        players_resolution_status?: ShiftLogSectionStatus;
        referees_resolution_status?: ShiftLogSectionStatus;
        sponsor_bar_resolution_status?: ShiftLogSectionStatus;
        shirts_resolution_status?: ShiftLogSectionStatus;
        technology_resolution_status?: ShiftLogSectionStatus;
        venue_resolution_status?: ShiftLogSectionStatus;
      }[];
    };
  };
};

const GET_USER_SHIFT_LOGS: TypedDocumentNode<GetUserShiftLogsResponse, GetUserShiftLogsInput> = gql`
  query UserShiftLogs($id: ID!, $pagination: PaginationInput) {
    user(_id: $id) {
      _id
      shiftLogs(pagination: $pagination) {
        hasMore
        totalCount
        shiftLogs {
          _id
          created_at
          created_by {
            _id
            fullName
          }
          day_of_week
          venue {
            _id
            shorthandName
          }
          sports
          pulse_check_rating
          content_capture_resolution_status
          daily_programming_resolution_status
          equipment_resolution_status
          hosts_resolution_status
          miscellaneous_resolution_status
          players_resolution_status
          referees_resolution_status
          sponsor_bar_resolution_status
          shirts_resolution_status
          technology_resolution_status
          venue_resolution_status
        }
      }
    }
  }
`;

export default GET_USER_SHIFT_LOGS;
