import { gql } from '@apollo/client';

export const ACTIVE_VOLO_PASS_MEMBERSHIP = gql`
  query activeVoloPassMembership {
    activeVoloPassMembership {
      stripeSubscriptionId
      status
      metadata {
        organization
        organizationId
      }
      plan {
        id
        interval
      }
    }
  }
`;

export const MEMBERSHIP_GET_PROMO = gql`
  mutation membershipGetPromo($input: StripePromoInput!) {
    membershipGetPromo(input: $input) {
      id
      valid
      amount_off
      percent_off
      duration
      duration_in_months
    }
  }
`;

export const ACTIVATE_MEMBERSHIP = gql`
  mutation startVoloPassMembership($input: StartMembershipInput!) {
    startVoloPassMembership(input: $input) {
      status
    }
  }
`;

export const START_TRIAL_MEMBERSHIP = gql`
  mutation startVoloPassTrialMembership($input: StartTrialMembershipInput!) {
    startVoloPassTrialMembership(input: $input) {
      status
      planStartsAt
      planExpiresAt
      isStripeAutoRenew
    }
  }
`;

export const RESUME_MEMBERSHIP = gql`
  mutation reinstateVoloPassMembership {
    reinstateVoloPassMembership {
      status
    }
  }
`;

export const CANCEL_MEMBERSHIP = gql`
  mutation cancelVoloPassMembership {
    cancelVoloPassMembership {
      membership {
        status
      }
    }
  }
`;

export const UPGRADE_VOLO_PASS_MEMBERSHIP_TO_ANNUAL = gql`
  mutation UpgradeVoloPassMembershipToAnnual($input: StartMembershipInput!) {
    upgradeVoloPassMembershipToAnnual(input: $input) {
      userId
      stripeSubscriptionId
      stripePlanId
      stripeCustomerId
      status
      plan {
        id
        interval
      }
    }
  }
`;

export const UPDATE_USER_PLAN = gql`
  mutation ($input: CurrentUserInput) {
    updateCurrentUser(input: $input) {
      currentUser {
        _id
      }
    }
  }
`;

export const USER_QUERY = gql`
  query currentUser {
    currentUser {
      _id
      membershipExpires
      homeOrganization
      paymentSources {
        isDefault
        isExpired
        last4
        brand
      }
    }
  }
`;

export const VP_ORG_TIERS_QUERY = gql`
  query organizationList {
    organizationList {
      _id
      name
      voloPassTiers {
        isActive
        monthlyPlanId
        monthlyDollarPrice
        monthlyFee
        annualPlanId
        annualDollarPrice
        annualFee
      }
    }
  }
`;
