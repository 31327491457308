import PropTypes from 'prop-types';

const UseActionStatesProps = {
  setUpdating: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  updating: PropTypes.bool,
};

export default UseActionStatesProps;
