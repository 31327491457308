import { gql } from '@apollo/client';

export default gql`
  query programPricing($input: ProgramPricingForRegistrationInput!) {
    programPricingForRegistration(input: $input) {
      pricingBreakdown {
        memberPrice
        originalPrice
      }
    }
  }
`;
