import request from '../request';

export default {
  profilePicture: (user_id, data) =>
    request.post(`/uploads/profile/${user_id}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
  orgIcon: (org_id, data) =>
    request.post(`/uploads/icon/${org_id}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
  orgLogo: (org_id, data) =>
    request.post(`/uploads/logo/${org_id}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
  emailPhoto: (org_id, user_id, data) =>
    request.post(`/uploads/email/${org_id}/${user_id}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
  supportPhoto: (org_id, user_id, data) =>
    request.post(`/uploads/support/${org_id}/${user_id}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
  incidentReportFiles: (incident_report_id, data) =>
    request.post(`/uploads/incident-report/${incident_report_id}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
};
