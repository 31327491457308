import withStringToHtml from '../../hocs/withStringToHtml';
import withFieldFanciness from './withFieldFanciness.jsx';
import withAutoresize from './withAutoresize.jsx';
import withCurrency from './withCurrency.jsx';
import Input from './Input';

export { Checkbox, Toggle, Radio, RadioGroup, Switch } from './FancyBoolean/FancyBoolean.jsx';
export { default as Input } from './Input.jsx';
export { default as EditableText } from './EditableText.jsx';
export { default as FancyDate } from './FancyDate.jsx';
export const FancyField = withFieldFanciness(Input);
export const AutoresizeInput = withAutoresize(Input);
export const StringToHtmlInput = withStringToHtml()(Input);
export const FancyCurrency = withCurrency(FancyField);
