import { useMemo } from 'react';

/**
 * useIntlDateTimeFormat: Wrapper for the Intl.DateTimeFormat API.
 * @param params see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
 * @returns {Intl.DateTimeFormat}
 */
const useIntlDateTimeFormat = (...params: Parameters<typeof Intl.DateTimeFormat>) => {
  return useMemo(() => new Intl.DateTimeFormat(...params), [params]);
};

export default useIntlDateTimeFormat;
