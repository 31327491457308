import { createPortal } from 'react-dom';
import React from 'react';
import { PORTAL_ID } from '../../utils/constants';

type Props = {
  /**
   * ID for DOM element to portal to
   * @default 'portalId'
   * */
  portalId?: string;
  children: React.ReactNode;
};

const Portal: React.FC<Props> = ({ children, portalId = PORTAL_ID }) =>
  createPortal(children, document.getElementById(portalId) as HTMLElement);

export default Portal;
