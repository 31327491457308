import * as _ from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

const Helmet = ({ title, link, meta, children }) => (
  <>
    <ReactHelmet>
      <title>{title}</title>
      {_.map(meta, (metaDetails, i) => (
        <meta key={i} {...metaDetails} />
      ))}
      {_.map(link, (linkDetails, i) => (
        <link key={i} {...linkDetails} />
      ))}
    </ReactHelmet>
    {children}
  </>
);

Helmet.propTypes = {
  title: PropTypes.string,
  link: PropTypes.arrayOf(PropTypes.shape({})),
  meta: PropTypes.arrayOf(PropTypes.shape({})),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})]),
};

Helmet.defaultProps = {
  title:
    'Volo Sports - Largest and best Coed adult Social Sports leagues in America.  Adult sports creating free youth sports.  We play so kids play free.',
  link: [],
  meta: [],
  children: null,
};

export default Helmet;
