export const GenderEnum = Object.freeze({
  OTHER: 'o',
  FEMALE: 'f',
  MALE: 'm',
});

export const DropInSlotGenderEnum = Object.freeze({
  MALE: 'm',
  FEMALE: 'f',
  ANY: 'a',
});

export const GenderNameEnum = Object.freeze({
  [GenderEnum.MALE]: 'Male',
  [GenderEnum.FEMALE]: 'Female',
  [GenderEnum.OTHER]: 'Non-Binary',
});

export const Genders = Object.freeze({
  [GenderEnum.MALE]: {
    value: GenderEnum.MALE,
    name: 'Men',
  },
  [GenderEnum.FEMALE]: {
    value: GenderEnum.FEMALE,
    name: 'Women',
  },
  [GenderEnum.OTHER]: {
    value: GenderEnum.OTHER,
    name: 'Any',
  },
});

// this is how we save gender in a league
export const ProgramGenderEnum = Object.freeze({
  COED: 'coed',
  MEN: 'men',
  WOMEN: 'women',
  OPEN: 'open',
});

export const ProgramGenders = Object.freeze({
  [ProgramGenderEnum.COED]: {
    value: ProgramGenderEnum.COED,
    name: 'Coed',
    validGenders: [GenderEnum.OTHER, GenderEnum.FEMALE, GenderEnum.MALE],
  },
  [ProgramGenderEnum.MEN]: {
    value: ProgramGenderEnum.MEN,
    name: 'Men',
    validGenders: [GenderEnum.OTHER, GenderEnum.MALE, GenderEnum.FEMALE],
  },
  [ProgramGenderEnum.WOMEN]: {
    value: ProgramGenderEnum.WOMEN,
    name: 'Women',
    validGenders: [GenderEnum.OTHER, GenderEnum.FEMALE],
  },
  [ProgramGenderEnum.OPEN]: {
    value: ProgramGenderEnum.OPEN,
    name: 'Open',
    validGenders: [GenderEnum.OTHER, GenderEnum.FEMALE, GenderEnum.MALE],
  },
});

export const DropInGenders = Object.freeze({
  [DropInSlotGenderEnum.ANY]: {
    value: DropInSlotGenderEnum.ANY,
    name: 'Any',
    validGenders: [GenderEnum.OTHER, GenderEnum.FEMALE, GenderEnum.MALE],
  },
  [DropInSlotGenderEnum.FEMALE]: {
    value: DropInSlotGenderEnum.FEMALE,
    name: 'Women',
    validGenders: [GenderEnum.OTHER, GenderEnum.FEMALE],
  },
  [DropInSlotGenderEnum.MALE]: {
    value: DropInSlotGenderEnum.MALE,
    name: 'Men',
    validGenders: [GenderEnum.OTHER, GenderEnum.MALE],
  },
});

export const GenderCaseChange = Object.freeze({
  MALE: 'm',
  FEMALE: 'f',
  OTHER: 'o',
});

export const GenderOptions = Object.freeze([
  {
    value: 'm',
    label: 'Man',
    display: 'm',
  },
  {
    value: 'f',
    label: 'Woman',
    display: 'w',
  },
  {
    value: 'o',
    label: 'Non-Binary',
    display: 'nb',
  },
]);

export const GenderLabels = Object.freeze({
  [GenderEnum.MALE]: 'm',
  [GenderEnum.FEMALE]: 'w',
  [GenderEnum.OTHER]: 'nb',
});
