import { gql, type TypedDocumentNode } from '@apollo/client';

type MembershipTiers = {
  isActive: boolean;
  monthlyPlanId: string;
  monthlyDollarPrice: number | null;
  annualPlanId: string;
  annualDollarPrice: number | null;
};

type OrganizationData = {
  organization: {
    _id: string;
    name: string;
    voloPassTiers: MembershipTiers;
  };
};

type OrganizationInput = {
  id: string;
};

/** Returns membership plan information for a given organization.
 * Query is intentionally organized to be able to easily add new memberships as they become available
 */
const ORG_MEMBERSHIP_TIERS: TypedDocumentNode<OrganizationData, OrganizationInput> = gql`
  query organization($id: ID) {
    organization(_id: $id) {
      _id
      name
      voloPassTiers {
        isActive
        monthlyPlanId
        monthlyDollarPrice
        annualPlanId
        annualDollarPrice
      }
    }
  }
`;

export default ORG_MEMBERSHIP_TIERS;
