import { StripeMembershipIntervalEnum, StripeSubscriptionStatus } from './stripe-enums';

export enum ReferralTypeEnum {
  USER = 'user',
  CAMPAIGN = 'campaign',
}

// Constants for Volo Pass/membership trial length
export enum MembershipTrialEnum {
  TWO_WEEKS = 'TWO_WEEKS',
  FOUR_WEEKS = 'FOUR_WEEKS',
}

export const MembershipTrialDaysMap = {
  [MembershipTrialEnum.TWO_WEEKS]: 14,
  [MembershipTrialEnum.FOUR_WEEKS]: 28,
} as const;

// For a future sprint, we should consider moving this to the Organization table
// in order to accommodate per-organization commitment periods.
export const MEMBERSHIP_MINIMUM_MONTHS = 3;

export enum MembershipEnum {
  VOLO_PASS = 'VOLO_PASS',
  VOLO_FITNESS = 'VOLO_FITNESS',
  VOLO_KIDS_RECURRING = 'VOLO_KIDS_RECURRING',
}

export const Memberships = {
  [MembershipEnum.VOLO_PASS]: {
    name: 'Volo Pass',
    value: MembershipEnum.VOLO_PASS,
  },
  [MembershipEnum.VOLO_FITNESS]: {
    name: 'Volo Fitness',
    value: MembershipEnum.VOLO_FITNESS,
  },
  [MembershipEnum.VOLO_KIDS_RECURRING]: {
    name: 'Volo Kids Recurring Donation',
    value: MembershipEnum.VOLO_KIDS_RECURRING,
  },
} as const;

// #region Volo membership naming convention
// Stripe values converted to Volo names
export const MembershipInterval = Object.freeze({
  [StripeMembershipIntervalEnum.MONTH]: {
    label: 'Monthly',
    value: StripeMembershipIntervalEnum.MONTH,
  },
  [StripeMembershipIntervalEnum.YEAR]: {
    label: 'Annual',
    value: StripeMembershipIntervalEnum.YEAR,
  },
});

export const MembershipStatus = {
  [StripeSubscriptionStatus.ACTIVE]: {
    label: 'Active',
    value: StripeSubscriptionStatus.ACTIVE,
  },
  [StripeSubscriptionStatus.TRIALING]: {
    label: 'Trialing',
    value: StripeSubscriptionStatus.TRIALING,
  },
  [StripeSubscriptionStatus.INCOMPLETE]: {
    label: 'Incomplete',
    value: StripeSubscriptionStatus.INCOMPLETE,
  },
  [StripeSubscriptionStatus.INCOMPLETE_EXPIRED]: {
    label: 'Incomplete - Payment Failed',
    value: StripeSubscriptionStatus.INCOMPLETE_EXPIRED,
  },
  [StripeSubscriptionStatus.PAST_DUE]: {
    label: 'Past Due',
    value: StripeSubscriptionStatus.PAST_DUE,
  },
  [StripeSubscriptionStatus.CANCELED]: {
    label: 'Expired',
    value: StripeSubscriptionStatus.CANCELED,
  },
  [StripeSubscriptionStatus.UNPAID]: {
    label: 'Unpaid',
    value: StripeSubscriptionStatus.UNPAID,
  },
  [StripeSubscriptionStatus.PAUSED]: {
    label: 'Paused',
    value: StripeSubscriptionStatus.PAUSED,
  },
} as const;
// #endregion

export type MembershipName = (typeof Memberships)[keyof typeof Memberships]['name'];

// #region Member-Exclusive Programs
export enum MembershipPerksEnum {
  IS_VOLO_PASS_EXCLUSIVE = 'is_volo_pass_exclusive',
  IS_PREMIER = 'is_premier',
}
// #endregion
