import * as _ from 'lodash-es';

export const getPlaceholder = ({ placeholder, label, name = 'Text' }) =>
  placeholder || label || _.capitalize(_.startCase(name.replace(/_/g, ' ')));
export const getLabel = ({ label, name = 'Text', noLabel }) =>
  noLabel ? '' : label || _.startCase(name.replace(/_/g, ' '));

export default {
  getLabel,
  getPlaceholder,
};
