import * as Sentry from '@sentry/react';
import Braze from '../../../utils/braze';

type SyncUserWithServicesArgs = {
  userId?: string;
  email?: string;
};

/**
 * Currently responsible for:
 * 1. Setting the current user with Braze
 * 2. Providing user scope to Sentry
 */
const syncUserWithServices = ({ userId, email }: SyncUserWithServicesArgs) => {
  if (userId) {
    const braze = new Braze();
    braze.changeUser(userId);
    if (email) {
      Sentry.configureScope(scope => {
        scope.setUser({
          id: userId,
          email,
        });
      });
    }
  }
};

export default syncUserWithServices;
