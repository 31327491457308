import React from 'react';
import loadable from '@loadable/component';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { multiIncludes } from '../utils/url-search-helpers';

const ErrorPage = loadable(() => import('../pages/ErrorPage'));

const VanityRoutes = () => {
  const { pathname } = useLocation();

  const seasons = ['/spring', '/winter', '/fall', '/summer'];
  const seasonPath = multiIncludes(pathname.toLowerCase(), seasons) ? pathname.toLowerCase() : null;

  return (
    <Switch>
      {seasonPath && <Route exact path={seasonPath} render={() => <Redirect to="/discover" />} />}
      <Route exact path="/prom" render={() => <Redirect to="/l/6238c1de5b4396f91d4982c9" />} />
      <Route name="error" component={ErrorPage} />
    </Switch>
  );
};

export default VanityRoutes;
