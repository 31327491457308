import useDiscoverStore from '../DiscoverStore';

const get = useDiscoverStore.getState;

const formatTimeRangeForQuery = () => {
  const { filters } = get();
  const { timeLow, timeHigh } = filters;
  return {
    start: timeLow.toString(),
    end: timeHigh.toString(),
  };
};

export default formatTimeRangeForQuery;
