import React from 'react';
import PropTypes from 'prop-types';
import { isHorizontalTableDataValid } from './tableUtils';

const HorizontalTable = ({ data }) => {
  const { headers, values } = data || {};

  if (!isHorizontalTableDataValid([headers, values])) {
    return (
      <div>
        Dataset must contain the following format:
        {JSON.stringify({
          headers: [{ value: 'Header 1' }, { value: 'Header 2' }], // lengths must match
          values: [
            [1, 2, 3],
            [3, 4, 5],
          ], // lengths must match
        })}
      </div>
    );
  }

  return (
    <table className="table-defaults">
      <tbody>
        <tr>
          {headers?.map(({ value: headerValue, headerColor = '#FFFFFF' }, hvIndex) => (
            <th
              className="border-light"
              key={`headers-array-${hvIndex}`}
              style={{
                backgroundColor: headerColor,
              }}
            >
              {headerValue}
            </th>
          ))}
        </tr>
        {values?.map((valueArray, vaIndex) => (
          <tr key={`values-array-${vaIndex}`}>
            {valueArray?.map((itemValue, ivKey) => {
              const key = `${itemValue}-${ivKey}`;
              return (
                <td className="border" key={key}>
                  {itemValue}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

HorizontalTable.propTypes = {
  data: PropTypes.shape({
    headers: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        headerColor: PropTypes.string,
      })
    ),
    values: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
    ),
  }),
};

HorizontalTable.defaultProps = {
  data: {
    headers: [],
    values: [],
  },
};

export default HorizontalTable;
