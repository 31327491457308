import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import sendToSentry from './sentryErrorHandler';
import ClientEnum from '../shared/client-name-context-enum';
import { useAuthStore } from '../zustand-stores';
import { baseURL, wsURL } from '../environment';

const rivallHttpLink = new HttpLink({
  uri: `${baseURL}/graphql`,
  credentials: 'include',
});

const rivallErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      const str = `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
        locations
      )}, Path: ${path}`;
      console.error(str);
      sendToSentry(new Error(str));
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${wsURL}/websockets`,
    connectionParams: () => {
      const token = useAuthStore.getState().authToken;
      return {
        authToken: token,
      };
    },
  })
);

const rivall = ApolloLink.from([rivallErrorLink, rivallHttpLink]);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  rivall
);

/**
 * Shared type policy wherever the `getShiftLogs` resolver is used.
 */
const shiftLogsListTypePolicy = keyArgs => ({
  keyArgs,
  merge(existing, incoming, { args }) {
    const pageNum = args?.pagination?.pageNum ?? 1;
    const numPerPage = args?.pagination?.numPerPage ?? 20;
    const offset = (pageNum - 1) * numPerPage;

    const { shiftLogs: existingLogs } = existing ?? {};
    const { hasMore, shiftLogs: incomingLogs, totalCount } = incoming;

    const merged = existingLogs ? existingLogs.slice(0) : [];
    for (let i = 0; i < incomingLogs.length; i += 1) {
      merged[offset + i] = incomingLogs[i];
    }

    return {
      hasMore,
      totalCount,
      shiftLogs: merged,
    };
  },
});

const client = new ApolloClient({
  link: splitLink,
  name: ClientEnum.WEB,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          discoverProgramTypes: {
            keyArgs: ['input', ['cityName']],
            merge: true,
          },
          discoverTopNeighborhoods: {
            keyArgs: ['input', ['cityName']],
            merge: true,
          },
          discoverTopVenues: {
            keyArgs: ['input', ['cityName']],
            merge: true,
          },
          discoverNeighborhoods: {
            keyArgs: ['input', ['cityName']],
            merge: true,
          },
          discoverVenues: {
            keyArgs: ['input', ['cityName']],
            merge: true,
          },
          discoverDropInLeagues: {
            keyArgs: [
              'organizationId',
              'sports',
              'neighborhoodIds',
              'venueIds',
              'beginsRange',
              ['start', 'end'],
            ],
            merge: (existing, incoming, { args }) => {
              if (!incoming) {
                return {};
              }
              if (args.pagination.pageNum === existing?.pageNum) return existing;
              if (args.pagination.pageNum === 1) return incoming;
              const { dropInLeagues: prevLeagues } = existing ?? {};
              const { dropInLeagues, ...rest } = incoming;
              return {
                ...rest,
                pageNum: args.pagination.pageNum,
                dropInLeagues: [...(prevLeagues ?? []), ...dropInLeagues],
              };
            },
          },
          getSentEmails: {
            keyArgs: false,
            // eslint-disable-next-line @typescript-eslint/default-param-last
            merge: (existing = [], incoming, { args }) => {
              const { emailId } = args?.pagination ?? {};
              if (!emailId) {
                return incoming;
              }
              const { emails: existingEmails } = existing ?? {};
              const { emails: incomingEmails, ...rest } = incoming;
              const merged = {
                ...rest,
                emails: [...existingEmails, ...incomingEmails],
              };
              return merged;
            },
          },
          userList: {
            keyArgs: [
              'input',
              [
                'cityName',
                'dateLow',
                'dateHigh',
                'registrationOption',
                'sportNames',
                'seasonNames',
                'registrantTypes',
                'selectedPrograms',
                'teamNames',
              ],
            ],
            merge: true,
          },
          incidentReports: {
            keyArgs: [
              'input',
              [
                'timeLow',
                'timeHigh',
                'dateLow',
                'dateHigh',
                'venueIds',
                'sportNames',
                'assignedTo',
                'incidentFlags',
                'incidentTypes',
                'organizationIds',
                'resolutionStatus',
              ],
            ],
            merge: (existing, incoming, { args: { input } }) => {
              const { pageNum = 1, numPerPage = 20 } = input.pagination || {};
              const offset = (pageNum - 1) * numPerPage;
              const merged = existing ? existing.slice(0) : [];
              for (let i = 0; i < incoming.length; i += 1) {
                merged[offset + i] = incoming[i];
              }
              return merged;
            },
          },
          approvedVenuesList: {
            keyArgs: [
              'input',
              ['searchText', 'disabledOnly', 'organizationId', 'shorthandNameSearch'],
            ],
            merge: (existing, incoming, { args: { input } }) => {
              if (!existing) return incoming || [];
              const { numPerPage = 0, pageNum = 1 } = input.pagination || {};
              const offset = numPerPage * (pageNum - 1);
              const venues = existing.slice(0);
              for (let i = 0; i < incoming.length; i += 1) {
                venues[i + offset] = incoming[i];
              }
              return venues;
            },
          },
          shiftLogs: shiftLogsListTypePolicy(['$filters']),
        },
      },
      League: {
        fields: {
          signupMetrics: {
            merge: true,
          },
          // If keyArgs is `undefined`, all arguments are keyed. A value of `false` means no arguments are keyed, which is the correct behavior
          shiftLogs: shiftLogsListTypePolicy(false),
        },
      },
      User: {
        fields: {
          // If keyArgs is `undefined`, all arguments are keyed. A value of `false` means no arguments are keyed, which is the correct behavior
          shiftLogs: shiftLogsListTypePolicy(false),
        },
      },
    },
  }),
});

export default client;
