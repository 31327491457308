/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import uniq from 'lodash-es/uniq';
import uniqBy from 'lodash-es/uniqBy';
import { components } from 'react-select';
import classNames from 'classnames/bind';
import searchIcon from '../../images/v2icons/search@3x.png?as=webp';
import { colors } from '../../shared/colors-objects';
import check from '../../images/v2icons/check-grey@3x.png?as=webp';
import styles from './filterSelect.module.scss';
import getCityCrest from './getCityCrest';

import Picture from '../Picture/Picture';

const cx = classNames.bind(styles);

export const searchIconStyles = dark => ({
  borderRadius: '32px',
  padding: 0,
  width: '32px',
  height: '32px',
  backgroundColor: dark ? 'white' : colors.listFocus,
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  fontWeight: '600',
  fontSize: '16px',
  letterSpacing: '-0.225px',
});

const pinIconStyles = {
  borderRadius: '32px',
  padding: 0,
  left: '-0.3rem',
  width: '32px',
  height: '32px',
  backgroundColor: colors.primary,
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  fontWeight: '600',
  fontSize: '16px',
  letterSpacing: '-0.225px',
};

const getIcon = (iconName, dark) => {
  switch (iconName) {
    case 'search':
      return (
        <div style={searchIconStyles(dark)}>
          <img src={searchIcon} alt="search icon" />
        </div>
      );
    case 'pin':
      return (
        <div style={pinIconStyles}>
          <Picture
            src="https://rivall-public.s3-us-west-1.amazonaws.com/home-page/pin.png"
            alt="pin icon"
          />
        </div>
      );
    default:
      return null;
  }
};

const CountIndicator = ({ count, dark, skinny }) => (
  <div className={cx('counter', skinny && 'smallCounter', { dark })}>{count}</div>
);

// adds a checkbox to default Option component
const filterOption = ({ children, ...props }) => {
  const { hasValue, value, isSelected } = props;
  const showCheck = hasValue && !!value && isSelected;
  return (
    <components.Option {...props}>
      <div className="d-flex align-items-center text-left">
        <div className={cx('filterCheck', { showCheck })}>
          {showCheck && <img src={check} alt="check" />}
        </div>
        {children}
      </div>
    </components.Option>
  );
};

// renders IndicatorsContainer, ValueContainer, and an additional
// search icon under certain conditions
const filterControl = props => {
  const { children, getValue, hasValue, selectProps } = props;
  const { getOptionValue } = selectProps;
  const values = getValue().map(item => (getOptionValue ? getOptionValue(item) : item.value));
  const { length } = uniq(values);

  const { isMulti, dark, icon: iconName, inHeaderCity, skinny, hideIcon } = selectProps;

  // scenarios where we render the search icon:
  // 1) single, searchable select with clear icon disabled in all states
  // 2) single, searchable select with clear icon enabled and nothing selected
  // 3) multi, searchable select when nothing is selected
  //    -- clearable is forced true for multi selects in filterSelectProps

  const icon = length === 0 || !isMulti ? getIcon(iconName, dark) : null;

  const countIndicator =
    length > 0 && isMulti ? <CountIndicator {...{ count: length, dark, skinny }} /> : null;
  const allGetValueData = getValue();

  const crest =
    inHeaderCity && hasValue && !skinny ? (
      <div
        className={cx(styles.crest)}
        style={{
          '&:hover': {
            cursor: 'pointer',
            border: '2px solid #163352',
          },
        }}
      >
        {getCityCrest(allGetValueData[0]?.label)}
      </div>
    ) : null;

  return (
    <components.Control {...props}>
      {crest}
      {!hideIcon && icon}
      {!hideIcon && countIndicator}
      {children}
    </components.Control>
  );
};

// throws out Selects default presentation for multivalue containers, and instead
// renders a simple plural or singular label representative of the current number of
// options selected
const filterValueContainer = props => {
  const { children, getValue, hasValue, selectProps } = props;
  const { length } = getValue();
  const {
    placeholder,
    miscStyles,
    isMulti,
    showValues,
    labelSingular,
    labelPlural,
    withDropdown,
    dark,
    icon,
    grey,
  } = selectProps;

  // some inline styling for the custom label
  // this particular component feels a little hacky and will
  // probably be revisited
  // const placeholderColor = dark ? 'white' : colors.placeholder;
  let color = icon === 'search' && length === 0 ? colors.placeholder : colors.primary;
  color = grey ? '#aaaaaa' : color;
  const labelStyles = {
    margin: length === 0 && !withDropdown && !icon ? 'auto' : '0',
    padding: length > 0 ? '5px' : '0',
    fontWeight: '500',
    color: dark ? 'white' : color,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    position: 'absolute',
    tabIndex: '1',
  };

  // container displays both the Input and label side by side.
  // we read the current search value and nullify the label element
  // whenever the user starts typing
  // also nullify label for single value select with an option selected -
  // in this case we render the default SingleValue
  const currentSearch = children[1].props.value; // children[1] is Input child
  const label = length === 1 ? labelSingular : labelPlural;

  // labelText is null if
  // // currentSearch.length > 0 || (!isMulti && hasValue)
  let labelText = (
    <div style={miscStyles?.singleValue ? miscStyles?.singleValue : labelStyles}>
      {`${length === 0 && !icon && placeholder === 'Select...' ? 'All ' : ''}${
        label || placeholder || 'Select'
      }`}
    </div>
  );

  if (currentSearch.length > 0 || (!isMulti && hasValue)) labelText = null;
  if (isMulti && showValues && length > 0) {
    const { singleValue = {} } = miscStyles;
    const values = getValue();
    const [firstItem, ...rest] = uniqBy(values, ({ value }) => value);
    const { label: itemLabel } = firstItem || {};
    labelText = (
      <div style={singleValue}>
        {itemLabel}
        {rest.length > 0 ? `, +${rest.length}` : ''}
      </div>
    );
  }

  return (
    <components.ValueContainer {...props}>
      {labelText}
      {!isMulti && hasValue ? children[0] : null} {/* SingleValue */}
      {children[1]} {/* Input */}
    </components.ValueContainer>
  );
};

export default {
  Option: filterOption,
  Control: filterControl,
  ValueContainer: filterValueContainer,
};
