import cx from 'classnames';
import moment from 'moment-timezone';
import React from 'react';
import { compose, setDisplayName, withStateHandlers } from 'recompose';
import { FancyField } from './index.js';

const DateInput = props => {
  const {
    month,
    day,
    year,
    onMonthChange,
    onDayChange,
    onYearChange,
    onMonthBlur,
    monthError,
    onDayBlur,
    dayError,
    onYearBlur,
    yearError,
    error,
  } = props;

  return (
    <div className={cx('d-flex')}>
      <FancyField
        value={month}
        placeholder="MM"
        grey
        style={{ maxWidth: '50px' }}
        inputStyle={{ textAlign: 'center' }}
        type="tel"
        onChange={onMonthChange}
        onBlur={onMonthBlur}
        error={monthError || error}
        noLabel
      />
      &nbsp;
      <FancyField
        value={day}
        placeholder="DD"
        grey
        style={{ maxWidth: '50px' }}
        inputStyle={{ textAlign: 'center' }}
        type="tel"
        onChange={onDayChange}
        onBlur={onDayBlur}
        error={dayError || error}
        noLabel
      />
      &nbsp;
      <FancyField
        value={year}
        placeholder="YYYY"
        grey
        style={{ maxWidth: '70px' }}
        inputStyle={{ textAlign: 'center' }}
        type="tel"
        onChange={onYearChange}
        onBlur={onYearBlur}
        error={yearError || error}
        noLabel
      />
    </div>
  );
};

const shouldEmitUpdate = ({ month, day, year }) =>
  month.length === 2 && day.length === 2 && year.length === 4;

const DEFAULT_FORMAT = 'YY/MM/DD';

export default compose(
  setDisplayName('DateInput'),
  withStateHandlers(
    ({ value, format }) => {
      const valueMoment = moment(value, format || DEFAULT_FORMAT);
      return {
        month: value ? valueMoment.format('MM') : '',
        day: value ? valueMoment.format('DD') : '',
        year: value ? valueMoment.format('YYYY') : '',
      };
    },
    {
      onMonthChange:
        ({ day, year }, { onChange, format }) =>
        month => {
          if (month.length > 2) return {};
          if (shouldEmitUpdate({ month, day, year })) {
            const dateMoment = moment(`${month}/${day}/${year}`, 'MM/DD/YYYY');
            onChange(dateMoment.format(format || DEFAULT_FORMAT));
          }
          return { month };
        },
      onDayChange:
        ({ month, year }, { onChange, format }) =>
        day => {
          if (day.length > 2) return {};
          if (shouldEmitUpdate({ month, day, year })) {
            const dateMoment = moment(`${month}/${day}/${year}`, 'MM/DD/YYYY');
            onChange(dateMoment.format(format || DEFAULT_FORMAT));
          }
          return { day };
        },
      onYearChange:
        ({ day, month }, { onChange, format }) =>
        year => {
          if (year.length > 4) return {};
          if (shouldEmitUpdate({ month, day, year })) {
            const dateMoment = moment(`${month}/${day}/${year}`, 'MM/DD/YYYY');
            onChange(dateMoment.format(format || DEFAULT_FORMAT));
          }
          return { year };
        },
      onMonthBlur:
        ({ month }) =>
        () => ({
          monthError: month.length !== 2 || parseInt(month, 10) > 12,
        }),
      onDayBlur:
        ({ day }) =>
        () => ({
          dayError: day.length !== 2 || parseInt(day, 10) > 31,
        }),
      onYearBlur:
        ({ year }) =>
        () => ({
          yearError: year.length !== 4,
        }),
    }
  )
)(DateInput);
