import { gql, type TypedDocumentNode } from '@apollo/client';

type OrganizationData = {
  _id: string;
  name: string;
};

type OrganizationListData = {
  organizationList: OrganizationData[];
};

const ORGANIZATION_LIST_QUERY: TypedDocumentNode<OrganizationListData> = gql`
  query organizationList {
    organizationList {
      _id
      name
    }
  }
`;

export default ORGANIZATION_LIST_QUERY;
