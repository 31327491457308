/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useState } from 'react';
import useLeagueSearch, { type UseLeagueSearchArgs } from './useLeagueSearch';
import FilterSelect from '../FilterSelect';
import type { LeagueListData } from '../../hooks';
import type { FilterSelectProps } from '../FilterSelect/types';

/** League option object */
export type LeagueOption = LeagueListData['leagueList']['leagues'][number];

/** `FilterSelect` props with league option data type 😵‍💫 */
type LeagueFilterSelectProps<isMulti extends boolean> = Omit<
  FilterSelectProps<LeagueOption, isMulti>,
  'inputValue' | 'options' | 'onInputChange'
> & {
  placeholder?: string;
  leagueFilters?: UseLeagueSearchArgs['filters'];
  queryOptions?: UseLeagueSearchArgs['queryOptions'];
  minimumSearchLength?: UseLeagueSearchArgs['minimumSearchLength'];
};

const LeagueFilterSelect = <isMulti extends boolean = false>({
  border = true,
  leagueFilters,
  noOptionsMessage,
  filterOption = null,
  placeholder = 'Type or paste program name',
  queryOptions,
  onChange = () => {},
  minimumSearchLength = 4,
  ...props
}: LeagueFilterSelectProps<isMulti>) => {
  const [input, setInput] = useState('');

  const { leagues, loading, error, data } = useLeagueSearch({
    input,
    queryOptions,
    minimumSearchLength,
    filters: leagueFilters,
  });

  const handleNoOptionsMessage: NonNullable<LeagueFilterSelectProps<isMulti>['noOptionsMessage']> =
    useCallback(
      ({ inputValue }) => {
        const isRequiredLength = inputValue.length >= minimumSearchLength;
        // Handle error if it exists
        if (error) return `There was an error. ${error?.message}`;
        // If there is no input, display placeholder
        if (!isRequiredLength && inputValue.length === 0) return 'Start typing to match programs..';
        // It we haven't reached the minimum search length, display message
        if (!isRequiredLength) return 'Keep typing to match programs..';
        // If the input is still debouncing, display loading state
        if (data === undefined) return 'Loading...';
        // Here there are truly no matches
        return 'No matches found';
      },
      [error, data, minimumSearchLength]
    );

  return (
    <FilterSelect
      {...props}
      border={border}
      inputValue={input}
      isLoading={loading}
      options={leagues}
      placeholder={placeholder}
      filterOption={filterOption}
      getOptionValue={(option: LeagueOption) => option._id}
      getOptionLabel={(option: LeagueOption) => option.name}
      noOptionsMessage={noOptionsMessage || handleNoOptionsMessage}
      onInputChange={(input, { action }) => {
        if (action === 'input-change') {
          setInput(input);
        } else if (action === 'set-value' && !props.isMulti) {
          setInput(input);
        }
      }}
      onChange={(...args) => {
        onChange(...args);
        if (args[1].action === 'clear') {
          setInput('');
        }
      }}
    />
  );
};

export default LeagueFilterSelect;
