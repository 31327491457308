export const PlatformEnum = Object.freeze({
  WEB: 'web',
  IOS: 'ios',
  ANDROID: 'android',
});

export const Platforms = Object.freeze({
  [PlatformEnum.ANDROID]: {
    value: PlatformEnum.ANDROID,
    label: 'Android',
  },
  [PlatformEnum.IOS]: {
    value: PlatformEnum.IOS,
    label: 'iOS',
  },
  [PlatformEnum.WEB]: {
    value: PlatformEnum.WEB,
    label: 'Web',
  },
});
