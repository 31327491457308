import classNames from 'classnames/bind';
import * as _ from 'lodash-es';
import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose, withStateHandlers } from 'recompose';
import { ActionEnum } from '../HostNav/links';
import Alerts from './Alerts';
import styles from './styles.module.scss';
import { OverflowMenu, SearchAndAddUsers } from '../../../components';
import dashboardLinks from '../HostNav/dashboard-links';

const cx = classNames.bind(styles);

const HeaderLinks = props => {
  const {
    showMobileMenu,
    showSearchPopup,
    updateNav,
    links,
    prefix,
    showAlerts,
    location,
    isAdmin,
  } = props;

  // only pass orgId instead of all params to each page
  const query = qs.parse(location.search, { ignoreQueryPrefix: true }) || {};
  const queryOrgId = query.organization ? `?organization=${query.organization}` : '';

  return (
    <div style={{ display: 'contents' }}>
      <div className={cx('right-menu-wrap')}>
        <div
          className={cx('header-links-container', { 'show-menu': showMobileMenu })}
          onBlur={() =>
            showMobileMenu && setTimeout(() => updateNav({ showMobileMenu: false }), 250)
          }
        >
          <div
            className={cx('mobile-trigger')}
            onClick={() => updateNav({ showMobileMenu: !showMobileMenu })}
            role="link"
            tabIndex={0}
            aria-label="mobile-trigger"
          />
          <div className={cx('header-links')}>
            {_.map(links, link => (
              <HeaderLink
                key={link.path}
                prefix={prefix}
                search={queryOrgId}
                {...link}
                toggleSearchPopup={() => updateNav({ showSearchPopup: !showSearchPopup })}
                pathname={location.pathname}
                isAdmin={isAdmin}
              />
            ))}
            {showAlerts ? <Alerts {...props} /> : null}
          </div>
        </div>
      </div>
      <SearchAndAddUsers
        noButton
        playerBackgroundInfo
        showSearchPopup={showSearchPopup}
        toggleSearchPopup={() => updateNav({ showSearchPopup: !showSearchPopup })}
        headerText="Search for Player Info"
        descriptionText="Use this to quickly look up information about a player who has played in one of your leagues."
        userButtonPressText="Lookup"
      />
    </div>
  );
};

const HeaderLink = props => {
  const {
    icon,
    path,
    title,
    prefix,
    toggleSearchPopup,
    pathname,
    search = '',
    action,
    isAdmin,
  } = props;

  const linkTo = prefix ? `/${prefix}/${path}${search}` : `/${path}${search}`;
  const active = pathname === linkTo || _.includes(pathname, `${linkTo}/`);

  if (action === ActionEnum.DASHBOARD_DROPDOWN) {
    return (
      <React.Fragment>
        <Link
          className={cx('header-link', _.split(path, '/').pop(), 'pr-2', {
            active,
            'is-icon': !!icon,
          })}
          to={linkTo}
          alt={title}
          onClick={() => path !== 'searchUser' && toggleSearchPopup}
        >
          {icon ? <img src={icon} alt={title} /> : title}
        </Link>
        <OverflowMenu
          options={_.map(
            _.sortBy(dashboardLinks(isAdmin), 'title'),
            ({ pathname: to, title: label }) => ({
              label,
              to: `${to}${search}`,
            })
          )}
          buttonType="verticalDots"
        />
      </React.Fragment>
    );
  }

  return (
    <Link
      className={cx('header-link', _.split(path, '/').pop(), { active, 'is-icon': !!icon })}
      to={linkTo}
      alt={title}
      onClick={() => path !== 'searchUser' && toggleSearchPopup}
    >
      {icon ? <img src={icon} alt={title} /> : title}
    </Link>
  );
};

HeaderLink.propTypes = {
  icon: PropTypes.string,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  toggleSearchPopup: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  action: PropTypes.string,
  isAdmin: PropTypes.bool,
};

HeaderLink.defaultProps = {
  icon: null,
  prefix: null,
  action: '',
  isAdmin: false,
};

HeaderLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      path: PropTypes.string,
    })
  ).isRequired,
  prefix: PropTypes.string,
  showAlerts: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string,
  }).isRequired,
  showMobileMenu: PropTypes.bool.isRequired,
  showSearchPopup: PropTypes.bool.isRequired,
  updateNav: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
};

HeaderLinks.defaultProps = {
  prefix: null,
  showAlerts: false,
  isAdmin: false,
};

export default compose(
  withRouter,
  withStateHandlers(
    () => ({
      showMobileMenu: false,
      showSearchPopup: false,
      shouldFade: false,
    }),
    {
      updateNav: state => update => ({
        ...state,
        ...update,
      }),
    }
  )
)(HeaderLinks);
