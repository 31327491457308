import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../apps/Anon/HomepageV2/Footer';
import VoloApp from '../apps/Anon/HomepageV2/VoloApp';
import { Button } from '../components';
import goal from '../images/footballgoalnew.jpg';
import mobileGoal from '../images/footballmobile.jpg';
import errorStyles from './errorpage.module.scss';

const ErrorPage = () => (
  <>
    {/* Large/XLarge lg xl */}
    <div className={errorStyles.body}>
      <div className="container d-none d-lg-block d-xl-block">
        <div className="row">
          <div className="col-lg-3 col-md-3 sm-3" />
          <div className="col-lg-3 col-md-3 sm-3" />
          <div className="col-lg-6 col-md-6 sm-3 ml-0 pl-0">
            <div className={errorStyles.ballDropped}>
              <span>We dropped the ball.</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 d-none d-sm-block">
            <div className={errorStyles.goalStyle}>
              <img src={goal} alt="goal icon" />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-3 sm-3" />
          <div className="col-lg-3 col-md-3 sm-3" />
          <div className="col-lg-4 col-md-4 sm-3 ml-0 pl-0 d-none d-sm-block">
            <div className={errorStyles.errorMsg}>
              This page doesn&apos;t exist or it moved somewhere else. We suggest you go to the
              homepage or find a program you like.
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-xl-3" />
            <div className="col-lg-3 col-xl-3 pl-xl-4 pr-xl-0 d-none d-lg-block xl-block">
              <Link to="/">
                <Button className={errorStyles.blueButton}>Homepage</Button>
              </Link>
            </div>
            <div className="col-lg-3 col-xl-3 pr-xl-4 pl-xl-0 d-none d-lg-block xl-block">
              <Link to="/discover">
                <Button
                  className={errorStyles.blueButton}
                  style={{ color: '#0a3355', background: '#ffffff' }}
                >
                  Find a program
                </Button>
              </Link>
            </div>
            <div className="col-lg-3 col-xl-3" />
          </div>
        </div>
      </div>
    </div>

    {/* Medium md */}
    <div className={errorStyles.body}>
      <div className="container d-none d-md-block d-lg-none">
        <div className="row">
          <div className="col-5" />
          <div className="col-7 ml-0 pl-0">
            <div className={errorStyles.ballDropped}>
              <span>We dropped the ball.</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 d-none d-sm-block ">
            <div className={errorStyles.goalStyle}>
              <img src={goal} alt="goal icon" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-3 sm-4" />
          <div className="col-lg-4 col-md-6 sm-4 d-none d-sm-block">
            <div className={errorStyles.errorMsg}>
              This page doesn&apos;t exist or it moved somewhere else. We suggest you go to the
              homepage or find a program you like.
            </div>
          </div>
          <div className="col-lg-4 col-md-3 sm-4" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-2" />
            <div className="col-4 mt-1">
              <Link to="/">
                <Button className={errorStyles.blueButton}>Homepage</Button>
              </Link>
            </div>
            <div className="col-4 mt-1">
              <Link to="/discover">
                <Button
                  className={errorStyles.blueButton}
                  style={{ color: '#0a3355', background: '#ffffff' }}
                >
                  Find a program
                </Button>
              </Link>
            </div>
            <div className="col-2" />
          </div>
        </div>
      </div>
    </div>

    {/* Small sm */}
    <div className={errorStyles.body}>
      <div className="container d-none d-sm-block d-md-none">
        <div className="row">
          <div className="col-12">
            <div className={errorStyles.ballDropped}>
              <span>We dropped the ball.</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className={errorStyles.goalStyle}>
              <img src={goal} alt="goal icon" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12" />
          <div className="col-lg-3 col-md-4 sm-3" />
          <div className="col-lg-4 col-md-3 sm-3 ml-0 pl-0 d-none d-sm-block">
            <div className={errorStyles.errorMsg}>
              This page doesn&apos;t exist or it moved somewhere else. We suggest you go to the
              homepage or find a program you like.
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-1" />
          <div className="col-5 mt-3 mb-1">
            <Link to="/">
              <Button className={errorStyles.blueButton}>Homepage</Button>
            </Link>
          </div>
          <div className="col-6 mt-3 mb-1">
            <Link to="/discover">
              <Button
                className={errorStyles.blueButton}
                style={{ color: '#0a3355', background: '#ffffff' }}
              >
                Find a program
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>

    {/* Mobile xs */}
    <div className={errorStyles.body}>
      <div className="d-block d-sm-none">
        <div className="row">
          <div className="col-12">
            <div className={errorStyles.ballDroppedXSm}>
              <span>We dropped the ball.</span>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="col-12">
            <div className="mobileGoal">
              <img src={mobileGoal} alt="goal icon" />
            </div>
            <div className={errorStyles.errorMsgXSm}>
              <p>
                This page doesn&apos;t exist or it moved somewhere else. We suggest you go to the
                homepage or find a program you like.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-1 pr-0" />
          <div className="col-sm-5 col-xs-6 pl-0 pr-0">
            <Link to="/">
              <Button className={errorStyles.blueButton}>Homepage</Button>
            </Link>
          </div>
          <div className="col-sm-5 col-xs-6 pl-0 pr-0">
            <Link to="/discover">
              <Button
                className={errorStyles.blueButton}
                style={{ color: '#0a3355', background: '#ffffff' }}
              >
                Find a program
              </Button>
            </Link>
          </div>
          <div className="col-sm-1 pr-0" />
        </div>
      </div>
    </div>
    <div>
      <VoloApp hideApp />
      <Footer />
    </div>
  </>
);

export default ErrorPage;
