import { gql } from '@apollo/client';

const DropinPricingBreakdownFragment = gql`
  fragment dropinPricingBreakdownFragment on PricingBreakdown {
    priceCents
    promoDiscountCents
    supportFeeCents
    processingFeeCents
    processingFeePercentage
    techFeeCents
    totalFeeCents
    donationAmount
    totalCents
    totalChargeCents
  }
`;

const DROPIN_PRICING = gql`
  query dropinPricingForRegistration($input: DropinPricingForRegistrationInput!) {
    dropinPricingForRegistration(input: $input) {
      nonmemberBreakdown {
        ...dropinPricingBreakdownFragment
      }
      memberBreakdown {
        ...dropinPricingBreakdownFragment
      }
      currentUserPaymentCriteria
    }
  }
  ${DropinPricingBreakdownFragment}
`;

export default DROPIN_PRICING;
