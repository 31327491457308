export default (isStaff, isHost) => {
  const links = [];

  if (isHost) {
    links.push({
      path: 'rfo/dashboard',
      title: 'Admin Dashboard',
    });
  }

  links.push({
    path: 'app/dashboard',
    title: isHost ? 'Player Dashboard' : 'Dashboard',
  });

  if (isStaff) {
    links.push({
      path: 'staff',
      title: 'Staff Portal',
    });
  }

  links.push({
    path: 'discover',
    title: 'Discover',
  });

  // links.push({
  //  title: 'SEARCH'
  // })

  return links;
};
