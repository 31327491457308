import { graphql } from '@apollo/client/react/hoc';
import * as _ from 'lodash-es';
import qs from 'qs';
import { withRouter } from 'react-router-dom';
import { branch, compose, withProps } from 'recompose';
import CURRENT_USER_QUERY from './CURRENT_USER_QUERY';
import RoleEnum from '../../shared/role-enum';

const withCurrentUser = () => WrappedComponent =>
  compose(
    withRouter,
    graphql(CURRENT_USER_QUERY, {
      options: () => ({
        fetchPolicy: 'network-only',
      }),
      props: ({ data: { loading, currentUser } }) => ({
        loading,
        currentUser,
      }),
    }),
    branch(
      ({ loading }) => loading,
      () => () => null
    ),
    withProps(props => {
      const { currentUser, history, organizationId: pastOrgId, loading } = props;

      if (!loading && !currentUser)
        return {
          isAuthenticated: false,
        };

      const query = qs.parse(_.get(history, 'location.search'), { ignoreQueryPrefix: true });
      let organizationId =
        _.get(currentUser, 'organizationId') ||
        pastOrgId ||
        _.get(currentUser, 'organizations.0._id');
      const queryOrgId = _.get(query, 'organization') || organizationId;

      if (_.isEmpty(queryOrgId) || queryOrgId === organizationId) {
        return {
          currentUser: {
            ...currentUser,
            organizationId,
          },
          organizationId,
          isAuthenticated: !!currentUser,
          roles: _.get(currentUser, 'roles'),
        };
      }

      // will add more checks as permissions grow
      const { roles, organizations } = currentUser || {};

      const orgIds = _.map(organizations, ({ _id }) => _id);
      // city admin
      if (_.includes(roles, RoleEnum.OWNER) && _.includes(orgIds, queryOrgId)) {
        organizationId = queryOrgId;
      }
      // super admin
      if (_.includes(roles, RoleEnum.ADMIN)) {
        organizationId = queryOrgId;
      }

      return {
        currentUser: {
          ...currentUser,
          organizationId,
        },
        organizationId,
        isAuthenticated: !!currentUser,
        roles: _.get(currentUser, 'roles'),
      };
    })
  )(WrappedComponent);

export default withCurrentUser;
