import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import InputSlider from './InputSlider';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

class TimePicker extends Component {
  constructor(props) {
    super(props);
    this.changeHours = this.changeHours.bind(this);
    this.changeMinutes = this.changeMinutes.bind(this);
  }

  changeHours(pos) {
    const m = this.props.moment;
    m.hours(parseInt(pos.x, 10));
    this.props.onChange(m);
  }

  changeMinutes(pos) {
    const m = this.props.moment;
    const adjusted = Math.min(Math.round(parseInt(pos.x, 10) / 5) * 5, 59);
    m.minutes(adjusted);
    this.props.onChange(m);
  }

  render() {
    const m = this.props.moment;

    return (
      <div className={cx('m-time', this.props.className)}>
        <div className="showtime">
          <span className={cx('time')}>{m.format('hh')}</span>
          <span className={cx('separater')}>:</span>
          <span className={cx('time')}>{m.format('mm')}</span>
          <span className={cx('ampm')}>{m.format('a z')}</span>
        </div>

        <div className={cx('sliders')}>
          <div className={cx('time-text')}>Hours:</div>
          <InputSlider
            className={cx('u-slider-time')}
            xmin={0}
            xmax={23}
            x={m.hour()}
            onChange={this.changeHours}
          />
          <div className={cx('time-text')}>Minutes:</div>
          <InputSlider
            className={cx('u-slider-time')}
            xmin={0}
            xmax={59}
            x={m.minute()}
            onChange={this.changeMinutes}
          />
        </div>
      </div>
    );
  }
}

export default TimePicker;
