/* eslint-disable no-unused-vars */
/* LeagueRoute */
// DEPRECATED
/* Handles redirects for landing pages (registration). */
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useAuthStore } from '../zustand-stores';

const getRedirect = ({ isAuthenticated, pathname, id, leagueId }) => {
  if (!id && !leagueId) {
    return '/not-found';
  }

  // if its an invite page, go to the group landing page
  if (pathname.includes('invite')) {
    return `/g/${id}`;
  }

  if (isAuthenticated && pathname.includes('/d/')) {
    return `/d/${leagueId}`;
  }

  // otherwise go to the league landing page
  if (!isAuthenticated && pathname.includes('register')) {
    return `/l/${id || leagueId}`;
  }

  return null;
};

// todo: this is a quick fix for ppl who land on league pages without the step
// eventually registration should just have its own router
export const RedirectToStepRoute = routeProps => (
  <Route
    {...routeProps}
    render={({ location }) => <Redirect to={`${location.pathname}/start`} />}
  />
);

const LeagueRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useAuthStore(store => store.isAuthenticated);

  const render = props => {
    const { location, match } = props;
    const { pathname } = location;
    const { id, leagueId } = match.params || {};

    const redirectTo = getRedirect({ isAuthenticated, pathname, id, leagueId });

    if (!redirectTo) return <Component {...props} />;

    return <Redirect to={redirectTo} />;
  };

  return <Route {...rest} render={render} />;
};

LeagueRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
};

export default LeagueRoute;
