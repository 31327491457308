import classNames from 'classnames/bind';
import { detect } from 'detect-browser';
import React from 'react';
import { branch, compose, withStateHandlers } from 'recompose';
import styles from './ie-worst.module.scss';
import Button from '../../components/Button';

const cx = classNames.bind(styles);

const IeIsTheWorst = ({ dismiss }) => (
  <div className={cx('ie-warning')}>
    It looks like you&apos;re using Internet Explorer. &nbsp;We can&apos;t guarantee a good
    experience on IE and strongly suggest using Chrome instead. &nbsp;Have questions? Contact us at{' '}
    <a href="mailto:info@volosports.com">info@volosports.com</a>.
    <div>
      <Button default small onClick={dismiss}>
        Dismiss
      </Button>
    </div>
  </div>
);

// uncomment the cookie logic if we want to persist the dismissal.
// for now we just really want to bother people.
export default compose(
  // withCookies,
  branch(
    () => {
      const browser = detect();
      return !browser || browser.bot || browser.name !== 'ie';
    },
    () => () => null
  ),
  withStateHandlers(
    () => ({
      shown: true, //! cookies.get('ieWarningWasDismissed'),
    }),
    {
      dismiss: () => () => ({ shown: false }),
      // cookies.set('ieWarningWasDismissed', 'true', {
      //   maxAge: 3 * 24 * 60 * 60, // 3 days in seconds
      // });
    }
  ),
  branch(
    ({ shown }) => !shown,
    () => () => null
  )
)(IeIsTheWorst);
