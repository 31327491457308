export enum SportSurfaceEnum {
  COURT = 'court',
  FIELD = 'field',
  LANE = 'lane',
  RENTAL = 'rental',
  SPACE = 'space',
}

export enum ActivityEnum {
  AXE_THROWING = 'AXE_THROWING',
  BADMINTON = 'BADMINTON',
  BAR_GAMES = 'BAR_GAMES',
  BAR_OLYMPICS = 'BAR_OLYMPICS',
  BASKETBALL = 'BASKETBALL',
  BINGO = 'BINGO',
  BOARD_GAMES = 'BOARD_GAMES',
  BOCCE = 'BOCCE',
  BOOTCAMP = 'BOOTCAMP',
  BOWLING = 'BOWLING',
  BROOMBALL = 'BROOMBALL',
  CLASS = 'CLASS',
  CALL_OF_DUTY = 'CALL_OF_DUTY',
  CLIMBING = 'CLIMBING',
  CORNHOLE = 'CORNHOLE',
  DARTS = 'DARTS',
  DODGEBALL = 'DODGEBALL',
  EVENT = 'EVENT',
  E_SPORTS = 'E_SPORTS',
  FIELD_HOCKEY = 'FIELD_HOCKEY',
  FIFA = 'FIFA',
  FOOD = 'FOOD',
  FLAG_FOOTBALL = 'FLAG_FOOTBALL',
  FLIP_CUP = 'FLIP_CUP',
  FUTSAL = 'FUTSAL',
  GAME_SHOW = 'GAME_SHOW',
  GOLF = 'GOLF',
  HAPPY_HOUR = 'HAPPY_HOUR',
  HOCKEY = 'HOCKEY',
  HIKING = 'HIKING',
  JACKBOX = 'JACKBOX',
  KANJAM = 'KANJAM',
  KICKBALL = 'KICKBALL',
  LACROSSE = 'LACROSSE',
  LASER_TAG = 'LASER_TAG',
  MADDEN = 'MADDEN',
  MARIO_KART = 'MARIO_KART',
  MEDICAL = 'MEDICAL',
  MINI_GOLF = 'MINI_GOLF',
  NHL = 'NHL',
  PICKLEBALL = 'PICKLEBALL',
  PING_PONG = 'PING_PONG',
  ROCKET_LEAGUE = 'ROCKET_LEAGUE',
  SAILING = 'SAILING',
  SHUFFLE_BOARD = 'SHUFFLE_BOARD',
  SKEEBALL = 'SKEEBALL',
  SKIING = 'SKIING',
  SMASH = 'SMASH',
  SQUASH = 'SQUASH',
  SOCCER = 'SOCCER',
  SOFTBALL = 'SOFTBALL',
  SPIN = 'SPIN',
  STREET_HOCKEY = 'STREET_HOCKEY',
  TENNIS = 'TENNIS',
  TESTING = 'TESTING',
  TRANSPORTATION = 'TRANSPORTATION',
  TRIP = 'TRIP',
  TRIVIA = 'TRIVIA',
  ULTIMATE_FRISBEE = 'ULTIMATE_FRISBEE',
  VOLLEYBALL = 'VOLLEYBALL',
  VOLUNTEER = 'VOLUNTEER',
  YOGA = 'YOGA',

  // Note: as of 4/9/19, the below types are not present in the sports collection
  //  in the database, so no leagues can be assigned as these.
  BASEBALL = 'BASEBALL',
  FOOTBALL = 'FOOTBALL',
  LAWN_BOWLING = 'LAWN_BOWLING',
  RACE = 'RACE',
}

export const Activities = Object.freeze({
  [ActivityEnum.AXE_THROWING]: {
    name: 'Axe Throwing',
    value: 'axe_throwing',
    surface: SportSurfaceEnum.RENTAL,
  },
  [ActivityEnum.BADMINTON]: {
    name: 'Badminton',
    value: 'badminton',
    surface: SportSurfaceEnum.COURT,
  },
  [ActivityEnum.BAR_GAMES]: {
    name: 'Bar Games',
    value: 'bar_games',
    surface: undefined,
  },
  [ActivityEnum.BAR_OLYMPICS]: {
    name: 'Bar Olympics',
    value: 'bar_olympics',
    surface: undefined,
  },
  [ActivityEnum.BASKETBALL]: {
    name: 'Basketball',
    value: 'basketball',
    surface: SportSurfaceEnum.COURT,
  },
  [ActivityEnum.BINGO]: {
    name: 'Bingo',
    value: 'bingo',
    surface: undefined,
  },
  [ActivityEnum.BOARD_GAMES]: {
    name: 'Board Games',
    value: 'board_games',
    surface: undefined,
  },
  [ActivityEnum.BOCCE]: {
    name: 'Bocce',
    value: 'bocce',
    surface: SportSurfaceEnum.COURT,
  },
  [ActivityEnum.BOOTCAMP]: {
    name: 'Bootcamp',
    value: 'bootcamp',
    surface: SportSurfaceEnum.SPACE,
  },
  [ActivityEnum.BOWLING]: {
    name: 'Bowling',
    value: 'bowling',
    surface: SportSurfaceEnum.LANE,
  },
  [ActivityEnum.BROOMBALL]: {
    name: 'Broomball',
    value: 'broomball',
    surface: undefined,
  },
  [ActivityEnum.CLASS]: {
    name: 'Class',
    value: 'class',
    surface: undefined,
  },
  [ActivityEnum.CALL_OF_DUTY]: {
    name: 'Call of Duty',
    value: 'cod',
    surface: undefined,
  },
  [ActivityEnum.CLIMBING]: {
    name: 'Climbing',
    value: 'climbing',
    surface: undefined,
  },
  [ActivityEnum.CORNHOLE]: {
    name: 'Cornhole',
    value: 'cornhole',
    surface: SportSurfaceEnum.RENTAL,
  },
  [ActivityEnum.DARTS]: {
    name: 'Darts',
    value: 'darts',
    surface: undefined,
  },
  [ActivityEnum.DODGEBALL]: {
    name: 'Dodgeball',
    value: 'dodgeball',
    surface: SportSurfaceEnum.COURT,
  },
  [ActivityEnum.E_SPORTS]: {
    name: 'E-Sports',
    value: 'e_sports',
    surface: undefined,
  },
  [ActivityEnum.EVENT]: {
    name: 'Event',
    value: 'event',
    surface: SportSurfaceEnum.SPACE,
  },
  [ActivityEnum.FIELD_HOCKEY]: {
    name: 'Field Hockey',
    value: 'field_hockey',
    surface: SportSurfaceEnum.FIELD,
  },
  [ActivityEnum.FIFA]: {
    name: 'Fifa',
    value: 'fifa',
    surface: undefined,
  },
  [ActivityEnum.FOOD]: {
    name: 'Food',
    value: 'food',
    surface: undefined,
  },
  [ActivityEnum.FLAG_FOOTBALL]: {
    name: 'Flag Football',
    value: 'flag_football',
    surface: SportSurfaceEnum.FIELD,
  },
  [ActivityEnum.FLIP_CUP]: {
    name: 'Flip Cup',
    value: 'flip_cup',
    surface: undefined,
  },
  [ActivityEnum.FUTSAL]: {
    name: 'Futsal',
    value: 'futsal',
    surface: SportSurfaceEnum.FIELD,
  },
  [ActivityEnum.GAME_SHOW]: {
    name: 'Game Show',
    value: 'game_show',
    surface: undefined,
  },
  [ActivityEnum.GOLF]: {
    name: 'Golf',
    value: 'golf',
    surface: undefined,
  },
  [ActivityEnum.HAPPY_HOUR]: {
    name: 'Happy Hour',
    value: 'happy_hour',
    surface: SportSurfaceEnum.SPACE,
  },
  [ActivityEnum.HIKING]: {
    name: 'Hiking',
    value: 'hiking',
    surface: undefined,
  },
  [ActivityEnum.HOCKEY]: {
    name: 'Hockey',
    value: 'hockey',
    surface: undefined,
  },
  [ActivityEnum.JACKBOX]: {
    name: 'Jackbox',
    value: 'jackbox',
    surface: undefined,
  },
  [ActivityEnum.KANJAM]: {
    name: 'KanJam',
    value: 'kanjam',
    surface: SportSurfaceEnum.RENTAL,
  },
  [ActivityEnum.KICKBALL]: {
    name: 'Kickball',
    value: 'kickball',
    surface: SportSurfaceEnum.FIELD,
  },
  [ActivityEnum.LACROSSE]: {
    name: 'Lacrosse',
    value: 'lacrosse',
    surface: SportSurfaceEnum.FIELD,
  },
  [ActivityEnum.LASER_TAG]: {
    name: 'Laser Tag',
    value: 'laser_tag',
    surface: SportSurfaceEnum.SPACE,
  },
  [ActivityEnum.MADDEN]: {
    name: 'Madden',
    value: 'madden',
    surface: undefined,
  },
  [ActivityEnum.MARIO_KART]: {
    name: 'Mario Kart',
    value: 'mario_kart',
    surface: undefined,
  },
  [ActivityEnum.MEDICAL]: {
    name: 'Medical',
    value: 'medical',
    surface: undefined,
  },
  [ActivityEnum.MINI_GOLF]: {
    name: 'Mini Golf',
    value: 'mini_golf',
    surface: undefined,
  },
  [ActivityEnum.NHL]: {
    name: 'NHL',
    value: 'nhl',
    surface: undefined,
  },
  [ActivityEnum.PICKLEBALL]: {
    name: 'Pickleball',
    value: 'pickleball',
    surface: SportSurfaceEnum.COURT,
  },
  [ActivityEnum.PING_PONG]: {
    name: 'Ping Pong',
    value: 'ping_pong',
    surface: undefined,
  },
  [ActivityEnum.ROCKET_LEAGUE]: {
    name: 'Rocket League',
    value: 'rocket_league',
    surface: undefined,
  },
  [ActivityEnum.SAILING]: {
    name: 'Sailing',
    value: 'sailing',
    surface: undefined,
  },
  [ActivityEnum.SHUFFLE_BOARD]: {
    name: 'Shuffle Board',
    value: 'shuffle_board',
    surface: undefined,
  },
  [ActivityEnum.SKEEBALL]: {
    name: 'Skeeball',
    value: 'skeeball',
    surface: undefined,
  },
  [ActivityEnum.SKIING]: {
    name: 'Skiing',
    value: 'skiing',
    surface: undefined,
  },
  [ActivityEnum.SOCCER]: {
    name: 'Soccer',
    value: 'soccer',
    surface: SportSurfaceEnum.FIELD,
  },
  [ActivityEnum.SOFTBALL]: {
    name: 'Softball',
    value: 'softball',
    surface: SportSurfaceEnum.FIELD,
  },
  [ActivityEnum.SQUASH]: {
    name: 'Squash',
    value: 'squash',
    surface: SportSurfaceEnum.COURT,
  },
  [ActivityEnum.SPIN]: {
    name: 'Spin',
    value: 'spin',
    surface: SportSurfaceEnum.SPACE,
  },
  [ActivityEnum.STREET_HOCKEY]: {
    name: 'Street Hockey',
    value: 'street_hockey',
    surface: undefined,
  },
  [ActivityEnum.SMASH]: {
    name: 'Super Smash Bros',
    value: 'smash',
    surface: undefined,
  },
  [ActivityEnum.TENNIS]: {
    name: 'Tennis',
    value: 'tennis',
    surface: SportSurfaceEnum.COURT,
  },
  [ActivityEnum.TESTING]: {
    name: 'Testing',
    value: 'testing',
    surface: undefined,
  },
  [ActivityEnum.TRANSPORTATION]: {
    name: 'Transportation',
    value: 'transportation',
    surface: undefined,
  },
  [ActivityEnum.TRIP]: {
    name: 'Trip',
    value: 'trip',
    surface: undefined,
  },
  [ActivityEnum.TRIVIA]: {
    name: 'Trivia',
    value: 'trivia',
    surface: undefined,
  },
  [ActivityEnum.ULTIMATE_FRISBEE]: {
    name: 'Ultimate Frisbee',
    value: 'ultimate_frisbee',
    surface: SportSurfaceEnum.FIELD,
  },
  [ActivityEnum.VOLLEYBALL]: {
    name: 'Volleyball',
    value: 'volleyball',
    surface: SportSurfaceEnum.COURT,
  },
  [ActivityEnum.VOLUNTEER]: {
    name: 'Volunteer',
    value: 'volunteer',
    surface: undefined,
  },
  [ActivityEnum.YOGA]: {
    name: 'Yoga',
    value: 'yoga',
    surface: SportSurfaceEnum.SPACE,
  },
} as const);

export const ActivityNamesEnum = Object.values(Activities).map(({ name }) => name);
export type TActivityName = (typeof ActivityNamesEnum)[number];

export const ActivityValuesEnum = Object.values(Activities).map(({ value }) => value);
export type TActivityValue = (typeof ActivityValuesEnum)[number];

/**
 * Enum of Allowed Rental Activities
 * This list populates based on which activities have the `surface` field filled out.
 *
 * Note: while the contents of the array are as expected, the type still includes activities
 * that don't have a surface field.
 */
export const RentalActivitiesEnum = Object.values(Activities).filter(activity =>
  Boolean(activity.surface)
);

/**
 * Union of activity names that are valid rental activities (i.e. have a `surface` field)
 */
export type TRentalActivityName = {
  [K in keyof typeof Activities]: 'surface' extends keyof (typeof Activities)[K]
    ? (typeof Activities)[K]['name']
    : never;
}[keyof typeof Activities];

export type TRentalActivityValue = {
  [K in keyof typeof Activities]: 'surface' extends keyof (typeof Activities)[K]
    ? (typeof Activities)[K]['value']
    : never;
}[keyof typeof Activities];

/**
 * Array of all valid rental activity names/values
 */
export const RentalActivityNamesEnum: TRentalActivityName[] = Object.values(
  RentalActivitiesEnum
).map(({ name }) => name as TRentalActivityName);

export const RentalActivityValuesEnum: TRentalActivityValue[] = Object.values(
  RentalActivitiesEnum
).map(({ value }) => value as TRentalActivityValue);

export const getActivityNameFromValue = (value: TActivityValue): TActivityName => {
  const activity = Object.values(Activities).find(activity => activity.value === value);

  // with typescript, this error should never happen
  if (!activity) {
    throw new Error(`Activity with value ${value} not found`);
  }

  return activity.name;
};

export const getValueFromActivityName = (activityName: TActivityName): TActivityValue => {
  const activity = Object.values(Activities).find(({ name }) => name === activityName);

  // with typescript, this error should never happen
  if (!activity) {
    throw new Error(`Activity with value ${activityName} not found`);
  }

  return activity.value;
};

export const getSurfaceFromActivityName = (
  activity: TRentalActivityName
): SportSurfaceEnum | undefined => {
  const activityObj = Object.values(Activities).find(activityObj => activityObj.name === activity);

  if (!activityObj || !('surface' in activityObj)) return undefined;

  return activityObj.surface;
};
