/* eslint-disable no-process-env */
export const apiEndpoint = '/api';
export const baseURL = process.env.REACT_APP_BASE_URL;
export const apiURL = process.env.REACT_APP_API_URL;
export const wsURL = process.env.REACT_APP_WS_URL;
export const siteUrl = process.env.REACT_APP_SITE_URL;
export const brazeEndpoint = process.env.REACT_APP_BRAZE_ENDPOINT;
export const brazeKey = process.env.REACT_APP_BRAZE_KEY;
export const fbAppId = process.env.REACT_APP_FACEBOOK_APP_ID;
export const fbClientId = process.env.REACT_APP_FACEBOOK_CLIENT_ID;
export const fbAppSecret = process.env.REACT_APP_FACEBOOK_APP_SECRET;
export const froalaKey = process.env.REACT_APP_FROALA_KEY;
export const fullCalendarKey = process.env.REACT_APP_FULLCALENDAR_KEY;
export const mapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const isLocal = process.env.REACT_APP_IS_LOCAL;
export const membershipPlanId = process.env.REACT_APP_MEMBERSHIP_PLAN_ID;
export const reCaptchaApiKey = process.env.REACT_APP_RECAPTCHA_API_SECRET_KEY;
export const reCaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_CLIENT_SITE_KEY;
export const remoteEnv = process.env.REACT_APP_REMOTE_ENV; // undefined on local
export const sentryDSN = process.env.REACT_APP_SENTRY_DSN;
export const stripeClientId = process.env.REACT_APP_STRIPE_CLIENT_ID;
export const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const isClient = typeof window !== 'undefined';
export const isDebug = process.env.NODE_ENV === 'development';
export const isProduction = process.env.REACT_APP_CLIENT_ENV === 'production';
export const ROOT_ELEMENT_ID = 'root';
export const WINDOW_APOLLO_STATE_KEY = '__APOLLO_STATE__';
export const WINDOW_INITIAL_STATE_KEY = '__INITIAL_STATE__';
