import { sessionAPI } from '../../../utils/api';
import resetState from './resetState';

/**
 * Action to logout the current user.
 *
 * 1. Calls the logout endpoint to clear user's authentication
 * 2. Resets the auth store to it's initial values
 */
const logout = async () => {
  // Clears user's authentication
  await sessionAPI.logOut();
  // Resets user's state
  resetState();
};

export default logout;
