import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { DISCOVER_VENUES, DISCOVER_TOP_VENUES } from './FILTER_QUERIES';
import { getParamsFromUrl, withPropsFromUrl } from './utils';
import { FilterSelect, Error } from '../../components';

const VenueFilter = props => {
  const {
    venueIds,
    disabled,
    onSelect,
    isMulti,
    className,
    updateSearchUrls,
    cityName,
    isHost,
    skip,
    hidePopular,
  } = props;

  const {
    data: venuesData = {},
    loading: venuesLoading,
    error: venuesError,
  } = useQuery(DISCOVER_VENUES, {
    skip: skip || !cityName,
    fetchPolicy: 'cache-and-network',
    variables: getParamsFromUrl(props, isHost),
  });

  const {
    data: topVenuesData = {},
    loading: topVenuesLoading,
    error: topVenuesError,
  } = useQuery(DISCOVER_TOP_VENUES, {
    skip: skip || hidePopular || !cityName,
    fetchPolicy: 'cache-and-network',
    variables: getParamsFromUrl(props, isHost),
  });

  const { discoverVenues = {} } = venuesData;
  const { discoverTopVenues = {} } = topVenuesData;

  const { venues = [] } = discoverVenues;
  const { venues: topVenues = [] } = discoverTopVenues;

  const venueOptions = [
    ...(topVenues.length > 0
      ? [
          {
            _id: '',
            shorthandName: 'Popular Venues',
            labelTitle: true,
            isDisabled: true,
          },
          ...topVenues,
          {
            _id: '',
            shorthandName: 'All Venues',
            isDisabled: true,
            labelTitle: true,
          },
        ]
      : []),
    ...venues,
  ].map(({ _id, shorthandName, labelTitle, isDisabled = false }) => ({
    label: shorthandName,
    value: _id,
    labelTitle,
    isDisabled,
  }));

  const onChange =
    typeof onSelect === 'function'
      ? onSelect
      : (v = []) => updateSearchUrls({ venueIds: v.map(({ value }) => value) });

  const loading = venuesLoading || topVenuesLoading;
  const error = venuesError || topVenuesError;

  if (error) return <Error error={error} />;

  return (
    <FilterSelect
      short
      shadow
      labelSingular="Venue"
      labelPlural="Venues"
      options={venueOptions}
      value={venueOptions.filter(({ value }) => venueIds.includes(value)) || null}
      onChange={onChange}
      disabled={disabled || loading || !cityName}
      multi={isMulti}
      className={className}
      // Styling for top venues labels
      styles={{
        option: (base, { data }) => {
          const { isDisabled } = data;
          return {
            ...base,
            ...(isDisabled
              ? {
                  color: '#e9ff70',
                  backgroundColor: '#183252',
                }
              : {}),
          };
        },
      }}
    />
  );
};

VenueFilter.propTypes = {
  onSelect: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  updateSearchUrls: PropTypes.func.isRequired,
  cityName: PropTypes.string,
  venueIds: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isHost: PropTypes.bool,
  className: PropTypes.string,
  skip: PropTypes.bool,
  hidePopular: PropTypes.bool,
};

VenueFilter.defaultProps = {
  onSelect: false,
  disabled: false,
  isMulti: false,
  isHost: false,
  venueIds: [],
  className: '',
  cityName: '',
  skip: false,
  hidePopular: false,
};

export default withPropsFromUrl()(VenueFilter);
