import * as _ from 'lodash-es';
import qs from 'qs';
import { intersection } from '../shared/array-helpers';

export const getPropFromUrl = ({ propName, props, defaultVal }) => {
  const {
    history: {
      location: { search },
    },
  } = props;
  const params = qs.parse(search, {
    ignoreQueryPrefix: true,
  });
  return params?.[propName] || defaultVal;
};

export const addSearchParams = ({ history, params }) => {
  const current = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });
  history.push({
    search: qs.stringify(
      {
        ...current,
        ...params,
      },
      { arrayFormat: 'brackets' }
    ),
  });
};

export const dashSpaceSwap = (param = '', which = 'toSpace') => {
  const lower = _.toLower(param);
  if (lower === 'washington dc' || lower === 'washington-dc') {
    // annoying hard code fix for washington dc
    return which === 'toSpace' ? 'Washington DC' : 'Washington-DC';
  }
  if (lower === 'sf premier soccer' || lower === 'sf-premier-soccer') {
    // same for SF Soccer in dev ^
    return which === 'toSpace' ? 'SF Premier Soccer' : 'SF-Premier-Soccer';
  }
  if (which === 'toSpace') {
    return _.startCase(lower).replace(/-/g, ' ');
  }
  return _.startCase(lower).replace(/ /g, '-');
};

export const dashSpaceObjectSwap = (params, which = 'toSpace') => {
  const updated = {};
  _.forEach(params, (v, k) => {
    if (_.isArray(v)) {
      updated[k] = _.map(v, p => dashSpaceSwap(p, which));
    }
    if (_.isString(v)) {
      updated[k] = dashSpaceSwap(v, which);
    }
  });
  return updated;
};

export const multiIncludes = (text, values) => {
  const re = new RegExp(values.join('|'));
  return re.test(text);
};

export const checkCity = props => {
  const pathname = props?.location.pathname || '';
  let cityName = pathname.split('/')[1] || '';
  if (cityName === 'discover') {
    cityName = getPropFromUrl({ props, propName: 'cityName' });
  } else if (
    intersection(['l', 'g', 'r'], [cityName]).length > 0 ||
    pathname.includes('app/register')
  ) {
    const { league, cityOptions } = props;
    const city = cityOptions?.find(({ value }) => league?.organization?._id === value);
    cityName = city?.label;
  } else if (
    intersection(
      [
        'about-us',
        'faq',
        'corporate',
        'volo-pass',
        'roster-grid',
        'policies',
        'rules',
        'contact-us',
        'legal',
        'app',
        'signup',
      ],
      [cityName]
    ).length > 0
  ) {
    cityName = '';
  } else if (cityName.toLowerCase() === 'staff') {
    const { organizationId, cityOptions } = props;
    const city = cityOptions?.find(({ value }) => organizationId === value);
    cityName = city?.label;
  } else if (cityName.toLowerCase() === 'trivia') {
    cityName = 'Virtual';
  }
  return cityName;
};
