import { gql } from '@apollo/client';

export const SEARCH_USER_QUERY = gql`
  query getUserByInfo($input: UserInfoInput!) {
    getUserByInfo(input: $input) {
      _id
      firstName
      lastName
      gender
      email
    }
  }
`;
