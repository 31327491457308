import qs from 'qs';
import { Redirect, Route, type RouteComponentProps } from 'react-router';
import { ErrorPage } from '../../pages';

const RentalVenueRedirect = ({ location }: RouteComponentProps) => {
  const search = qs.parse(location.search);
  const venueId = search['?id'];
  if (!venueId) {
    return <Route component={ErrorPage} />;
  }

  return (
    <Redirect
      to={{
        pathname: `/r/${venueId}`,
        search: '?view=venueView',
      }}
    />
  );
};

export default RentalVenueRedirect;
