import moment from 'moment-timezone';
import useDiscoverStore from '../DiscoverStore';

const get = useDiscoverStore.getState;

export const DateFormat = 'YY/MM/DD';

const formatDateRangeForQuery = () => {
  const { filters } = get();
  const { dateLow, dateHigh } = filters;

  const formattedDateLow = moment(dateLow, DateFormat, true).startOf('day');
  const formattedDateHigh = moment(dateHigh, DateFormat, true).endOf('day');

  const end = formattedDateHigh.isValid() ? formattedDateHigh : null;
  const start = formattedDateLow.isValid() ? formattedDateLow : null;

  return {
    start,
    end,
  };
};

export default formatDateRangeForQuery;
