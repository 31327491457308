import * as _ from 'lodash-es';

export const omitDeepTypename = collection => {
  if (!collection) return collection;
  return JSON.parse(JSON.stringify(collection), (key, value) =>
    key === '__typename' ? undefined : value
  );
};

export const omitDeepSpecificKey = (collection, deleteKey) => {
  if (!collection) return collection;
  return JSON.parse(JSON.stringify(collection), (key, value) =>
    key === deleteKey ? undefined : value
  );
};

export const omitJSONTypename = json => {
  const cleaned = {};
  _.forOwn(json, (value, key) => {
    if (key !== '__typename') {
      cleaned[key] = value;
    }
  });
  return cleaned;
};
