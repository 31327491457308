/* eslint-disable react/jsx-props-no-spreading */
import cx from 'classnames';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styles from './filterSelect.module.scss';
import getSelectProps, { selectDefaultProps } from './filterSelectProps';
import { type FilterSelectProps } from './types';

const FilterSelect = <Option, isMulti extends boolean = false>(
  props: FilterSelectProps<Option, isMulti>
) => {
  const { creatable = false, errorMsg = '' } = props;
  return (
    <>
      {!creatable && <Select {...getSelectProps(props)} />}
      {creatable && <CreatableSelect {...getSelectProps(props)} />}
      {errorMsg && <div className={cx(styles.errorMsg)}>{errorMsg}</div>}
    </>
  );
};

FilterSelect.defaultProps = {
  ...selectDefaultProps,
};

export default FilterSelect;
