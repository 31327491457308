import React from 'react';
import withAutoresize from './withAutoresize.jsx';
import withAutosave from './withAutosave.jsx';
import Input from './Input.jsx';

const AutosaveAutoresizeInput = withAutosave(withAutoresize(Input));

const EditableText = ({ saveStatusPosition = false, inputStyle = {}, ...props }) => (
  <AutosaveAutoresizeInput
    inputStyle={{ padding: '3px 0', ...inputStyle }}
    saveStatusPosition={saveStatusPosition}
    styleName="editableText"
    {...props}
  />
);

export default EditableText;
