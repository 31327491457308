import React from 'react';
import PropTypes from 'prop-types';
import { getImageMimeType } from '../../utils/url-util';

const Source = props => {
  const { srcSet } = props;

  const type = getImageMimeType(srcSet);

  return <source type={type} srcSet={srcSet} />;
};

Source.propTypes = {
  srcSet: PropTypes.string.isRequired,
};

export default Source;
