/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { capitalize, some } from 'lodash-es';
import { useQuery } from '@apollo/client';
import { FilterSelect, Loading, Error, ListContainer } from '../../../components';
import { Checkbox } from '../../../components/fancyFieldsV2';
import { WaiverAction, WaiverStatus } from '../components';
import { PLAYER_WAIVERS_QUERY } from '../PLAYER_DETAILS_QUERIES';

const filterStyles = Object.freeze({
  container: base => ({
    ...base,
    width: '100%',
  }),
  valueContainer: base => ({
    ...base,
    width: '100%',
    fontSize: '.75rem',
    textAlign: 'left',
    margin: '0 1rem',
  }),
  control: base => ({
    ...base,
    width: '100%',
    border: '1px solid black',
    minHeight: '1.75rem',
    height: '1.75rem',
  }),
});

const PlayerWaiversTab = ({ userId, userDetails }) => {
  const [filters, setFilters] = useState({
    signed: false,
    unsigned: false,
    seasons: [],
    types: [],
  });

  const updateFilters = update => setFilters(previous => ({ ...previous, ...update }));
  const filtersApplied = () => {
    if (filters.signed || filters.unsigned || filters.seasons.length || filters.types.length) {
      return true;
    }
    return false;
  };

  const {
    data = {},
    loading,
    error,
    refetch,
  } = useQuery(PLAYER_WAIVERS_QUERY, {
    skip: !userId,
    fetchPolicy: 'network-only',
    variables: { userId },
  });

  if (loading) return <Loading type="inline" />;
  if (error) return <Error error={error} />;

  const { getUserWaivers = [] } = data;

  const { availableSeasons, availableTypes } = getUserWaivers.reduce(
    (acc, { waiver_type, season }) => {
      const { availableSeasons: seasons, availableTypes: types } = acc;
      return {
        availableSeasons: [...new Set([...seasons, season])],
        availableTypes: [...new Set([...types, waiver_type])],
      };
    },
    {
      availableSeasons: [],
      availableTypes: [],
    }
  );

  const seasonsOptions = availableSeasons.map(season => ({
    value: season,
    label: capitalize(season),
  }));
  const typesOptions = availableTypes.map(type => ({ value: type, label: capitalize(type) }));

  const filtered = getUserWaivers.filter(({ signed, season, waiver_type }) =>
    some(
      filters,
      () =>
        filters.seasons.includes(season) ||
        filters.types.includes(waiver_type) ||
        (filters.signed && signed) ||
        (filters.unsigned && !signed)
    )
  );

  return (
    <>
      <div className="d-flex my-3">
        <div className="col-12 col-md-3">
          <h3>Filter by:</h3>
        </div>
        <div className="col-12 col-md-3">
          <div className="text-left mb-2">
            <b>Status</b>
          </div>
          <div className="text-left mt-3">
            <Checkbox
              className="mr-2"
              label="signed"
              value={filters.signed}
              onChange={val => updateFilters({ signed: val })}
            />
            <Checkbox
              className="mr-2"
              label="unsigned"
              value={filters.unsigned}
              onChange={val => updateFilters({ unsigned: val })}
            />
          </div>
        </div>
        <div className="col-12 col-md-3">
          <div className="text-left mb-2">
            <b>Season</b>
          </div>
          <FilterSelect
            styles={filterStyles}
            multi
            clearable
            labelSingular="Season"
            labelPlural="Seasons"
            options={seasonsOptions}
            value={seasonsOptions.filter(v => filters.seasons?.includes(v?.value))}
            onChange={v => {
              const values = v.map(item => item.value);
              updateFilters({ seasons: values });
            }}
          />
        </div>
        <div className="col-12 col-md-3">
          <div className="text-left mb-2">
            <b>Type of Waiver</b>
          </div>
          <FilterSelect
            styles={filterStyles}
            multi
            clearable
            labelSingular="Type"
            labelPlural="Types"
            options={typesOptions}
            value={typesOptions.filter(v => filters.types.includes(v.value))}
            onChange={v => {
              const values = v.map(item => item.value);
              updateFilters({ types: values });
            }}
          />
        </div>
      </div>
      <ListContainer
        columnTitles={['Waiver', 'Program', 'Season', 'Signed', 'Date Signed', 'Actions']}
        columnData={(filtersApplied() ? filtered : getUserWaivers).map((waiver = {}) => [
          waiver.waiver_type ? capitalize(waiver.waiver_type) : 'General',
          waiver.program_id ? (
            <Link
              to={{
                pathname: `/rfo/league/${waiver.program_id}/view`,
                search: `?organization=${waiver.organizationId}`,
              }}
            >
              {waiver.program_name}
            </Link>
          ) : (
            waiver.program_name
          ),
          waiver.season ? capitalize(waiver.season) : '',
          <WaiverStatus signed={!!waiver.date_signed} />,
          waiver.date_signed ? new Date(waiver.date_signed).toLocaleString() : '',
          <WaiverAction
            {...{
              ...waiver,
              refetch,
              userDetails,
            }}
          />,
        ])}
        numOfCol={6}
        emptyText="No waivers"
      />
    </>
  );
};

PlayerWaiversTab.propTypes = {
  userId: PropTypes.string.isRequired,
  userDetails: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
};

export default PlayerWaiversTab;
