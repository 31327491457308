export const ACCOUNT_CREATION = 'player_account_created';
export const PROGRAM_REGISTRATION = 'player_program_registered';
export const VOLOPASS_SUBSCRIPTION_START = 'volopass_subscription_start';
export const VOLOPASS_SUBSCRIPTION_CANCEL = 'volopass_subscription_cancel';
export const VOLOPASS_SUBSCRIPTION_REINSTATE = 'volopass_subscription_reinstate';

export const SubscriptionStatesEnum = Object.freeze({
  SUBSCRIBED: 'subscribed',
  UNSUBSCRIBED: 'unsubscribed',
  OPTED_IN: 'opted_in',
});
