// withAutosave
// Debounces an onSave function
// and presents saving/success/error states

import * as _ from 'lodash-es';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './fancy.module.scss';

const callFunc = (func, args) => {
  if (typeof func === 'function') func(args);
};

const withAutosave = FieldComponent => {
  class AutosaveComponent extends Component {
    static propTypes = {
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      /* Gets { value } as an argument. Must return a function. */
      onSave: PropTypes.func.isRequired,
      /* Called when save fails with { error } (Error) */
      onError: PropTypes.func,
      /* Called when save succeeds with { value } */
      onSuccess: PropTypes.func,
      /* Milliseconds since the last change to delay save. */
      saveDelay: PropTypes.number,
      /* Save status indicator options. Hidden when false. */
      saveStatusPosition: PropTypes.oneOf([false, 'top', 'bottom', 'topLeft', 'bottomLeft']),
      wrapStyle: PropTypes.shape({}),
      statusStyle: PropTypes.shape({}),
      passRawEvent: PropTypes.bool,
    };

    static defaultProps = {
      onError: null,
      onSuccess: null,
      saveStatusPosition: 'top',
      saveDelay: 500,
      wrapStyle: {},
      statusStyle: {},
      passRawEvent: true,
      value: undefined,
    };

    static blacklistedInputProps = [
      'staticVariables',
      'valueKey',
      'inputKey',
      'onSave',
      'onError',
      'onSuccess',
      'saveStatus',
      'saveDelay',
      'value',
      'mutation',
      'wrapStyle',
    ];

    constructor(props) {
      super(props);
      this.onChange = this.onChange.bind(this);
      this.state = {
        value: props.value,
        saving: false,
        lastSaved: null,
        error: null,
        focused: props.initFocus || false,
      };
    }

    componentDidMount() {
      this.mounted = true;
    }

    componentDidUpdate({ value: prevPropsValue }, __) {
      const { value: propsValue } = this.props;
      const { error, value: stateValue, focused } = this.state;
      if (prevPropsValue !== propsValue) {
        // if we have an error but the prop value has updated
        // to match what we have in state, we can assume the error was resolved.
        if (propsValue === stateValue && error) {
          this.setState({ error: null }); /* eslint-disable-line react/no-did-update-set-state */
        }

        // if the prop has updated but the user isn't
        // focused on the field, we can assume the value was updated
        // elsewhere and that should be reflected here.
        if (propsValue !== stateValue && !focused) {
          this.setState({
            value: propsValue,
          }); /* eslint-disable-line react/no-did-update-set-state */
        }
      }
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    onChange(e) {
      const { passRawEvent, saveDelay } = this.props;
      const value = passRawEvent ? e.target.value : e;
      const callback = saveDelay ? this.setSaveTimeout : this.handleSave;
      this.setState({ value }, callback);
    }

    onSaveSuccess(savedValue) {
      const { onSuccess } = this.props;
      const { value: currentValue } = this.state;
      if (this.mounted) {
        this.setState({
          lastSaved: new Date(),
          saving: savedValue !== currentValue,
          error: false,
        });
      }
      callFunc(onSuccess, { value: savedValue });
    }

    setSaveTimeout() {
      if (this.saveTimeout) {
        clearTimeout(this.saveTimeout);
      }
      const { saveDelay } = this.props;
      this.saveTimeout = setTimeout(() => {
        this.handleSave();
      }, saveDelay);
    }

    saveStatusText() {
      const { saving, lastSaved, error } = this.state;
      if (saving) return 'Saving...';
      if (error) return error.message || 'Unknown error saving.';
      if (lastSaved) return <span>Last saved {moment(lastSaved).format('h:mma')}</span>;
      return <span>&nbsp;</span>;
    }

    async handleSave() {
      const { value } = this.state;
      const { value: currentValue, onError, onSave } = this.props;
      if (value === currentValue) return;

      this.setState({ saving: true, error: false });

      try {
        await onSave({ value });
      } catch (error) {
        console.error(error);
        callFunc(onError, { error });
        if (this.mounted) {
          this.setState({ saving: false, error });
        }
        return;
      }

      this.onSaveSuccess(value);
    }

    onFocus = () => {
      this.setState({ focused: true });
    };

    onBlur = () => {
      this.setState({ focused: false });
    };

    render() {
      const { error, value, saving } = this.state;
      const { saveStatusPosition, wrapStyle, statusStyle } = this.props;
      const compProps = _.omit(this.props, this.blacklistedInputProps);
      const Field = (
        <FieldComponent
          {...compProps}
          passRawEvent
          {...(value !== undefined ? { value } : {})}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          error={!!error}
          saving={saving}
          errorText="Unable to save."
          onChange={this.onChange}
        />
      );

      if (!saveStatusPosition) {
        return Field;
      }

      const SaveStatusComp = (
        <p className={cx(styles.autosaveStatus, styles[saveStatusPosition])} style={statusStyle}>
          {this.saveStatusText()}
        </p>
      );
      const saveStatusAbove = !saveStatusPosition.includes('bottom');

      return (
        <div
          className={cx(styles.autosaveWrap, { [styles.autosaveError]: error })}
          style={wrapStyle}
        >
          {saveStatusAbove && SaveStatusComp}
          {Field}
          {!saveStatusAbove && SaveStatusComp}
        </div>
      );
    }
  }

  return AutosaveComponent;
};

export default withAutosave;
