import PropTypes from 'prop-types';
import { CYCLIC_PROP } from '../common-props';

const TournamentProps = PropTypes.shape({
  _id: PropTypes.string,
  league_id: PropTypes.string,
  url: PropTypes.string,
  challonge_id: PropTypes.string,
  tournament_type: PropTypes.string,
  name: PropTypes.string,
  start_at: PropTypes.string,
  rounds: PropTypes.arrayOf(
    PropTypes.shape({
      roundNum: PropTypes.number,
      roundName: PropTypes.string,
      days: PropTypes.arrayOf(
        PropTypes.shape({
          dateStr: PropTypes.string,
          games: PropTypes.arrayOf(
            PropTypes.shape({
              _id: PropTypes.string,
              startTimeStr: PropTypes.string,
              teams: PropTypes.arrayOf(
                PropTypes.shape({
                  team: CYCLIC_PROP,
                  outcome: PropTypes.string,
                  score: PropTypes.number,
                })
              ),
            })
          ),
        })
      ),
    })
  ),
  games: PropTypes.arrayOf(CYCLIC_PROP),
});

export default TournamentProps;
