import React, { Component } from 'react';
import PropTypes from 'prop-types';
import down from '../../images/icons/angle-down.svg';
import up from '../../images/icons/angle-up.svg';

import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.setMaxHeight = this.setMaxHeight.bind(this);
    this.reveal = this.reveal.bind(this);
    this.state = {
      showDetail: this.props.initShown || false,
      maxHeight: 0,
    };
  }

  componentDidMount() {
    this.setMaxHeight();
    window.addEventListener('resize', this.setMaxHeight.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setMaxHeight.bind(this));
  }

  setMaxHeight() {
    if (!this.block) return;
    const maxHeight = this.block.getBoundingClientRect().height;
    this.setState({ maxHeight });
  }

  reveal() {
    this.setMaxHeight();
    this.setState({ showDetail: !this.state.showDetail });
  }

  render() {
    const { showDetail, maxHeight } = this.state;
    const { className, style, title, trigger, children, triggerStyle, titleStyle } = this.props;
    const detailHeight = showDetail ? `${maxHeight}px` : '0px';
    const transitionSpeed = maxHeight > 1000 ? 0.5 : 0.3;

    return (
      <div className={cx('dropdown-wrap', className)} style={style}>
        <a onClick={this.reveal} style={triggerStyle}>
          {trigger || (
            <div className={cx('trigger', { open: showDetail })}>
              {title ? (
                <h2 style={titleStyle} className="m-0">
                  {title}
                </h2>
              ) : null}{' '}
              {showDetail ? <img src={up} /> : <img src={down} />}
            </div>
          )}
        </a>

        <div
          className={cx('dropdown-detail', { open: showDetail })}
          style={{
            maxHeight: detailHeight,
            transition: `max-height ${transitionSpeed}s ease-in-out`,
          }}
        >
          <div ref={n => (this.block = n)}>{children}</div>
        </div>
      </div>
    );
  }
}

Dropdown.propTypes = {
  initShown: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  trigger: PropTypes.element,
  style: PropTypes.object,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Dropdown;
