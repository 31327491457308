import { gql } from '@apollo/client';

export default gql`
  query currentUser {
    currentUser {
      _id
      roles
      email
      firstName
      lastName
      picture
      organizationId
      gender
      emailHidden
      phoneHidden
      is_private
      organizations {
        _id
        name
      }
    }
  }
`;
