/* eslint-disable no-console */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
// Soccer until new generic is added
import defaultSportIcon from '../images/v2icons/color/generic@3x.png?as=webp';
import defaultWhiteSportIcon from '../images/v2icons/white/soccer@3x.png?as=webp';
import { getMediumBlueSportIcon } from '../utils/get-sport-icons';

// todo: these are too small to use larger than 24x24
const sportIconWhiteSwitch = (sportName, size = '@3x') => {
  switch (sportName) {
    case 'Axe Throwing':
      return require(`../images/v2icons/white/axethrowing${size}.png?as=webp`);
    case 'Badminton':
      return require(`../images/v2icons/white/badminton${size}.png?as=webp`);
    case 'Bar Games':
    case 'Bar Olympics':
      return require(`../images/v2icons/white/bargames${size}.png?as=webp`);
    case 'Baseball':
      return require(`../images/v2icons/white/baseball${size}.png?as=webp`);
    case 'Basketball':
      return require(`../images/v2icons/white/basketball${size}.png?as=webp`);
    case 'Bingo':
      return require(`../images/v2icons/white/bingo${size}.png?as=webp`);
    case 'Board Games':
      return require(`../images/v2icons/white/boardgames${size}.png?as=webp`);
    case 'Bocce':
      return require(`../images/v2icons/white/bocceball${size}.png?as=webp`);
    case 'Bootcamp':
      return require(`../images/v2icons/white/bootcamp${size}.png?as=webp`);
    case 'Bowling':
      return require(`../images/v2icons/white/bowling${size}.png?as=webp`);
    case 'Broomball':
      return require('../images/v2icons/white/broomball.png?as=webp');
    case 'Call of Duty':
      return require(`../images/v2icons/white/cod${size}.png?as=webp`);
    case 'Classes':
    case 'Class':
      return require(`../images/v2icons/white/fitnessclass_${size}.png?as=webp`);
    case 'Cornhole':
      return require(`../images/v2icons/white/cornhole${size}.png?as=webp`);
    case 'Darts':
      return require(`../images/v2icons/white/darts${size}.png?as=webp`);
    case 'Dodgeball':
      return require(`../images/v2icons/white/dodgeball${size}.png?as=webp`);
    case 'Event':
      return require(`../images/v2icons/white/event${size}.png?as=webp`);
    case 'Field Hockey':
      return require(`../images/v2icons/white/fieldhockey${size}.png?as=webp`);
    case 'Fifa':
      return require(`../images/v2icons/white/fifa${size}.png?as=webp`);
    case 'Food':
      return require(`../images/v2icons/white/forkknife${size}.png?as=webp`);
    case 'Flip Cup':
      return require(`../images/v2icons/white/flipcup${size}.png?as=webp`);
    case 'Flag Football':
    case 'Football':
      return require(`../images/v2icons/white/football${size}.png?as=webp`);
    case 'Futsal':
      return require(`../images/v2icons/white/futsal${size}.png?as=webp`);
    case 'Game Show':
      return require(`../images/v2icons/white/gameshow${size}.png?as=webp`);
    case 'Golf':
    case 'Jackbox':
      return require(`../images/v2icons/white/jackbox${size}.png?as=webp`);
    case 'Lacrosse':
      return defaultWhiteSportIcon;
    case 'Madden':
      return require(`../images/v2icons/white/madden${size}.png?as=webp`);
    case 'Mario Kart':
      return require(`../images/v2icons/white/mariokart${size}.png?as=webp`);
    case 'Mini Golf':
      return require(`../images/v2icons/white/golf${size}.png?as=webp`);
    case 'Medical':
      return require(`../images/v2icons/white/medical${size}.png?as=webp`);
    case 'NHL':
      return require(`../images/v2icons/white/nhl${size}.png?as=webp`);
    case 'Hockey':
    case 'Rocket League':
      return require(`../images/v2icons/white/rocketleague${size}.png?as=webp`);
    case 'Street Hockey':
      return require(`../images/v2icons/white/hockey${size}.png?as=webp`);
    case 'Super Smash Bros':
      return require(`../images/v2icons/white/smash${size}.png?as=webp`);
    case 'KanJam':
      return require(`../images/v2icons/white/kanjam${size}.png?as=webp`);
    case 'Kickball':
      return require(`../images/v2icons/white/kickball${size}.png?as=webp`);
    case 'Laser Tag':
      return require(`../images/v2icons/white/lasertag${size}.png?as=webp`);
    case 'Pickleball':
      return require(`../images/v2icons/white/pickleball${size}.png?as=webp`);
    case 'Ping Pong':
    case 'Table Tennis':
      return require(`../images/v2icons/white/pingpong${size}.png?as=webp`);
    case 'Testing':
      return require(`../images/v2icons/white/testing${size}.png?as=webp`);
    case 'Transportation':
      return require(`../images/v2icons/white/transportation${size}.png?as=webp`);
    case 'Trivia':
      return require(`../images/v2icons/white/trivia${size}.png?as=webp`);
    case 'Running':
    case 'Marathon':
    case 'Race':
      return require(`../images/v2icons/white/running${size}.png?as=webp`);
    case 'Sailing':
      return require(`../images/v2icons/white/sailing${size}.png?as=webp`);
    case 'Skeeball':
      return require(`../images/v2icons/white/skeeball${size}.png?as=webp`);
    case 'Skiing':
    case 'Soccer':
      return require(`../images/v2icons/white/soccer${size}.png?as=webp`);
    case 'Softball':
      return require(`../images/v2icons/white/softball${size}.png?as=webp`);
    case 'Spin':
      return defaultWhiteSportIcon;
    case 'Squash':
      return require('../images/v2icons/white/squash.png?as=webp');
    case 'Tennis':
      return require(`../images/v2icons/white/tennis${size}.png?as=webp`);
    case 'Trip':
      return require(`../images/v2icons/white/trip${size}.png?as=webp`);
    case 'Ultimate Frisbee':
    case 'Frisbee':
      return require(`../images/v2icons/white/frisbee${size}.png?as=webp`);
    case 'Volleyball':
      return require(`../images/v2icons/white/volleyball${size}.png?as=webp`);
    case 'Volunteer':
      return require(`../images/v2icons/white/volunteer${size}.png?as=webp`);
    case 'Yoga':
      return require(`../images/v2icons/white/yoga${size}.png?as=webp`);
    default:
      return defaultWhiteSportIcon;
  }
};

export const getSportIcon = (sportName, size) => {
  try {
    return getMediumBlueSportIcon(sportName, size);
  } catch (e) {
    console.error(e);
    return defaultSportIcon;
  }
};

export const getSportIconWhite = (sportName, size) => {
  try {
    return sportIconWhiteSwitch(sportName, size);
  } catch (e) {
    console.error(e);
    return defaultWhiteSportIcon;
  }
};

// deprecate
export const getSportIconColor = getSportIcon;
