/* eslint-disable react/jsx-props-no-spreading */
import React, { type ComponentProps } from 'react';
import { FontAwesomeIcon, IconButton, Spinner } from '@rivallapp/volosports-components';
import { faDownload } from '@fortawesome/pro-solid-svg-icons/faDownload';
import { usePrintWaiver, type UsePrintWaiverArgs } from './usePrintWaiver';

const DownloadIcon = <FontAwesomeIcon icon={faDownload} />;

type IconButtonProps = ComponentProps<typeof IconButton>;

const defaultProps: IconButtonProps = {
  variant: 'ghost',
  rounded: 'full',
};

type Props = Omit<IconButtonProps, 'onPress'> & UsePrintWaiverArgs;

const PrintWaiverIconButton: React.FC<Props> = ({
  waiverId,
  fullName,
  dateSigned,
  icon = DownloadIcon,
  ...props
}) => {
  const [NODE, print, printing] = usePrintWaiver({ waiverId, fullName, dateSigned });

  return (
    <>
      <IconButton
        icon={printing ? <Spinner size={16} /> : icon}
        {...defaultProps}
        {...props}
        onPress={print}
      />
      {NODE}
    </>
  );
};

export default PrintWaiverIconButton;
