import React from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';

import styles from '../../../../../styles/main.module.scss';

const cx = classNames.bind(styles);

export type LinkItem = {
  name: string;
  href: string;
};

type DropdownLinkListProps = {
  heading: string | JSX.Element;
  links: LinkItem[];
};
const DropdownLinkList: React.FC<DropdownLinkListProps> = ({ heading, links }) => (
  <div className="col-12 col-md-auto">
    <h4>{heading}</h4>
    <ul className={cx('innerList')}>
      {links.map(({ name, href }) => (
        <li key={name}>
          {href.startsWith('http') ? (
            <a href={href} target="_blank" rel="noopener noreferrer">
              {name}
            </a>
          ) : (
            <Link to={href}>{name}</Link>
          )}
        </li>
      ))}
    </ul>
  </div>
);

export default DropdownLinkList;
