import getProgramTypesFromTabs from '../utils/getProgramTypesFromTabs';
import Tabs, { MainTabEnum, SubTabEnum } from '../../../shared/discover-tabs-enum';
import useDiscoverStore from '../DiscoverStore';
import initialDiscoverStore from '../initialDiscoverStore';

const { filters: initialFilters } = initialDiscoverStore;

const updateMainView = view => {
  const set = useDiscoverStore.setState;
  const get = useDiscoverStore.getState;

  const { filters } = get();
  const { cityName } = filters;
  const { subTabs } = Tabs[view];
  const fallbackSubView = subTabs[0];

  set(store => ({
    ...store,
    view,
    subView: fallbackSubView,
    currentPage: 1,
    isDaily:
      fallbackSubView === SubTabEnum.DAILY || (view === MainTabEnum.EVENTS && !fallbackSubView),
    defaultProgramTypes: getProgramTypesFromTabs(view, fallbackSubView),
    filters: {
      ...initialFilters,
      cityName,
    },
  }));
};

export default updateMainView;
