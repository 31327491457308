import { useMutation, useQuery } from '@apollo/client';
import classNames from 'classnames/bind';
import { useState } from 'react';
import styles from '../player-details.module.scss';
import MembershipPayments from './MembershipPayments';
import PaymentAction from './PaymentAction';
import { DELETE_CREDITS, GET_USER_PAYMENTS_UPDATED } from './PLAYER_PAYMENT_MUTATION_AND_QUERIES';
import ProgramPayments from './ProgramPayments';
import { AreYouSure, Loading } from '../../../components';
import { formatCents } from '../../../utils';

const cx = classNames.bind(styles);

export type CreditState = {
  showCreditPopup?: boolean;
  creditId?: string;
  organizationId?: string;
};

type Props = {
  userId: string;
  setUpdating: (val: boolean) => void;
  setSuccess: (val: string) => void;
  setError: (val: any) => void;
};

const PlayerPaymentContainer = (props: Props) => {
  const { userId, setUpdating, setSuccess, setError } = props;

  const [deleteCreditState, setDeleteCreditState] = useState<CreditState>({});
  const [registrantDeleteCredits] = useMutation(DELETE_CREDITS);

  const { loading, error, refetch, data } = useQuery(GET_USER_PAYMENTS_UPDATED, {
    skip: !userId,
    fetchPolicy: 'network-only',
    variables: { userPaymentListInput: { userId, pagination: { numPerPage: 50 } } }, // TODO: Add pagination
  });

  if (loading) return <Loading />;
  if (error || !userId) return <div>Error! {JSON.stringify(error || 'Missing userId')}</div>;

  const deleteCredit = async () => {
    const { creditId, organizationId } = deleteCreditState;
    setUpdating(true);
    try {
      await registrantDeleteCredits({
        variables: {
          input: {
            userId,
            creditId,
            organizationId,
          },
        },
      });
      await refetch();
      setDeleteCreditState({ ...deleteCreditState, showCreditPopup: false });
      setSuccess('Player credit deleted.');
    } catch (e) {
      setError(e);
    } finally {
      setUpdating(false);
    }
  };

  const { showCreditPopup } = deleteCreditState;
  const {
    payments = [],
    totalPayments,
    totalDonations,
    totalNet,
    totalRefunds,
    totalCredits = 0,
  } = data?.userPayments ?? {};

  return (
    <>
      <div className="container">
        <div className="row my-4">
          <div className="col mx-auto p-1">
            <div className={cx('paymentBox')}>
              <h2>Total Payments</h2>
              <h2 className={cx('regColor', 'mt-3')}>{formatCents(totalPayments)}</h2>
            </div>
          </div>
          <div className="col mx-auto p-1">
            <div className={cx('paymentBox')}>
              <h2>Net Revenue</h2>
              <h2 className={cx('regColor', 'mt-3')}>{formatCents(totalNet)}</h2>
            </div>
          </div>
          <div className="col mx-auto p-1">
            <div className={cx('paymentBox')}>
              <h2>Total Refunds</h2>
              <h2 className={cx('redColor', 'mt-3')}>-{formatCents(totalRefunds)}</h2>
            </div>
          </div>
          <div className="col mx-auto p-1">
            <div className={cx('paymentBox')}>
              <h2>Available Credits</h2>
              <h2 className={cx('greenColor', 'mt-3')}>+{formatCents(totalCredits)}</h2>
            </div>
          </div>
          <div className="col mx-auto p-1">
            <div className={cx('paymentBox')}>
              <h2>Donations Made</h2>
              <h2 className={cx('regColor', 'mt-3')}>{formatCents(totalDonations)}</h2>
            </div>
          </div>
        </div>
        <ProgramPayments
          payments={payments}
          availableCredits={totalCredits}
          setDeleteCreditState={setDeleteCreditState}
        />
        <MembershipPayments userId={userId} />
      </div>
      <PaymentAction userId={userId} payments={payments} refetch={refetch} />
      <AreYouSure
        body="Are you sure you want to delete this credit?"
        hidden={!showCreditPopup}
        yesAction={deleteCredit}
        close={() => setDeleteCreditState({ ...deleteCreditState, showCreditPopup: false })}
      />
    </>
  );
};

export default PlayerPaymentContainer;
