import PropTypes from 'prop-types';
import UserProps from '../models/user-props';

const UseCurrentUserProps = {
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  currentUser: UserProps,
  roles: PropTypes.arrayOf(PropTypes.string),
  organizationId: PropTypes.string,
};

export default UseCurrentUserProps;
