import { useCallback, useState } from 'react';

const useSelectedParams = (selectedParamsList, optionalCallback) => {
  const [selectedParams, setSelectedParams] = useState(selectedParamsList);

  const setSelectedCallback = useCallback(
    value => {
      if (optionalCallback) optionalCallback(value);
      setSelectedParams(current => {
        if (current?.includes(value)) {
          return current?.filter(currentItem => currentItem !== value);
        }
        return [...new Set([...current, value])];
      });
    },
    [optionalCallback]
  );

  return [selectedParams, setSelectedCallback];
};

export default useSelectedParams;
