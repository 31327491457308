// import React from 'react';
// import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { compose, setDisplayName, branch } from 'recompose';
import { graphql } from '@apollo/client/react/hoc';
import SupportPopupPresenter, { SupportPopupLoading } from './SupportPopupPresenter.jsx';

const USER_LEAGUES = gql`
  query currentUser {
    currentUser {
      _id
      roles
      leagueConnections {
        league {
          _id
          name
          stage
          activityName
          contact {
            name
            email
            phoneNumber
          }
        }
        team {
          _id
          name
        }
        group {
          _id
          name
        }
      }
    }
  }
`;

const SupportPopupContainer = compose(
  setDisplayName('SupportPopupContainer'),
  graphql(USER_LEAGUES, {
    props: ({ data }) => data,
  }),
  branch(
    ({ loading }) => loading,
    () => SupportPopupLoading
  )
)(SupportPopupPresenter);

export default SupportPopupContainer;
