import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment-timezone';

import { formatCents } from '../../utils';
import { useAlertMessage } from '../index';
import {
  ACTIVATE_MEMBERSHIP,
  ACTIVE_VOLO_PASS_MEMBERSHIP,
  CANCEL_MEMBERSHIP,
  MEMBERSHIP_GET_PROMO,
  RESUME_MEMBERSHIP,
  START_TRIAL_MEMBERSHIP,
  UPGRADE_VOLO_PASS_MEMBERSHIP_TO_ANNUAL,
  UPDATE_USER_PLAN,
  USER_QUERY,
  VP_ORG_TIERS_QUERY,
} from './QUERIES';
import { UPDATE_USER_HOME_ORG } from '../../features/PlayerDetails/PLAYER_DETAILS_MUTATIONS';

const useVoloPassDetails = (
  { annualPlanOverride, city } = { annualPlanOverride: '', city: '' }
) => {
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [planStatus, setPlanStatus] = useState(null);
  /** @type {[import('../../apps/Anon/HomepageV2/VoloPassV2/VoloPassSignUpModal/components/types').PlanDetails, import('react').SetStateAction<import('../../apps/Anon/HomepageV2/VoloPassV2/VoloPassSignUpModal/components/types').PlanDetails>]} */
  const [planDetails, setPlanDetails] = useState({
    selectedOrganizationId: '',
    planId: null,
    promo: '',
    enteringPromo: false,
    promoData: null,
    variant: 'monthly',
  });

  const { promoData, planId, selectedOrganizationId } = planDetails;

  const { showError, showSuccess } = useAlertMessage();

  const updatePlanDetails = useCallback(
    update => setPlanDetails({ ...planDetails, ...update }),
    [planDetails]
  );

  const [membershipGetPromoQuery] = useMutation(MEMBERSHIP_GET_PROMO);
  const [startMembershipMutation, { loading: startMembershipUpdating }] =
    useMutation(ACTIVATE_MEMBERSHIP);
  const [startTrialMembership, { loading: startTrialMembershipUpdating }] =
    useMutation(START_TRIAL_MEMBERSHIP);
  const [cancelMembershipMutation, { loading: cancelMembershipUpdating }] =
    useMutation(CANCEL_MEMBERSHIP);
  const [updateUserPlanMutation] = useMutation(UPDATE_USER_PLAN);
  const [reinstateMembershipMutation, { loading: reinstateMembershipUpdating }] =
    useMutation(RESUME_MEMBERSHIP);
  const [updateUserHomeOrganization] = useMutation(UPDATE_USER_HOME_ORG);
  const [
    upgradeVoloPassMembershipToAnnualMutation,
    { loading: upgradeVoloPassMembershipToAnnualLoading },
  ] = useMutation(UPGRADE_VOLO_PASS_MEMBERSHIP_TO_ANNUAL);

  const {
    data: orgListData,
    loading: orgListLoading,
    error: orgListError,
    refetch: orgListRefetch,
  } = useQuery(VP_ORG_TIERS_QUERY, {
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ organizationList }) => {
      const selectedCity = city
        ? organizationList.find(o => o.name === city)
        : organizationList.find(o => o._id === selectedOrganizationId);
      updatePlanDetails({
        planId: annualPlanOverride || selectedCity?.voloPassTiers?.monthlyPlanId,
      });
    },
  });

  const { organizationList = [] } = useMemo(() => orgListData || {}, [orgListData]);

  const selectedCity = useMemo(() => {
    if (city) return organizationList.find(o => o.name === city);
    return organizationList.find(o => o._id === selectedOrganizationId);
  }, [organizationList, selectedOrganizationId, city]);

  const cityOptions = useMemo(() => {
    return organizationList?.map(({ _id, name }) => ({
      value: _id,
      label: name,
    }));
  }, [organizationList]);

  const {
    data: activeData,
    loading: activeLoading,
    error: activeError,
    refetch: activeRefetch,
  } = useQuery(ACTIVE_VOLO_PASS_MEMBERSHIP, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ activeVoloPassMembership }) => {
      if (activeVoloPassMembership) {
        const selectedCity = organizationList.find(
          o => o._id === activeVoloPassMembership.metadata.organizationId
        );
        setPlanStatus(activeVoloPassMembership.status);
        updatePlanDetails({
          selectedOrganizationId: activeVoloPassMembership.metadata.organizationId,
          planId: annualPlanOverride || selectedCity?.voloPassTiers?.annualPlanId,
          variant: 'annual',
        });
      }
    },
  });

  const { activeVoloPassMembership } = activeData || {};
  const activeVoloPassPlanDetails = activeVoloPassMembership
    ? {
        planId: activeVoloPassMembership.plan.id,
        planInterval: activeVoloPassMembership.plan.interval,
        planOrganization: activeVoloPassMembership.metadata.organizationId,
      }
    : {};

  const {
    data: userData,
    loading: userLoading,
    error: userError,
    refetch,
  } = useQuery(USER_QUERY, {
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ currentUser }) => {
      if (!activeVoloPassMembership) {
        const selectedCity = city
          ? organizationList.find(o => o.name === city)
          : organizationList.find(o => o._id === currentUser.homeOrganization);
        updatePlanDetails({
          selectedOrganizationId: city ? selectedCity._id : currentUser.homeOrganization,
          planId: annualPlanOverride || selectedCity?.voloPassTiers?.monthlyPlanId,
        });
      }
    },
  });

  const { membershipExpires } = userData?.currentUser || {};

  const expiredDate = moment(+membershipExpires).format('MM/DD/YYYY');
  const afterExpireDate = moment().isAfter(moment(expiredDate).endOf('day'));

  const refetchAll = async () => {
    await Promise.all([orgListRefetch(), refetch(), activeRefetch()]);
  };

  const checkPayment = async () => {
    // in case someone just added their card info, have to refetch
    if (!userData.currentUser?.paymentSources?.length) await refetch();
    const paymentSources = userData.currentUser?.paymentSources || [];
    const defaultSource = paymentSources.find(p => p.isDefault && !p.isExpired);
    if (!defaultSource) {
      throw new Error('Please make sure you have at least one payment on file.');
    }
  };

  const validatePromo = async promoEntry => {
    try {
      if (!promoEntry) return;
      updatePlanDetails({ promoData: null });
      const { data: resData } = await membershipGetPromoQuery({
        variables: { input: { promoId: promoEntry, orgId: selectedCity._id } },
      });
      updatePlanDetails({ promoData: resData?.membershipGetPromo });
    } catch (e) {
      showError(e);
    }
  };

  const getPromoText = () => {
    if (!promoData) return '';
    const { amount_off, percent_off, duration, duration_in_months } = promoData;
    const isAnnual = planDetails.variant === 'annual';
    const intervalText = isAnnual ? 'year' : 'month';
    switch (duration) {
      case 'repeating':
        return `${amount_off ? formatCents(amount_off) : ''} ${percent_off ? `${percent_off}%` : ''} off Volo Pass${isAnnual ? '' : ` for ${duration_in_months} ${intervalText}s`}!`;
      case 'forever':
        return `${amount_off ? formatCents(amount_off) : ''} ${percent_off ? `${percent_off}%` : ''} off Volo Pass every ${intervalText}!`;
      case 'once':
      default:
        return `${amount_off ? formatCents(amount_off) : ''} ${percent_off ? `${percent_off}%` : ''} off Volo Pass for 1 ${intervalText}!`;
    }
  };

  const startPlan = async () => {
    try {
      await checkPayment();
      if (!planId) throw new Error('Please choose a plan before saving.');
      await updateUserHomeOrganization({
        variables: { input: { organizationId: selectedCity._id } },
      });
      const { data: activateData } = await startMembershipMutation({
        variables: {
          input: {
            planId,
            ...(promoData?.valid ? { promoCode: promoData.id } : {}),
            orgId: selectedCity._id,
          },
        },
      });
      await updateUserPlanMutation({ variables: { input: { chosenPlan: 'pass' } } });
      await refetch();
      setPlanStatus(activateData?.startMembership?.status);
      showSuccess('Successfully started Volo Pass');
    } catch (e) {
      showError(e);
    }
  };

  const reinstatePlan = async () => {
    try {
      await checkPayment();
      const { data: reinstateData } = await reinstateMembershipMutation();
      await updateUserPlanMutation({ variables: { input: { chosenPlan: 'pass' } } });
      setPlanStatus(reinstateData?.reinstateMembership?.status);
      showSuccess('Successfully reinstated Volo Pass.');
    } catch (e) {
      showError(e);
    }
  };

  const cancelPlan = async () => {
    try {
      const { data: cancelData } = await cancelMembershipMutation();
      await updateUserPlanMutation({ variables: { input: { chosenPlan: 'base' } } });
      setPlanStatus(cancelData?.cancelMembership?.status);
      showSuccess('Successfully cancelled Volo Pass.');
    } catch (e) {
      showError(e);
    }
  };

  const upgradeVoloPassPlan = async () => {
    try {
      await checkPayment();
      if (!planId) throw new Error('Please choose a plan before saving.');
      await upgradeVoloPassMembershipToAnnualMutation({
        variables: {
          input: {
            planId,
            ...(promoData?.valid ? { promoCode: promoData.id } : {}),
            orgId: selectedCity._id,
          },
        },
      });
      await refetch();
      showSuccess('Successfully upgraded to annual Volo Pass!');
    } catch (e) {
      showError(e);
    }
  };

  return {
    acceptedTerms,
    afterExpireDate,
    cancelPlan,
    cancelMembershipUpdating,
    cityOptions,
    error: orgListError || userError || (activeError && activeError.message !== 'not_found'),
    getPromoText,
    loading: orgListLoading || activeLoading || userLoading,
    updating:
      upgradeVoloPassMembershipToAnnualLoading ||
      startMembershipUpdating ||
      startTrialMembershipUpdating ||
      reinstateMembershipUpdating ||
      cancelMembershipUpdating,
    membershipExpires,
    planDetails,
    planStatus,
    activeVoloPassPlanDetails,
    reinstatePlan,
    reinstateMembershipUpdating,
    selectedCity,
    setAcceptedTerms,
    startMembershipMutation,
    startMembershipUpdating,
    startPlan,
    startTrialMembership,
    updatePlanDetails,
    updateUserPlanMutation,
    upgradeVoloPassMembershipToAnnualLoading,
    upgradeVoloPassPlan,
    validatePromo,
    refetchAll,
  };
};

export default useVoloPassDetails;
