import PropTypes from 'prop-types';
import { CYCLIC_PROP, NameUrlProps } from '../common-props';

const PermitProps = PropTypes.shape({
  _id: PropTypes.bool,
  approvedVenueId: PropTypes.bool,
  start: PropTypes.bool,
  end: PropTypes.bool,
  referenceId: PropTypes.bool,
  sublocations: PropTypes.arrayOf(PropTypes.string),
  centsDaily: PropTypes.number,
  centsHourly: PropTypes.number,
  note: PropTypes.bool,
  pdf: NameUrlProps,
  timeframe: PropTypes.shape({
    date: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    timezone: PropTypes.string,
  }),
  sublocationOptions: PropTypes.arrayOf(PropTypes.string),
  leagues: PropTypes.arrayOf(CYCLIC_PROP),
  leagueIds: PropTypes.arrayOf(PropTypes.string),
});

export default PermitProps;
