import { gql } from '@apollo/client';

export const LEAGUE_BASICS_QUERY = gql`
  query upcomingLeaguesQuery($input: LeagueListInput!) {
    leagueList(input: $input) {
      leagues {
        _id
        name
        featured_order
        start_date
        details {
          description
        }
        activityName
        programType
      }
    }
  }
`;

export const ORGANIZATION_SPORT_QUERY = gql`
  query organization($organizationId: ID!, $getVoloDetails: Boolean!) {
    organization(_id: $organizationId, getVoloDetails: $getVoloDetails) {
      _id
      contact_email
      contact_phone_number
      homepageDetails {
        sportDefaults {
          programTypes {
            name
            image {
              name
              url
            }
            description
          }
          competitionLevels {
            level
            description
          }
        }
        activeSports {
          sportName
        }
        sportDetails {
          sportName
          hideSport
          notes
          hero {
            heroText
            heroSubText
            heroImage {
              image {
                name
                url
              }
            }
            heroCta {
              text
              url
              newTab
            }
          }
          stylesOfPlay {
            title
            description
            image {
              url
            }
          }
          faq {
            answer
            question
          }
          programTypes {
            hide
            name
            description {
              isCustom
              text
            }
            programImage {
              name
              url
            }
          }
          competitionLevels {
            hidden
            level
            description
          }
          formats {
            description
            format
            formatImage {
              url
            }
            gender
            maxPlayersOnField {
              total
              male
              female
            }
            minPlayersPerGame {
              total
              male
              female
            }
            minTeamSize {
              total
              male
              female
            }
          }
        }
      }
      approvedVenues {
        _id
        disabled
        shorthandName
        pdfUploads
        place_id
        location {
          lat
          lng
        }
        neighborhood
        city
        formatted_address
        sportDetails {
          sportName
          description
        }
        image {
          url
        }
      }
    }
  }
`;

export const FAQS_QUERY = gql`
  query homepageFaqs {
    homepageFaqs {
      faqs {
        question
        answer
      }
    }
  }
`;

export const GET_RULESETS_QUERY = gql`
  query rulesetsList($input: RulesetsInput) {
    rulesetsList(input: $input) {
      rulesetsList {
        sport
        rulesets {
          _id
          gender
          format
          subtitle
          oneSheeter
          description
        }
      }
    }
  }
`;

export const GET_CITY_RULESETS_LIST = gql`
  query cityRulesetsList($input: RulesetsInput!) {
    cityRulesetsList(input: $input) {
      rulesetsList {
        sport
        rulesets {
          _id
          gender
          format
          subtitle
        }
      }
    }
  }
`;

export const GET_RULESET_DETAILS = gql`
  query ruleset($rulesetId: ID!) {
    ruleset(_id: $rulesetId) {
      description
      oneSheeter
    }
  }
`;

export const NATIONAL_SLIDE_QUERY = gql`
  query nationalSlides {
    nationalSlides {
      slideDuration
      heroSlides {
        slideImage
        slideText
        slideSubText
        slideButtonText
        slideLink
        newTab
        secondarySlideButtonText
        secondarySlideLink
        secondaryNewTab
      }
    }
  }
`;

export const ORGANIZATION_HERO_QUERY = gql`
  query organization($organizationId: ID!) {
    organization(_id: $organizationId) {
      _id
      heroSlides {
        slideImage
        slideText
        slideSubText
        slideButtonText
        slideLink
        newTab
        secondarySlideButtonText
        secondarySlideLink
        secondaryNewTab
      }
      slideDuration
    }
  }
`;

export const ORGANIZATION_LIST_QUERY = gql`
  query organizationList {
    organizationList {
      _id
      name
      crest
      contact_phone_number
      contact_email
      weather_line_number
      location {
        coordinates {
          lat
          lng
        }
      }
      voloPassTiers {
        isActive
      }
    }
  }
`;

export const DISCOVER_SPORTS = gql`
  query discoverSports($input: DiscoverInput!) {
    discoverSports(input: $input) {
      sportNames
    }
  }
`;
