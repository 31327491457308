// withStringToHtml
// translates a string value into html
// replacing ** with bold tags
// and line breaks with <br /> tags
import { withHandlers, withStateHandlers, compose, setPropTypes } from 'recompose';
import PropTypes from 'prop-types';

const htmlToString = html => {
  const addStrong = html.replace(/<b>(.+?)<\/b>/g, '**$1**');
  const addLineBreaks = addStrong.replace(/<br>/g, '\\n');
  const addLineBreaks2 = addLineBreaks.replace(/<br\/>/g, '\n');
  const removeOtherTags = addLineBreaks2.replace(/(&nbsp;|<([^>]+)>)/gi, '');
  return removeOtherTags;
};

const stringToHtml = message => {
  const string = JSON.stringify(message);
  const removeTags = string.replace(/(&nbsp;|<([^>]+)>)/gi, '');
  const addStrong = removeTags.replace(/\*\*(.+?)\*\*/g, '<b>$1</b>');
  const addLineBreaks = addStrong.replace(/\\n/g, '<br/>');
  const removeQuotesAndBackSlashes = addLineBreaks.replace(/(^"|"$|\\)/g, '');
  return `<span>${removeQuotesAndBackSlashes}</span>`;
};

const withStringToHtml = () => WrappedComponent =>
  compose(
    setPropTypes({
      value: PropTypes.string,
      onChange: PropTypes.func.isRequired,
    }),
    withStateHandlers(
      ({ html }) => ({
        value: htmlToString(html || ''),
      }),
      {
        setValue: () => value => ({ value }),
      }
    ),
    withHandlers({
      onChange:
        ({ onChange, setValue }) =>
        string => {
          setValue(string);
          onChange(stringToHtml(string));
        },
    })
  )(WrappedComponent);

export default withStringToHtml;
