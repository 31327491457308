import { gql } from '@apollo/client';

export default gql`
  query League($id: ID!) {
    league(_id: $id) {
      _id
      start_date
      header
      programType
      displayName
      registrantCount
      banner_text
      start_time_estimate
      end_time_estimate
      registration {
        _id
        minSize
        maxSize
        memberPrice
        nonMemberPrice
      }
      sport {
        _id
        name
      }
      venue {
        _id
        shorthandName
        timezone
        neighborhood
        formatted_address
        location {
          lat
          lng
        }
      }
      schedules {
        _id
        startTimeStr
        endTimeStr
        games {
          _id
          end_time
          start_time
        }
      }
    }
  }
`;
