import React from 'react';
import * as _ from 'lodash-es';
import PropTypes from 'prop-types';
import { compose, withState, withHandlers, withStateHandlers, lifecycle } from 'recompose';
import { Button } from '../../components';
import { FancyField } from '../../components/fancyFieldsV2';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const HtmlEditor = ({
  content,
  placeholder,
  label,
  setContent,
  onSave,
  buttonText,
  onlyFieldShow,
  style,
}) => (
  <React.Fragment>
    {!onlyFieldShow ? (
      <span className={cx('html-editor')} style={style}>
        <p>
          <i>Tip:</i> Wrap text with **two asterisks** to make it <b>bold</b>.
        </p>
        <label>{label}</label>
        <FancyField
          grey
          placeholder={placeholder}
          noLabel
          textarea
          value={content}
          onChange={setContent}
        />
        <Button className={cx('save', { disabled: !content })} onClick={onSave}>
          {buttonText}
        </Button>
      </span>
    ) : (
      <FancyField
        blockStyle
        placeholder={placeholder}
        noLabel
        textarea
        value={content}
        inputStyle={{ minHeight: '100px', marginBottom: '10px', ...style }}
        onChange={setContent}
      />
    )}
  </React.Fragment>
);

HtmlEditor.propTypes = {
  buttonText: PropTypes.string,
  content: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  setContent: PropTypes.func.isRequired,
  onlyFieldShow: PropTypes.bool,
  style: PropTypes.shape({}),
};

HtmlEditor.defaultProps = {
  label: 'Message',
  placeholder: 'Enter your message here',
  buttonText: 'Done',
  onlyFieldShow: false,
  style: {},
};

export default compose(
  withStateHandlers(
    ({ initialContent, isEmpty, ...rest }) => {
      if (!_.isEmpty(initialContent)) {
        const addStrong = _.replace(initialContent, /<b>(.+?)<\/b>/g, '**$1**');
        const addLineBreaks = _.replace(addStrong, /<br>/g, '\\n');
        const addLineBreaks2 = _.replace(addLineBreaks, /<br\/>/g, '\n');
        const removeOtherTags = _.replace(addLineBreaks2, /(&nbsp;|<([^>]+)>)/gi, '');
        return { content: removeOtherTags, ...rest };
      }
      return { content: '', ...rest };
    },
    {
      setContent: state => content => {
        const { onlyFieldShow, submit } = state;
        if (onlyFieldShow) {
          const removeTags = _.replace(content, /(&nbsp;|<([^>]+)>)/gi, '');
          const addStrong = _.replace(removeTags, /\*\*(.+?)\*\*/g, '<b>$1</b>');
          const addLineBreaks = _.replace(addStrong, /(\r\n|\r|\n)/g, '<br/>');
          const removeQuotesAndBackSlashes = _.replace(addLineBreaks, /(^"|"$|\\)/g, '');
          submit(`<span>${removeQuotesAndBackSlashes}</span>`);
        }
        return { content };
      },
    }
  ),
  withHandlers({
    onSave: props => () => {
      const { content, submit } = props;
      const removeTags = _.replace(content, /(&nbsp;|<([^>]+)>)/gi, '');
      const addStrong = _.replace(removeTags, /\*\*(.+?)\*\*/g, '<b>$1</b>');
      const addLineBreaks = _.replace(addStrong, /\\n/g, '<br/>');
      const removeQuotesAndBackSlashes = _.replace(addLineBreaks, /(^"|"$|\\)/g, '');
      submit(`<span>${removeQuotesAndBackSlashes}</span>`);
    },
  })
)(HtmlEditor);
