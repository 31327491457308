import React from 'react';
import PropTypes from 'prop-types';
import { isVerticalTableDataValid } from './tableUtils';

const VerticalTable = ({ data }) => {
  if (!isVerticalTableDataValid(data)) {
    <div>
      Dataset must contain the following format:
      {JSON.stringify([
        {
          values: [1, 2, 3],
          header: 'Random Header',
        },
      ])}
    </div>;
  }

  return (
    <table className="table-defaults">
      <tbody>
        {data?.map((dataset = [], i) => {
          const { header = '', headerColor = '#FFFFFF', headerWidth, values = [] } = dataset || {};
          return (
            <tr key={`${header}-${i}`}>
              <th
                className="border-light"
                style={{
                  backgroundColor: headerColor,
                  ...(headerWidth
                    ? {
                        width: headerWidth,
                      }
                    : {}),
                }}
              >
                {header}
              </th>
              {values?.map((item = '', j) => {
                const key = `${j}-value-item`;
                return (
                  <td className="border-light" key={key}>
                    {item}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

VerticalTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      backgroundColor: PropTypes.string,
      key: PropTypes.string,
    })
  ),
};

VerticalTable.defaultProps = {
  data: [],
};

export default VerticalTable;
