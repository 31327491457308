import { useState } from 'react';
import { useAlert } from 'react-alert';
import { errorMessage } from '../utils';

const useActionStates = ({ withAlerts = false, fallbackErrorMsg = '' } = {}) => {
  const alert = useAlert();

  const [actionState, setActionState] = useState({
    updating: false,
    error: false,
    success: false,
  });

  // Currently being called with string, error obj and one use of null
  const setError = value => {
    const error = value === 'undefined' || value;
    const message = error ? errorMessage({ error, fallbackErrorMsg }) : false;
    if (error && withAlerts) {
      alert.error(message, { timeout: 5000 });
    }
    setActionState({ updating: false, success: false, error: message });
  };

  // Set success param can be changed to string
  // Only file calling it with boolean is:
  // Apps -> Onboarding -> 150-VerifyPhone -> VerifyPhoneContainer.js
  const setSuccess = value => {
    if (typeof value === 'string' && withAlerts) {
      alert.success(value, { timeout: 3000 });
    }
    setActionState({ updating: false, error: false, success: value });
  };

  const setUpdating = value =>
    setActionState({
      error: false,
      success: false,
      updating: Boolean(value),
    });

  return {
    setError,
    setSuccess,
    setUpdating,
    ...actionState,
  };
};

export default useActionStates;
