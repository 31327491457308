import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import styles from './button.module.scss';

const styleBooleans = [
  // sizes
  'wide',
  'small',
  'large',
  'block',

  // font weight
  'light',
  'bold',

  // colors
  'primary',
  'action',
  'secondary',
  'success',
  'info',
  'warning',
  'danger',
  'default',
  'white',
  'grey',
  'playpass',
  'navy',
  'neon',
  'skyblue',

  // types
  'link',
  'square',
  'active',
  'inactive',
  'loading',
  'disabled',
  'ghost',
];

const Button = props => {
  const {
    attrs,
    children,
    className,
    disabled,
    href,
    id,
    label,
    loading,
    onClick,
    style,
    styleName,
    to,
    type,
    newWindow,
  } = props;

  const disabledProps = disabled
    ? {
        disabled: 'disabled',
        'aria-disabled': true,
      }
    : {};

  const classes = [
    styles.button,
    // eslint-disable-next-line react/destructuring-assignment
    ...styleBooleans.map(op => (props[op] ? styles[op] : '')),
    styleName ? styles[styleName] : '',
    className,
  ];

  const buttonProps = {
    type,
    className: cx(classes, { disabled, loading }),
    style,
    id,
    ...disabledProps,
    ...attrs,
  };

  const buttonChild = children || label;

  if (to) {
    return (
      <Link {...buttonProps} to={to} target={newWindow ? '_blank' : null}>
        {buttonChild}
      </Link>
    );
  }
  if (href) {
    return (
      <a {...buttonProps} href={href}>
        {buttonChild}
      </a>
    );
  }
  return (
    <button {...buttonProps} onClick={onClick}>
      {buttonChild}
    </button>
  ); // eslint-disable-line
};

Button.defaultProps = {
  attrs: {},
  children: null,
  className: null,
  href: null,
  label: 'Submit',
  onClick: () => {},
  style: {},
  styleName: '',
  type: 'button',
  id: null,
  newWindow: false,
  disabled: false,
  ...(styleBooleans.reduce((acc, option) => ({ ...acc, [option]: false })), {}),
};

Button.propTypes = {
  attrs: PropTypes.objectOf(PropTypes.string),
  block: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node])),
  ]),
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  href: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  style: PropTypes.shape({}),
  styleName: PropTypes.string,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  newWindow: PropTypes.bool,
  disabled: PropTypes.bool,
  ...(styleBooleans.reduce((acc, option) => ({ ...acc, [option]: PropTypes.bool })), {}),
};

export default Button;
