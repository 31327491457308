import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { ProgramTypeEnum, ProgramType } from '@rivallapp/volosports-components';

import { useAlertMessage, useRegistrationData } from '..';
import { getSportIconColor } from '../../utils/icons';
import {
  DROPIN_GAME_INFO,
  DROPIN_LEAGUE_INFO,
  DROPIN_TEAM_INFO,
  MY_DROPIN_RSVPS,
} from '../../apps/APP/PlayerDashboard/PLAYER_DASHBOARD_QUERY';
import UNRSVP_DROPIN from '../../apps/APP/PlayerDashboard/components/PlayerProgramList/PlayerDropInListItem/UNRSVP_DROPIN_MUTATION';
import { CURRENT_USER_SELF_WITHDRAW } from '../../features/PlayerDetails/PlayerPayment/PLAYER_PAYMENT_MUTATION_AND_QUERIES';

const usePlayerDropInInfo = props => {
  const { gameId, leagueId } = props;

  const history = useHistory();

  const { programRosterRefetch, isEnrolledRefetch, isMember } = useRegistrationData();

  const { showSuccess, showError } = useAlertMessage();

  const [showPopup, setShowPopup] = useState('');

  const [unrsvpDropInMutation, { loading: unrsvpDropInUpdating }] = useMutation(UNRSVP_DROPIN);
  const [selfWithdraw, { loading: selfWithdrawLoading }] = useMutation(CURRENT_USER_SELF_WITHDRAW);

  const { data: gameData, loading: gameLoading } = useQuery(DROPIN_GAME_INFO, {
    skip: !gameId,
    fetchPolicy: 'network-only',
    variables: { gameId },
  });

  const { game } = gameData || {};

  const {
    data: rsvpsData,
    loading: rsvpsLoading,
    refetch: rsvpsRefetch,
  } = useQuery(MY_DROPIN_RSVPS, {
    fetchPolicy: 'network-only',
    variables: {
      pagination: {
        numPerPage: 99,
        pageNum: 1,
      },
    },
  });

  const { rsvps = [] } = rsvpsData?.myRsvps ?? {};
  const rsvpsMap = rsvps.map(item => item?.teamId);

  const teamId = game?.teamIds.filter(team => rsvpsMap.includes(team))[0];

  const { data: leagueData, loading: leagueLoading } = useQuery(DROPIN_LEAGUE_INFO, {
    skip: !(leagueId || game?.league?._id),
    fetchPolicy: 'network-only',
    variables: { leagueId: leagueId || game?.league?._id },
  });

  const { league } = leagueData || {};

  const { programType } = league || '';

  const adjustedProgramType = !leagueId ? ProgramTypeEnum.DROPIN : programType;

  const { data: teamData, loading: teamLoading } = useQuery(DROPIN_TEAM_INFO, {
    skip: !teamId,
    fetchPolicy: 'network-only',
    variables: { teamId },
  });

  const { team } = teamData || {};

  const unrsvp = useCallback(async () => {
    try {
      await unrsvpDropInMutation({
        variables: { gameId },
      });
      await rsvpsRefetch();
      showSuccess('Successfully left drop-in!');
      history.push('/app/dashboard');
    } catch (err) {
      showError(err);
    } finally {
      setShowPopup('');
    }
  }, [unrsvpDropInMutation, gameId, showError, showSuccess, history, rsvpsRefetch]);

  const onRSVPUpdate = useCallback(
    async (registrantId, teamScheduleRefetch) => {
      try {
        await selfWithdraw({
          variables: {
            input: {
              registrantId,
            },
          },
        });
        await teamScheduleRefetch();
        await programRosterRefetch();
        await isEnrolledRefetch();
        showSuccess(
          `Successfully cancelled ${ProgramType[programType].name.toLowerCase()} registration!`
        );
        history.push('/app/dashboard');
      } catch (err) {
        showError(err);
      } finally {
        setShowPopup('');
      }
    },
    [
      selfWithdraw,
      programRosterRefetch,
      isEnrolledRefetch,
      showSuccess,
      programType,
      history,
      showError,
    ]
  );

  const sportName = game?.league.activityName;

  const isRsvpdForDropIn = rsvps.some(r => r?.gameId === game?._id);

  const loading = gameLoading || teamLoading || leagueLoading || rsvpsLoading;

  return {
    loading,
    game,
    league,
    team,
    unrsvp,
    onRSVPUpdate,
    sportName,
    sportIcon: getSportIconColor(sportName),
    programType: adjustedProgramType || '',
    neighborhood: league?.neighborhood?.name || '',
    venueName: league?.venue?.name || '',
    venueUrl: league?.venue?.maps_url_query || '',
    timezone: league?.venue?.timezone,
    captainName: team?.captain?.fullName,
    captainPhone: team?.captain?.phone_number,
    captainEmail: team?.captain?.email,
    colorHex: team?.color?.hex,
    colorName: team?.color?.name,
    teamName: team?.name,
    start_time: game?.start_time,
    end_time: game?.end_time,
    gameName: game?.name,
    contactName: league?.profile?.contact_name,
    contactPhone: league?.profile?.contact_phone,
    showPopup,
    setShowPopup,
    unrsvpDropInUpdating,
    selfWithdrawLoading,
    isRsvpdForDropIn,
    isMember,
  };
};

export default usePlayerDropInInfo;
