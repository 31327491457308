import getProgramTypesFromTabs from '../utils/getProgramTypesFromTabs';
import { SubTabEnum } from '../../../shared/discover-tabs-enum';
import useDiscoverStore from '../DiscoverStore';
import initialDiscoverStore from '../initialDiscoverStore';

const { filters: initialFilters } = initialDiscoverStore;

const updateSubView = subView => {
  const set = useDiscoverStore.setState;
  const get = useDiscoverStore.getState;

  const { filters } = get();
  const { cityName } = filters;

  set(store => ({
    subView,
    currentPage: 1,
    isDaily: subView === SubTabEnum.DAILY,
    defaultProgramTypes: getProgramTypesFromTabs(store.view, subView),
    filters: {
      ...initialFilters,
      cityName,
      isVirtual: subView === SubTabEnum.VIRTUAL || false,
    },
  }));
};

export default updateSubView;
