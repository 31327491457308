import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const PopupWrap = ({
  close,
  style,
  children,
  noModal,
  className,
  hidden,
  removeZIndex = false,
}) => {
  if (hidden) return <span />;

  return (
    <span className={cx('popup-wrap')}>
      {noModal ? null : <div className={cx('modal')} onClick={close} role="alertdialog" />}
      <div className={cx('popup-component', !removeZIndex && 'zindex', className)} style={style}>
        <div className={cx('close-x')} onClick={close} role="button" tabIndex={0}>
          𐄂
        </div>
        {children}
      </div>
    </span>
  );
};

PopupWrap.propTypes = {
  close: PropTypes.func,
  style: PropTypes.shape({}),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  noModal: PropTypes.bool,
  className: PropTypes.string,
  hidden: PropTypes.bool,
  removeZIndex: PropTypes.bool,
};

PopupWrap.defaultProps = {
  close: undefined,
  style: {},
  children: undefined,
  noModal: true,
  className: '',
  hidden: true,
  removeZIndex: false,
};

export default PopupWrap;
