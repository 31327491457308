import baltimoreCrest from '../../images/home/Volo-City-Blue-Crests/Volo-Crest-BaltimoreNavy.png?as=webp';
import bostonCrest from '../../images/home/Volo-City-Blue-Crests/Volo-Crest-BostonNavy.png?as=webp';
import charlestonCrest from '../../images/home/Volo-City-Blue-Crests/Volo-Crest-CharlestonNavy.png?as=webp';
import denverCrest from '../../images/home/Volo-City-Blue-Crests/Volo-Crest-DenverNavy.png?as=webp';
import kidsFoundationCrest from '../../images/home/Volo-City-Blue-Crests/Volo-Crest-KidsFoundationNavy.png?as=webp';
import jerseyCrest from '../../images/home/Volo-City-Blue-Crests/Volo-Crest-NJNavy.png?as=webp';
import nycCrest from '../../images/home/Volo-City-Blue-Crests/Volo-Crest-NYCNavy.png?as=webp';
import sanDiegoCrest from '../../images/home/Volo-City-Blue-Crests/Volo-Crest-SanDiegoNavy.png?as=webp';
import sanFranciscoCrest from '../../images/home/Volo-City-Blue-Crests/Volo-Crest-SanFranciscoNavy.png?as=webp';
import sanJoseCrest from '../../images/home/Volo-City-Blue-Crests/Volo-Crest-SanJoseNavy.png?as=webp';
import seattleCrest from '../../images/home/Volo-City-Blue-Crests/Volo-Crest-SeattleNavy.png?as=webp';
import virtualCrest from '../../images/home/Volo-City-Blue-Crests/Volo-Crest-VirtualNavy.png?as=webp';
import dcCrest from '../../images/home/Volo-City-Blue-Crests/Volo-Crest-WashingtonDCNavy.png?as=webp';
import general from '../../images/home/Volo-City-Blue-Crests/Volo-GeneralNavy.png?as=webp';

export default cityValue => {
  switch (cityValue) {
    case 'Baltimore':
      return <img src={baltimoreCrest} width="100%" alt="Baltimore Crest" />;
    case 'Boston':
      return <img src={bostonCrest} width="100%" alt="Boston Crest" />;
    case 'Charleston':
      return <img src={charlestonCrest} width="100%" alt="Charleston Crest" />;
    case 'Denver':
      return <img src={denverCrest} width="100%" alt="Denver Crest" />;
    case 'Kids Foundation':
      return <img src={kidsFoundationCrest} width="100%" alt="Kids Foundation Crest" />;
    case 'Los Angeles':
      return null; // TODO waiting on crest image
    case 'New York Metro Area':
      return <img src={nycCrest} width="100%" alt="New York Metro Area Crest" />;
    case 'Morristown':
      return <img src={jerseyCrest} width="100%" alt="Morristown Crest" />;
    case 'San Diego':
      return <img src={sanDiegoCrest} width="100%" alt="San Diego Crest" />;
    case 'San Francisco':
      return <img src={sanFranciscoCrest} width="100%" alt="San Francisco Crest" />;
    case 'San Jose':
      return <img src={sanJoseCrest} width="100%" alt="San Jose Crest" />;
    case 'Seattle':
      return <img src={seattleCrest} width="100%" alt="Seattle Crest" />;
    case 'Washington DC':
      return <img src={dcCrest} width="100%" alt="Washington DC Crest" />;
    case 'Virtual':
      return <img src={virtualCrest} width="100%" alt="Virtual Crest" />;
    default:
      return <img src={general} width="100%" alt="Volo Crest" />;
  }
};
