import PropTypes from 'prop-types';
import React from 'react';
import Source from './Source';
import { changeExtension, getExtensionsSources, WEBP } from '../../utils/url-util';

const Picture = props => {
  const { src, alt, width, height, className } = props;

  if (!src) return null;

  const sources = getExtensionsSources(src)
    .map(ext => {
      const srcSet = changeExtension(src, ext);
      return <Source srcSet={srcSet} key={`${srcSet}_${ext}`} />;
    })
    .concat(<Source srcSet={src} key={src} />);

  const optionalProps = {
    width,
    height,
    className,
  };

  const image = (
    <img src={changeExtension(src, WEBP)} loading="lazy" alt={alt} {...optionalProps} />
  );

  return (
    <picture>
      {sources}
      {image}
    </picture>
  );
};

Picture.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

Picture.defaultProps = {
  alt: '',
  width: undefined,
  height: undefined,
  className: undefined,
};

export default Picture;
