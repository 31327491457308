import { createRef } from 'react';
import { compose, setDisplayName, withHandlers, withState, lifecycle } from 'recompose';
import withActionStates from '../../hocs/withActionStates';
import CollapsedMenuPresenter from './CollapsedMenuPresenter';

export default compose(
  setDisplayName('CollapsedMenu'),
  withActionStates({ withAlerts: true }),
  withState('clicked', 'setClicked', false),
  withState('menuRef', 'setMenuRef', createRef()),
  withHandlers({
    checkOutsideClick:
      ({ menuRef, setClicked }) =>
      event => {
        const menu = menuRef.current;
        if (!menu || menu.contains(event.target)) {
          return;
        }
        setClicked(false);
      },
  }),
  lifecycle({
    componentDidMount() {
      const { checkOutsideClick } = this.props;
      document.addEventListener('mousedown', checkOutsideClick);
    },

    componentWillUnmount() {
      const { checkOutsideClick } = this.props;
      document.removeEventListener('mousedown', checkOutsideClick);
    },
  })
)(CollapsedMenuPresenter);
