import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Calendar from './calendar';
import styles from './styles.module.scss';
import Time from './time';
import { Button } from '../../components';

const cx = classNames.bind(styles);

const CalendarPicker = props => {
  const {
    moment,
    calendarOnly,
    className,
    style,
    onDateSelection,
    onChange,
    prevMonthIcon,
    nextMonthIcon,
    doNotDisablePastDates,
    hideSave,
    onSave,
    cancelButton,
    close,
  } = props;

  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (moment?.hour) moment.hour(12);
  }, [moment]);

  const handleClickTab = (newTab, e) => {
    e.preventDefault();
    setTab(newTab);
  };

  const handleSave = e => {
    e.preventDefault();
    if (onSave) onSave();
  };

  return (
    <div className={cx('m-input-moment', 'moment-picker', className)} style={style}>
      {!calendarOnly && (
        <div className={cx('options')}>
          <button
            type="button"
            className={cx('ion-calendar', 'im-btn', { 'is-active': tab === 0 })}
            onClick={handleClickTab(0)}
          >
            Date
          </button>
          <button
            type="button"
            className={cx('ion-clock', 'im-btn', { 'is-active': tab === 1 })}
            onClick={handleClickTab.bind(null, 1)}
          >
            Time
          </button>
        </div>
      )}
      <div className={cx('tabs')}>
        <Calendar
          className={cx('tab', { 'is-active': tab === 0 })}
          moment={moment}
          onDateSelection={onDateSelection}
          onChange={onChange}
          prevMonthIcon={prevMonthIcon}
          nextMonthIcon={nextMonthIcon}
          doNotDisablePastDates={doNotDisablePastDates}
        />
        {!calendarOnly && (
          <Time
            className={cx('tab', { 'is-active': tab === 1 })}
            moment={moment}
            onChange={onChange}
          />
        )}
      </div>
      {!hideSave && (
        <button
          type="button"
          className={cx('im-btn', 'btn-save', 'ion-checkmark')}
          onClick={handleSave}
        >
          Save
        </button>
      )}
      <div className="text-center">
        {!!cancelButton && (
          <Button link className={cx('cancel-button')} onClick={close}>
            Close
          </Button>
        )}
      </div>
    </div>
  );
};

CalendarPicker.propTypes = {
  calendarOnly: PropTypes.bool,
  cancelButton: PropTypes.bool,
  className: PropTypes.string,
  close: PropTypes.func,
  doNotDisablePastDates: PropTypes.bool,
  hideSave: PropTypes.bool,
  moment: PropTypes.shape({
    hour: PropTypes.func,
  }),
  nextMonthIcon: PropTypes.string,
  onChange: PropTypes.func,
  onDateSelection: PropTypes.func,
  onSave: PropTypes.func,
  prevMonthIcon: PropTypes.string,
  style: PropTypes.shape({}),
};

CalendarPicker.defaultProps = {
  prevMonthIcon: 'ion-ios-arrow-left',
  nextMonthIcon: 'ion-ios-arrow-right',
  calendarOnly: false,
  cancelButton: false,
  hideSave: false,
  doNotDisablePastDates: false,
  className: '',
  close: null,
  onChange: null,
  onDateSelection: null,
  onSave: null,
  moment: null,
  style: {},
};

export default CalendarPicker;
