const RevenueCustomParams = Object.freeze({
  FOR_SALE: 'FOR_SALE',
  ACTIVE: 'ACTIVE',
});

const RatingsCustomParams = Object.freeze({
  SPONSOR_BAR: 'SPONSOR_BAR',
  REFEREE: 'REFEREE',
  VENUE: 'VENUE',
});

const SignupsCustomParams = Object.freeze({
  GROUP: 'GROUP',
  PREPAID: 'PREPAID',
  FREE_AGENT: 'FREE_AGENT',
});

export { RevenueCustomParams, RatingsCustomParams, SignupsCustomParams };
