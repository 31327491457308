import { useContext, useEffect, useState, type FC } from 'react';
import classNames from 'classnames/bind';
import { detect } from 'detect-browser';
import { useQuery } from '@apollo/client';
import { useLocation, useRouteMatch } from 'react-router';
import { ORGANIZATION_SPORT_QUERY } from '../HOMEPAGE_QUERIES';
import { OrganizationsContext } from '../../../../contexts';
import getCityOptions from '../../../../utils/get-city-options';
import { checkCity, dashSpaceSwap } from '../../../../utils/url-search-helpers';
import TopBar from './TopBar';
import StickyBottomBar from './StickyBottomBar';
import styles from '../../../../styles/main.module.scss';
import DiscoverBar from './DiscoverBar';

const cx = classNames.bind(styles);

type Props = {
  /**
   * Hides the top bar with links to contact us, player dashboard, admin dashboard, etc.
   * @default false
   * */
  hideTopBar?: boolean;
  /**
   * Hides the bottom bar with Play Volo, Volo Kids, Info, etc.
   * @default false
   * */
  hideStickyBottomBar?: boolean;
  /**
   * Hides the discover bar with links to various discover pages.
   * @default false
   */
  hideDiscoverBar?: boolean;
};

const Header: FC<Props> = ({
  hideTopBar = false,
  hideDiscoverBar = false,
  hideStickyBottomBar = false,
}) => {
  const browserOS = detect()?.os;
  const isMobile = browserOS === 'iOS' || browserOS === 'Android OS';

  const [sticky, setSticky] = useState(false);
  const [hideNav, setHideNav] = useState(true);
  const [hideSports, setHideSports] = useState(isMobile);

  const { path } = useRouteMatch();
  const location = useLocation();

  // verifies that path is a valid org name (ex. /Super-Sportz)
  // otherwise sets cityName to '' (ex. /about-us)
  const checkCityPath = checkCity({ location });
  const cityName = dashSpaceSwap(checkCityPath, 'toSpace');

  const organizationList = useContext(OrganizationsContext);
  const cityOptions = getCityOptions(organizationList);
  const selectedOrg = organizationList?.find(({ name }) => name === cityName);
  const cityOrgId = selectedOrg?._id;

  const { data: { organization } = {} } = useQuery(ORGANIZATION_SPORT_QUERY, {
    skip: !cityOrgId,
    variables: {
      organizationId: cityOrgId,
      getVoloDetails: true,
    },
  });

  const organizationSports = organization?.homepageDetails?.activeSports ?? [];

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 40) {
        if (!sticky) setSticky(true);
      } else if (sticky) setSticky(false);
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [sticky]);

  return (
    <div id="full-header" className={cx('header', sticky && 'squeeze')}>
      {!hideTopBar && <TopBar />}
      {!hideStickyBottomBar && (
        <StickyBottomBar
          cityName={cityName}
          cityOptions={cityOptions}
          hideNav={hideNav}
          sticky={sticky}
          organizationSports={organizationSports}
          logo={selectedOrg?.logo}
          setHideNav={setHideNav}
          hideSports={hideSports}
          setHideSports={setHideSports}
          isMobile={isMobile}
          goToNational={path === `/${dashSpaceSwap(cityName, 'toDash')}`}
        />
      )}
      {!hideDiscoverBar && <DiscoverBar />}
    </div>
  );
};

export default Header;
