import classNames from 'classnames/bind';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import SupportPopup from '../SupportPopup';
import styles from './styles.module.scss';
import { ConfirmButton } from '..';
import { useLogout } from '../../hooks';
import { useAuthStore } from '../../zustand-stores';

const cx = classNames.bind(styles);

const Footer = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [supportPopup, showSupportPopup] = useState(false);

  const isAuthenticated = useAuthStore(store => store.isAuthenticated);

  const { logoutAndRedirect, logoutAndRefetch } = useLogout();

  // TODO | HeaderContainer is currently using the withCurrentUser HOC to determine
  // TODO | the user's roles, so when the user logs out from the home page with
  // TODO | `logoutAndRedirect({ redirectPath: '/' })`, the header query does not refetch
  // TODO | and the UI is not updated to reflect the logged out state. Once HeaderContainer
  // TODO | is refactored to use `const roles = useAuthStore(store => store.roles)`, this
  // TODO | will not be a problem, and we swap back to just `logoutAndRedirect()`.
  // Also these anchor tags should be converted to buttons
  const handleLogout = async () => {
    if (pathname === '/') {
      await logoutAndRefetch();
    } else {
      await logoutAndRedirect();
    }
  };

  return (
    <>
      <SupportPopup hidden={!supportPopup} close={() => showSupportPopup(false)} />
      <footer className={cx('footer')}>
        <div className="container">
          <div className={cx('right')}>
            <ul>
              <li>
                <a
                  onClick={() => showSupportPopup(true)}
                  alt="Contact Support"
                  role="button"
                  tabIndex={0}
                >
                  Support
                </a>
              </li>
              <li>
                <Link to="/legal">Legal</Link>
              </li>
              <li>
                <Link to="/about-us">About</Link>
              </li>
              {isAuthenticated && (
                <li>
                  <a onClick={handleLogout} role="button" tabIndex={0}>
                    Logout
                  </a>
                </li>
              )}
              <li className={cx('copyright')}>
                <ConfirmButton
                  link
                  onClick={() => history.push('/egg')}
                  confirmText="Easter Egg Found!"
                >
                  © {moment().year()} Volo
                </ConfirmButton>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
