import { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const TabNavContainer = props => {
  const { allTabs, initialTab, className, headerWidth, noContainer } = props;
  const [tabSelected, setTabSelected] = useState(initialTab);

  return (
    <div {...{ className }}>
      <div
        className={cx(styles.headerList)}
        style={{ ...(headerWidth ? { width: headerWidth } : {}) }}
      >
        {allTabs.map(({ label }, idx) => (
          <span
            key={label}
            className={cx({ [styles.activeTab]: tabSelected === idx })}
            onClick={() => setTabSelected(idx)}
            role="link"
            tabIndex={0}
          >
            {label}
          </span>
        ))}
      </div>
      <div className={`${!noContainer && 'container'} mt-3`}>
        {allTabs[tabSelected].component()}
      </div>
    </div>
  );
};

TabNavContainer.propTypes = {
  allTabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      // NOTE: Previously the prop type here was `PropTypes.func`, which isn't correct to capture
      // react components. This won't be fixed because this really should be implemented with a
      // router instead.
      // eslint-disable-next-line react/forbid-prop-types
      component: PropTypes.any,
    })
  ).isRequired,
  initialTab: PropTypes.number,
  headerWidth: PropTypes.number,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
  noContainer: PropTypes.bool,
};

TabNavContainer.defaultProps = {
  initialTab: 0,
  headerWidth: null,
  className: '',
  noContainer: false,
};

export default TabNavContainer;
