import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import { devtools } from 'zustand/middleware';
import type { RentalCreationStore } from './types';
import { isProduction } from '../../environment';

export const getInitialState = (): RentalCreationStore => ({
  organization: '',
  venue: '',
  selected_sports: [],
  images: [],
  selected_dates: [],
  gist: '',
  rules: '',
  perks: undefined,
  waiver: '',
  custom_perks: '',
  start_date: '',
  end_date: '',
  existing_images: [],
});

export const useRentalCreationStore = createWithEqualityFn(
  devtools(getInitialState, { enabled: !isProduction }),
  shallow
);

export const resetRentalCreationStore = () => {
  useRentalCreationStore.setState(getInitialState());
};
