import * as _ from 'lodash-es';
import * as braze from '@braze/web-sdk';
import { ACCOUNT_CREATION } from '../shared/braze-helpers';

/**
 * Wrappers for Braze SDK methods
 * The inline require allows us to get around SSR issues with the SDK
 */
export default class Braze {
  constructor() {
    // eslint-disable-next-line global-require
    this.braze = braze;
  }

  /**
   * Braze SDK initializer. Also shows messages and opens a user session
   * @param {String} key - Braze API Key
   * @param {Object} options - Object of Braze SDK options
   * @todo Subscribe to content card updates
   * @todo Initialize web-push: https://www.braze.com/docs/developer_guide/platform_integration_guides/web/push_notifications/integration/
   */
  initialize(key, options) {
    this.braze.initialize(key, options);
    this.braze.automaticallyShowInAppMessages();
    this.braze.openSession();
  }

  /**
   * Changes the active Braze user
   * @param {String} _id - User ID
   */
  changeUser(_id) {
    this.braze.changeUser(_id);
  }

  /**
   * Gets the current Braze user
   * @returns {Object} - Current Braze user
   */
  getUser() {
    return this.braze.getUser();
  }

  /**
   * Event logger for Braze
   * @param {String} eventName - Custom event name, found in {@link /shared/braze-custom-events.js}
   * @param {Object} eventProperties - Custom event attributes
   */
  logCustomEvent(eventName, eventProperties) {
    this.braze.logCustomEvent(eventName, eventProperties);
  }

  /**
   * Custom helper to iterate over delta object, updates Braze SDK with changed values.
   * @param {Object} delta - Attributes to update with Braze SDK
   * @deprecated 1/11/23 in favor of server-side update
   */
  updateUser(delta) {
    const brazeUser = this.getUser();
    let birthday;
    _.forOwn(delta, (value, key) => {
      switch (key) {
        case 'gender':
          brazeUser.setGender(value);
          break;
        case 'email':
          brazeUser.setEmail(value);
          break;
        case 'firstName':
          brazeUser.setFirstName(value);
          break;
        case 'lastName':
          brazeUser.setLastName(value);
          break;
        case 'phoneNumber':
          brazeUser.setPhoneNumber(value);
          break;
        case 'birthday':
          birthday = new Date(value);
          brazeUser.setDateOfBirth(
            birthday.getFullYear(),
            birthday.getMonth() + 1,
            birthday.getDate()
          );
          break;
        case 'homeCity':
          brazeUser.setHomeCity(value);
          break;
        case 'emailSub':
          brazeUser.setEmailNotificationSubscriptionType(value);
          break;
        case 'pushSub':
          brazeUser.setPushNotificationSubscriptionType(value);
          break;
        default:
          break;
      }
    });
  }

  /**
   * Custom helper for player program registration
   * @param {String} _id - User ID
   * @param {Object} props - Attributes to update with Braze SDK
   * @deprecated 1/11/23 in favor of server-side update
   */
  registerUser(_id, props) {
    this.changeUser(_id);
    this.updateUser(props);
    this.logCustomEvent(ACCOUNT_CREATION);
  }
}
