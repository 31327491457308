import { SignupsCustomParams } from './host-dashboard-custom-params-enum';

export enum RegistrantTypeEnum {
  GROUP_CAPTAIN = 'group_captain',
  PRIVATE_CAPTAIN = 'private_captain',
  GROUP_MEMBER = 'group_member',
  PRIVATE_MEMBER = 'private_member',
  INDIVIDUAL = 'individual',
  WAITLISTED_INDIVIDUAL = 'waitlist_individual',
  STAFF = 'staff',
  CANCELLED = 'cancelled',
}

export const RegistrantTypes = Object.freeze({
  [RegistrantTypeEnum.GROUP_CAPTAIN]: {
    value: RegistrantTypeEnum.GROUP_CAPTAIN,
    label: 'Group captain',
    abbrev: 'GC',
    isCaptain: true,
    type: SignupsCustomParams.GROUP,
  },
  [RegistrantTypeEnum.PRIVATE_CAPTAIN]: {
    value: RegistrantTypeEnum.PRIVATE_CAPTAIN,
    label: 'Prepaid team captain',
    abbrev: 'PTC',
    isCaptain: true,
    type: SignupsCustomParams.PREPAID,
  },
  [RegistrantTypeEnum.GROUP_MEMBER]: {
    value: RegistrantTypeEnum.GROUP_MEMBER,
    label: 'Group member',
    abbrev: 'GM',
    type: SignupsCustomParams.GROUP,
  },
  [RegistrantTypeEnum.PRIVATE_MEMBER]: {
    value: RegistrantTypeEnum.PRIVATE_MEMBER,
    label: 'Prepaid team member',
    abbrev: 'PTM',
    type: SignupsCustomParams.PREPAID,
  },
  [RegistrantTypeEnum.INDIVIDUAL]: {
    value: RegistrantTypeEnum.INDIVIDUAL,
    label: 'Free agent',
    abbrev: 'FA',
    type: SignupsCustomParams.FREE_AGENT,
  },
  [RegistrantTypeEnum.WAITLISTED_INDIVIDUAL]: {
    value: RegistrantTypeEnum.WAITLISTED_INDIVIDUAL,
    label: 'Waitlist',
    abbrev: 'W',
  },
  [RegistrantTypeEnum.CANCELLED]: {
    value: RegistrantTypeEnum.CANCELLED,
    label: undefined,
  },
  [RegistrantTypeEnum.STAFF]: {
    value: RegistrantTypeEnum.STAFF,
    label: 'Hosts',
    abbrev: 'H',
  },
});

export const isCaptainType = (kind: RegistrantTypeEnum) =>
  kind === RegistrantTypeEnum.GROUP_CAPTAIN || kind === RegistrantTypeEnum.PRIVATE_CAPTAIN;
export default RegistrantTypeEnum;

export const RegistrantTypeEnumMap = {
  captains: [RegistrantTypeEnum.GROUP_CAPTAIN, RegistrantTypeEnum.PRIVATE_CAPTAIN],
  groups: [RegistrantTypeEnum.GROUP_CAPTAIN, RegistrantTypeEnum.GROUP_MEMBER],
  prepaid_teams: [RegistrantTypeEnum.PRIVATE_CAPTAIN, RegistrantTypeEnum.PRIVATE_MEMBER],
  free_agents: [RegistrantTypeEnum.INDIVIDUAL, RegistrantTypeEnum.WAITLISTED_INDIVIDUAL],
  waitlist: [RegistrantTypeEnum.WAITLISTED_INDIVIDUAL],
};
