const checkValidFilterKey = (update = {}, object = {}) => {
  const exists = true;
  Object.keys(update).forEach(key => {
    // if key does not exist in initialStore, then it should be undefined
    if (object[key] === undefined) {
      throw new Error(
        `key: ${key} does not exist in 2nd argument ${object.name}, check your update object to make sure it has the correct keys`
      );
    }
  });

  return exists;
};

export default checkValidFilterKey;
