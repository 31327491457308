import qs from 'qs';
import { useMemo, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useUrlParams = () => {
  const { search } = useLocation();
  const history = useHistory();

  const memoizedUrlParams = useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search]);

  const updateUrlParams = useCallback(
    updatedParams => {
      const updatedSearch = { ...memoizedUrlParams, ...updatedParams };
      history.replace({ search: qs.stringify(updatedSearch) });
    },
    [memoizedUrlParams, history]
  );

  const replaceUrlParams = useCallback(
    newParams => {
      history.replace({ search: qs.stringify(newParams) });
    },
    [history]
  );

  /**
   * New York and North Jersey orgs were renamed to Ney York Metro Area and Morristown respectively
   * To avoid complicated logic elsewhere, any time they appear in the url params replace them.
   */
  useEffect(() => {
    if (memoizedUrlParams?.cityName && memoizedUrlParams.cityName.toLowerCase() === 'new york') {
      updateUrlParams({ ...memoizedUrlParams, cityName: 'New York Metro Area' });
    } else if (
      memoizedUrlParams?.cityName &&
      memoizedUrlParams.cityName.toLowerCase() === 'north jersey'
    ) {
      updateUrlParams({ ...memoizedUrlParams, cityName: 'Morristown' });
    }
  }, [memoizedUrlParams, updateUrlParams]);

  return {
    updateUrlParams,
    replaceUrlParams,
    urlParams: memoizedUrlParams,
  };
};

export default useUrlParams;
