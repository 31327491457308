import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';
import { ColorIndicator, FilterSelect } from '../../components';
import { jerseyOptions as allJerseyOptions } from '../../shared/jersey-colors';

const JerseyOption = props => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <div className="text-left">
        <ColorIndicator size="14px" color={data} /> {data.name}{' '}
        <small className="text-muted">({data.hex})</small>
      </div>
    </components.Option>
  );
};

JerseyOption.propTypes = {
  data: PropTypes.shape({
    hex: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

const JerseySelectorV2 = props => {
  const {
    omitColors, // optional - used to filter out options
    options,
    onSelect,
    selectedColor,
  } = props;

  const comps = {
    Option: JerseyOption,
    ...components.propsComponents,
  };

  const selectedNames = omitColors ? omitColors.map(({ name }) => name) : [];
  const availColorOptions = (options || allJerseyOptions)
    .filter(({ name }) => !selectedNames.includes(name))
    .map(({ name, hex }) => ({ name, hex }))
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <FilterSelect
      border
      withDropdown
      placeholder="Select a Color"
      components={comps}
      value={(options || allJerseyOptions).find(({ hex }) => hex === selectedColor?.hex)}
      CustomOption={JerseyOption}
      options={availColorOptions}
      getOptionLabel={({ name }) => name}
      onChange={({ name, hex }) => onSelect({ name, hex })}
    />
  );
};

const colorPropType = PropTypes.shape({
  hex: PropTypes.string,
  name: PropTypes.string.isRequired,
});

JerseySelectorV2.propTypes = {
  onSelect: PropTypes.func.isRequired,
  omitColors: PropTypes.arrayOf(colorPropType),
  options: PropTypes.arrayOf(colorPropType), // optional, otherwise will list all
  selectedColor: colorPropType,
};

JerseySelectorV2.defaultProps = {
  options: null,
  omitColors: [],
  selectedColor: null,
};

export default JerseySelectorV2;
