import {
  type ComponentProps,
  type Dispatch,
  type SetStateAction,
  type FC,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { useQuery } from '@apollo/client';
import {
  Modal,
  VoloPassSignupAd,
  View,
  MembershipInterval,
  StripeMembershipIntervalEnum,
  useCurrentUser,
  useUserMembershipStatus,
  useUserVoloPassMembershipStatus,
} from '@rivallapp/volosports-components';

import { Loading } from '../../../../../components';
import {
  VoloPassCitySelect,
  VoloPassSignUpHeader,
  VoloPassSignUp,
  VoloPassWaitlistMessage,
} from './components';
import ORG_MEMBERSHIP_TIERS from './gql/ORG_MEMBERSHIP_TIERS';

interface VoloPassSignUpModalProps extends ComponentProps<typeof Modal> {
  showSignUpModal: boolean;
  setShowSignUpModal: Dispatch<SetStateAction<boolean>>;
  refetch: () => void;
  /** Non-member clicks "Sign Up" on Annual tile, OR monthly member clicks "Sign Up"/"Upgrade" */
  annualOverride?: boolean;
  /** If user comes to this modal through the `/cityName/volo-pass page` */
  cityPageId?: string;
}

/** Sign-up modal for Volo Pass.
 *
 * Allows users to select a city and sign up for that city's Volo Pass membership.
 *
 * Displays a waitlist message for cities that do not have Volo Pass enabled.
 *
 * Allows monthly Volo Pass members to upgrade to an annual membership within the same organization.
 *
 * This shouldn't be a page annual members can get to through the site UI
 */
const VoloPassSignUpModal: FC<VoloPassSignUpModalProps> = ({
  showSignUpModal,
  setShowSignUpModal,
  cityPageId,
  annualOverride = false,
  refetch,
  ...props
}) => {
  const { currentUser, loading: currentUserLoading } = useCurrentUser();
  const [selectedCityId, setSelectedCityId] = useState('');

  const { isVoloPassMember } = useUserMembershipStatus();

  const { voloPassMembershipLoading, voloPassMembershipType, voloPassMembershipOrganizationId } =
    useUserVoloPassMembershipStatus();

  const defaultOrganizationId =
    voloPassMembershipOrganizationId || cityPageId || currentUser.homeOrganization || '';
  const isMonthlyVoloPassMember =
    isVoloPassMember &&
    voloPassMembershipType === MembershipInterval[StripeMembershipIntervalEnum.MONTH].label;

  /** 8/26/2024 - This useEffect is only running when the data initially loads so that we don't have an empty city select.
   * The data fetching is slow and asynchronous and trying to set the state without it,
   * as well as useMemo, useCallback, and setting the defaultValue on the Select all didn't work.
   *
   * It sets the city select to either the organization id for an existing plan or the current user's home org if they're not a member.
   *
   * NOTE: Using the Select to set a new selectedCityId does NOT cause it to run */
  useEffect(() => {
    if (!selectedCityId) setSelectedCityId(defaultOrganizationId);
  }, [defaultOrganizationId, selectedCityId]);

  const { data: selectedOrganizationData, loading: selectedOrganizationDataLoading } = useQuery(
    ORG_MEMBERSHIP_TIERS,
    {
      skip: !selectedCityId && !defaultOrganizationId,
      fetchPolicy: 'cache-and-network',
      variables: {
        id: selectedCityId || defaultOrganizationId!,
      },
    }
  );

  // Memoized because real users likely aren't going to be switching through organizations
  const organizationVoloPassDetails = useMemo(() => {
    const { organization } = selectedOrganizationData || {};
    const { voloPassTiers, name: cityName = '', _id: cityId = '' } = organization || {};
    const {
      isActive,
      annualDollarPrice = 0,
      monthlyDollarPrice = 0,
      monthlyPlanId = '',
      annualPlanId = '',
    } = voloPassTiers || {};

    return {
      cityId,
      cityName,
      isActive,
      annualDollarPrice,
      monthlyDollarPrice,
      monthlyPlanId,
      annualPlanId,
    };
  }, [selectedOrganizationData]);

  const loading = currentUserLoading || voloPassMembershipLoading;

  if (loading) return <Loading />;

  return (
    <Modal
      isOpen={showSignUpModal}
      useRNModal
      onClose={() => setShowSignUpModal(false)}
      size="full"
      {...props}
    >
      <Modal.Content width={{ md: 800 }} minHeight={640} backgroundColor="white.600">
        <Modal.CloseButton />
        <Modal.Body flexDirection={{ md: 'row' }} padding={6}>
          <VoloPassSignupAd maxHeight={300} />
          <View flex={1} paddingLeft={6}>
            <VoloPassSignUpHeader
              isVoloPassCity={organizationVoloPassDetails.isActive}
              isMonthlyVoloPassMember={isMonthlyVoloPassMember}
            />
            {/* Monthly members can only upgrade from their curent plan's city */}
            {!isMonthlyVoloPassMember && (
              <VoloPassCitySelect
                selectedCityId={selectedCityId}
                setSelectedCityId={setSelectedCityId}
              />
            )}
            {organizationVoloPassDetails.isActive ? (
              <VoloPassSignUp
                organizationVoloPassDetails={organizationVoloPassDetails}
                setShowSignUpModal={setShowSignUpModal}
                annualOverride={annualOverride || isMonthlyVoloPassMember}
                refetch={refetch}
              />
            ) : (
              <VoloPassWaitlistMessage
                cityName={organizationVoloPassDetails.cityName}
                loading={selectedOrganizationDataLoading}
              />
            )}
          </View>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default VoloPassSignUpModal;
