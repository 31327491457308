import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { ErrorMessageEnum } from '../shared/error-codes';

type Args = {
  fallbackErrorMsg?: string;
};

/** Alternative to useActionStates that is strictly for alert messages */
const useAlertMessage = ({ fallbackErrorMsg = '' }: Args = {}) => {
  const alert = useAlert();

  // determine error message from code enum or return fallback
  const errorMessage = useCallback(
    (error: any) => {
      if (typeof error === 'string') return error;
      if (!error) return fallbackErrorMsg || 'Unknown Error';
      const keyMatch = Object.keys(ErrorMessageEnum).find(code => error?.message?.includes(code));
      return (
        ErrorMessageEnum[keyMatch as keyof typeof ErrorMessageEnum] ||
        fallbackErrorMsg ||
        error.message ||
        'Unknown Error'
      );
    },
    [fallbackErrorMsg]
  );

  const showError = useCallback<(error: unknown) => void>(
    (value: unknown) => alert.error(errorMessage(value), { timeout: 5000 }),
    [alert, errorMessage]
  );

  const showSuccess = useCallback<(message: string) => void>(
    (message = 'Success') => alert.success(message, { timeout: 3000 }),
    [alert]
  );

  const showInfo = useCallback<(message: string) => void>(
    message => alert.info(message, { timeout: 5000 }),
    [alert]
  );

  return {
    showInfo,
    showError,
    showSuccess,
  };
};

export default useAlertMessage;
