import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import ErrorMessage from '../ErrorMessage/ErrorMessage';
import FancyField from '../FancyField/FancyField';
import Button from '../Button';
import styles from './styles.module.scss';
import { useLogin } from '../../hooks';

const cx = classNames.bind(styles);

/**
 * @param {object} param
 * @param {boolean} [param.preventRedirect]
 * Default behavior for the login form is to redirect the user
 * after logging in (ex. `LoginPage`). To prevent this behavior and instead refresh
 * the current view, set `preventRedirect` to true (ex. `LoginModal`).
 */
const LoginForm = ({ preventRedirect }) => {
  const [password, setPassword] = useState('');
  const [credential, setCredential] = useState('');

  const { error, loading, loginAndRefresh, loginAndRedirect } = useLogin();

  /** @param {React.FormEvent} event */
  const handleSubmit = async event => {
    event.preventDefault();
    if (preventRedirect) {
      await loginAndRefresh({ password, credential });
    } else {
      await loginAndRedirect({ password, credential });
    }
  };

  return (
    <div id="auth" className={cx('login')}>
      <div className={cx('email-container')}>
        <form onSubmit={handleSubmit}>
          <div style={{ margin: '0 auto 15px', display: 'inline-block' }}>
            <FancyField
              type="text"
              value={credential}
              spellCheck="none"
              autoCorrect="none"
              autoComplete="none"
              property="credential"
              autoCapitalize="none"
              label="Email, Telephone or Username"
              updateChange={({ value }) => setCredential(value)}
              initFocus
            />
          </div>
          <div style={{ margin: '0 auto 15px', display: 'inline-block' }}>
            <FancyField
              type="password"
              value={password}
              property="password"
              autoComplete="current-password"
              updateChange={({ value }) => setPassword(value)}
            />
          </div>
          <div>
            <Button
              action
              type="submit"
              onClick={handleSubmit}
              disabled={loading || !credential || !password}
            >
              Log in
            </Button>
          </div>
        </form>
        <Link className={cx('forgot')} to="/login/forgot" target="_blank">
          Forgot password?
        </Link>
        <ErrorMessage error={error} />
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  preventRedirect: PropTypes.bool,
};

LoginForm.defaultProps = {
  preventRedirect: false,
};

export default LoginForm;
