import { MainTabEnum, SubTabEnum } from '../../../shared/discover-tabs-enum';
import { ProgramTypeEnum } from '../../../shared/program-type-enum';

const getProgramTypesFromTabs = (view, subView) => {
  switch (view) {
    case MainTabEnum.SPORTS:
      if (subView === SubTabEnum.DAILY) {
        return [
          ProgramTypeEnum.PICKUP,
          ProgramTypeEnum.PRACTICE,
          ProgramTypeEnum.DROPIN,
          ProgramTypeEnum.CLINIC,
        ];
      }
      if (subView === SubTabEnum.LEAGUE) {
        return [ProgramTypeEnum.LEAGUE];
      }
      if (subView === SubTabEnum.TOURNAMENT) {
        return [ProgramTypeEnum.TOURNAMENT];
      }
      break;
    case MainTabEnum.EVENTS:
      return [ProgramTypeEnum.EVENT];
    case MainTabEnum.CLASSES:
      if (subView === SubTabEnum.DAILY) {
        return [
          ProgramTypeEnum.CLASS,
          ProgramTypeEnum.CLINIC,
          ProgramTypeEnum.SERIES, // update TBD
        ];
      }
      if (subView === SubTabEnum.SERIES) {
        return [ProgramTypeEnum.SERIES];
      }
      break;
    case MainTabEnum.VOLUNTEER:
      return [ProgramTypeEnum.VOLUNTEER_LEAGUE, ProgramTypeEnum.VOLUNTEER_EVENT];
    case MainTabEnum.COURT_RENTALS:
      return [ProgramTypeEnum.RENTAL];
    default:
      throw new Error(`${view} is not a valid MainTab`);
  }

  return [];
};

export default getProgramTypesFromTabs;
