import blacklist from 'blacklist';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

class InputSlider extends Component {
  constructor(props) {
    super(props);

    this.getClientPosition = this.getClientPosition.bind(this);
    this.getPosition = this.getPosition.bind(this);
    this.change = this.change.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.getPos = this.getPos.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  getClientPosition(e) {
    const { touches } = e;
    if (touches && touches.length) {
      const finger = touches[0];
      return {
        x: finger.clientX,
        y: finger.clientY,
      };
    }

    return {
      x: e.clientX,
      y: e.clientY,
    };
  }

  getPosition() {
    let top = ((this.props.y - this.props.ymin) / (this.props.ymax - this.props.ymin)) * 100;
    let left = ((this.props.x - this.props.xmin) / (this.props.xmax - this.props.xmin)) * 100;

    if (top > 100) top = 100;
    if (top < 0) top = 0;
    if (this.props.axis === 'x') top = 0;
    top += '%';

    if (left > 100) left = 100;
    if (left < 0) left = 0;
    if (this.props.axis === 'y') left = 0;
    left += '%';

    return { top, left };
  }

  change(pos, dragEnd) {
    if (!this.props.onChange) return;

    const rect = ReactDOM.findDOMNode(this).getBoundingClientRect();
    const { width } = rect;
    const { height } = rect;
    let { left } = pos;
    let { top } = pos;
    const { axis } = this.props;

    if (left < 0) left = 0;
    if (left > width) left = width;
    if (top < 0) top = 0;
    if (top > height) top = height;

    let x = 0;
    let y = 0;
    if (axis === 'x' || axis === 'xy') {
      x = (left / width) * (this.props.xmax - this.props.xmin) + this.props.xmin;
    }
    if (axis === 'y' || axis === 'xy') {
      y = (top / height) * (this.props.ymax - this.props.ymin) + this.props.ymin;
    }

    this.props.onChange({ x, y });
  }

  handleMouseDown(e) {
    e.preventDefault();
    const dom = this.refs.handle;
    const clientPos = this.getClientPosition(e);

    this.start = {
      x: dom.offsetLeft,
      y: dom.offsetTop,
    };

    this.offset = {
      x: clientPos.x,
      y: clientPos.y,
    };

    document.addEventListener('mousemove', this.handleDrag);
    document.addEventListener('mouseup', this.handleDragEnd);

    document.addEventListener('touchmove', this.handleDrag);
    document.addEventListener('touchend', this.handleDragEnd);
    document.addEventListener('touchcancel', this.handleDragEnd);
  }

  getPos(e) {
    const clientPos = this.getClientPosition(e);
    const rect = ReactDOM.findDOMNode(this).getBoundingClientRect();
    const posX = clientPos.x + this.start.x - this.offset.x;
    const posY = clientPos.y + this.start.y - this.offset.y;

    return {
      left: posX,
      top: posY,
    };
  }

  handleDrag(e) {
    e.preventDefault();
    this.change(this.getPos(e));
  }

  handleDragEnd(e) {
    e.preventDefault();
    document.removeEventListener('mousemove', this.handleDrag);
    document.removeEventListener('mouseup', this.handleDragEnd);

    document.removeEventListener('touchmove', this.handleDrag);
    document.removeEventListener('touchend', this.handleDragEnd);
    document.removeEventListener('touchcancel', this.handleDragEnd);

    if (this.props.onDragEnd) {
      this.props.onDragEnd();
    }
  }

  handleClick(e) {
    const clientPos = this.getClientPosition(e);
    const rect = ReactDOM.findDOMNode(this).getBoundingClientRect();

    this.change(
      {
        left: clientPos.x - rect.left,
        top: clientPos.y - rect.top,
      },
      true
    );
  }

  render() {
    const { axis } = this.props;
    const props = blacklist(
      this.props,
      'axis',
      'x',
      'y',
      'xmin',
      'xmax',
      'ymin',
      'ymax',
      'onChange',
      'onDragEnd',
      'className',
      'onClick'
    );
    const pos = this.getPosition();
    const valueStyle = {};
    if (axis === 'x') valueStyle.width = pos.left;
    if (axis === 'y') valueStyle.height = pos.top;

    props.className = cx('u-slider', `u-slider-${axis}`, this.props.className);

    return (
      <div {...props} onClick={this.handleClick}>
        <div className={cx('value')} style={valueStyle} />
        <div
          className={cx('handle')}
          ref="handle"
          onTouchStart={this.handleMouseDown}
          onMouseDown={this.handleMouseDown}
          onClick={function (e) {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
          style={pos}
        />
      </div>
    );
  }
}

InputSlider.propTypes = {
  axis: PropTypes.string,
  x: PropTypes.number,
  xmax: PropTypes.number,
  xmin: PropTypes.number,
  y: PropTypes.number,
  ymax: PropTypes.number,
  ymin: PropTypes.number,
};

InputSlider.defaultProps = {
  axis: 'x',
  xmin: 0,
  ymin: 0,
};

export default InputSlider;
