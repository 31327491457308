import useDiscoverStore from '../DiscoverStore';

const toggleShowMobileFilters = () => {
  const get = useDiscoverStore.getState;
  const set = useDiscoverStore.setState;

  const { showMobileFilters } = get();
  set({ showMobileFilters: !showMobileFilters });
};

export default toggleShowMobileFilters;
