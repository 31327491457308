import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

// workaround for useSearchParams() from react-router-dom v6

const useSearchQuery = () => {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  return query;
};

export default useSearchQuery;
