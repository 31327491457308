import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './alert.module.scss';

export const alertOptions = {
  position: 'top right',
  offset: '10px',
  transition: 'fade',
  timeout: 10,
  containerStyle: {
    zIndex: 10000,
  },
};

const AlertTemplate = props => {
  const { options, message, style, close } = props;
  return (
    <div className={cx(styles.alertTemplate, styles[options.type])} {...{ style }}>
      {message}

      <a role="button" className={cx(styles.closeX)} onClick={close} onKeyDown={close} tabIndex={0}>
        𐄂
      </a>
    </div>
  );
};

AlertTemplate.propTypes = {
  options: PropTypes.shape({}),
  style: PropTypes.shape({}),
  message: PropTypes.node.isRequired,
};

AlertTemplate.defaultProps = {
  options: {
    type: 'info',
  },
  style: {},
};

export default AlertTemplate;
