/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import defaultSportIcon from '../images/v2icons/color/generic@3x.png?as=webp';

const mediumBlueSportIcon = (sportName, size = '3x') => {
  switch (sportName) {
    case 'Axe Throwing':
      return require(
        `../images/Volo-Sports-Icons/Circles/MediumBlue/AxeThrowing${size}.png?as=webp`
      );
    case 'Badminton':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Badminton${size}.png?as=webp`);
    case 'Bar Games':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/BarGames${size}.png?as=webp`);
    case 'Bar Olympics':
      return require(
        `../images/Volo-Sports-Icons/Circles/MediumBlue/BarOlympics${size}.png?as=webp`
      );
    case 'Baseball':
      return defaultSportIcon;
    // return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/baseball${size}.png`);
    case 'Basketball':
      return require(
        `../images/Volo-Sports-Icons/Circles/MediumBlue/Basketball${size}.png?as=webp`
      );
    case 'Bingo':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Bingo${size}.png?as=webp`);
    case 'Board Games':
      return require(
        `../images/Volo-Sports-Icons/Circles/MediumBlue/BoardGames${size}.png?as=webp`
      );
    case 'Bocce':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Bocce${size}.png?as=webp`);
    case 'Bootcamp':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Bootcamp${size}.png?as=webp`);
    case 'Bowling':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Bowling${size}.png?as=webp`);
    case 'Call of Duty':
      return require(
        `../images/Volo-Sports-Icons/Circles/MediumBlue/CallofDuty${size}.png?as=webp`
      );
    case 'Classes':
    case 'Class':
      return defaultSportIcon;
    case 'Climbing':
      return require('../images/Volo-Sports-Icons/Circles/MediumBlue/Climbing.png?as=webp');
    case 'Cornhole':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Cornhole${size}.png?as=webp`);
    case 'Darts':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Darts${size}.png?as=webp`);
    case 'Dodgeball':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Dodgeball${size}.png?as=webp`);
    case 'Event':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Events${size}.png?as=webp`);
    case 'Field Hockey':
      return require(
        `../images/Volo-Sports-Icons/Circles/MediumBlue/FieldHockey${size}.png?as=webp`
      );
    case 'Fifa':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Fifa${size}.png?as=webp`);
    case 'Food':
      return defaultSportIcon;
    case 'Flip Cup':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/FlipCup${size}.png?as=webp`);
    case 'Flag Football':
    case 'Football':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Football${size}.png?as=webp`);
    case 'Futsal':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Futsal${size}.png?as=webp`);
    case 'Game Show':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/GameShow${size}.png?as=webp`);
    case 'Golf':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Golf${size}.png?as=webp`);
    case 'Hiking':
      return defaultSportIcon;
    case 'Hockey':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Hockey${size}.png?as=webp`);
    case 'Jackbox':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/JackBox${size}.png?as=webp`);
    case 'KanJam':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/KanJam${size}.png?as=webp`);
    case 'Lacrosse':
      return defaultSportIcon;
    case 'Madden':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Madden${size}.png?as=webp`);
    case 'Mario Kart':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/MarioKart${size}.png?as=webp`);
    case 'Mini Golf':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Golf${size}.png?as=webp`);
    case 'NHL':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/NHL${size}.png?as=webp`);
    case 'Pickleball':
      return require(
        `../images/Volo-Sports-Icons/Circles/MediumBlue/Pickleball${size}.png?as=webp`
      );
    case 'Rocket League':
      return require(
        `../images/Volo-Sports-Icons/Circles/MediumBlue/RocketLeague${size}.png?as=webp`
      );
    case 'Street Hockey':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Hockey${size}.png?as=webp`);
    case 'Super Smash Bros':
      return require(
        `../images/Volo-Sports-Icons/Circles/MediumBlue/SuperSmashBros${size}.png?as=webp`
      );
    case 'Kickball':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Kickball${size}.png?as=webp`);
    case 'Laser Tag':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/LaserTag${size}.png?as=webp`);
    case 'Ping Pong':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Pingpong${size}.png?as=webp`);
    case 'Table Tennis':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Pingpong${size}.png?as=webp`);
    case 'Testing':
    case 'Transportation':
      return defaultSportIcon;
    case 'Trivia':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Trivia${size}.png?as=webp`);
    case 'Running':
    case 'Marathon':
    case 'Race':
      return defaultSportIcon;
    case 'Sailing':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Sailing${size}.png?as=webp`);
    case 'ShuffleBoard':
    case 'Shuffle Board':
      return require('../images/Volo-Sports-Icons/Circles/MediumBlue/ShuffleBoard3x.png?as=webp');
    case 'Skeeball':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Skeeball${size}.png?as=webp`);
    case 'Soccer':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Soccer${size}.png?as=webp`);
    case 'Softball':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Softball${size}.png?as=webp`);
    case 'Spin':
      return defaultSportIcon;
    case 'Tennis':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Tennis${size}.png?as=webp`);
    case 'Trip':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Trip${size}.png?as=webp`);
    case 'Ultimate Frisbee':
    case 'Frisbee':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Frisbee${size}.png?as=webp`);
    case 'Volleyball':
      return require(
        `../images/Volo-Sports-Icons/Circles/MediumBlue/Volleyball${size}.png?as=webp`
      );
    case 'Volunteer':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Volunteer${size}.png?as=webp`);
    case 'Yoga':
      return require(`../images/Volo-Sports-Icons/Circles/MediumBlue/Yoga${size}.png?as=webp`);
    default:
      return defaultSportIcon;
  }
};

// eslint-disable-next-line import/prefer-default-export
export const getMediumBlueSportIcon = (sportName, size) => {
  try {
    const img = mediumBlueSportIcon(sportName, size);
    if (typeof img === 'object') {
      // WEIRD FIX FOR WEIRD ISSUE WTF
      return img.default;
    }
    return img;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return defaultSportIcon;
  }
};

/*
 NEW SPORTS ICONS 3/21/2023
*/
/* Blue Icons */
export const getBlueSportIcon = sportName => {
  switch (sportName) {
    case 'Axe Throwing':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/AxeThrowing.png?as=webp');
    case 'Badminton':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Badminton.png?as=webp');
    case 'Bar Games':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/BarGames.png?as=webp');
    case 'Bar Olympics':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/BarOlympics.png?as=webp');
    // case 'Baseball': return (`../images/Volo-Sports-Icons/blue-sport-icons/Baseball.png?as=webp`);
    case 'Basketball':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Basketball.png?as=webp');
    case 'Bingo':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Bingo.png?as=webp');
    case 'Board Games':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/BoardGames.png?as=webp');
    case 'Bocce':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Bocce.png?as=webp');
    case 'Bootcamp':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Bootcamp.png?as=webp');
    case 'Bowling':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Bowling.png?as=webp');
    case 'Broomball':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Broomball.png?as=webp');
    case 'Call of Duty':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/CallofDuty.png?as=webp');
    case 'Climbing':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Climbing.png?as=webp');
    case 'Cornhole':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Cornhole.png?as=webp');
    // case 'Cricket': return require(`../images/Volo-Sports-Icons/blue-sport-icons/Cricket.png?as=webp`);
    // case 'Credit': return require(`../images/Volo-Sports-Icons/blue-sport-icons/Credit.png?as=webp`);
    // case 'Cycling': return require(`../images/Volo-Sports-Icons/blue-sport-icons/Cycling.png?as=webp`);
    case 'Darts':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Darts.png?as=webp');
    case 'Dodgeball':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Dodgeball.png?as=webp');
    // case 'Duckpin': return require(`../images/Volo-Sports-Icons/blue-sport-icons/Duckpin.png?as=webp`);
    case 'E-Sports':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/eSports.png?as=webp');
    case 'Event':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Events.png?as=webp');
    case 'Field Hockey':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/FieldHockey.png?as=webp');
    case 'FIFA':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Fifa.png?as=webp');
    case 'Flag Football':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/FlagFootball.png?as=webp');
    case 'Flip Cup':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/FlipCup.png?as=webp');
    // case 'Football': return require(`../images/Volo-Sports-Icons/blue-sport-icons/FlagFootball.png?as=webp`);
    case 'Frisbee':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Frisbee.png?as=webp');
    case 'Futsal':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Futsal.png?as=webp');
    // case 'Food': return require(`../images/Volo-Sports-Icons/blue-sport-icons/Food.png?as=webp`);
    case 'Game Show':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/GameShow.png?as=webp');
    case 'Golf':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Golf.png?as=webp');
    case 'Happy Hour':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/HappyHour.png?as=webp');
    case 'Hiking':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Hiking.png?as=webp');
    case 'Hockey':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Hockey.png?as=webp');
    // case 'Ice Hockey': return (`../images/Volo-Sports-Icons/blue-sport-icons/Hockey.png?as=webp`);
    case 'Jackbox':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/JackBox.png?as=webp');
    case 'KanJam':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/KanJam.png?as=webp');
    case 'Kickball':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Kickball.png?as=webp');
    case 'Lacrosse':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Lacrosse.png?as=webp');
    case 'Laser Tag':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/LaserTag.png?as=webp');
    // case 'Lawn Bowling': return (`../images/Volo-Sports-Icons/blue-sport-icons/LawnBowling.png?as=webp`);
    case 'Madden':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Madden.png?as=webp');
    case 'Mario Kart':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/MarioKart.png?as=webp');
    case 'Medical':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Medical.png?as=webp');
    case 'Mini Golf':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/MiniGolf.png?as=webp');
    case 'NHL':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/NHL.png?as=webp');
    case 'Pickleball':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/PickleBall.png?as=webp');
    case 'Ping Pong':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Pingpong.png?as=webp');
    case 'Rocket League':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/RocketLeague.png?as=webp');
    // case 'Rugby': return (`../images/Volo-Sports-Icons/blue-sport-icons/Rugby.png?as=webp`);
    // case 'Running': return (`../images/Volo-Sports-Icons/blue-sport-icons/Running.png?as=webp`);
    case 'Sailing':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Sailing.png?as=webp');
    case 'Shuffle Board':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/ShuffleBoard.png?as=webp');
    case 'Skeeball':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Skeeball.png?as=webp');
    case 'Skiing':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Skiing.png?as=webp');
    case 'Soccer':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Soccer.png?as=webp');
    case 'Softball':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Softball.png?as=webp');
    case 'Spin':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Spin.png?as=webp');
    case 'Squash':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Squash.png?as=webp');
    case 'Street Hockey':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/StreetHockey.png?as=webp');
    case 'Super Smash Bros':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Smash.png?as=webp');
    // case 'Spikeball': return (`../images/Volo-Sports-Icons/blue-sport-icons/Spikeball.png?as=webp`);
    case 'Tennis':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Tennis.png?as=webp');
    // case 'Testing': return (`../images/Volo-Sports-Icons/blue-sport-icons/testing.png?as=webp`);
    case 'Transportation':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Transportation.png?as=webp');
    case 'Trip':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Trip.png?as=webp');
    case 'Trivia':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Trivia.png?as=webp');
    case 'Ultimate Frisbee':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/UltimateFrisbee.png?as=webp');
    case 'Volleyball':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Volleyball.png?as=webp');
    case 'VoloPass':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/VoloPass.png?as=webp');
    case 'Volunteer':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Volunteer.png?as=webp');
    case 'Yoga':
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Yoga.png?as=webp');
    default:
      return require('../images/Volo-Sports-Icons/blue-sport-icons/Default.png?as=webp');
  }
};
