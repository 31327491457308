import React from 'react';
// import PropTypes from 'prop-types';
import getLinks from './links';
import HeaderLinks from '../HeaderLinks';

export default props => {
  const { isStaff, isHost } = props;
  const links = getLinks(isStaff, isHost);

  return <HeaderLinks {...props} links={links} />;
};
