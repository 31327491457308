import { useQuery, gql } from '@apollo/client';

const GET_LEAGUE_ID_FROM_GAME = gql`
  query game($_id: ID!) {
    game(_id: $_id) {
      _id
      league {
        _id
      }
    }
  }
`;

const useGetLeagueIdFromGameId = gameId => {
  const {
    data: gameData,
    loading,
    error,
  } = useQuery(GET_LEAGUE_ID_FROM_GAME, {
    skip: !gameId,
    fetchPolicy: 'cache-and-network',
    variables: {
      _id: gameId,
    },
  });
  const leagueId = gameData?.game?.league?._id || null;

  return { leagueId, loading, error };
};

export default useGetLeagueIdFromGameId;
