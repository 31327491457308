import { sessionAPI } from '../../../utils/api';
import useAuthStore from '../AuthStore';
import syncUserWithServices from '../helpers/syncUserWithServices';

const isPhone = (credential: string) =>
  /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/.test(credential);

export type LoginArgs = {
  /** Credential can be either a phone number, email or username */
  credential: string;
  password: string;
};

/**
 * Action to call the login endpoint with the provided credentials.
 *
 * If successful, it will set the user's state and provide context to Braze and Sentry.
 *
 * If unsuccessful, an error will be thrown.
 */
const login = async ({ credential, password }: LoginArgs) => {
  if (!credential) throw new Error('Email, username, or phone number required.');
  if (!password) throw new Error('Password required.');

  const response = await sessionAPI.logIn({
    password,
    [isPhone(credential) ? 'phone' : 'email']: credential,
  });

  console.log('[AuthStore] Login success');

  useAuthStore.setState({
    isAuthenticated: true,
    userId: response.data?.user_id,
    roles: response.data?.roles || [],
    authToken: response.data?.authObj?.token,
  });

  syncUserWithServices({
    userId: response.data?.user_id,
    email: response.data?.user?.email,
  });

  return response.data;
};

export default login;
