import PropTypes from 'prop-types';
import React from 'react';
import SupportPopupBody from './SupportPopupContainer';
import Popup from '../../components/Popup';

const SupportPopup = ({ hidden, close }) => (
  <Popup {...{ hidden, close }}>
    <div className="text-left">
      <SupportPopupBody />
    </div>
  </Popup>
);

SupportPopup.propTypes = {
  hidden: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default SupportPopup;
