import { type FC, useMemo } from 'react';
import { Heading } from '@rivallapp/volosports-components';

import { type VoloPassSignUpHeaderProps } from './types';

const VoloPassSignUpHeader: FC<VoloPassSignUpHeaderProps> = ({
  isVoloPassCity = true,
  isMonthlyVoloPassMember = false,
}) => {
  const headerText = useMemo(() => {
    if (!isVoloPassCity) return 'COMING SOON TO YOUR CITY!';
    if (isMonthlyVoloPassMember) return 'ANNUAL MEMBERSHIP';
    return 'BECOME A MEMBER';
  }, [isVoloPassCity, isMonthlyVoloPassMember]);
  return (
    <Heading color="secondary.600" fontSize={44} lineHeight={44} letterSpacing={-0.5}>
      {headerText}
    </Heading>
  );
};

export default VoloPassSignUpHeader;
