import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import Select from 'react-select';
import styles from './styles.module.scss';

import AnonNav from './AnonNav';
import HostNav from './HostNav';
import PlayerNav from './PlayerNav';

import { addSearchParams } from '../../utils/url-search-helpers';

import Picture from '../../components/Picture/Picture';

const cx = classNames.bind(styles);

class AppHeader extends Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      scrolling: 0,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    if (this.menuTimeout) {
      clearTimeout(this.menuTimeout);
    }
  }

  handleScroll(event) {
    const scrollPosition = event.srcElement?.scrollingElement?.scrollTop ?? 0;
    this.setState({ scrolling: scrollPosition });
  }

  render() {
    const {
      isAuth,
      isRfo,
      organization,
      organizationId,
      location,
      showDropdown,
      organizationList = [],
    } = this.props;
    const { scrolling } = this.state;

    let RightMenu;

    if (!isAuth) {
      RightMenu = AnonNav;
    } else if (isRfo) {
      RightMenu = HostNav;
    } else {
      RightMenu = PlayerNav;
    }

    let orgLogo = null;
    if (isRfo) {
      const { logo, icon, name } = organization || {};
      const imgSrc = logo || icon;
      if (imgSrc) {
        orgLogo = <Picture className={cx('org-logo')} src={imgSrc} alt={name} />;
      }
    }

    const handleCityChange = change => {
      if (organizationId !== change.value) {
        const { history } = this.props;
        addSearchParams({ history, params: { organization: change.value } });
      }
    };

    // add each url you want to be able to toggle cities
    const showCityPaths = [
      'programs',
      'messages',
      'dashboard',
      'settings',
      'staff',
      'promo-codes',
      'create',
      'leagues',
      'beta/leagues',
      'ratings',
      'approved-venues',
      'city-page-details',
      'city-hero-details',
      'neighborhoods',
      'stripe-setup',
      'daily-dropins',
      'rentals',
    ].map(route => `/rfo/${route}`);

    return (
      <header className={cx('header', { scrolling })}>
        <div className="container align-items-center justify-content-between">
          <div className={cx('left')}>
            {showDropdown &&
            'pathname' in location &&
            showCityPaths.some(path => location.pathname.includes(path)) ? (
              <div className={cx('home-link')}>
                <Link to="/" alt="Volo Sports">
                  <div className={cx('logo-thumb')} />
                </Link>
                <span className={cx('bar')} />
                <Select
                  value={organizationList.find(org => org.value === organizationId) ?? {}}
                  options={organizationList}
                  onChange={handleCityChange}
                  className={cx('city-select')}
                  styles={{
                    control: base => ({
                      ...base,
                      height: '35px',
                      minHeight: '35px',
                    }),
                  }}
                />
              </div>
            ) : (
              <Link
                className={cx('home-link')}
                to={{
                  pathname: '/rfo/leagues',
                  search: `?organization=${organizationId}`,
                }}
                alt="Volo"
              >
                <div className={cx('logo-thumb')} />
                {orgLogo && <span className={cx('bar')} />}
                {orgLogo}
              </Link>
            )}
          </div>
          <RightMenu
            {...this.props}
            scrolling={scrolling}
            toggleMobileMenu={this.toggleMobileMenu}
            closeMenuAfterDelay={this.closeMenuAfterDelay}
          />
        </div>
      </header>
    );
  }
}

AppHeader.propTypes = {
  isRfo: PropTypes.bool.isRequired,
  isAuth: PropTypes.bool.isRequired,
  isHost: PropTypes.bool,
  isStaff: PropTypes.bool,
  currentUser: PropTypes.shape({
    _id: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
  }),
  organization: PropTypes.shape({
    _id: PropTypes.string,
    logo: PropTypes.string,
    icon: PropTypes.string,
    name: PropTypes.string,
  }),
  cities: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
  organizationId: PropTypes.string,
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  organizationList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  showDropdown: PropTypes.bool,
};

AppHeader.defaultProps = {
  isStaff: false,
  isHost: false,
  currentUser: null,
  organization: null,
  cities: [],
  organizationId: null,
  organizationList: [],
  showDropdown: false,
};

export default AppHeader;
