import PropTypes from 'prop-types';
import FroalaEditor from 'react-froala-wysiwyg';
import getToolbarButtons from './tools/getToolbarButtons';
import { baseURL, froalaKey } from '../../environment.js';
import { Error, Loading } from '..';
import { useCurrentUser } from '../../hooks';

import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';
import { REQUEST_HEADERS } from '../../utils/api/request';

const TextEditor = props => {
  const { disableMedia, height, html, onChange, onFocus, placeholder, organizationId } = props;

  const {
    loading,
    error,
    currentUser: { _id: user_id, organizationId: curUserOrganizationId } = {},
  } = useCurrentUser();

  if (loading) return <Loading type="inline" />;
  if (error) return <Error error={error} />;

  return (
    <FroalaEditor
      tag="textarea"
      config={{
        ...(onFocus
          ? {
              events: {
                click() {
                  onFocus();
                },
              },
            }
          : {}),
        ...(height ? { height } : {}),
        heightMin: 300,
        placeholder,
        toolbarButtons: getToolbarButtons({ disableMedia }),
        imageUploadURL: `${baseURL}/api/uploads/email/${organizationId || curUserOrganizationId}/${user_id}`,
        imageManagerLoadURL: `${baseURL}/api/uploads/get-images/${organizationId || curUserOrganizationId}`,
        requestWithCredentials: true,
        requestHeaders: REQUEST_HEADERS,
        key: froalaKey,
      }}
      pluginsEnabled={['charCounter']}
      model={html}
      onModelChange={onChange}
    />
  );
};

TextEditor.propTypes = {
  disableMedia: PropTypes.bool,
  height: PropTypes.number,
  html: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  organizationId: PropTypes.string,
};

TextEditor.defaultProps = {
  disableMedia: false,
  height: undefined,
  html: '',
  onFocus: undefined,
  placeholder: 'Edit your content here',
  organizationId: undefined,
};

export default TextEditor;
