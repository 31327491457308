import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from '../../styles/main.module.scss';

const cx = classNames.bind(styles);

const Card = props => {
  const { children, classes, height, leftStripe, noShadow } = props;
  return (
    <div
      className={cx(!noShadow && 'default-shadow', classes)}
      style={{
        ...(height ? { height: `${height}px` } : {}),
        ...(leftStripe ? { borderLeft: '2px solid #EAFF70' } : {}),
      }}
    >
      {children}
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  classes: PropTypes.string,
  leftStripe: PropTypes.bool,
  noShadow: PropTypes.bool,
  height: PropTypes.number,
};

Card.defaultProps = {
  classes: '',
  height: 0,
  leftStripe: false,
  noShadow: false,
};

export default Card;
