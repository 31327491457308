import classNames from 'classnames/bind';
import * as _ from 'lodash-es';
import React, { Component } from 'react';
import styles from './styles.module.scss';
import Time from './time';
import TimeInterval from './time-interval';

const cx = classNames.bind(styles);

class TimePicker extends Component {
  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
  }

  handleSave(e) {
    e.preventDefault();
    if (this.props.onSave) this.props.onSave();
  }

  render() {
    const m = this.props.moment;
    const props = _.omit(this.props, ['className', 'moment', 'onSave', 'timeframe', 'hideSave']);
    props.className = cx('m-input-moment', 'time-picker', 'moment-picker', this.props.className);

    return (
      <div {...props}>
        <div>
          {this.props.timeframe ? (
            <TimeInterval
              className={cx('is-active')}
              moment={m}
              onChange={this.props.onChange}
              interval={this.props.interval}
            />
          ) : (
            <Time
              className={cx('tab', 'is-active')}
              moment={m}
              onChange={this.props.onChange}
              interval={this.props.interval}
            />
          )}
        </div>
        {!this.props.hideSave ? (
          <button
            type="button"
            className={cx('im-btn', 'btn-save', 'ion-checkmark', {
              'time-interval': this.props.timeframe,
            })}
            onClick={this.handleSave}
          >
            Done
          </button>
        ) : null}
      </div>
    );
  }
}

export default TimePicker;
