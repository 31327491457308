/* eslint-disable no-nested-ternary */
import { camelCase } from '@rivallapp/volosports-components';
import { dashSpaceSwap } from '../utils/url-search-helpers';
import { Auth } from '../zustand-stores/AuthStore';

export const noHeader = pageName => {
  switch (pageName) {
    case 'egg':
    case 'waiver':
    case 'forgot':
    case 'reset':
    case 'homepage':
    case 'faq':
    case 'aboutUs':
    case 'corporate':
    case 'rosterGrid':
    case 'virtual':
    case 'rules':
    case 'trivia':
    case 'policies':
    case 'contactUs':
    case 'legal':
    case 'register':
    case 'l':
    case 'g':
    case 'd':
    case 'game':
    case 'drop-in':
    case 'discover':
      // case 'signup':
      return true;
    default:
      return false;
  }
};

export const getPageName = ({ pathname = '', organizationsList }) => {
  const orgNames = organizationsList?.map(({ name }) => name);

  if (pathname.includes('volo-pass')) return 'voloPass';

  if (
    pathname === '/' ||
    pathname === '/login' ||
    orgNames.includes(dashSpaceSwap(pathname.split('/')[1], 'toSpace'))
  )
    return 'homepage';

  const nameIdx = pathname.includes('/app') || pathname.includes('/rfo') ? 2 : 1;

  return camelCase(pathname.split('/')[nameIdx]);
};

export const getAppName = ({ pathname = '', organizationsList }) => {
  const orgNames = organizationsList?.map(({ name }) => name);
  let path = pathname.split('/')[1] || '';

  if (orgNames.includes(dashSpaceSwap(path, 'toSpace'))) path = '';

  switch (path) {
    case 'app':
      return 'rfp';
    case 'inv':
      return 'refer';
    case '':
    case 'login':
    case 'aboutUs':
    case 'faq':
    case 'corporate':
    case 'volo-pass':
    case 'rosterGrid':
    case 'contactUs':
    case 'virtual':
    case 'rules':
    case 'trivia':
    case 'policies':
      return 'home';
    case 'discover':
      return Auth.getIsAuthenticated() ? 'home' : 'discover';
    default:
      return path || '';
  }
};

const getPageOptions = ({ pathname, organizationsList }) => {
  const appName = getAppName({ pathname, organizationsList });
  const pageName = getPageName({ pathname, organizationsList });

  return {
    appName,
    pageName,
    noHeader: noHeader(pageName),
  };
};

export default getPageOptions;
