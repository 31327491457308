import * as _ from 'lodash-es';
import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../components/Button';
import { LeagueStageEnum } from '../../shared/league-status-enum';
import RoleEnum from '../../shared/role-enum';

const br = '%0D%0A';
const supportLink = `
mailto:info@volosports.com?subject=👋
&body=${br}${br}${br}------------------------${br}${br}
Thanks for reaching out to Volo. If you're experiencing issues, the below information can help us help you.${br}${br}
Device (iPhone X, PC, etc): ${br}
Browser (Chrome, Edge, etc): ${br}
Email connected to your Volo account: ${br}
League(s) you're currently in: ${br}
Description of the problem you're experiencing: ${br}

`;

const ProgramList = ({ leagueConnections }) => {
  const filtered = leagueConnections.filter(
    ({ league }) => league.stage !== LeagueStageEnum.ARCHIVED
  );
  return (
    <div>
      <h2 style={{ fontWeight: 500 }} className="mt-4 mb-2">
        For questions about programs you're in
      </h2>
      <div className="mt-1">
        {filtered.map(({ league }) => (
          <div key={`sp${league._id}`} className="mb-3">
            <div className="d-flex justify-content-between">
              <div>
                <div style={{ fontWeight: 600 }}>{league.name}</div>
                {_.get(league, 'contact.name')}{' '}
              </div>
              <div>
                <small>
                  <Button
                    small
                    default
                    disabled={!_.get(league, 'contact.email')}
                    href={`mailto:${league.contact.email}`}
                  >
                    Email
                  </Button>{' '}
                  <Button
                    small
                    default
                    disabled={!_.get(league, 'contact.phoneNumber')}
                    href={`tel:${league.contact.phoneNumber}`}
                  >
                    Call
                  </Button>
                </small>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const LeagueSupport = ({ roles, leagueConnections }) => {
  // If it's a host who has never participated as a player
  if ((roles || []).includes(RoleEnum.OWNER) && !(leagueConnections || []).length) {
    return null;
  }

  return (
    <div className="text-left">
      <div>
        <h2 style={{ fontWeight: 500 }} className="mb-2">
          For a majority of questions
        </h2>
        <p>
          Contact the host of the program you have questions about or are trying to register for.
          You can find host contact information on program pages under <i>Contact host</i>.
        </p>
      </div>

      {_.get(leagueConnections, '0') && <ProgramList {...{ leagueConnections }} />}
    </div>
  );
};

const SupportPopupPresenter = props => {
  const { currentUser } = props;

  return (
    <div>
      <LeagueSupport {...(currentUser || {})} />
      <div className="mt-4">
        <h2 style={{ fontWeight: 500 }} className="mb-2">
          For technical support
        </h2>
        <p>
          For <u>technical issues only</u> (login, signup, bugs, etc.), please reach out to{' '}
          <a href={supportLink}>info@volosports.com</a>.
        </p>
      </div>
    </div>
  );
};

SupportPopupPresenter.propTypes = {};

export const SupportPopupLoading = () => <div>Loading...</div>;
export default SupportPopupPresenter;
