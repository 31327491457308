import * as _ from 'lodash-es';

export const jerseyColors = [
  {
    name: 'White',
    hex: '#ffffff',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'common'],
  },
  {
    name: 'Black',
    hex: '#000000',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'common'],
  },
  {
    name: 'Sports Grey',
    hex: '#afafaf',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'common'],
  },
  {
    name: 'Navy',
    hex: '#1c203b',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'common'],
  },
  {
    name: 'Natural',
    hex: '#e9e3cb',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', '100% Cotton T-shirt', 'common'],
  },
  {
    name: 'Indigo Blue',
    hex: '#626c79',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'common'],
  },
  {
    name: 'Sapphire',
    hex: '#0387b8',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'common'],
  },
  {
    name: 'Carolina Blue',
    hex: '#91b0e6',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'common'],
  },
  {
    name: 'Purple',
    hex: '#4f237a',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'common'],
  },
  {
    name: 'Red',
    hex: '#e00928',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'common'],
  },
  {
    name: 'Maroon',
    hex: '#721e38',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'common'],
  },
  {
    name: 'Cardinal',
    hex: '#922238',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'common'],
  },
  {
    name: 'Heliconia',
    hex: '#dd0584',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'common'],
  },
  {
    name: 'Azalea',
    hex: '#e274a5',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'common'],
  },
  {
    name: 'Gold',
    hex: '#f0a93d',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'common'],
  },
  {
    name: 'Daisy',
    hex: '#ecd45a',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'common'],
  },
  {
    name: 'Orange',
    hex: '#dc582a',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'common'],
  },
  {
    name: 'Forest',
    hex: '#14370d',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'common'],
  },
  {
    name: 'Irish Green',
    hex: '#3ba658',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'common'],
  },
  {
    name: 'Lime',
    hex: '#86b660',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'common'],
  },
  {
    name: 'Charcoal',
    hex: '#494949',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Sky',
    hex: '#92cfe1',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Light Blue',
    hex: '#afcdf3',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Antique Cherry Red',
    hex: '#811e36',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Safety Pink',
    hex: '#e97299',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Light Pink',
    hex: '#e9c6d4',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Cornsilk',
    hex: '#feed8c',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Sand',
    hex: '#d1c8b7',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Military Green',
    hex: '#5f6a50',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Dark Chocolate',
    hex: '#413829',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Safety Orange',
    hex: '#ff7900',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Texas Orange',
    hex: '#b25f2d',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Antique Irish Green',
    hex: '#39804f',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Kiwi',
    hex: '#85a35b',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Mint Green',
    hex: '#c8eec1',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Safety Green',
    hex: '#e6f53a',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Dark Heather',
    hex: '#4d4d4d',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Royal',
    hex: '#395389',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'common'],
  },
  {
    name: 'Orchid',
    hex: '#b79dcc',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'common'],
  },
  {
    name: 'Ice Grey',
    hex: '#c9c9c9',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Ash',
    hex: '#e3e4e6',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Heather Sapphire',
    hex: '#5790b4',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', '100% Cotton T-shirt', 'uncommon'],
  },

  {
    name: 'Chestnut',
    hex: '#7b6a5a',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', 'common'],
  },
  {
    name: 'Tangerine',
    hex: '#f29c5e',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', 'common'],
  },
  {
    name: 'Jade Dome',
    hex: '#008b80',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', 'common'],
  },
  {
    name: 'Kelly',
    hex: '#108b5d',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', 'common'],
  },

  {
    name: 'Blue Dusk',
    hex: '#262e3b',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Antique Royal',
    hex: '#24365f',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Metro Blue',
    hex: '#3b4165',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Heather Navy',
    hex: '#4c5064',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Galapagos Blue',
    hex: '#104f5f',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Stone Blue',
    hex: '#70869b',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Heather Indigo',
    hex: '#738a9a',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Iris',
    hex: '#6588c8',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Cherry Red',
    hex: '#d2023e',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Heather Cardinal',
    hex: '#a04759',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Vegas Gold',
    hex: '#e6d5a7',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Tan',
    hex: '#b8a789',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Prairie Dust',
    hex: '#848065',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Olive',
    hex: '#5a584b',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', 'uncommon'],
  },
  {
    name: 'Pistachio',
    hex: '#b0ce92',
    brand: 'Gildan',
    categories: ['Ultra Cotton T-shirt', 'uncommon'],
  },

  {
    name: 'Gravel',
    hex: '#8a8a8b',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Graphite Heather',
    hex: '#7f7f7f',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Tweed',
    hex: '#515254',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Blackberry',
    hex: '#342d3c',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Cobalt',
    hex: '#35304d',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Midnight',
    hex: '#3a4850',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Neon Blue',
    hex: '#3c5c8a',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Antique Sapphire',
    hex: '#007d9e',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Tropical Blue',
    hex: '#219099',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Lilac',
    hex: '#494a7a',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Violet',
    hex: '#6d6b9a',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Heather Radiant Orchid',
    hex: '#c58fad',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Berry',
    hex: '#6f214b',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Garnet',
    hex: '#6e171e',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Heather Red',
    hex: '#a54d58',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Antique Orange',
    hex: '#9a432b',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Sunset',
    hex: '#c77259',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Coral Silk',
    hex: '#e76e68',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Tennessee Orange',
    hex: '#ec913f',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Yellow Haze',
    hex: '#f3d3a0',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Neon Green',
    hex: '#c2ee7c',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Electric Green',
    hex: '#7ca454',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Turf Green',
    hex: '#3c6039',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Antique Jade Dome',
    hex: '#00756e',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Heather Military Green',
    hex: '#74746d',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Russet',
    hex: '#42322d',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Brown Savana',
    hex: '#6e5f52',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Old Gold',
    hex: '#b2a074',
    brand: 'Gildan',
    categories: ['100% Cotton T-shirt'],
  },

  {
    name: 'White',
    hex: '#ffffff',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'common'],
  },
  {
    name: 'Black',
    hex: '#000000',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'common'],
  },
  {
    name: 'Heather Grey',
    hex: '#a8adb0',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'common'],
  },
  {
    name: 'Navy',
    hex: '#1e2949',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'common'],
  },
  {
    name: 'Royal',
    hex: '#355f9b',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'common'],
  },
  {
    name: 'Red',
    hex: '#b20216',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'common'],
  },
  {
    name: 'Cranberry',
    hex: '#6a0c26',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'common'],
  },
  {
    name: 'Grass',
    hex: '#57a460',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'common'],
  },
  {
    name: 'Teal',
    hex: '#00a3c4',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'common'],
  },
  {
    name: 'Purple',
    hex: '#675098',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'common'],
  },

  {
    name: 'Ash Grey',
    hex: '#dadadd',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'uncommon'],
  },
  {
    name: 'New Silver',
    hex: '#c3c3c3',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'uncommon'],
  },
  {
    name: 'Asphalt',
    hex: '#5a5a5a',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'uncommon'],
  },
  {
    name: 'Lapis',
    hex: '#3d4986',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'uncommon'],
  },
  {
    name: 'Baby Blue',
    hex: '#99bad9',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'uncommon'],
  },
  {
    name: 'Light Blue',
    hex: '#d3dfeb',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'uncommon'],
  },
  {
    name: 'Turquoise',
    hex: '#89bbc9',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'uncommon'],
  },
  {
    name: 'Aqua',
    hex: '#86d2e2',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'uncommon'],
  },
  {
    name: 'Light Aqua',
    hex: '#c5e5d3',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'uncommon'],
  },
  {
    name: 'Pink',
    hex: '#e2a1c3',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'uncommon'],
  },
  {
    name: 'Light Pink',
    hex: '#f0d3d8',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'uncommon'],
  },
  {
    name: 'Lemon',
    hex: '#f2ef9e',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'uncommon'],
  },
  {
    name: 'Gold',
    hex: '#ebba15',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'uncommon'],
  },
  {
    name: 'Sunshine',
    hex: '#efd782',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'uncommon'],
  },
  {
    name: 'Creme',
    hex: '#ece1cd',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'uncommon'],
  },
  {
    name: 'Brown',
    hex: '#4a3728',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'uncommon'],
  },
  {
    name: 'Orange',
    hex: '#f05438',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'uncommon'],
  },
  {
    name: 'Lime',
    hex: '#b7dbb3',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'uncommon'],
  },
  {
    name: 'Mint',
    hex: '#89c2a1',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'uncommon'],
  },
  {
    name: 'Kelly Green',
    hex: '#157b49',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'uncommon'],
  },
  {
    name: 'Lieutenant',
    hex: '#797a68',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'uncommon'],
  },
  {
    name: 'Olive',
    hex: '#5b683a',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'uncommon'],
  },
  {
    name: 'Army',
    hex: '#645d4b',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'uncommon'],
  },
  {
    name: 'Forest',
    hex: '#214838',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'uncommon'],
  },
  {
    name: 'Eggplant',
    hex: '#5b425c',
    brand: 'American Apparel',
    categories: ['Jersey T-shirt', 'uncommon'],
  },

  {
    name: 'White',
    hex: '#ffffff',
    brand: 'Hanes',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Ash',
    hex: '#dfe0e2',
    brand: 'Hanes',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Light Steel',
    hex: '#c8c8c8',
    brand: 'Hanes',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Charcoal Heather',
    hex: '#4a4b4d',
    brand: 'Hanes',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Smoke Grey',
    hex: '#4e4e4e',
    brand: 'Hanes',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Black',
    hex: '#000000',
    brand: 'Hanes',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Navy',
    hex: '#3c4255',
    brand: 'Hanes',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Deep Royal',
    hex: '#505386',
    brand: 'Hanes',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Denim Blue',
    hex: '#798493',
    brand: 'Hanes',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Light Blue',
    hex: '#aac2de',
    brand: 'Hanes',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Deep Forest',
    hex: '#2e4233',
    brand: 'Hanes',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Lime',
    hex: '#b2dc6e',
    brand: 'Hanes',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Yellow',
    hex: '#f0e788',
    brand: 'Hanes',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Gold',
    hex: '#eda243',
    brand: 'Hanes',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Orange',
    hex: '#cf5d20',
    brand: 'Hanes',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Deep Red',
    hex: '#b52432',
    brand: 'Hanes',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Maroon',
    hex: '#5e0531',
    brand: 'Hanes',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Purple',
    hex: '#543774',
    brand: 'Hanes',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Pale Pink',
    hex: '#f4d2e2',
    brand: 'Hanes',
    categories: ['100% Cotton T-shirt'],
  },
  {
    name: 'Sand',
    hex: '#c8c1af',
    brand: 'Hanes',
    categories: ['100% Cotton T-shirt'],
  },
];

export const jerseyOptions = _.uniqBy(jerseyColors, 'hex');

export default jerseyColors;
