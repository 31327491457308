import qs from 'qs';
import type { FC, ReactNode } from 'react';
import classNames from 'classnames/bind';
import { Link, useLocation } from 'react-router-dom';
import {
  Colors,
  ProgramTypeEnum,
  Row,
  MainTabEnum,
  SubTabEnum,
  WhatsADropIn,
} from '@rivallapp/volosports-components';

import styles from '../../../../styles/main.module.scss';
import { checkCity, dashSpaceSwap } from '../../../../utils/url-search-helpers';
import type { DiscoverStore } from '../../../../zustand-stores/DiscoverStore/initialDiscoverStore';
import { DiscoverActions } from '../../../../zustand-stores/DiscoverStore';

const cx = classNames.bind(styles);

type DiscoverLinkOptions = {
  /**
   * Text to display in link.
   */
  label: string;
  /**
   * Optional additional class name to apply to link.
   */
  className?: string;
  /**
   * Element displayed to right of link.
   * Currently used to display drop-in info icon
   * */
  right?: ReactNode;
  /**
   * Search params to stringify and append to link
   * */
  params?: {
    view?: DiscoverStore['view'];
    subView?: DiscoverStore['subView'];
    programTypes?: ProgramTypeEnum[];
  };
};

const DiscoverBarLinks: DiscoverLinkOptions[] = [
  {
    label: 'PLAY NOW',
    className: 'discover-bar-link-bold',
  },
  {
    label: 'LEAGUES',
    params: { view: MainTabEnum.SPORTS, subView: SubTabEnum.LEAGUE },
  },
  {
    label: 'TOURNAMENTS',
    params: { view: MainTabEnum.SPORTS, subView: SubTabEnum.TOURNAMENT },
  },
  {
    label: 'PICK UPS',
    params: {
      view: MainTabEnum.SPORTS,
      subView: SubTabEnum.DAILY,
      programTypes: [ProgramTypeEnum.PICKUP],
    },
  },
  {
    label: 'DROP-INS',
    right: <WhatsADropIn variant="icon" popupBehavior="popover" iconProps={{ size: 'sm' }} />,
    params: {
      view: MainTabEnum.SPORTS,
      subView: SubTabEnum.DAILY,
      programTypes: [ProgramTypeEnum.DROPIN],
    },
  },
  {
    label: 'CLASSES',
    params: { view: MainTabEnum.CLASSES },
  },
  {
    label: 'EVENTS',
    params: { view: MainTabEnum.EVENTS },
  },
  {
    label: 'PRIVATE RENTALS',
    params: { view: MainTabEnum.COURT_RENTALS },
  },
  {
    label: 'VOLUNTEER',
    params: { view: MainTabEnum.VOLUNTEER },
  },
];

const DiscoverBar: FC = () => {
  const location = useLocation();
  const checkCityPath = checkCity({ location });
  const cityName = dashSpaceSwap(checkCityPath, 'toSpace');

  const handleClick = () => {
    // Ensure the city is cleared from global discover state if no city is selected on the current page.
    if (!cityName) DiscoverActions.updateFilters({ cityName: null });
  };

  return (
    <Row minH="35px" overflowX="scroll" backgroundColor={Colors.accents.complimentaryYellow}>
      {DiscoverBarLinks.map(({ label, right, params, className }, i, arr) => (
        <Row
          px={4}
          key={label}
          alignItems="center"
          textTransform="uppercase"
          ml={i === 0 ? 'auto' : undefined}
          mr={i === arr.length - 1 ? 'auto' : undefined}
        >
          <Link
            className={cx('discover-bar-link', className)}
            onClick={handleClick}
            to={{
              pathname: '/discover',
              search: qs.stringify({
                ...(cityName ? { cityName } : {}),
                ...params,
              }),
            }}
          >
            {label}
          </Link>
          {right}
        </Row>
      ))}
    </Row>
  );
};

export default DiscoverBar;
