import { gql, type TypedDocumentNode } from '@apollo/client';

export type UserPaymentsInput = {
  userPaymentListInput: {
    userId: string;
    pagination?: {
      numPerPage?: number;
      pageNum?: number;
    };
  };
};

export type TPaymentListItem = {
  paymentId: string;
  leagueId?: string;
  rentalId?: string;
  date: string; // Timestamp date string
  reason?: string;
  // This is actually total payments - keeping the name since it is used in multiple places
  net: number;
  credits: number;
  donation?: number;
  refund?: number;
  refundIssuedAt?: string; // Timestamp date string
  revenue?: number;
  maxRefundableCents?: number;

  // TODO: Refactor backend/frontend to use proper enums
  /** 'Refund' | 'Paid' | 'Credit' */
  status: string;
  /** 'R' | 'P' | 'GC' | 'LC' */
  type: string;

  isDropin?: boolean;
  manuallyAddedBy?: string;
  organizationId?: string;
  promoCode?: string;
};

export type UserPaymentsData = {
  userPayments: {
    payments: TPaymentListItem[];
    /** Sum of user's payments. Includes fees and donations. */
    totalPayments: number;
    /** Total revenue made from this user. Any refunds made will deduct from this value  */
    totalNet?: number;
    /** Total refunds given to this user */
    totalRefunds?: number;
    /** Total donations made by this user */
    totalDonations?: number;
    /** Total credits available for this user to use */
    totalCredits?: number;
  };
};

export const GET_USER_PAYMENTS_UPDATED: TypedDocumentNode<UserPaymentsData, UserPaymentsInput> =
  gql`
    query getUserPayments($userPaymentListInput: UserDetailListInput!) {
      userPayments(input: $userPaymentListInput) {
        payments {
          paymentId
          leagueId
          rentalId
          date
          net
          reason
          credits
          maxRefundableCents
          donation
          refund
          refundIssuedAt
          revenue
          status
          type
          isDropin
          manuallyAddedBy
          organizationId
          promoCode
        }
        totalPayments
        totalNet
        totalRefunds
        totalDonations
        totalCredits
      }
    }
  `;

export const ISSUE_REFUND = gql`
  mutation registrantIssueRefund($input: RegistrantIssueRefundInput!) {
    registrantIssueRefund(input: $input) {
      registrant {
        _id
      }
    }
  }
`;

export const REMOVE_WITHOUT_REFUND = gql`
  mutation registrantRemoveWithoutRefund($input: RegistrantRemoveWithoutRefundInput!) {
    registrantRemoveWithoutRefund(input: $input) {
      registrant {
        _id
      }
    }
  }
`;

export const CURRENT_USER_SELF_WITHDRAW = gql`
  mutation currentUserSelfWithdraw($input: CurrentUserSelfWithdrawInput!) {
    currentUserSelfWithdraw(input: $input) {
      registrant {
        _id
      }
    }
  }
`;

export const ADD_CREDITS = gql`
  mutation registrantAddCredits($input: RegistrantAddCreditsInput!) {
    registrantAddCredits(input: $input) {
      currentUser {
        _id
      }
    }
  }
`;

export const DELETE_CREDITS = gql`
  mutation registrantDeleteCredits($input: RegistrantDeleteCreditsInput!) {
    registrantDeleteCredits(input: $input) {
      currentUser {
        _id
      }
    }
  }
`;

export const USER_MEMBERSHIP_HISTORY_QUERY = gql`
  query userMembershipHistory($userId: ID!, $pagination: StripePaginationInput) {
    membershipInvoicesForUser(userId: $userId, pagination: $pagination) {
      membershipInvoices {
        stripeInvoiceId
        createdAt
        status
        total
      }
      hasMore
    }
  }
`;
