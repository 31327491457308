import updateFilters from '../utils/updateFilters';
import useDiscoverStore from '../DiscoverStore';
import initialDiscoverStore from '../initialDiscoverStore';

const { filters: initialFilters } = initialDiscoverStore;

const clearFilters = () => {
  const get = useDiscoverStore.getState;
  const set = useDiscoverStore.setState;

  const { filters } = get();
  const { isVirtual, cityName } = filters;

  set({ currentPage: 1 });

  updateFilters({
    ...initialFilters,
    // stay the same
    cityName,
    isVirtual,
  });
};

export default clearFilters;
