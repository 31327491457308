import PropTypes from 'prop-types';
import { CYCLIC_PROP } from '../common-props';

const DayProps = PropTypes.shape({
  _id: PropTypes.string,
  week: CYCLIC_PROP,
  weekId: PropTypes.string,
  week_num: PropTypes.number,
  games: PropTypes.arrayOf(CYCLIC_PROP),
  date: PropTypes.string,
  is_tournament: PropTypes.bool,
});

export default DayProps;
