import useDiscoverStore from '../DiscoverStore';

const updateQueryStatus = update => {
  const set = useDiscoverStore.setState;

  set(store => ({
    queryStatus: {
      ...store.queryStatus,
      ...update,
    },
  }));
};

export default updateQueryStatus;
