// needs to be kept up to date with _variables
export const brand = Object.freeze({
  rivallblue: '#163352',
  rivallnavy: '#0a3355',
  brickred: '#b4371e',
  neonYellow: '#E9FF70',
  neonTeal: '#4CE0D2',
  lightTeal: '#C0F4EF',
  grey800: '#4c4c4c',
  grey500: '#979797',
  grey400: '#aaaaaa',
  grey200: '#ebeeee',
  grey100: '#f7f7f7',
});

export const colors = Object.freeze({
  primary: brand.rivallblue,
  listBorder: brand.grey200,
  listHover: brand.grey500,
  listFocus: brand.grey100,
  placeholder: brand.grey400,
});
