export const ActionEnum = Object.freeze({
  CREATE_MENU: 'CREATE_MENU',
  DASHBOARD_DROPDOWN: 'DASHBOARD_DROPDOWN',
});

export default [
  {
    path: 'create/start',
    title: 'Create',
  },
  {
    path: 'leagues',
    title: 'Programs',
  },
  {
    path: 'players',
    title: 'Players',
  },
  {
    path: 'ratings',
    title: 'Ratings',
  },
  {
    path: 'messages/compose',
    title: 'Messages',
  },
  {
    path: 'dashboard',
    title: 'Dashboard',
    action: ActionEnum.DASHBOARD_DROPDOWN,
  },
];
