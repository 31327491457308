import loadable from '@loadable/component';
import { Route, Switch } from 'react-router-dom';
import UnauthenticatedRoute from './UnauthenticatedRoute';
import VanityRoutes from './VanityController';

const DiscoverPage = loadable(() => import('../apps/Discover'), { ssr: false });
const ForgotPassword = loadable(() => import('../apps/Anon/ForgotPassword/ForgotPassword'));
const NotFoundPage = loadable(() => import('../apps/Anon/NotFound'));
const ResetPassword = loadable(() => import('../apps/Anon/ForgotPassword/ResetPassword'));
const Egg = loadable(() => import('../apps/Anon/Egg/Egg'));
const Unsubscribe = loadable(() => import('../apps/Anon/Unsubscribe/Unsubscribe'));
const VerifyEmail = loadable(() => import('../apps/Anon/VerifyEmail/VerifyEmail'));
const ErrorPage = loadable(() => import('../pages/ErrorPage'));
const DayOfWaiver = loadable(() => import('../apps/Anon/DayOfWaiver/DayOfWaiver'));

const PublicController = () => (
  <Switch>
    <UnauthenticatedRoute exact path="/login/forgot" name="forgot" component={ForgotPassword} />
    <UnauthenticatedRoute exact path="/login/reset" name="reset" component={ResetPassword} />
    <Route exact path="/unsubscribe" name="unsubscribe" component={Unsubscribe} />
    <Route exact path="/verify-email" name="verify_email" component={VerifyEmail} />
    <Route exact path="/discover" name="discover_public" component={DiscoverPage} />
    <Route exact path="/not-found" name="not-found" component={NotFoundPage} />
    <Route exact path="/egg" name="egg" component={Egg} />
    <Route exact path="/waiver" component={DayOfWaiver} />
    <VanityRoutes />
    <Route name="error" component={ErrorPage} />
  </Switch>
);

export default PublicController;
