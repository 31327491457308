import * as _ from 'lodash-es';
import { brand } from '../../shared/colors-objects';

const tempPrimary = '#163352';

export const colors = {
  primary: tempPrimary,
  focusBorder: brand.grey200,
  controlHover: brand.grey200,
  listHover: tempPrimary,
  listDisabled: '#b9b9b9',
  listFocus: brand.grey200,
  placeholder: brand.grey400,
  neonGreen: '#E9FF70',
};

// some optional props to pass into FilterSelect:
// shadow={bool} - adds a box shadow to help white-on-white filters stand out
// dark={bool} - inverts the color scheme. useful for would-be white-on-white clashes
// border={bool} - adds a grey border around the control
// borderColor={string} - allows for any border color
// backgroundColor={string} - sets background color of control
// controlStyles={{css-in-js}} - allows for spreading of any additional
//                               styles to control
// menuStyles={{css-in-js}} - spread additional menu styles
// menuMaxHeight={string} - specify max height (as css string value eg. '200px') of menu
const filterSelectStyles = (props = {}) => {
  const { miscStyles } = props;
  const {
    clearIndicator: clearIndicatorStyles = undefined,
    container: containerStyles = undefined,
    control: controlStyles = undefined,
    dropdownIndicator: dropdownIndicatorStyles = undefined,
    indicatorsContainer: indicatorsContainerStyles = undefined,
    indicatorSeparator: indicatorSeparatorStyles = undefined,
    input: inputStyles = undefined,
    menu: menuStyles = undefined,
    menuList: menuListStyles = undefined,
    multiValue: multiValueStyles = undefined,
    option: optionStyles = undefined,
    placeholder: placeholderStyles = undefined,
    valueContainer: valueContainerStyles = undefined,
    singleValue: singleValueStyles = undefined,
  } = miscStyles || {};

  return {
    clearIndicator: base => ({
      ...base,
      padding: '4px',
      ...(clearIndicatorStyles || {}),
    }),
    container: () => ({
      padding: '0px',
      position: 'relative',
      width: '100%',
      ...(containerStyles || {}),
    }),
    control: (__, { isFocused, isDisabled }) => {
      const focusColor = colors.primary;
      let borderColor = props.borderColor || (props.border ? brand.grey200 : 'white');
      let backgroundColor = props.backgroundColor || 'white';
      if (props.dark) {
        borderColor = colors.primary;
        backgroundColor = colors.primary;
      }
      if (props.grey) {
        borderColor = '#f7f7f7';
        backgroundColor = '#f7f7f7';
      }
      if (isDisabled) borderColor = colors.listDisabled;
      const color = isFocused ? focusColor : borderColor;

      const borderStyles = {
        border: `2px solid ${props.inHeaderCity && props.value ? colors.neonGreen : color}`,
        padding: '8px',
        ...(props.radius ? { borderRadius: props.radius } : { borderRadius: '4px' }),
        ...(props.shadow ? { boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)' } : {}),
        '&:hover': {
          cursor: 'pointer',
          ...(isFocused || isDisabled
            ? {}
            : {
                border: `2px solid ${props.dark ? brand.grey800 : colors.controlHover}`,
              }),
        },
        ...(props.rounded || props.grey
          ? {
              border: `1px solid ${props.grey ? '#f7f7f7' : '#aaaaaa'}`,
              borderRadius: '100px',
            }
          : {}),
      };
      return {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: (props.skinny && '35px') || (props.short ? '40px' : '48px'),
        transition: 'border 0.3s, background-color 0.3s, box-shadow 0.3s',
        backgroundColor,
        ...borderStyles,
        ...(isDisabled
          ? {
              cursor: 'not-allowed !important',
              backgroundColor: colors.listDisabled,
            }
          : {}),
        ...(props.inHeaderCity && !_.isEmpty(props.value)
          ? {
              backgroundColor: colors.neonGreen,
            }
          : {}),
        ...(props.controlStyles || {}), // deprecating in favor of misc styles
        ...(controlStyles || {}),
      };
    },
    dropdownIndicator: (base, { isMulti, hasValue }) => ({
      ...(props.withDropdown && !(isMulti && hasValue) ? base : { display: 'none' }),
      ...(props.menuPlacement === 'top' ? { transform: 'rotate(180deg)' } : {}),
      padding: '4px',
      ...(dropdownIndicatorStyles || {}),
    }),
    indicatorsContainer: () => {
      let styles = {
        display: 'flex',
        alignItems: 'center',
        border: 'none',
        padding: '0',
      };
      if (props?.hideIndicator) styles = { width: '0', overflow: 'hidden' };
      return {
        ...styles,
        ...(indicatorsContainerStyles || {}),
      };
    },
    indicatorSeparator: () => ({
      display: 'none',
      ...(indicatorSeparatorStyles || {}),
    }),
    input: base => ({
      ...base,
      padding: '0px',
      ...(props.dark ? { color: 'white' } : {}),
      justifyContent: 'flex-start',
      ...(inputStyles || {}),
    }),
    menu: base => ({
      ...base,
      width: '100%',
      borderRadius: '4px',
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
      padding: 0,
      zIndex: 11,
      overflowX: 'visible',
      ...(props.menuStyles || {}), // deprecating in favor of misc styles
      ...(menuStyles || {}),
    }),
    menuList: base => ({
      ...base,
      borderRadius: '4px',
      padding: '0',
      maxHeight: props.inHeaderCity ? '100vh' : props.maxMenuHeight || '200px',
      overflowX: 'visible',
      ...(menuListStyles || {}),
    }),
    multiValue: base => ({
      ...base,
      display: 'none',
      ...(multiValueStyles || {}),
    }),
    option: (__, { isFocused }) => ({
      padding: '10px',
      transition: 'background-color 0.3s, color 0.3s',
      fontSize: '1.1em',
      cursor: 'pointer',
      ...(isFocused
        ? {
            backgroundColor: colors.listFocus,
          }
        : {}),
      '&:hover': {
        backgroundColor: colors.listHover,
        color: 'white !important',
      },
      ...(optionStyles || {}),
    }),
    placeholder: base => ({
      ...base,
      ...(placeholderStyles || {}),
    }),
    valueContainer: base => ({
      ...base,
      flexWrap: 'nowrap',
      maxWidth: '100%',
      margin: 0,
      padding: 0,
      ...(props.inHeaderCity && !props.skinny && !_.isEmpty(props.value)
        ? {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            left: '-1.1rem',
          }
        : {}),
      ...(valueContainerStyles || {}),
    }),
    singleValue: (base, { hasValue }) => ({
      ...base,
      whiteSpace: 'nowrap',
      width: '100%',
      maxWidth: '100%',
      color: props.dark ? 'white' : colors.primary,
      fontWeight: '500',
      textAlign: 'left',
      ...(props.inHeaderCity && hasValue
        ? {
            color: colors.primary,
          }
        : {}),
      ...(singleValueStyles || {}),
    }),
    menuPortal: base => ({
      ...base,
      ...(props.menuPortalTarget ? { zIndex: 99999999 } : {}),
    }),
  };
};

export default filterSelectStyles;
