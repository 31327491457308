export const getToday = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
};

export const addDays = (date, days) => {
  if (!date) return new Date();
  if (date && !days) return new Date(date);
  const dateToModify = new Date(date);
  dateToModify.setDate(dateToModify.getDate() + days);
  return dateToModify;
};

export const convertMinutesToTimestring = value => {
  const minute = typeof value !== 'number' ? parseInt(value, 10) % 60 : value % 60;
  const hour =
    typeof value !== 'number' ? Math.floor(parseInt(value, 10) / 60) : Math.floor(value / 60);

  const date = new Date();
  date.setHours(hour, minute, 0, 0);
  const [, isoTime] = (date.toISOString() || '').split('T');
  return `T${isoTime}`;
};

/**
 * Given a number of minutes between 0 and 1410, returns a string in the format 'HH:mm'
 */
export const formatMinutesToHHmm = minutes => {
  const minutesInt = parseInt(minutes, 10) || -1;

  if (minutesInt < 0 || minutesInt > 1410) return null;

  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours < 10 ? `0${hours}` : hours}:${mins < 10 ? `0${mins}` : mins}`;
};

export const formattedDateToISOString = ({ date = '', time = '00:00:00:000' }) => {
  if (!date) return new Date().toDateString();
  if (typeof date !== 'string') return date.toISOString();
  let isoDate = new Date();
  if (date.match(/^\d\d\/(0?[1-9]|[1][0-2])\/(0?[1-9]|[12][0-9]|3[01])$/)) {
    // 'YY/MM/DD' format
    const [year, month, day] = date.split('/');
    const [hour, min, sec, ms] = time.split(':');
    isoDate.setFullYear(`20${year}`, month - 1, day);
    isoDate.setHours(hour, min, sec, ms);
  } else {
    const inputDate = !Number.isNaN(new Date(date)) ? new Date(date) : new Date();
    const [hour, min, sec, ms] = time.split(':');
    inputDate.setHours(hour, min, sec, ms);
    isoDate = inputDate;
  }
  return isoDate.toISOString();
};

export const formatISODate = ({ date, separator = '/', yearLength = 2 }) => {
  const formattedDate = new Date(date).toISOString();
  const [year, month, day] = formattedDate.split('-') || [];
  return `${year.slice(yearLength)}${separator}${month}${separator}${day.slice(0, 2)}`;
};

export const modifyMonth = ({ count = 0, date = new Date() }) => {
  const modifiedDate = new Date(date);
  modifiedDate.setMonth(modifiedDate.getMonth() + count);
  modifiedDate.setHours(0, 0, 0, 0);
  return modifiedDate;
};

export const militaryToStandardTime = time => {
  if (!time) return 'error';
  const [hour, min, sec] = time.split(':');
  const formatHour = hour - 12 > 0 ? hour - 12 : hour;
  const period = hour - 12 >= 0 ? 'PM' : 'AM';

  const final = `${formatHour}:${min}${sec ? `:${sec}` : ''} ${period}`;
  return final;
};

export const timeStringToMinutes = (timeString = '00:00') => {
  const [hour, minute] = timeString.split(':');
  const parsedHour = parseInt(hour, 10);
  const parsedMinute = parseInt(minute, 10);
  return parsedHour * 60 + parsedMinute;
};
