import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import OverflowMenu from '../../../../components/OverflowMenu/OverflowMenu';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const CustomButton = <div className={cx('alert-trigger')} />;

class NavAlerts extends Component {
  constructor(props) {
    super(props);
    this.toggleAlerts = this.toggleAlerts.bind(this);
    this.state = {
      showAlerts: this.props.initShown || false,
    };
  }

  toggleAlerts() {
    this.setState({ showAlerts: !this.state.showAlerts });
  }

  render() {
    const { alerts } = this.props;
    const hasAlerts = alerts && alerts.length;
    if (!hasAlerts) return null;

    return (
      <div className={cx('alerts-container', { 'has-alerts': hasAlerts })}>
        <OverflowMenu
          CustomButton={CustomButton}
          options={alerts}
          menuStyle={{
            fontSize: '12px',
          }}
        />
      </div>
    );
  }
}

NavAlerts.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.shape({})),
  initShown: PropTypes.bool,
};

NavAlerts.defaultProps = {
  alerts: [],
  initShown: false,
};

export default NavAlerts;
