import useAuthStore from '../AuthStore';
import initialAuthStore from '../initialAuthStore';

/**
 * Reset the auth store with the initial state values.
 *
 * ### Important to note that this will not totally logout the user.
 *
 * __You must also call the logout endpoint with `Auth.logout`
 * or `useLogout` in order to properly clear the user's authentication.__
 * */
const resetState = () => useAuthStore.setState(initialAuthStore);

export default resetState;
