/* eslint react/jsx-filename-extension: 0 */
import * as _ from 'lodash-es';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './fancy.module.scss';

const getLabel = ({ placeholder, label, name = 'Text', noLabel }) => {
  if (noLabel) return null;
  return label || placeholder || _.startCase(name.replace(/_/g, ' '));
};

const getErrorText = ({ required, errorText }) =>
  errorText || (required ? 'Field is required.' : null);

const withFieldFanciness = FieldComponent => {
  class FieldGroup extends Component {
    static propTypes = {
      attrs: PropTypes.shape({}),
      border: PropTypes.bool,
      className: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      disabled: PropTypes.bool,
      errorText: PropTypes.string,
      helpText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
      fieldClassName: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      label: PropTypes.string,
      noLabel: PropTypes.bool,
      persistLabel: PropTypes.bool,
      name: PropTypes.string,
      onChange: PropTypes.func.isRequired,
      required: PropTypes.bool,
      stickyHelpText: PropTypes.bool,
      style: PropTypes.shape({}),
      transform: PropTypes.func,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      inputStyle: PropTypes.shape({}),
    };

    static defaultProps = {
      attrs: {},
      border: false,
      className: null,
      disabled: false,
      errorText: null,
      helpText: '',
      fieldClassName: 'w-100',
      label: null,
      noLabel: false,
      persistLabel: false,
      required: false,
      stickyHelpText: false,
      style: {},
      transform: null,
      value: undefined,
      inputStyle: {},
    };

    constructor(props) {
      super(props);
      this.onChange = this.onChange.bind(this);
      this.onFocus = this.onFocus.bind(this);
      this.onBlur = this.onBlur.bind(this);
      this.getText = this.getText.bind(this);
      this.label = getLabel(props);
      this.errorText = getErrorText(props);
      const { initFocus, isFocused } = props;
      this.state = {
        error: false,
        focused: initFocus || isFocused,
      };
    }

    onFocus() {
      this.setState({ focused: true });
    }

    onBlur(e) {
      this.setState({ focused: false });
      const { value } = e.target;
      const { required, onBlur } = this.props;
      if (onBlur) {
        onBlur(e);
      }
      if (!required) return;
      this.setState({ error: !value });
    }

    onChange(e) {
      let { value } = e.target;
      const { required, transform, onChange } = this.props;
      if (transform) {
        value = transform(value);
      }
      this.setState({ error: !value && required });
      onChange(value);
    }

    getText() {
      const { helpText, error: propsError, maxLength, value } = this.props;
      const { focused, error } = this.state;
      if (!helpText && maxLength) {
        const remaining = maxLength - (value || '').length;
        return `${remaining} character${remaining === 1 ? '' : 's'} remaining`;
      }
      if (focused) return helpText;
      if (error || propsError) {
        return getErrorText(this.props);
      }
      return helpText;
    }

    render() {
      const {
        grey,
        border,
        className,
        disabled,
        error: propsError,
        fieldClassName,
        stickyHelpText,
        style,
        value,
        persistLabel,
        inputStyle,
        withAnalytics, // eslint-disable-line
        onBlur, // eslint-disable-line
        ...fieldAttrs
      } = this.props;
      const { error: stateError, focused } = this.state;

      const error = propsError || stateError;
      const text = this.getText();

      return (
        <div
          className={cx(styles.formGroup, className, {
            [styles.grey]: grey,
            [styles.error]: error,
            [styles.focused]: focused,
          })}
          style={style}
        >
          {this.label && (
            <p
              className={cx(styles.label, {
                [styles.border]: border,
                [styles.disabled]: disabled,
                [styles.error]: error,
                [styles.focused]: focused,
                [styles.grey]: grey,
                [styles.populated]: (!!value && value.length) || persistLabel,
              })}
            >
              {this.label}
            </p>
          )}
          <FieldComponent
            className={fieldClassName}
            value={value}
            border={border}
            grey={grey}
            error={error}
            disabled={disabled}
            onChange={this.onChange}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            style={inputStyle}
            {...fieldAttrs}
          />
          {text && (
            <p
              className={cx(styles.helpText, {
                [styles.empty]: !text,
                [styles.disabled]: disabled && text,
                [styles.error]: error && text,
                [styles.focused]: (focused && text) || stickyHelpText,
              })}
            >
              {text}
            </p>
          )}
        </div>
      );
    }
  }

  return FieldGroup;
};

export default withFieldFanciness;
