import { useSuspenseQuery } from '@apollo/client';

import { Link } from 'react-router-dom';
import { Suspense } from 'react';
import { GenericErrorBoundary, ListContainer, Loading } from '../../../components';
import USER_REGISTRANTS, {
  type RegistrantItem,
  type RentalRegistrantItem,
} from './PLAYER_PARTICIPATION_QUERIES';
import { RentalRegistrantTypeOptions } from '../../../shared/rental-enums';
import { RegistrantTypes } from '../../../shared/registrant-type-enum';
import { upperFirst } from '../../../shared/string-manipulations';
import {
  ParticipationActivityIcon,
  ParticipationRating,
  ParticipationTeamLink,
  ParticipationWaiver,
} from './components/ListComponents';

export type PlayerParticipationProps = {
  userId: string;
  userDetails: {
    _id: string;
    isVoloPassMember: boolean;
    membershipExpires?: string;
    firstName?: string;
    lastName?: string;
    gender?: string;
    email: string;
    phone_number?: string;
    countryCode?: string;
    picture?: string;
    _created?: string;
    birthday?: string;
    status?: string;
    username: string;
    deactivated?: boolean;
    is_host: boolean;
  };
};

// TODO: Pagination?
const PlayerParticipation = ({ userId, userDetails }: PlayerParticipationProps) => {
  const { data, refetch: refetchLeagueList } = useSuspenseQuery(USER_REGISTRANTS, {
    variables: { input: { userId } },
  });

  const { items = [] } = data.userRegistrants ?? {};

  /** Returns column data when given Registrant data (everything except Rentals) */
  const getRegistrantItem = ({ item }: { item: RegistrantItem }) => {
    const { _id, league, stagedTeam, waiver, isDropin, group, dropInSlot, registrantKind } = item;

    const registrantTypeLabel = isDropin
      ? 'Drop-In'
      : RegistrantTypes[registrantKind]?.label || 'Free agent';

    let teamName = isDropin ? dropInSlot?.team?.name : stagedTeam?.name;
    // Override for former user leagues query
    if (!teamName && group) {
      teamName = group.name;
    }

    return [
      <ParticipationActivityIcon activityName={league.activityName} />,
      <Link
        to={{
          pathname: `/rfo/league/${league._id}/view`,
          search: `?organization=${league.organizationId}`,
        }}
      >
        {league.name}
      </Link>,
      <ParticipationTeamLink
        teamTo={`/rfo/league/${league._id}/assemble?organization=${league.organizationId}`}
        registrantType={registrantTypeLabel}
        teamName={teamName}
      />,
      upperFirst(league.seasonName),
      <ParticipationRating />,
      <ParticipationWaiver
        waiver={waiver}
        refetchLeagueList={refetchLeagueList}
        registrantId={_id}
        userDetails={userDetails}
      />,
    ];
  };

  /** Returns column data when given RentalRegistrant data */
  const getRentalRegistrantItem = ({ item }: { item: RentalRegistrantItem }) => {
    const { _id, rental, waiver, rentalRegistrantKind } = item;

    const rentalId = rental._id;

    return [
      <ParticipationActivityIcon activityName={rental.activity_name} />,
      <Link to={{ pathname: `/rfo/rental/${rentalId}/view` }}>{rental.name}</Link>,
      <ParticipationTeamLink
        teamTo={`/rfo/rental/${rentalId}/reservations`}
        registrantType={RentalRegistrantTypeOptions[rentalRegistrantKind].label}
        teamName={`${rental.activity_name} Rental`}
      />,
      <p>---</p>, // No seasons for rentals
      <ParticipationRating />,
      <ParticipationWaiver
        waiver={waiver}
        refetchLeagueList={refetchLeagueList}
        registrantId={_id}
        userDetails={userDetails}
        isRental
      />,
    ];
  };

  return (
    <div className="mt-4">
      <ListContainer
        columnTitles={['', 'Program', 'Team', 'Season', 'ParticipationRating', 'Waiver']}
        columnData={items.map(item => {
          return item.__typename === 'Registrant'
            ? getRegistrantItem({ item })
            : getRentalRegistrantItem({ item });
        })}
        numOfCol={6}
        emptyText="User has not participated in any leagues"
      />
    </div>
  );
};

const SuspensePlayerParticipation = (props: PlayerParticipationProps) => (
  <Suspense fallback={<Loading />}>
    <GenericErrorBoundary>
      <PlayerParticipation {...props} />
    </GenericErrorBoundary>
  </Suspense>
);

export default SuspensePlayerParticipation;
