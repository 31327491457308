import * as _ from 'lodash-es';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import classNames from 'classnames/bind';
import right from '../../images/icons/angle-right.svg';
import left from '../../images/icons/angle-left.svg';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const Day = props => {
  const { date, week, m, visibleMonth, onClick, doNotDisablePastDates, viewingMonth } = props;
  const prevMonth = week === 0 && date > 7;
  const nextMonth = week >= 4 && date <= 14;
  const dayIsSelected =
    (prevMonth
      ? m.month() === visibleMonth - 1
      : nextMonth
        ? m.month() === visibleMonth + 1
        : visibleMonth === m.month()) && date === m.date();

  const selected = viewingMonth.clone().date(date);
  if (prevMonth) selected.subtract(1, 'month');
  if (nextMonth) selected.add(1, 'month');

  return (
    <td
      onClick={onClick}
      className={cx({
        'other-month': prevMonth || nextMonth,
        'current-day': dayIsSelected,
        'gray-day': !doNotDisablePastDates && selected.isBefore(moment().startOf('day')),
      })}
    >
      {date}
    </td>
  );
};

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.selectDate = this.selectDate.bind(this);
    this.prevMonth = this.prevMonth.bind(this);
    this.nextMonth = this.nextMonth.bind(this);
    this.hideMessage = this.hideMessage.bind(this);

    this.state = {
      viewingMonth: this.props.moment.clone(),
      message: '',
    };
    this.today = moment();
  }

  hideMessage() {
    this.setState({ message: null });
  }

  selectDate(date, week) {
    this.hideMessage();
    const { viewingMonth } = this.state;
    const prevMonth = week === 0 && date > 7;
    const nextMonth = week >= 4 && date <= 14;

    const selected = viewingMonth.clone().date(date);
    if (prevMonth) selected.subtract(1, 'month');
    if (nextMonth) selected.add(1, 'month');

    const { doNotDisablePastDates = false } = this.props;
    if (!selected.isSameOrAfter(this.today, 'day') && !doNotDisablePastDates) {
      this.setState({ message: 'cannot select a date in the past.' });
    } else if (!selected.isSame(viewingMonth.clone().date(date), 'month')) {
      this.setState();
    } else {
      const m = this.props.moment;
      m.date(selected.date());
      m.month(selected.month());
      m.year(selected.year());

      this.props.onChange(m);
      if (this.props.onDateSelection) {
        this.props.onDateSelection();
      }
    }
  }

  prevMonth(e) {
    this.hideMessage();
    e.preventDefault();
    this.setState({ viewingMonth: this.state.viewingMonth.subtract(1, 'month') });
  }

  nextMonth(e) {
    this.hideMessage();
    e.preventDefault();
    this.setState({ viewingMonth: this.state.viewingMonth.add(1, 'month') });
  }

  render() {
    const { viewingMonth, message } = this.state;
    const m = this.props.moment;

    const d1 = viewingMonth.clone().subtract(1, 'month').endOf('month').date();
    const d2 = viewingMonth.clone().date(1).day();
    const d3 = viewingMonth.clone().endOf('month').date();

    const visibleDays = [].concat(
      _.range(d1 - d2 + 1, d1 + 1),
      _.range(1, d3 + 1),
      _.range(1, 42 - d3 - d2 + 1)
    );

    const visibleWeeks = _.chunk(visibleDays, 7);
    const dows = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    return (
      <div className={cx('cal-wrap')}>
        <div className={cx('m-calendar', this.props.className)}>
          <div className={cx('toolbar')}>
            <img
              className={cx('prev-month')}
              alt="prev-month"
              onClick={this.prevMonth}
              src={left}
            />
            <span className={cx('current-date')}>{viewingMonth.format('MMMM YYYY')}</span>
            <img
              className={cx('next-month')}
              alt="next-month"
              onClick={this.nextMonth}
              src={right}
            />
          </div>
          <table>
            <thead>
              <tr>
                {dows.map((dow, d) => (
                  <td key={d}>{dow}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              {visibleWeeks.map((row, week) => (
                <tr key={week}>
                  {row.map(date => (
                    <Day
                      key={date}
                      date={date}
                      week={week}
                      m={m}
                      visibleMonth={viewingMonth.month()}
                      viewingMonth={viewingMonth}
                      onClick={() => this.selectDate(date, week)}
                      doNotDisablePastDates={this.props.doNotDisablePastDates}
                    />
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {message ? <p className={cx('alert')}>{message}</p> : null}
      </div>
    );
  }
}

export default Calendar;
