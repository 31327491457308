import PropTypes from 'prop-types';
import { CYCLIC_PROP } from '../common-props';

const DivisionProps = PropTypes.shape({
  _id: PropTypes.string,
  organization: CYCLIC_PROP,
  name: PropTypes.string,
  permanent: PropTypes.string,
});

export default DivisionProps;
