import useDiscoverStore from './DiscoverStore';
import actions from './actions';
import helpers from './helpers';

/*
  actions and helpers are exported so that they can be used in other files without having to
  import the store itself and hook into it only to get access to the actions and helpers
  which never change

  this also removes these methods from the store itself so that they are not exposed to the
  store's state, which can lead to bugs / inadvertently overwriting these methods

  this also allows typing to work properly and infer each action and helper function
  in whatever file it is being used.
*/

export const DiscoverActions = actions;
export const DiscoverHelpers = helpers;

export default useDiscoverStore;
