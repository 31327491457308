import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';

import initialAuthStore, { AUTH_STORE_VERSION } from './initialAuthStore';
import type { AuthStore } from './initialAuthStore';
import { isProduction } from '../../environment';

const useAuthStore = create<AuthStore>()(
  devtools(
    persist(
      () => {
        // -- 07/07/23 --
        // Remove the auth token that was previously stored in localStorage. At the
        // time this was written, tokens were being issued with a 365 day expiration,
        // so this code can technically be removed on/after 07/07/24.
        localStorage.removeItem('token');
        return initialAuthStore;
      },
      {
        name: 'auth-store',
        version: AUTH_STORE_VERSION,
        partialize: ({ isAuthenticated, roles, authToken }) => ({
          isAuthenticated,
          roles,
          authToken,
        }),
        onRehydrateStorage: () => {
          return (state, error) => {
            if (error) {
              console.error('[AuthStore] Error rehydrating state', error);
            } else if (!isProduction && state?.isAuthenticated) {
              console.log('[AuthStore] User previously authenticated');
            }
          };
        },
      }
    ),
    { enabled: !isProduction }
  )
);

export default useAuthStore;
