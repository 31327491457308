import React from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';

import styles from '../../../styles/main.module.scss';

const cx = classNames.bind(styles);

/** @type {Array<{name: string, href: string}>} */
export const AvailableBetas = [
  // Fill in or delete betas as they become available.
  {
    name: 'Beta Admin Program Cards',
    href: '/rfo/beta/leagues',
  },
  {
    name: 'Shift Log Links',
    href: '/rfo/beta/shift-log-links',
  },
];

/** @type {React.FC} */
const BetaLanding = () => {
  if (!AvailableBetas || AvailableBetas.length === 0) {
    return (
      <p style={{ margin: 'auto', textAlign: 'center' }}>
        No beta features are currently being tested. Please visit again at a later time.
      </p>
    );
  }

  return (
    // The styles here are lifted from Discover SelectACity.jsx
    <div className={cx('select-a-city')}>
      <h2>Available Beta Features</h2>
      <div className={cx('content')}>
        {AvailableBetas.map(beta => (
          <Link className={cx('card')} key={beta.name} to={beta.href}>
            <h2 className={cx('cityName')}>{beta.name}</h2>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BetaLanding;
