import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Auth } from '../zustand-stores/AuthStore';
import useAlertMessage from './useAlertMessage';
import client from '../apollo/client';

type Args = {
  /**
   * Path to redirect to after a logging out with `logoutAndRedirect`
   * @default '/'
   */
  redirectPath?: string;
};

/**
 * Provides basic logout functionality through two callbacks:
 *
 * `logoutAndRedirect`
 * logout the current user and redirect them to a different page.
 *
 * `logoutAndRefetch`
 * logout the current user and refetch all active queries.
 */
const useLogout = (args: Args = {}) => {
  const history = useHistory();
  const { showError } = useAlertMessage();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null);

  /**
   * Logout current user and redirect to a different page
   *
   * Best suited for something like a dashboard where we need to
   * send the user to a different page after logging out.
   * */
  const logoutAndRedirect = useCallback(
    async ({ redirectPath }: Args = {}) => {
      try {
        setLoading(true);
        await Auth.logout();
        client.clearStore();
        history.push(redirectPath || args.redirectPath || '/');
      } catch (err) {
        setError(err as AxiosError);
        showError('Error logging out..');
      } finally {
        setLoading(false);
      }
    },
    [history, args.redirectPath, showError]
  );

  /**
   * Logout current user and refetch all active queries.
   *
   * Best suited for something like discover where we don't need to redirect the
   * user away after logging out.
   *
   * __NOTE: This will throw GraphQL errors if its on an authenticated page since
   * we're re-executing queries that rely on the user being authenticated__
   * */
  const logoutAndRefetch = useCallback(async () => {
    try {
      setLoading(true);
      await Auth.logout();
      await client.resetStore();
    } catch (err) {
      setError(err as AxiosError);
      showError('Error logging out..');
    } finally {
      setLoading(false);
    }
  }, [showError]);

  return {
    error,
    loading,
    logoutAndRedirect,
    logoutAndRefetch,
  };
};

export default useLogout;
