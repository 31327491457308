import React, { createContext, useContext, useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';
import { PageLoader } from '../components';

type Organization = {
  _id: string;
  name: string;
  crest: string;
  contact_phone_number: string;
  contact_email: string;
  weather_line_number: string;
  logo: string;
  sponsorLogos: {
    _id: string;
    sponsorLogo: string;
    sponsorName: string;
    sponsorLink: string;
  }[];
  location: {
    coordinates: {
      lat: number;
      lng: number;
    };
  };
  voloPassTiers: {
    isActive: boolean;
    monthlyFee: number;
    monthlyDollarPrice: number;
  };
};

type OrganizationsQuery = {
  organizationList: Organization[];
};

const ORGANIZATION_LIST_QUERY = gql`
  query organizationList {
    organizationList {
      _id
      name
      crest
      contact_phone_number
      contact_email
      weather_line_number
      logo
      sponsorLogos {
        _id
        sponsorLogo
        sponsorName
        sponsorLink
      }
      location {
        coordinates {
          lat
          lng
        }
      }
      voloPassTiers {
        isActive
        monthlyDollarPrice
        monthlyFee
      }
    }
  }
`;

const OrganizationsContext = createContext<OrganizationsQuery['organizationList']>([]);

export const useOrganizationsContext = () => useContext(OrganizationsContext);

export const OrganizationsProvider: React.FC<React.PropsWithChildren> = props => {
  const { children } = props;

  const { data, loading } = useQuery<OrganizationsQuery>(ORGANIZATION_LIST_QUERY);

  const value = useMemo(() => data?.organizationList || [], [data]);

  if (loading) return <PageLoader />;

  return <OrganizationsContext.Provider value={value}>{children}</OrganizationsContext.Provider>;
};

export default OrganizationsContext;
