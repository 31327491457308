import { Provider as AlertProvider } from 'react-alert';
import classNames from 'classnames/bind';

import { ScrollToTop } from '../components';
import AlertTemplate, { alertOptions } from '../components/AlertTemplate';
import AppContainer from './AppContainer';
import InternetExplorerIsTheWorst from './InternetExplorerIsTheWorst';
import Controller from '../routes';
import { PORTAL_ID } from '../utils/constants';
import { usePageOptions, useSilentLogin } from '../hooks';
import styles from '../styles/layout.module.scss';

const cx = classNames.bind(styles);

const App = () => {
  // Sends a request on page load if the user was previously
  // authenticated to silently reauthenticate them. This will
  // also sync the user with Braze and Sentry.
  useSilentLogin();

  // This is older code refactored into a hook for now.
  // Still not ideal. Not sure why main is split up from AppContainer
  const pageOptions = usePageOptions();

  return (
    <main className={cx([pageOptions.appName, pageOptions.pageName])}>
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <ScrollToTop />
        <AppContainer {...pageOptions}>
          <Controller />
          <div id={PORTAL_ID} />
        </AppContainer>
      </AlertProvider>
      <InternetExplorerIsTheWorst />
    </main>
  );
};

export default App;
