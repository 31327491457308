import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './button.module.scss';

const cx = classNames.bind(styles);

const ToggleButtons = props => {
  const { options, optionsSelected, onChange, rounded, disabled } = props;

  return (
    <div className={cx('view-toggles')}>
      <div className={cx('toggle-buttons-group', disabled && 'disabled')}>
        {options.map(({ value, label, disabled: optionDisabled }, index) => {
          const key = `${label}-${index}`;
          return (
            <div
              className={cx(
                'toggle-button',
                optionDisabled && 'disabled',
                optionsSelected.includes(value) && 'selected',
                rounded && 'rounded'
              )}
              role="button"
              tabIndex={0}
              onClick={() => {
                onChange(value);
              }}
              key={key}
            >
              <span>{label}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

ToggleButtons.propTypes = {
  rounded: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  optionsSelected: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

ToggleButtons.defaultProps = {
  rounded: false,
  options: [],
  optionsSelected: [],
  onChange: undefined,
  disabled: false,
};

export default ToggleButtons;
