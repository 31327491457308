import PropTypes from 'prop-types';
import { CYCLIC_PROP } from '../common-props';

const UserProps = PropTypes.shape({
  _id: PropTypes.string,
  username: PropTypes.string,
  fullName: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  displayNameTeams: PropTypes.string,
  displayNamePickups: PropTypes.string,
  gender: PropTypes.string,
  zip: PropTypes.string,
  shirt_size: PropTypes.string,
  birthday: PropTypes.string,
  picture: PropTypes.string,
  leagues: PropTypes.arrayOf(CYCLIC_PROP),
  allLeagues: PropTypes.arrayOf(CYCLIC_PROP),
  activeLeagues: PropTypes.arrayOf(CYCLIC_PROP),
  email: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string),
  age: PropTypes.number,
  phone_number: PropTypes.string,
  contactInfoHidden: PropTypes.bool,
  staffOff: PropTypes.arrayOf(PropTypes.string),
  player_details: PropTypes.shape({
    _id: PropTypes.string,
    fullName: PropTypes.string,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    birthday: PropTypes.string,
    gender: PropTypes.string,
    shirt_size: PropTypes.string,
    username: PropTypes.string,
    picture: PropTypes.string,
    zip: PropTypes.string,
  }),
});

export default UserProps;
