import { Link } from 'react-router-dom';
import { dashSpaceSwap } from '../../utils/url-search-helpers';
import { DiscoverActions } from '../../zustand-stores/DiscoverStore';

type Props = {
  cityName?: string;
  children: React.ReactNode;
};

const LinkToRentals = ({ cityName, children }: Props) => {
  const search = `${cityName ? `cityName=${dashSpaceSwap(cityName, 'toSpace')}&` : ''}view=COURT%20RENTALS`;

  const handleClick = () => {
    // Ensure the city is cleared from global discover state if no city is selected on the current page.
    if (!cityName) DiscoverActions.updateFilters({ cityName: null });
  };

  return (
    <Link
      onClick={handleClick}
      to={{
        pathname: '/discover',
        search,
      }}
    >
      {children}
    </Link>
  );
};
export default LinkToRentals;
