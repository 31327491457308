import { type TypedDocumentNode, gql } from '@apollo/client';

type GetUpgradePricingData = {
  getVoloPassAnnualUpgradePricing: {
    _id: string;
    subtotalCents: number;
    taxCents: number;
    totalCents: number;
    /** If there's a proration applied, it's a negative number */
    proratedAmountCents: number;
  };
};

type GetUpgradePricingInput = {
  input: {
    planId: string;
    promoCode?: string;
  };
};

const GET_UPGRADE_PRICING: TypedDocumentNode<GetUpgradePricingData, GetUpgradePricingInput> = gql`
  query getVoloPassUpgradePricing($input: StartMembershipInput!) {
    getVoloPassAnnualUpgradePricing(input: $input) {
      id
      subtotalCents
      taxCents
      totalCents
      proratedAmountCents
    }
  }
`;

export default GET_UPGRADE_PRICING;
