import updateFilters from '../utils/updateFilters';
import useDiscoverStore from '../DiscoverStore';

const updateCityFilter = cityName => {
  const get = useDiscoverStore.getState;
  const set = useDiscoverStore.setState;

  const { filters } = get();

  if (cityName === filters?.cityName) return;

  set({ currentPage: 1 });

  updateFilters({
    cityName,
    selectedSkillLevels: [],
    selectedSportNames: [],
    selectedNeighborhoodIds: [],
    selectedVenueIds: [],
  });
};

export default updateCityFilter;
