import { StyleSheet } from 'react-native';
import { voloGreys } from '@rivallapp/volosports-components';

// Copied nearly 1:1 from mobile
export default StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  label: {
    flex: 1,
  },
  strikethrough: {
    marginRight: 5,
    color: voloGreys.medium,
    fontSize: 12,
    textDecorationLine: 'line-through',
  },
  value: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
});
