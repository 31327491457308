import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.module.scss';
import { Popup } from '../../../components';

const cx = classNames.bind(styles);

const AreYouSure = ({ close, hidden, yesAction, header, body, optional, customYes, customNo }) => (
  <Popup hidden={hidden} close={close} className="wider">
    <div className={cx('archive-you-sure')}>
      <h3>{header}</h3>
      {typeof body === 'string' ? <p>{body}</p> : body}
      <br />
      <h4>{optional}</h4>
      <button className={cx('yes')} onClick={yesAction} type="button">
        {customYes || 'Yes'}
      </button>
      <button className={cx('no')} onClick={close} type="button">
        {customNo || 'No'}
      </button>
    </div>
  </Popup>
);

AreYouSure.propTypes = {
  close: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
  yesAction: PropTypes.func.isRequired,
  customYes: PropTypes.string,
  customNo: PropTypes.string,
  header: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  optional: PropTypes.string,
};

AreYouSure.defaultProps = {
  header: 'Are you sure?',
  hidden: true,
  customYes: null,
  customNo: null,
  body: '',
  optional: '',
};

export default AreYouSure;
