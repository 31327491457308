// withActionStates({ options })(<ReactComponent />)
//    @param {Object} options
//    @param {Boolean} withAlerts - success and error alerts will show if true
//    @param {String} fallbackErrorMsg - message to show if no error codes match

import * as _ from 'lodash-es';
import { compose, withStateHandlers } from 'recompose';
import { withAlert } from 'react-alert';
import PropTypes from 'prop-types';
import { errorMessage } from '../../utils';

export const actionStatePropTypes = {
  updating: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.shape({})]).isRequired,
  success: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  setError: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  setUpdating: PropTypes.func.isRequired,
};

// set val if not an event.
const setValOrToggle = ({ curr, val }) =>
  val === undefined || !!_.get(val, 'target') ? !curr : val;

/** #### For functional components consider using useActionStates  */
const withActionStates =
  ({ withAlerts = false, fallbackErrorMsg = '' } = {}) =>
  WrappedComponent =>
    compose(
      withAlert(),
      withStateHandlers(() => ({ updating: false, error: false, success: false }), {
        setError:
          ({ error: curr }, { alert }) =>
          val => {
            const error = setValOrToggle({ curr, val });
            const message = error && errorMessage({ error, fallbackErrorMsg });
            if (error && withAlerts) {
              alert.error(message, {
                timeout: 5000,
              });
            }
            return {
              updating: false,
              success: false,
              error: message,
            };
          },
        setSuccess:
          ({ success: curr }, { alert }) =>
          val => {
            const success = setValOrToggle({ curr, val });
            if (!!success && withAlerts) {
              alert.success(val, {
                timeout: 3000,
              });
            }
            return {
              updating: false,
              error: false,
              success,
            };
          },
        setUpdating:
          ({ updating: curr }) =>
          val => ({
            error: false,
            success: false,
            updating: setValOrToggle({ curr, val }),
          }),
      })
    )(WrappedComponent);

export default withActionStates;
