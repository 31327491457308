import { gql } from '@apollo/client';

export default gql`
  query game($id: ID!) {
    game(_id: $id) {
      _id
      start_time
      end_time
      startTimeStr
      endTimeStr
      location {
        _id
        formatted_address
        name
        neighborhood
        timezone
      }
      league {
        _id
        sport {
          _id
          name
        }
      }
    }
  }
`;
