import { MainTabEnum } from '../../../shared/discover-tabs-enum';
import {
  ClassesFilterList,
  EventsFilterList,
  eventsAndClasses,
} from '../../../shared/activity-filter-enum';

const applyViewFilters = (view, unfilteredList) => {
  if (view === MainTabEnum.EVENTS) {
    return EventsFilterList;
  }
  if (view === MainTabEnum.CLASSES) {
    return ClassesFilterList;
  }
  return unfilteredList;
};

export const applySportsFilter = sports =>
  sports.filter(sport => !eventsAndClasses.includes(sport));

export default applyViewFilters;
