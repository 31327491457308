import PropTypes from 'prop-types';
import { ColorProps, CYCLIC_PROP } from '../common-props';

const GameProps = PropTypes.shape({
  _id: PropTypes.string,
  weekId: PropTypes.string,
  week: CYCLIC_PROP,
  dayId: PropTypes.string,
  day: CYCLIC_PROP,
  schedule: CYCLIC_PROP,
  week_num: PropTypes.number,
  league: CYCLIC_PROP,
  teams: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        color: ColorProps,
        score: PropTypes.number,
        outcome: PropTypes.string,
        players: PropTypes.arrayOf(CYCLIC_PROP),
        captain: PropTypes.string,
      }),
    ])
  ),
  winner: PropTypes.string,
  start_time: PropTypes.string,
  end_time: PropTypes.string,
  date: PropTypes.string,
  rsvped: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string,
      registrantId: PropTypes.string,
      teamId: PropTypes.string,
      fullName: PropTypes.string,
      displayNameTeams: PropTypes.string,
      gender: PropTypes.string,
      picture: PropTypes.string,
      response: PropTypes.string,
      timestamp: PropTypes.number,
    })
  ),
  field_name: PropTypes.string,
  is_tournament: PropTypes.bool,
  timezone: PropTypes.string,
  scores: PropTypes.arrayOf(
    PropTypes.shape({
      team_id: PropTypes.string,
      forfeit: PropTypes.bool,
      score: PropTypes.number,
      _id: PropTypes.string,
    })
  ),
  location: PropTypes.shape({
    _id: PropTypes.string,
    neighborhood: PropTypes.string,
    name: PropTypes.string,
    formatted_address: PropTypes.string,
    city: PropTypes.string,
    zip: PropTypes.number,
    timezone: PropTypes.string,
  }),
  forfeitter: PropTypes.string,
  tournament_round: PropTypes.number,
  teamIds: PropTypes.arrayOf(PropTypes.string),
  _creator: PropTypes.string,
  _updated: PropTypes.string,
  startTimeStr: PropTypes.string,
  endTimeStr: PropTypes.string,
  availableTeams: PropTypes.arrayOf(CYCLIC_PROP),
  teamRsvps: PropTypes.arrayOf(
    PropTypes.shape({
      teamId: PropTypes.string,
      femaleYesCount: PropTypes.number,
      maleYesCount: PropTypes.number,
      anyYesCount: PropTypes.number,
      totalYesCount: PropTypes.number,
    })
  ),
});

export default GameProps;
