import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getPlaceholder } from './fancy-helpers.js';
import styles from './fancy.module.scss';

const cx = classnames.bind(styles);

class Input extends Component {
  constructor(props) {
    super(props);
    this.focus = this.focus.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.placeholder = getPlaceholder(props);
    this.inputRef = props.ref || React.createRef();
    if (!props.disabled && !props.onChange)
      console.warn(`onChange required for input ${props.name}!`);
  }

  componentDidMount() {
    const { initFocus } = this.props;
    if (initFocus) {
      this.focus();
    }
  }

  focus() {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  onFocus() {
    const { onFocus } = this.props;
    if (onFocus) onFocus();
  }

  onBlur(e) {
    const { onBlur } = this.props;
    if (onBlur) onBlur(e);
  }

  onChange(e) {
    const { transform, onChange, passRawEvent } = this.props;
    if (passRawEvent) return onChange(e);
    let { value } = e.target;
    if (transform) {
      value = transform(value);
    }
    return onChange(value);
  }

  render() {
    const {
      attrs,
      autocomplete,
      border,
      className,
      disabled,
      error,
      grey,
      short,
      blockStyle,
      heavyBorder,
      name,
      style,
      styleName,
      type,
      value,
      defaultValue,
      saving,
      onKeyDown,
      onKeyPress,
      persistPlaceholder,
      readOnly,
      textarea,
      maxLength,
      searchIcon,
      loading,
      verified,
      min,
      max,
    } = this.props;

    const fieldProps = {
      ref: this.inputRef,
      className: cx(styles.fancyField, className, styles[styleName], {
        [styles.persistPlaceholder]: persistPlaceholder,
        [styles.heavy]: heavyBorder,
        [styles.border]: border,
        [styles.grey]: grey,
        [styles.short]: short,
        [styles.error]: error,
        [styles.disabled]: disabled,
        [styles.readonly]: readOnly,
        [styles.searchIcon]: searchIcon,
        [styles.loading]: loading,
        [styles.verified]: verified,
        [styles.blockStyle]: blockStyle,
        'text-flash': saving,
        [styles.color]: type === 'color',
      }),
      style,
      type,
      ...(type === 'number'
        ? {
            ...(min ? { min } : {}),
            ...(max ? { max } : {}),
          }
        : {}),
      value,
      ...(defaultValue ? { defaultValue } : {}),
      name,
      autoComplete: autocomplete,
      placeholder: this.placeholder,
      onChange: this.onChange,
      onBlur: this.onBlur,
      onFocus: this.onFocus,
      onKeyDown,
      onKeyPress,
      disabled,
      readOnly,
      maxLength,
      ...attrs,
    };

    if (textarea) return <textarea {...fieldProps} />;
    return <input {...fieldProps} />;
  }
}

Input.propTypes = {
  attrs: PropTypes.shape({}),
  autocomplete: PropTypes.string,
  border: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  initFocus: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  /** when true, onChange will pass the event instead of transforming it into a key-value pair. */
  passRawEvent: PropTypes.bool,
  style: PropTypes.shape({}),
  styleName: PropTypes.string,
  transform: PropTypes.func,
  type: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  saving: PropTypes.bool,
  heavyBorder: PropTypes.bool,
  persistPlaceholder: PropTypes.bool,
  ref: PropTypes.node,
  readOnly: PropTypes.bool,
};

Input.defaultProps = {
  attrs: {},
  autocomplete: '',
  border: false,
  className: 'w-100',
  disabled: false,
  error: false,
  initFocus: false,
  onBlur: null,
  onFocus: null,
  onKeyDown: () => {},
  passRawEvent: false,
  style: {},
  styleName: null,
  transform: null,
  type: 'text',
  defaultValue: undefined,
  value: '',
  saving: false,
  heavyBorder: false,
  persistPlaceholder: false,
  ref: null,
  readOnly: false,
};

// export const InputWithRef = React.forwardRef((props, ref) => <Input ref={ref} {...props} />);

export default Input;
