import { gql, type TypedDocumentNode } from '@apollo/client';
import type { RoleEnum } from '../../../shared/role-enum';
import type { ProgramTypeEnum } from '../../../shared/program-type-enum';
import type {
  NotificationStatusEnum,
  NotificationTypeEnum,
} from '../../../shared/notification-type-enum';
import type { RSVPEnum } from '../../../shared/rsvp-enum';

type HeaderQueryData = {
  currentUser: {
    _id: string;
    _created: Date;
    firstName: string;
    lastName: string;
    username: string;
    picture?: string;
    email: string;
    emailVerified: boolean;
    roles: RoleEnum[];
    availableCredits: {
      organizationId: string;
      organizationName: string;
      amount: number;
      used: number;
    }[];
    paymentSources: {
      isDefault: boolean;
      isExpired: boolean;
    }[];
    contactPreferences: {
      email: boolean;
      phone: boolean;
      text: boolean;
    };
    notifications: {
      recommendations: {
        email: boolean;
      };
    };
    notificationPreferences: {
      marketing: {
        email: boolean;
        push: boolean;
        sms: boolean;
      };
    };
    chosenPlan: string; // TODO enum?
    membershipExpires?: Date;
  };
};

export const HEADER_QUERY: TypedDocumentNode<HeaderQueryData> = gql`
  query currentUser {
    currentUser {
      _id
      _created
      firstName
      lastName
      username
      picture
      email
      emailVerified
      roles
      availableCredits {
        organizationId
        organizationName
        amount
        used
      }
      paymentSources {
        isDefault
        isExpired
      }
      contactPreferences {
        email
        phone
        text
      }
      notifications {
        recommendations {
          email
        }
      }
      notificationPreferences {
        marketing {
          email
          push
          sms
        }
      }
      chosenPlan
      membershipExpires
    }
  }
`;

type GetUserCombinedLeaguesRentalsData = {
  currentuser: {
    _id: string;
    membershipExpires?: Date;
    combinedLeaguesRentals: {
      league: {
        _id: string;
        name: string;
        stage: string; // TODO enum
        start_date: Date;
        featured_order?: number;
        banner_text?: string;
        activityName: string;
      };
      team: {
        _id: string;
        name: string;
        color: {
          hex: string;
        };
      };
      group: {
        _id: string;
        name: string;
        color: {
          hex: string;
        };
      };
      nextGame: {
        _id: string;
        opponent: {
          _id: string;
          name: string;
          color: {
            hex: string;
          };
        };
        rsvp: {
          _id: string;
          playerResponse: RSVPEnum;
        };
      };
      rentalDetails: {
        courts: string[];
        venue: string;
        start_time: Date;
        end_time: Date;
      };
    }[];
  };
};

const GET_USER_COMBINED_LEAGUES_RENTALS: TypedDocumentNode<GetUserCombinedLeaguesRentalsData> = gql`
  query getUserCombinedLeaguesRentals {
    currentUser {
      _id
      membershipExpires
      combinedLeaguesRentals {
        league {
          _id
          name
          stage
          start_date
          featured_order
          banner_text
          activityName
        }
        team {
          _id
          name
          color {
            hex
          }
        }
        group {
          _id
          name
          color {
            hex
          }
        }
        nextGame {
          _id
          opponent {
            _id
            name
            color {
              hex
            }
          }
          rsvp {
            _id
            playerResponse
          }
        }
        rentalDetails {
          courts
          venue
          start_time
          end_time
        }
      }
    }
  }
`;

export default GET_USER_COMBINED_LEAGUES_RENTALS;

type SendVeriticationEmailData = {
  sendVerificationEmail: {
    status: string;
  };
};

type SendVerificationEmailVars = {
  input: {
    email: string;
  };
};

export const SEND_EMAIL_VERIFICATION: TypedDocumentNode<
  SendVeriticationEmailData,
  SendVerificationEmailVars
> = gql`
  mutation sendVerificationEmail($input: SendVerificationEmailInput!) {
    sendVerificationEmail(input: $input) {
      status
    }
  }
`;

type MyRSVPSData = {
  myRsvps: {
    rsvps: {
      gameId: string;
      teamId: string;
      dropInSlotId: string;
      registrantId: string;
      leagueId: string;
      startDate: Date;
    }[];
  };
};

type MyRSVPSVars = {
  pagination: {
    pageNum: number;
    numPerPage: number;
  };
};

export const MY_DROPIN_RSVPS: TypedDocumentNode<MyRSVPSData, MyRSVPSVars> = gql`
  query myRsvps($pagination: PaginationInput!) {
    myRsvps(pagination: $pagination) {
      rsvps {
        gameId
        teamId
        dropInSlotId
        registrantId
        leagueId
        startDate
      }
    }
  }
`;

type DropInGameInfoData = {
  game: {
    _id: string;
    start_time: Date;
    startTimeStr: string;
    end_time: Date;
    endTimeStr: string;
    teamIds: string[];
    name: string;
    field_name?: string;
    location: {
      neighborhood: string;
      name: string;
      timezone: string;
    };
    league: {
      name: string;
      displayName: string;
      _id: string;
      activityName: string;
      photoUrl?: string;
      divisionDescriptions?: string[];
      venue: {
        formatted_address: string;
        timezone: string;
      };
      contact: {
        name: string;
        phoneNumber: string;
        email: string;
      };
    };
  };
};

export const DROPIN_GAME_INFO: TypedDocumentNode<DropInGameInfoData, { gameId: string }> = gql`
  query dropinGameInfo($gameId: ID!) {
    game(_id: $gameId) {
      _id
      start_time
      startTimeStr
      end_time
      endTimeStr
      teamIds
      name
      field_name
      location {
        neighborhood
        name
        timezone
      }
      league {
        name
        displayName
        _id
        activityName
        photoUrl
        divisionDescriptions
        venue {
          formatted_address
          timezone
        }
        contact {
          name
          phoneNumber
          email
        }
      }
    }
  }
`;

type DropInLeagueInfoData = {
  league: {
    _id: string;
    displayName: string;
    programType: ProgramTypeEnum;
    venue: {
      _id: string;
      name: string;
      maps_url_query: string;
      location: {
        lat: number;
        lng: number;
      };
      timezone: string;
    };
    neighborhood: {
      _id: string;
      name: string;
    };
    contact: {
      name: string;
      phoneNumber: string;
      email: string;
    };
  };
};

export const DROPIN_LEAGUE_INFO: TypedDocumentNode<DropInLeagueInfoData, { leagueId: string }> =
  gql`
    query dropinLeagueInfo($leagueId: ID!) {
      league(_id: $leagueId) {
        _id
        displayName
        programType
        venue {
          _id
          name
          maps_url_query
          location {
            lat
            lng
          }
          timezone
        }
        neighborhood {
          _id
          name
        }
        contact {
          name
          phoneNumber
          email
        }
        programType
      }
    }
  `;

type DropInTeamInfoData = {
  team: {
    _id: string;
    name: string;
    color: {
      hex: string;
      name: string;
    };
    captain: {
      _id: string;
      fullName: string;
      email: string;
      phone_number: string;
    };
  };
};

export const DROPIN_TEAM_INFO: TypedDocumentNode<DropInTeamInfoData, { teamId: string }> = gql`
  query team($teamId: ID!) {
    team(_id: $teamId) {
      _id
      name
      color {
        hex
        name
      }
      captain {
        _id
        fullName
        email
        phone_number
      }
    }
  }
`;

export type Notification = {
  _id: string;
  _created: Date;
  userId: string;
  organizationId: string;
  type: NotificationTypeEnum;
  status: NotificationStatusEnum;
  __typename: string;
  // type specific fields

  // Unsigned program waiver fields
  league: {
    _id: string;
    name: string;
    waiverId: string;
  };
  registrantId: string;

  // Unsigned rental waiver fields
  rental: {
    _id: string;
    name: string;
    waiver: {
      _id: string;
    };
  };
  rentalRegistrantId: string;
};

export type GetUserAlertsData = {
  getNotificationsList: {
    allNotifications: Notification[];
  };
};

export type GetUserAlertsVars = {
  input: {
    pagination: MyRSVPSVars['pagination'];
    status: NotificationStatusEnum;
  };
};

export const GET_USER_ALERTS: TypedDocumentNode<GetUserAlertsData, GetUserAlertsVars> = gql`
  query getNotificationsList($input: GetActivityListResponse) {
    getNotificationsList(input: $input) {
      allNotifications {
        _id
        _created
        userId
        organizationId
        type
        status
        ... on UnsignedWaiverNotification {
          __typename
          league {
            _id
            name
            waiverId
          }
          registrantId
        }
        ... on UnsignedRentalWaiverNotification {
          __typename
          rental {
            _id
            name
            waiver {
              _id
            }
          }
          rentalRegistrantId
        }
      }
    }
  }
`;
