/* eslint-disable global-require */
import cx from 'classnames';
import * as _ from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import Button from '../../components/Button';

let DayPicker = () => <div />;
if (typeof window !== 'undefined') {
  DayPicker = require('react-day-picker');
  require('react-day-picker/lib/style.css');
}

export default props => {
  let { onClear, selectableFrom, selectableTo, onChange, selected, month } = props;

  if (selectableFrom) {
    selectableFrom = selectableFrom.add(1, 'day');
  }
  if (selectableTo) {
    selectableTo = selectableTo.add(1, 'day');
  }

  return (
    <div className={cx('ml-4 d-flex flex-column')}>
      <h3>Please select any additional dates</h3>
      <DayPicker
        month={month || null}
        selectedDays={selected}
        onDayClick={(newDay, { disabled }) => {
          if (!disabled) {
            onChange(newDay);
          }
        }}
        disabledDays={[
          {
            ...(selectableFrom ? { before: selectableFrom.toDate() } : {}),
          },
          {
            ...(selectableTo ? { after: selectableTo.toDate() } : {}),
          },
        ]}
      />
      <Button
        disabled={_.size(selected) < 1}
        small
        primary
        className="mb-4"
        onClick={() => onClear()}
      >
        Clear all Selections
      </Button>
      {(selectableFrom || selectableTo) && (
        <b>
          Additional dates are selectable
          {selectableFrom && ` from ${moment(selectableFrom, 'YY/MM/DD').format('MMMM Do YYYY')}`}
          {selectableTo && ` to ${moment(selectableTo, 'YY/MM/DD').format('MMMM Do YYYY')}`}
        </b>
      )}
    </div>
  );
};
