import { gql } from '@apollo/client';

export const HOST_NAV_QUERY = gql`
  query currentUser {
    currentUser {
      _id
      organization {
        _id
        name
        logo
        icon
        hasStripeExpressAccount
      }
    }
  }
`;

export const ORGANIZATION_QUERY = gql`
  query organizationBasics($organizationId: ID!) {
    organization(_id: $organizationId) {
      _id
      name
      logo
      icon
      hasStripeExpressAccount
    }
  }
`;
