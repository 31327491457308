/* eslint-disable no-console */
import * as _ from 'lodash-es';
import { isProduction } from '../environment';

const getErrResString = err => {
  if (!err.response) return null;

  if (err.response.data) {
    if (err.response.data.message) {
      return `${err.response.data.message}`;
    }
    if (typeof err.response.data === 'object') {
      console.warn(
        `Couldn't determine error message from data object with keys: ${_.keyBy(err.response.data)}`
      );
      return null;
    }
    return `${err.response.data}`;
  }

  if (err.response.message) {
    return `${err.response.message} :[`;
  }

  if (typeof err.response === 'object') {
    console.warn(
      `Couldn't determine error message from response object with keys: ${_.keyBy(
        err.response.data
      )}`
    );
    return null;
  }

  console.warn(`Couldn't get error message from error response: ${err.response}`);
  return null;
};

export default (err, backup) => {
  if (!err) return backup || 'Whoops! An unknown error occurred.';
  if (typeof err === 'string') {
    return err;
  }

  if (!isProduction) {
    console.error(err);
  }

  const errResString = getErrResString(err);
  if (errResString) {
    return errResString;
  }
  if (err.message) {
    return `${err.message}`;
  }
  const errToString = `${err.toString()} :(`;
  return backup || errToString || 'Oops! An unknown error occurred.';
};
