// eslint-disable-next-line import/prefer-default-export
export enum StaticUrls {
  // Volo Kids
  VOLOKIDS = 'https://volokids.org',
  VOLOKIDS_VOLUNTEER = 'https://volokids.org/volunteer',
  VOLOKIDS_CORPORATEGIVING = 'https://volokids.org/corporate-giving',
  VOLOKIDS_PLAY = 'https://volokids.org/play/',
  VOLOKIDS_DONATE = 'https://donate.volokids.org/',

  // Sponsor Bar
  BUDLIGHT = 'https://www.budlight.com/',
  DCUNITED = 'https://www.dcunited.com/',
  KINDSNACKS = 'https://www.kindsnacks.com/',

  // Social Media
  FACEBOOK = 'https://www.facebook.com/playvolo/',
  LINKEDIN = 'https://www.linkedin.com/company/volosports',
  INSTAGRAM = 'https://www.instagram.com/volosports/',

  // SimplyBook
  // Book virtual private events hosted by Volo
  SIMPLYBOOK = 'https://volo.simplybook.me/v2/#book/count/1/',

  // Rental Forms
  // Alt version has a button to request edit access
  CONCIERGE = 'https://docs.google.com/forms/d/e/1FAIpQLSf5sjH1iD_qy6OMPpV3o75UTN6_5fP1F3Cv6z-SBYn3BnYMiw/viewform?usp=pp_url',
  CONCIERGE_ALT = 'https://docs.google.com/forms/d/15TDUXKbfXjmHdpJPA9njGsdmrR5N2omzTLrYoc1LwvU/viewform?ts=5f776ffd&gxids=7757&edit_requested=true',

  // About Volo
  ATHLETES = 'https://info.volosports.com/athletes',
  CAREERS = 'https://www.teamworkonline.com/youth-sports-jobs/volo-sports-jobs/volo-sports-jobs',
  NY_LAWYERS_LEAGUE = 'https://www.lawyersleague.com/',
  NY_CORPORATE_LEAGUE = 'https://corporateleague.com/',

  // Mobile downloads
  IOS = 'https://apps.apple.com/us/app/volo-sports/id1467419938',
  ANDROID = 'https://play.google.com/store/apps/details?id=com.mobile3',

  // Partnerships
  PARTNERSHIPS = 'https://rivall-public.s3.us-west-1.amazonaws.com/home-page/VoloSports_Partnership.pdf',

  // Cancel VP
  CANCEL_VP = 'https://forms.gle/GAad2N6PgXR1DriT7',

  // Volo Pass Waitlist
  VOLO_PASS_WAITLIST = 'https://forms.gle/gzWSgiSQdpReQbJRA',

  // Google - shown during sign-up process
  GOOGLE_PRIVACY = 'https://policies.google.com/privacy',
  GOOGLE_TOS = 'https://policies.google.com/terms',

  // Corporate page managed by Marketing
  CORPORATE = 'https://info.volosports.com/corporate',
}
