import { type FC, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { ActivityIndicator } from 'react-native-web';
import { View, Heading, Select, Text } from '@rivallapp/volosports-components';

import { type VoloPassCitySelectProps } from './types';
import ORGANIZATION_LIST from '../gql/ORGANIZATION_LIST';

const VoloPassCitySelect: FC<VoloPassCitySelectProps> = ({ selectedCityId, setSelectedCityId }) => {
  const { data, loading, error } = useQuery(ORGANIZATION_LIST);

  const organizationOptions = useMemo(() => {
    const { organizationList } = data || {};
    return organizationList?.map(({ _id, name }) => ({
      value: _id,
      label: name,
    }));
  }, [data]);

  const onValueChange = (value: string) => setSelectedCityId(value);

  if (error) return <Text>There was an error loading the city list</Text>;
  if (loading) return <ActivityIndicator />;

  return (
    <View marginTop={4}>
      <Heading fontSize={16} color="primary.600">
        Select Your City
      </Heading>
      <Select selectedValue={selectedCityId} onValueChange={onValueChange} flex={1}>
        {organizationOptions?.map(({ value, label }) => (
          <Select.Item key={value} value={value} label={label} />
        ))}
      </Select>
    </View>
  );
};

export default VoloPassCitySelect;
