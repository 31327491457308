import { useEffect } from 'react';

const useUnloadWarning = () => {
  useEffect(() => {
    const unloadCallback = (e: BeforeUnloadEvent) => {
      e.returnValue = true;
    };
    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);
};

export default useUnloadWarning;
