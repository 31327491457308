import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const Icon = ({ ...props }) => {
  const colorz = [
    // '#0000ff', //$teal
    '#9ce2db',
  ];

  const getColor = () => colorz[Math.floor(Math.random() * colorz.length)];

  const hereYouGo = ({ first_name, last_name, firstName, lastName }) => {
    let initials = '';
    if (first_name && first_name.length) initials += first_name[0];
    if (last_name && last_name.length) initials += last_name[0];
    if (firstName && firstName.length) initials += firstName[0];
    if (lastName && lastName.length) initials += lastName[0];
    return initials;
  };

  const getInitials = () => {
    if (props.first_name || props.last_name || props.firstName) return hereYouGo(props);
    if (props.profile) return hereYouGo(props.profile);
    if (props.user) return hereYouGo(props.user.profile);
    return 'R';
  };

  const picurl = props.picture
    ? props.picture
    : props.profile
      ? props.profile.picture
      : props.user
        ? props.user.profile.picture
        : null;

  const ico = picurl ? (
    <div className={cx('profpic')} style={{ backgroundImage: `url(${picurl})` }} />
  ) : (
    <div className={cx('circle')}>
      <h3>{getInitials()}</h3>
    </div>
  );

  return (
    <span
      className={cx('icon', props.className, {
        large: props.large,
      })}
      style={props.style}
    >
      {ico}
    </span>
  );
};

export default Icon;
