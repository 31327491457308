import initialStore from '../initialDiscoverStore';
import checkValidFilterKey from './checkValidFilterKey';

import useDiscoverStore from '../DiscoverStore';

const updateFilters = update => {
  const set = useDiscoverStore.setState;

  if (typeof update === 'object') {
    const checkPassed = checkValidFilterKey(update, initialStore.filters);
    if (!checkPassed) return;
  }

  set(state => ({
    filters: {
      ...state.filters,
      ...(typeof update === 'function' ? update(state.filters) : update),
    },
  }));
};

export default updateFilters;
