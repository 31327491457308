const defaultStyles = {
  root: {
    position: 'relative',
    paddingBottom: '0px',
  },
  autocompleteContainer: {
    position: 'absolute',
    top: '100%',
    backgroundColor: 'white',
    width: '100%',
    zIndex: 9999,
  },
  autocompleteItem: {
    backgroundColor: '#ffffff',
    padding: '10px',
    cursor: 'pointer',
  },
  autocompleteItemActive: {
    backgroundColor: '#fafafa',
  },
};

export default defaultStyles;
