import loadable from '@loadable/component';
import { Switch, Route } from 'react-router-dom';

const AppDashboard = loadable(() => import('../apps/APP/PlayerDashboard/PlayerDashboard'));
const PlayerProgram = loadable(() => import('../apps/APP/PlayerProgram'), { ssr: false });
const PlayerRental = loadable(() => import('../apps/APP/PlayerRental'), { ssr: false });
const DailyPlayerProgram = loadable(() => import('../apps/APP/DailyPlayerProgram'), { ssr: false });
const ErrorPage = loadable(() => import('../pages/ErrorPage'));

const AppController = () => (
  <Switch>
    <Route path="/app/dashboard" component={AppDashboard} />
    <Route path="/app/member/:leagueId" component={PlayerProgram} />
    <Route path="/app/reservation/:reservationId" component={PlayerRental} />
    <Route path="/app/:gameId" component={DailyPlayerProgram} />
    <Route component={ErrorPage} />
  </Switch>
);

export default AppController;
