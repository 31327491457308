import PropTypes from 'prop-types';
import { CYCLIC_PROP } from '../common-props';

const ScheduleProps = PropTypes.shape({
  _id: PropTypes.string,
  start_date: PropTypes.string,
  startDateStr: PropTypes.string,
  dateRangeStr: PropTypes.string,
  days_of_week: PropTypes.arrayOf(PropTypes.string),
  league: CYCLIC_PROP,
  weeks: PropTypes.arrayOf(CYCLIC_PROP),
  games: PropTypes.arrayOf(CYCLIC_PROP),
  days: PropTypes.arrayOf(CYCLIC_PROP),
  dow_values: PropTypes.arrayOf(PropTypes.number),
  timeframe: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  num_weeks: PropTypes.number,
  has_tournament: PropTypes.bool,
  num_tournament_weeks: PropTypes.number,
  num_games_per_team_per_week: PropTypes.number,
  num_simultaneous_games: PropTypes.number,
  game_length_in_minutes: PropTypes.number,
  timezone: PropTypes.string,
  startTimeStr: PropTypes.string,
  endTimeStr: PropTypes.string,
  perDayMinutes: PropTypes.string,
});

export default ScheduleProps;
