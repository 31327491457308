import React from 'react';
import PropTypes from 'prop-types';
import { isSuperLight } from '../../utils/get-color';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const ColorIndicator = ({ color, style, size, addBorder }) => {
  let hex;
  if (typeof color === 'string' && color.indexOf('#') >= 0) {
    hex = color;
  } else if (color) {
    hex = color.hex;
  }

  let borderColor;
  if (hex && isSuperLight(hex)) borderColor = '#dddddd';
  else if (addBorder) borderColor = '#ffffff';
  else borderColor = hex;

  const borderWidth = addBorder ? '2px' : '1px';

  const styleObj = {
    backgroundColor: hex,
    borderColor,
    borderWidth,
    ...(size ? { width: size, height: size } : {}),
    ...style,
  };
  return <span className={cx('color-indicator')} style={styleObj} />;
};

ColorIndicator.propTypes = {
  color: PropTypes.oneOfType([
    PropTypes.shape({
      hex: PropTypes.string,
      indexOf: PropTypes.number,
    }),
    PropTypes.string,
  ]),
  size: PropTypes.string,
  style: PropTypes.shape({}),
  addBorder: PropTypes.bool,
};

ColorIndicator.defaultProps = {
  color: undefined,
  size: undefined,
  style: undefined,
  addBorder: false,
};

export default ColorIndicator;
