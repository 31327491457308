import { isProduction as isProdClient } from '../environment';

export const STRIPE_VOLO_PASS_PRODUCT_IDS = isProdClient
  ? [
      'prod_LITLNLTQyFSivl', // Stripe Name: "Volo Pass"
      'prod_NVG4P6j069WhXs', // "Volo Pass NYC PT Staff Membership"
      'prod_HtYy8GGEKYArWh', // "Volo Pass Monthly"
      'prod_F8LERRtiSSoC7B', // "VoloPass - Monthly"
    ]
  : ['prod_F8QWiJBlmvvEl7']; // "Membership 999"

export const STRIPE_VOLO_FITNESS_PRODUCT_IDS = isProdClient
  ? ['prod_QM0Wp3JfFYuDEu'] // "Volo Fitness"
  : ['prod_QM0LwJOYA6tTHY']; // "Volo Fitness Membership"

export const productIdIsVoloPass = (productId: string) =>
  STRIPE_VOLO_PASS_PRODUCT_IDS.includes(productId);

export const productIdIsVoloFitness = (productId: string) =>
  STRIPE_VOLO_FITNESS_PRODUCT_IDS.includes(productId);

/** See https://github.com/rivallapp/volosports-tech-platform/wiki/Volo-Pricing-Structure-Overview */
export const StripeTaxRates = Object.freeze({
  // Volo Pass Tech Fees in Production
  price_1KbsOjCyzbeSckFnDm3WXS8P: 'txr_1KLVw3CyzbeSckFn7BVCWON2',
  price_1KbsOjCyzbeSckFns5tgZmeu: 'txr_1KLVv3CyzbeSckFnG0Wv4UK8',
  price_1KbsOjCyzbeSckFnhR8Hrrim: 'txr_1KLVtTCyzbeSckFnvOasTO0S',
  price_1KbsOjCyzbeSckFnap86C811: 'txr_1KHaKsCyzbeSckFnx7glgmX9',
  price_1KbsOjCyzbeSckFnXqlXcgti: 'txr_1KHaK6CyzbeSckFnOOjcWD2R',
  price_1KbsOjCyzbeSckFneFclgfTt: 'txr_1KLUTLCyzbeSckFnP4ziP8sX',
  price_1KbsOjCyzbeSckFnnVsLwv0R: 'txr_1KHaHsCyzbeSckFn33HtfNJc',
  price_1KbsOjCyzbeSckFnLPNo510C: 'txr_1KLUFyCyzbeSckFnMEljV0kv',
  price_1KbsOjCyzbeSckFnPz0JgFXe: 'txr_1KHaGACyzbeSckFnwi4aFITA',
  price_1KbsOjCyzbeSckFnGIsnrTLk: 'txr_1KLUEMCyzbeSckFnNlE0E4tA',
  price_1KbsOjCyzbeSckFnaWjGYSKQ: 'txr_1KHaBaCyzbeSckFnAANl5N1h',
  price_1KbsOjCyzbeSckFn7E8xImha: 'txr_1KHa9sCyzbeSckFnrRnbAb6n',
  price_1KbsOjCyzbeSckFn6ZLYuMjl: 'txr_1KHa4sCyzbeSckFnsPQzEH3l',

  // Volo Fitness Tech Fees in Production
  price_1PVIYLCyzbeSckFnazGymk8D: 'txr_1PfREuCyzbeSckFnVzLYMvlS',
  price_1PVIY9CyzbeSckFnYCh9NedQ: 'txr_1PfRIHCyzbeSckFnMwlQyN1Y',
  price_1PVIXyCyzbeSckFnTLM3vlLW: 'txr_1PfRKBCyzbeSckFnFSDMu9Bk',
  price_1PVIXiCyzbeSckFnr5jQTPmF: 'txr_1PfRLTCyzbeSckFnnWJlzjmx',
  price_1PVIXXCyzbeSckFnK79eB1NJ: 'txr_1PfRNYCyzbeSckFneeS1n5Mm',
  price_1PVIXLCyzbeSckFngmd5F0SB: 'txr_1PfROnCyzbeSckFnHYJC840C',
  price_1PVIX8CyzbeSckFniTOND4Ab: 'txr_1PfRQiCyzbeSckFnt4aSOKHr',
  price_1PVIWxCyzbeSckFnXtJYExO5: 'txr_1PfRS3CyzbeSckFn4rs1qOny',
  price_1PVIWmCyzbeSckFnbtoToHmW: 'txr_1PfRT9CyzbeSckFnL8SNxtw4',
  price_1PVIWYCyzbeSckFnw065CfQu: 'txr_1PfRUhCyzbeSckFns1rDHmPt',
  price_1PVIWOCyzbeSckFnCcN5zMXU: 'txr_1PfRVxCyzbeSckFn344geakk',
  price_1PVIW9CyzbeSckFngYqp72ey: 'txr_1PfRXBCyzbeSckFnHA58qpEe',
  price_1PVIVwCyzbeSckFnHuhSHCaz: 'txr_1PfRYECyzbeSckFnRcF80gZa',
  price_1PVIVZCyzbeSckFnxUwWzvMB: 'txr_1PfRZOCyzbeSckFnEHMyBQMu',

  // Flat 1.5% fee in staging
  STAGING: 'txr_1K47vRCyzbeSckFnhuocqEPw',
});

export const getStripeTaxRate = (planId: string): string | undefined => {
  if (!isProdClient) return StripeTaxRates.STAGING;

  const stringIsPlanId = (str: string): str is keyof typeof StripeTaxRates => str in StripeTaxRates;

  return stringIsPlanId(planId) ? StripeTaxRates[planId] : undefined;
};

// Stripe does not export its enums so we must manually define them here.
export const StripeInvoiceStatus = Object.freeze({
  DRAFT: 'draft',
  OPEN: 'open',
  PAID: 'paid',
  UNCOLLECTIBLE: 'uncollectible',
  VOID: 'void',
});

export const StripeDiscountDuration = Object.freeze({
  ONCE: 'once',
  REPEATING: 'repeating',
  FOREVER: 'forever',
});

export enum StripeSubscriptionStatus {
  ACTIVE = 'active',
  TRIALING = 'trialing',
  /** Initial payment has failed but user can technically still access benefits */
  INCOMPLETE = 'incomplete',
  /** First invoice went unpaid for over 23 hours */
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
  PAUSED = 'paused',
}

export enum StripeMembershipIntervalEnum {
  MONTH = 'month',
  YEAR = 'year',
}

export enum StripePaymentStatus {
  PENDING = 'pending',
  ERROR = 'error',
  OK = 'ok',
  REFUNDED = 'refunded',
  PARTIALLY_REFUNDED = 'partially_refunded',
}

export enum StripeChargeStatus {
  SUCCEEDED = 'succeeded',
  PENDING = 'pending',
  FAILED = 'failed',
}
