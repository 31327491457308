import { Route, Redirect } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';

import { Auth } from '../zustand-stores/AuthStore';

interface UnauthenticatedRouteProps extends RouteProps {
  /** Redirect path */
  redirect?: string;
}

/** Redirects if a user is authenticated. */
const UnauthenticatedRoute: React.FC<UnauthenticatedRouteProps> = ({
  redirect = '/',
  ...props
}) => {
  if (Auth.getIsAuthenticated()) {
    console.log('Route requires user to be unauthenticated. Redirecting...');
    return <Redirect to={redirect} />;
  }

  return <Route {...props} />;
};

export default UnauthenticatedRoute;
