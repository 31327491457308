/* global Stripe */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, withState } from 'recompose';
import Button from '../../../../../components/Button';
import ColorEnum from '../../../../../utils/colors';
import { stripePublishableKey } from '../../../../../environment';

class StripeForm extends Component {
  componentDidMount() {
    this.stripe = Stripe(stripePublishableKey);
    const elements = this.stripe.elements({
      fonts: [
        {
          family: 'Apercu',
          src: 'url("https://s3-us-west-1.amazonaws.com/rivall-public/Apercu/apercu_regular_pro.woff")',
        },
      ],
      locale: 'en',
    });
    this.card = elements.create('card', {
      style: {
        base: {
          color: ColorEnum.text_color,
          fontFamily: '"Apercu", "Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontWeight: '400',
          fontSize: '14px',
          '::placeholder': {
            color: ColorEnum.text_muted,
          },
        },
        invalid: {
          color: ColorEnum.danger,
          iconColor: ColorEnum.danger,
        },
      },
    });
    this.card.mount('#card-element');
    this.card.addEventListener('change', e => {
      this.onFormChange(e);
    });
  }

  onFormChange = e => {
    const { setFormStatus } = this.props;
    if (e.error) {
      setFormStatus('error');
    } else {
      setFormStatus(e.complete ? 'complete' : 'incomplete');
    }
  };

  submit = async () => {
    const { card } = this;
    const { onTokenSuccess, setFormStatus } = this.props;

    setFormStatus('loading');

    this.stripe.createToken(card, { currency: 'usd' }).then(result => {
      if (!result.error) {
        onTokenSuccess(result);
      }
      setFormStatus(null);
    });

    this.hackyFocusFixForIOS();
  };

  componentWillUnmount() {
    this.hackyFocusFixForIOS();
  }

  hackyFocusFixForIOS() {
    // hack to prevent ios keyboard from continuing to pop up after submission.
    // this was an issue as of feb 2018. see https://stackoverflow.com/questions/48015579
    if (this.card) this.card.blur();
    if (!this.focusFixInput) {
      return;
    }
    this.focusFixInput.focus();
    this.focusFixInput.blur();
  }

  render() {
    const { formStatus } = this.props;

    return (
      <React.Fragment>
        <div id="card-element"> &nbsp;</div>

        {formStatus !== 'incomplete' && (
          <Button
            default
            block
            className="mt-2"
            onClick={this.submit}
            disabled={formStatus !== 'complete'}
            loading={formStatus === 'loading'}
          >
            Use Card
          </Button>
        )}

        <input
          ref={ip => {
            this.focusFixInput = ip;
          }}
          style={{
            height: '0px',
            width: '0px',
            overflow: 'hidden',
            border: 'none',
            fontSize: '0',
            lineHeight: '0',
            padding: '0',
            margin: '0',
          }}
        />
      </React.Fragment>
    );
  }
}

StripeForm.propTypes = {
  onTokenSuccess: PropTypes.func.isRequired,
};

export default compose(withState('formStatus', 'setFormStatus', 'incomplete'))(StripeForm);
