export const JPG = 'jpg';
export const AVIF = 'avif';
export const WEBP = 'webp';
export const GIF = 'gif';

/**
  Returns a new filename with the extension replaced.

  For example for the file name a.jpg and newExt webp
  the results is a.webp.

  @param  filename  filename to be replaced the extension.
  @param  newExt    extension to be used.

*/
const changeExtensionFileName = (filename, newExt) => {
  const parts = filename.split('.');
  if (parts.length > 1) {
    parts[parts.length - 1] = newExt;
    return parts.join('.');
  }
  return `${filename}.${newExt}`;
};

/**
  Returns a new src (that point to a file) with the extension replaced.

  The src is expected to be an absolute URL (https://).

  For example for src https://www.x.y/a.jpeg and newExt webp the results
  will be https://www.x.y/a.webp.

  @param  src     absolute URL.
  @param  newExt  extension to be used.

*/
export const changeExtension = (src, newExt) => {
  const url = new URL(src);
  const paths = url.pathname.split('/');
  if (paths.length > 1) {
    const fileName = paths[paths.length - 1];
    const newFileName = changeExtensionFileName(fileName, newExt);
    paths[paths.length - 1] = newFileName;
    url.pathname = paths.join('/');
    return url.toString();
  }
  return `${src}.${newExt}`;
};

/**
  Returns the file extension used by the absolute URL src.

  For example for src https://www.x.y/a.jpeg the result will be jpeg or
  empty string in case the url doesn't have file extension.

  @param  src   absolute URL.

*/
export const getExtension = src => {
  const url = new URL(src);
  const paths = url.pathname.split('/');
  if (paths.length > 1) {
    const fileName = paths[paths.length - 1];
    const fileParts = fileName.split('.');
    if (fileParts.length > 1) {
      return fileParts[fileParts.length - 1];
    }
  }
  return '';
};

/**
  Returns the content type of the file used by the absolute URL src.

  For example for src https://www.x.y/a.png the result will be image/png.

  @param  src   absolute URL.

*/
export const getImageMimeType = src => {
  const ext = getExtension(src).toLowerCase();
  if (ext === JPG) {
    // Standard correct value.
    return 'image/jpeg';
  }
  return `image/${ext}`;
};

/**
  Returns the list of extensions availables for the file used by the absolute URL src.

  By default is expected to returns [avif, webp] but in the case of gif it will be available
  only the [webp].

  For external reason there will be no avif for gif.

  @param  src   absolute URL.

*/
export const getExtensionsSources = src => {
  if (getExtension(src) === GIF) {
    return [WEBP];
  }
  return [AVIF, WEBP];
};
