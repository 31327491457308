import * as _ from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';
import Button from './index';

const ButtonGroup = props => {
  const { buttons, buttonProps, unselectedProps, selectedProps, selected, onChange, multi } = props;

  const onClick = buttonVal => {
    if (!multi || !Array.isArray(selected)) {
      return onChange(buttonVal);
    }
    if (selected.includes(buttonVal)) {
      return onChange(_.difference(selected, [buttonVal]));
    }
    return onChange(_.union(selected, [buttonVal]));
  };

  const isSelected = buttonVal =>
    Array.isArray(selected) ? selected.includes(buttonVal) : selected === buttonVal;

  return (
    <>
      {buttons.map(({ label, value }) => (
        <Button
          key={value}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...buttonProps}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(isSelected(value) ? selectedProps : unselectedProps)}
          onClick={() => onClick(value)}
        >
          {label}
        </Button>
      ))}
    </>
  );
};

ButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    })
  ).isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  multi: PropTypes.bool,
  buttonProps: PropTypes.shape({}),
  unselectedProps: PropTypes.shape({}),
  selectedProps: PropTypes.shape({}),
};

ButtonGroup.defaultProps = {
  multi: false,
  buttonProps: {},
  selected: null,
  unselectedProps: { default: true },
  selectedProps: { primary: true },
};

export default ButtonGroup;
