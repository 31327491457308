export const difference = <T>(arr1: T[] = [], arr2: T[] = []) => {
  if (!arr1.length || !arr2.length) {
    return [...arr1, ...arr2];
  }
  return [arr1, arr2].reduce((a, b) => a.filter(c => !b.includes(c)));
};

/**
 * Takes two arrays and returns two arrays with the unique values from each array.
 *
 * @example
 * const arr1 = [1, 2, 3, 4, 5];
 * const arr2 = [3, 4, 5, 6, 7];
 * const [uniqueArr1, uniqueArr2] = removeDuplicates(arr1, arr2);
 * console.log(uniqueArr1); // [1, 2]
 * console.log(uniqueArr2); // [6, 7]
 */
export const removeDuplicates = <T>(arr1: T[] = [], arr2: T[] = []) => {
  if (!arr1.length || !arr2.length) {
    return [[...arr1], [...arr2]];
  }

  return [difference(arr1, arr2), difference(arr2, arr1)];
};

export const intersection = <T>(arr1: T[] = [], arr2: T[] = []) => {
  if (!arr1.length || !arr2.length) {
    return [...arr1, ...arr2];
  }
  return [arr1, arr2].reduce((a, b) => a.filter(c => b.includes(c)));
};

export const chunkArray = <T>(arr: T[] = [], chunkSize = 1, cache: T[][] = []): T[][] => {
  if (chunkSize <= 0) return cache;
  const tmp = [...arr];
  while (tmp.length) cache.push(tmp.splice(0, chunkSize));
  return cache;
};

export const CHUNK_BREAKPOINT = 11;
export const chunkWithBreakpoint = <T>(array: T[] = [], breakpoint = CHUNK_BREAKPOINT): T[][] =>
  chunkArray(array, array.length > breakpoint ? Math.ceil(array.length / 2) : breakpoint);

/**
 * @param array
 * @param value
 * @returns if array contains value, returns new array with value removed. otherwise, returns new array with value included
 */
export const toggleArrayItem = <T>(array: T[] = [], value: T | undefined = undefined): T[] => {
  if (!Array.isArray(array)) return [];
  if (!value) return array;

  if (array.includes(value)) {
    return array.filter(item => item !== value);
  }
  return [...array, value];
};

export const getUniques = <T>(array: T[] = []) => [...new Set(array)];
