import React from 'react';
import { type FallbackProps, ErrorBoundary } from 'react-error-boundary';
import Error from '../Error';

const GenericError: React.FC<FallbackProps> = ({ error }) => <Error error={error} />;

/**
 * A generic wrapper for use with Error Boundaries. Displays the existing `<Error />` component with
 * no other options.
 *
 * @example
 * ```tsx
 * import { GenericErrorBoundary } from 'components';
 *
 * const ThingThatThrowsError = () => {
 *   const handlePress = () => {
 *     throw new Error('oops!');
 *   };
 *   return <button onClick={() => handlePress()}>Press me!</button>;
 * };
 *
 * const App = () => {
 *   return (
 *     <GenericErrorBoundary>
 *       <ThingThatThrowsError />
 *     </GenericErrorBoundary>
 *   );
 * };
 * ```
 */
const GenericErrorBoundary: React.FC<React.PropsWithChildren> = ({ children }) => (
  <ErrorBoundary FallbackComponent={GenericError}>{children}</ErrorBoundary>
);

export default GenericErrorBoundary;
