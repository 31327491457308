import { gql, type TypedDocumentNode, useQuery } from '@apollo/client';
import useCurrentUser from './useCurrentUser';

export type LeagueListVars = {
  input: {
    bounds?: {
      ne: { lat: number; lng: number };
      sw: { lat: number; lng: number };
    };
    cityName?: string;
    dateHigh?: string;
    dateLow?: string;
    daysOfWeek?: string[];
    discoverable?: boolean;
    nameSearch?: string;
    neighborhoodIds?: string[];
    omitArchived?: boolean;
    omitReoccuringChildren?: boolean;
    omitReoccuringParents?: boolean;
    organizationId?: string;
    pagination?: {
      numPerPage?: number;
      pageNum?: number;
    };
    programStatus?: string;
    programType?: string;
    programTypes?: string[];
    season?: string;
    sortBy?: string;
    seasonNames?: string[];
    sport?: string;
    sportNames?: string[];
    stage?: string;
    stages?: string[];
    venueIds?: string[];
  };
};

export type LeagueListData = {
  leagueList: {
    count: number;
    endCursor: number;
    leagues: {
      _id: string;
      name: string;
      archived: boolean;
      seasonName: string;
      activityName: string | null;
    }[];
  };
};

export const LEAGUE_LIST: TypedDocumentNode<LeagueListData, LeagueListVars> = gql`
  query LeagueListQuery($input: LeagueListInput!) {
    leagueList(input: $input) {
      count
      endCursor
      leagues {
        _id
        name
        archived
        seasonName
        activityName
      }
    }
  }
`;

/**
 * Hook replacement for `withOrganizationLeagues` HOC.
 *
 * Returns the full organization league list for the current logged in staff member.
 */
export const useOrganizationLeagues = () => {
  const { organizationId } = useCurrentUser();

  const query = useQuery(LEAGUE_LIST, {
    skip: !organizationId,
    fetchPolicy: 'network-only',
    variables: {
      input: {
        organizationId,
        omitArchived: false,
      },
    },
  });

  return {
    ...query,
    organizationId,
    leagues: query.data?.leagueList.leagues || [],
  };
};

export default useOrganizationLeagues;
