import * as _ from 'lodash-es';
import { compose, withProps, setDisplayName } from 'recompose';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import withCurrentUser from '../../hocs/withCurrentUser';
import { ORGANIZATION_QUERY } from './HostNav/HOST_NAV_QUERY';
import Header from './Header.jsx';
import RoleEnum from '../../shared/role-enum';

export default compose(
  setDisplayName('HeaderContainer'),
  withRouter,
  withCurrentUser(),
  withProps(({ currentUser, location }) => {
    const { roles = [], organizations = [], firstName = '' } = currentUser || {};
    return {
      currentUser: {
        ...currentUser,
        name: firstName,
      },
      isAuth: !!currentUser,
      isRfo: _.includes(_.get(location, 'pathname'), 'rfo'),
      ...(currentUser
        ? {
            isStaff: _.includes(roles, RoleEnum.STAFF),
            isHost: _.includes(roles, RoleEnum.OWNER) || _.includes(roles, RoleEnum.ADMIN),
            isAdmin: _.includes(roles, RoleEnum.ADMIN),
          }
        : {}),
      organizationList: _.orderBy(
        _.map(organizations, ({ _id, name }) => ({ value: _id, label: name })),
        ['label']
      ),
      showDropdown: roles.ADMIN || organizations.length > 1,
    };
  }),
  graphql(ORGANIZATION_QUERY, {
    skip: ({ organizationId }) => !organizationId,
    props: ({ data: { loading, organization } }) => ({
      loading,
      organization,
    }),
  })
)(Header);
