import PropTypes from 'prop-types';
import React from 'react';
import HeaderLinks from '../HeaderLinks';
import links from './links';
import { OrganizationProps } from '../../../types';

const HostNav = ({ organization, isAdmin }) => {
  const { hasStripeExpressAccount } = organization || {};

  const alerts = hasStripeExpressAccount
    ? null
    : [
        {
          label: 'Your organization has no Stripe account connected. Click here to configure.',
          to: `/rfo/stripe-setup?organization=${organization?._id}`,
        },
      ];

  return (
    <HeaderLinks
      {...{ organization }}
      isAdmin={isAdmin}
      links={links}
      showAlerts
      alerts={alerts}
      prefix="rfo"
    />
  );
};

HostNav.propTypes = {
  organization: OrganizationProps,
  isAdmin: PropTypes.bool,
};

HostNav.defaultProps = {
  organization: {},
  isAdmin: false,
};

export default HostNav;
