import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './styles.module.scss';
import { Button } from '..';

const cx = classNames.bind(styles);

const MAX_FILESIZE = 52428800;

const createStyles = ({ isCircle, height }) => ({
  baseStyle: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    position: 'relative',
    minHeight: height,
    borderRadius: isCircle ? '50%' : '2px',
  },
  focusedStyle: {
    borderColor: '#2196f3',
  },
  acceptStyle: {
    borderColor: '#00e676',
  },
  rejectStyle: {
    borderColor: '#ff1744',
  },
});

const asyncReadFile = file =>
  new Promise((resolve, reject) => {
    if (file.size > MAX_FILESIZE) reject(new Error('Unable to upload - file size is too large'));
    const reader = new FileReader();
    const split = file.type.split('/');
    reader.onload = () => {
      resolve({ file: reader.result, type: split[split.length - 1] });
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

const PhotoUploader = props => {
  const {
    recommendedDimensions,
    text,
    onSave,
    allowMulti,
    updating,
    hideSave,
    initialPhoto,
    style,
  } = props;

  const [photoPreview, setPhotoPreview] = useState(initialPhoto ? [{ file: initialPhoto }] : []);

  const handleOnDrop = useCallback(
    async acceptedFiles => {
      setPhotoPreview([]);
      const files = await Promise.all(acceptedFiles.map(file => asyncReadFile(file)));
      setPhotoPreview(files);
      // if not showing save button, immediately return files
      if (hideSave) onSave(files);
    },
    [hideSave, onSave]
  );

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop: handleOnDrop,
    multiple: allowMulti,
    accept: 'image/*',
  });

  const { baseStyle, focusedStyle, acceptStyle, rejectStyle } = createStyles(props);

  const dropZoneStyle = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [baseStyle, isFocused, focusedStyle, isDragAccept, acceptStyle, isDragReject, rejectStyle]
  );

  return (
    <div className={cx('photo-uploader', 'container')} style={{ ...style }}>
      <div {...getRootProps({ style: dropZoneStyle })}>
        <input {...getInputProps()} />
        <p>{text}</p>
        {recommendedDimensions && (
          <p className="mt-2">Recommended dimensions: {recommendedDimensions}</p>
        )}
        {photoPreview.map(({ file }) => (
          <p className={cx('preview-file')} key={file}>
            <img height="75" src={file} alt="icon preview" />
          </p>
        ))}
      </div>
      {!hideSave && (
        <div className="mt-3">
          <Button disabled={!photoPreview.length || updating} onClick={() => onSave(photoPreview)}>
            {updating ? 'Saving...' : 'Save Picture'}
          </Button>
        </div>
      )}
    </div>
  );
};

PhotoUploader.propTypes = {
  onSave: PropTypes.func.isRequired,
  photoPreview: PropTypes.string,
  recommendedDimensions: PropTypes.string,
  text: PropTypes.string,
  allowMulti: PropTypes.bool,
  updating: PropTypes.bool,
  hideSave: PropTypes.bool,
  initialPhoto: PropTypes.string,
  isCircle: PropTypes.bool,
  height: PropTypes.number,
  style: PropTypes.shape({}),
};

PhotoUploader.defaultProps = {
  photoPreview: null,
  recommendedDimensions: null,
  text: 'Drag n drop some files here, or click to select files',
  allowMulti: false,
  updating: false,
  hideSave: false,
  initialPhoto: null,
  isCircle: false,
  height: 150,
  style: {},
};

export default PhotoUploader;
