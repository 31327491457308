import useDiscoverStore from '../DiscoverStore';

const updateFilterCollapsed = (filterKey, collapsed) => {
  const get = useDiscoverStore.getState;
  const set = useDiscoverStore.setState;

  const { filtersCollapsed } = get();

  if (!(filterKey in filtersCollapsed) || filtersCollapsed.filterKey === collapsed) return;

  set(state => ({
    filtersCollapsed: {
      ...state.filtersCollapsed,
      [filterKey]: collapsed,
    },
  }));
};

export default updateFilterCollapsed;
