/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import { components as reactSelectComponents } from 'react-select';
import filterComponents from './filterSelectComponents';
import filterSelectStyles from './filterSelectStyles';

export const selectPropTypes = {
  menuPlacement: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  onChange: PropTypes.func,
  multi: PropTypes.bool,
  labelSingular: PropTypes.string,
  labelPlural: PropTypes.string,
  searchable: PropTypes.bool,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  styles: PropTypes.shape({}),
  components: PropTypes.shape({}),
  placeholder: PropTypes.string,
  radius: PropTypes.string,
  skinny: PropTypes.bool,
  border: PropTypes.bool,
  dark: PropTypes.bool,
  borderColor: PropTypes.string,
  short: PropTypes.bool,
  inHeaderCity: PropTypes.bool,
  rounded: PropTypes.bool,
  withDropdown: PropTypes.bool,
  miscStyles: PropTypes.shape({}),
  CustomOption: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  noOptionsMessage: PropTypes.func,
  controlShouldRenderValue: PropTypes.bool,
  CustomDropdownIndicator: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  filterOption: PropTypes.func,
  inputValue: PropTypes.string,
  onInputChange: PropTypes.func,
  isLoading: PropTypes.bool,
  errorMsg: PropTypes.string,
  creatable: PropTypes.bool,
  shadow: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
};

export const selectDefaultProps = {
  menuPlacement: 'bottom',
  value: undefined,
  options: undefined,
  onChange: undefined,
  shadow: false,
  multi: false,
  labelSingular: undefined,
  labelPlural: undefined,
  searchable: true,
  clearable: false,
  disabled: false,
  styles: undefined,
  components: undefined,
  placeholder: undefined,
  radius: '0px',
  skinny: false,
  border: false,
  dark: false,
  borderColor: undefined,
  short: false,
  inHeaderCity: false,
  rounded: false,
  withDropdown: true,
  miscStyles: {},
  CustomOption: undefined,
  noOptionsMessage: undefined,
  controlShouldRenderValue: true,
  inputValue: undefined,
  onInputChange: undefined,
  isLoading: false,
  errorMsg: '',
  creatable: false,
  filterOption: undefined,
  getOptionLabel: undefined,
  getOptionValue: undefined,
};

const CustomDropdown = props => (
  <reactSelectComponents.DropdownIndicator {...props}>▾</reactSelectComponents.DropdownIndicator>
);

const filterSelectProps = props => {
  const {
    value,
    options,
    onChange,
    multi,
    // need to specify singular and plural labels for multi value selects.
    // for single value selects, specifying either "labelPlural" or
    // "placeholder" works
    labelSingular,
    labelPlural,
    searchable,
    clearable,
    disabled,
    styles,
    components,
    icon,
    withDropdown = true,
    placeholder = undefined,
    skinny,
    short,
    inHeaderCity,
    rounded,
    menuPlacement,
    CustomOption,
    CustomDropdownIndicator,
    controlShouldRenderValue,
    ...otherProps
  } = props;

  const selectComponents = {
    ...filterComponents,
    ...components,
    ...(withDropdown
      ? {
          DropdownIndicator: CustomDropdownIndicator || CustomDropdown,
        }
      : {}),
  };

  // providing a style for a component overwrites all styling
  // done here. use props defined in filterSelectStyles.js
  // for finer control without need to rewrite entire style

  const selectStyles = {
    ...filterSelectStyles(props),
    ...styles,
  };

  return {
    ...otherProps,
    value,
    options,
    onChange,
    isMulti: multi,
    labelSingular,
    labelPlural,
    placeholder,
    isSearchable: searchable,
    isClearable: clearable || multi,
    isDisabled: disabled,
    styles: selectStyles,
    components: selectComponents,
    closeMenuOnSelect: !multi,
    hideSelectedOptions: false,
    autosize: false,
    icon,
    withDropdown,
    skinny,
    short,
    inHeaderCity,
    rounded,
    menuPlacement,
    CustomOption,
    controlShouldRenderValue,
  };
};

export default filterSelectProps;
