import { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';

import RoleEnum from '../shared/role-enum';
import { Auth } from '../zustand-stores/AuthStore';

interface AuthenticatedRouteProps extends RouteProps {
  /**
   * Redirect path
   * @default '/login'
   * */
  redirect: string;
  /** Role to authenticate route against */
  role: keyof typeof RoleEnum;
}

/**
 * Redirects if a user is __NOT__ authenticated
 *
 * If a role is specified, user must have that access level.
 *
 * | Role     | Roles with access           |
 * | :------- | :-------------------------- |
 * | ADMIN    | ADMIN, OWNER                |
 * | OWNER    | ADMIN, OWNER                |
 * | STAFF    | ADMIN, OWNER, STAFF         |
 * | PLAYER   | ADMIN, OWNER, STAFF, PLAYER |
 */
const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({
  redirect = '/login',
  role: requiredRole,
  ...props
}) => {
  const { location } = props;

  // Set a redirect link on-mount if the user is unauthenticated.
  // They will be sent to the login page and be brought back after
  // authenticating if they have access.
  useEffect(() => {
    if (!Auth.getIsAuthenticated()) {
      Auth.setRedirect(`${location?.pathname as string}${location?.search as string}`);
    }
  }, [location]);

  // User is not logged in
  if (!Auth.getIsAuthenticated()) {
    console.log(`Route requires user to be authenticated. Redirecting...`);
    return <Redirect to={redirect} />;
  }

  // User does not have access to this page
  if (!Auth.hasAccess(requiredRole)) {
    console.log(`Route requires role level: ${requiredRole}. Redirecting...`);
    // Consider having an actual fallback page for this?
    return <Redirect to="/" />;
  }

  return <Route {...props} />;
};

export default AuthenticatedRoute;
