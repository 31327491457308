import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);
const VoloPassLogoSrc =
  'https://rivall-public.s3.us-west-1.amazonaws.com/logos/pure-white-VoloPassLogo.png';

/**
 * @typedef VPAdBannerProps
 * @property {string} href - The url that the button links to
 */

/** @type React.FC<VPAdBannerProps> */
const VPAdBanner = ({ href }) => {
  const [isBannerShown, setIsBannerShown] = useState(true);

  const handleWindowScroll = () => {
    if (window.scrollY !== 0) {
      setIsBannerShown(false);
    }
  };

  useEffect(() => {
    // Once the user starts scrolling, hide the banner; we don't want the banner
    // covering other parts of the site, especially if they are in a tight screen
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleWindowScroll);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', handleWindowScroll);
      }
    };
  }, []);

  return (
    <div
      className={cx('vp-ad-banner', isBannerShown || 'hidden')}
      onMouseEnter={() => setIsBannerShown(true)}
    >
      <Link to={href}>
        <img className={cx('vp-logo')} src={VoloPassLogoSrc} alt="Volo Pass Logo" height="40" />
        <div className={cx('divider')} />
        <p>Explore Membership</p>
      </Link>
    </div>
  );
};

VPAdBanner.propTypes = {
  href: PropTypes.string,
};

VPAdBanner.defaultProps = {
  href: '',
};

export default VPAdBanner;
