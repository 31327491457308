// should be kept up to date with /styles/_variables.scss.

export const BrandColors = Object.freeze({
  rivall_blue: '#1e62b4',
  rivall_blue_300: '#4a90e2',
  rivall_navy: '#0a3355',
  brick_red: '#b4371e',
  dusty_teal: '#509371',
  sun_yellow: '#fcce2e',
  tangerine: '#f5a41d',
  facebook: '#3b5998',
  stripe: '#5b66de',
  grey_800: '#4c4c4c',
  grey_500: '#979797',
  grey_400: '#aaaaaa',
  grey_300: '#d7d7d7',
  grey_200: '#ebeeee',
  grey_100: '#f7f7f7',
  magenta: 'rgb(206, 33, 149)',
});

export default Object.freeze({
  action: BrandColors.tangerine,
  primary: BrandColors.rivall_blue,
  error: BrandColors.brick_red,
  danger: BrandColors.brick_red,
  success: BrandColors.dusty_teal,
  warning: BrandColors.sun_yellow,
  btn_disabled_bg: BrandColors.white_neutral,
  btn_disabled_color: BrandColors.lightish_neutral,
  text_color: BrandColors.grey_800,
  text_muted: BrandColors.grey_400,
  border_color: BrandColors.grey_200,
  bg_light: BrandColors.grey_100,
});
