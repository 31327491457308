import { gql } from '@apollo/client';

export const DiscoverProgramsFragment = gql`
  fragment discoverProgramsFragment on League {
    _id
    start_date
    activityName
    formatType
    header
    programType
    displayName
    gender
    days_of_week_estimate
    start_date
    start_time_estimate
    end_time_estimate
    num_weeks_estimate
    organization {
      name
    }
    programTag {
      _id
      name
    }
    divisionDescriptions
    banner_text
    price_phase
    hasDivisions
    schedules {
      _id
      days_of_week
      start_date
      startTimeStr
      endTimeStr
      num_weeks
    }
    details {
      features
    }
    neighborhood {
      _id
      name
    }
    venue {
      _id
      name
      formatted_address
      location {
        lat
        lng
      }
    }
    waiverId
    registration {
      phases {
        earlyBird {
          individualPrice
          prepaidTeamPrice
          startDateStr
          endDateStr
        }
        regular {
          individualPrice
          prepaidTeamPrice
          startDateStr
          endDateStr
        }
        lastChance {
          individualPrice
          prepaidTeamPrice
          startDateStr
          endDateStr
        }
      }
    }
  }
`;

export default gql`
  query discoverLeaguePrograms($input: DiscoverInput!) {
    discoverPrograms(input: $input) {
      leagues {
        ...discoverProgramsFragment
      }
      count
      endCursor
    }
  }
  ${DiscoverProgramsFragment}
`;
