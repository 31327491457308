import axios from 'axios';
import { apiEndpoint, baseURL } from '../../environment';

export const REQUEST_HEADERS = Object.freeze({
  'x-volo-csrf-prevention': true,
});

export const request = axios.create({
  baseURL: `${baseURL}${apiEndpoint}`,
  withCredentials: true,
  headers: REQUEST_HEADERS,
});

export default request;
