import _, { startCase, toLower } from 'lodash-es';

import { ErrorMessageEnum } from '../shared/error-codes';

export { default as errorString } from './error-string';

const cap = v => v.substr(0, 1).toUpperCase() + v.substr(1);

// converts anything (good-boy, goodBoy, good boy, good_boy) to Good boy
export const capCase = str => {
  const strt = startCase(str);
  return `${strt.substr(0, 1).toUpperCase()}${strt.substr(1).toLowerCase()}`;
};

// divides by 100 (does NOT round cents)
// example: 1999 => 19.99
export const centsToDollars = (cents, toString = false) => {
  const toDollars = cents / 100;
  return toString ? String(toDollars) : +toDollars;
};

// uses regex to remove decimal place (does NOT round like toFixed(2))
// example: 19.99 => 1999
// fancy example: 1 => 100
export const dollarsToCents = dollars => {
  let decimalDollars = dollars;
  //  make sure if we pass in $1, it goes to 1.00, not just 1 (1 cent)
  if (!_.includes(dollars, '.')) {
    decimalDollars += '.00';
  }
  // add decimal place in case we pass in 1.5 instead of 1.50
  if (decimalDollars.charAt(decimalDollars.length - 2) === '.') {
    decimalDollars += '0';
  }
  return parseInt(_.replace(decimalDollars, /\D+/g, ''), 10);
};

export const insertCommas = n =>
  !n && n !== 0 ? '' : n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

// user formatPrice to round cost
export const formatPrice = p => {
  if (Number.isNaN(+p)) {
    return '';
  }
  const withCommas = insertCommas((+p).toFixed(2));
  return `$${withCommas.replace(/(\d)(?=(\d{3})+\.)/g, '$1')}`;
};

export const formatCents = c => formatPrice(centsToDollars(c));

// user centsToPrice for unrounded cost
export const centsToPrice = c => {
  if (Number.isNaN(parseInt(c, 10))) {
    return '';
  }
  const withCommas = insertCommas((c / 100).toFixed(2));
  return `$${withCommas.replace(/(\d)(?=(\d{3})+\.)/g, '$1')}`;
};

export const intFromFormattedPrice = p => {
  if (!p) return 0;
  const parsedPrice = _.replace(p, /\D+/g, ''); // replace $ and ,
  return +parsedPrice || 0;
};

export const formatEmail = e => (e ? e.toLowerCase().replace(/ /g, '') : '');

export const formatPhone = p => {
  if (!p) return '';
  const number = typeof p === 'number' ? `${parseInt(p, 10) || ''}` : p?.replace(/[\W_]/g, '');
  return number?.replace(/^(\d{3})(\d{3})(\d{4})+$/, '($1) $2-$3');
};

export const isValidFormattedPhoneNumber = p =>
  !!p.match(/^\((\d{3})\) (\d{3})-(\d{4})+$/) || !!p.match(/^\+1(\d{10})/) || !!p.match(/^\d{11}/);

export const shortName = (firstName, lastName) => {
  if (!lastName) {
    return firstName || '';
  }
  return `${firstName} ${lastName.slice(0, 1)}`;
};

export const formatUsername = u => {
  if (!u) return '';
  return u.toLowerCase().replace(/[^0-9a-z_]/g, '');
};

export const possessive = str => {
  if (!str) return '';
  if (str.slice(-1) === 's') return `${str}'`;
  return `${str}'s`;
};

export const pluralize = (str, num) => {
  if (!str) return '';
  if (num === 1) return str;
  return `${str}s`;
};

export const capitalize = v => {
  if (!v) return '';
  if (v.split(' ').length > 1) {
    const capped = v.split(' ').map(val => {
      if ('the of is and'.indexOf(val) >= 0) return val;
      return cap(val);
    });
    return capped.join(' ');
  }

  return cap(v);
};

export const prettyUrl = url => {
  if (!url) return '';

  if (url.indexOf('www.') >= 0) {
    const split = url.split('www.');
    return split[split.length - 1];
  }
  if (url.indexOf('://') >= 0) {
    const split = url.split('://');
    return split[split.length - 1];
  }

  return url;
};

/**
 * @param {string[]} list - list of strings to create a comma separated list
 * @param {object} options
 * @param {boolean} includeOxfordComma - whether or not to include the oxford comma
 * @param {string} joiner - joins the end of the list, defaults to 'and'
 * @returns {string} A string represented from the combination of a list of strings with
 * or without an oxford comma
 */
export const commaSeparatedList = (list, { includeOxfordComma = true, joiner = 'and' } = {}) => {
  if (list.length > 1) {
    const clonedList = [...list];
    const last = clonedList.pop();
    // only include the oxford comma if the original list length is 3 or more (now at least two
    // since last has been popped off)
    return `${clonedList.join(', ')}${
      clonedList.length > 1 && includeOxfordComma ? ',' : ''
    } ${joiner} ${last}`;
  }
  if (list.length === 1) {
    return list[0];
  }
  return '';
};

export const getLabelOptions = a =>
  _.map(a, v => ({ label: capitalize(v), value: toLower(v.replace(' ', '_')) }));

export const isValidUrl = string =>
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/.test(
    string
  ); // http protocol required!

// get difference between json
export const filterJSON = (initial, updated) => {
  const result = {};
  _.map(initial, (_v, key) => {
    if (!_.isEqual(_.sortBy(initial[key]), _.sortBy(updated[key]))) {
      result[key] = updated[key];
    }
  });
  return result;
};

export const formatBday = val => {
  switch (val.length) {
    case 1:
      if (+val > 1) {
        return `0${val}/`;
      }
      return val;
    case 4:
      if (+val[3] > 3) {
        return `${val.split('/')[0]}/0${val.split('/')[1]}/`;
      }
      return val;
    case 2:
    case 5:
      return `${val}/`;
    default:
      return val;
  }
};

export const snakeToStartCase = input =>
  input
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

// determine error message from code enum or return fallback
export const errorMessage = ({ error, fallbackErrorMsg }) => {
  if (typeof error === 'string') {
    return error;
  }
  const errorCodes = Object.keys(ErrorMessageEnum);
  const keyMatch = errorCodes.find(code => error.message.includes(code));

  return ErrorMessageEnum[keyMatch] || fallbackErrorMsg || error.message || 'Unknown Error';
};
