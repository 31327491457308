import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from './index';

const ConfirmButton = ({
  confirmText = 'Are you sure?',
  onClick,
  children,
  confirmingProps,
  regularProps,
  confirmTextDuration,
  ...props
}) => {
  const [confirming, setConfirming] = useState(false);
  const timeout = useRef();

  useEffect(() => {
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, []);

  const handleConfirming = e => {
    e.preventDefault();
    setConfirming(true);
    timeout.current = setTimeout(() => {
      setConfirming(false);
    }, confirmTextDuration);
  };

  return (
    <Button
      {...props}
      {...(confirming ? confirmingProps : regularProps)}
      onClick={confirming ? onClick : handleConfirming}
    >
      {confirming ? confirmText : children}
    </Button>
  );
};

export default ConfirmButton;

ConfirmButton.propTypes = {
  confirmText: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node])),
  ]),
  confirmingProps: PropTypes.shape({}),
  regularProps: PropTypes.shape({}),
  confirmTextDuration: PropTypes.number,
};

ConfirmButton.defaultProps = {
  confirmText: 'Are you sure?',
  onClick: undefined,
  children: undefined,
  confirmingProps: {},
  regularProps: {},
  confirmTextDuration: 3000,
};
