import PropTypes from 'prop-types';
import { ColorProps, GenderCompositionProps, CYCLIC_PROP } from '../common-props';

const GroupProps = PropTypes.shape({
  _id: PropTypes.string,
  captainId: PropTypes.string,
  name: PropTypes.string,
  color: ColorProps,
  private: PropTypes.bool,
  prepaid: PropTypes.bool,
  num_members_estimate: PropTypes.number,
  notes: PropTypes.string,
  registrants: PropTypes.arrayOf(CYCLIC_PROP),
  captain: CYCLIC_PROP,
  division_selection: PropTypes.string,
  password_hint: PropTypes.string,
  league: CYCLIC_PROP,
  deepLink: PropTypes.string,
  player_notes: PropTypes.number,
  stats: PropTypes.shape({
    count: PropTypes.number,
    composition: GenderCompositionProps,
  }),
});

export default GroupProps;
