import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Image.jsx
// Automatically shows a fallback or hides if the image source fails.
// Be sure to unmount the Image component when you expect the image
//   source to change. Otherwise, the srcError state may not be reset.

class Image extends Component {
  constructor(props) {
    super(props);
    this.getSrc = this.getSrc.bind(this);
    this.onSrcError = this.onSrcError.bind(this);
    this.state = {
      srcError: false,
    };
  }

  onSrcError() {
    this.setState({ srcError: true });
  }

  getSrc() {
    const { srcError } = this.state;
    const { defaultSrc, localSrc, path, src } = this.props;

    if (srcError) return defaultSrc;
    if (localSrc) {
      return `${path}${localSrc}`;
    }
    return src || defaultSrc;
  }

  render() {
    const { style, className, attrs = {}, alt } = this.props;

    const src = this.getSrc();

    if (!src) return <div {...{ style, className }} />;
    return (
      <img {...{ style, className, ...attrs }} alt={alt} src={src} onError={this.onSrcError} />
    );
  }
}

Image.propTypes = {
  path: PropTypes.string,
  src: PropTypes.string,
  localSrc: PropTypes.string,
  defaultSrc: PropTypes.string,
  alt: PropTypes.string,
  style: PropTypes.shape({}),
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
  attrs: PropTypes.shape({}),
};

Image.defaultProps = {
  path: '../images/',
  localSrc: null,
  src: null,
  defaultSrc: null,
  alt: '',
  style: {},
  className: '',
  attrs: {},
};

export default Image;
