import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Activities } from '../../shared/activity-name-enum';
import { DISCOVER_SPORTS, DISCOVER_TOP_SPORTS } from './FILTER_QUERIES';
import { DISCOVER_TOP_SPORTS_LIMIT } from '../../apps/Discover/hooks/useDiscoverTopSports';
import { getParamsFromUrl, withPropsFromUrl } from './utils';
import { FilterSelect, Loading } from '../../components';

const SportFilter = props => {
  const {
    cityName,
    sportNames,
    disabled,
    onSelect,
    isMulti,
    className,
    updateSearchUrls,
    miscStyles,
    hideIcon,
    hideIndicator,
    showValues,
    showAllOptions,
    skip,
    hidePopular,
    isHost,
  } = props;

  const { data: sportsData, loading } = useQuery(DISCOVER_SPORTS, {
    skip,
    fetchPolicy: 'cache-and-network',
    variables: {
      ...getParamsFromUrl(props, isHost),
    },
  });

  const { data: topSportsData, loading: topSportsLoading } = useQuery(DISCOVER_TOP_SPORTS, {
    skip: skip || hidePopular,
    fetchPolicy: 'cache-and-network',
    variables: {
      ...getParamsFromUrl(props, isHost),
      limit: DISCOVER_TOP_SPORTS_LIMIT,
    },
  });

  const allSportNames = sportsData?.discoverSports?.sportNames || [];
  const topSports = topSportsData?.discoverTopSports?.sportNames || [];

  const availableSports = showAllOptions
    ? Object.values(Activities).map(({ name }) => name)
    : allSportNames;

  const sportNamesOptions = [
    ...(topSports.length > 0
      ? [
          {
            sportNames: 'Popular Sports',
            _id: '',
            isDisabled: true,
          },
          ...topSports,
          {
            sportNames: 'All Sports',
            _id: '',
            isDisabled: true,
          },
        ]
      : []),
    ...availableSports,
  ].map(value => {
    if (typeof value === 'string') {
      return {
        label: value,
        value,
      };
    }
    return {
      label: value.sportNames,
      value: '',
      isDisabled: true,
    };
  });

  const onChange =
    typeof onSelect === 'function'
      ? onSelect
      : (v = []) => {
          updateSearchUrls({ sportNames: v.map(({ value }) => value) });
        };

  if (loading || topSportsLoading) return <Loading />;

  return (
    <FilterSelect
      short
      shadow
      hideIcon={hideIcon}
      hideIndicator={hideIndicator}
      labelSingular={cityName === 'Virtual' ? 'Game' : 'Sport'}
      labelPlural={cityName === 'Virtual' ? 'Games' : 'Sports'}
      options={sportNamesOptions}
      value={sportNames.map(name => ({ label: name, value: name }))}
      onChange={onChange}
      disabled={disabled || loading}
      multi={isMulti}
      className={className}
      miscStyles={miscStyles}
      showValues={showValues}
      styles={{
        option: (base, { data }) => {
          const { isDisabled } = data;
          return {
            ...base,
            ...(miscStyles?.option ? { ...miscStyles?.option } : {}),
            ...(isDisabled
              ? {
                  color: '#e9ff70',
                  backgroundColor: '#183252',
                }
              : {}),
          };
        },
      }}
    />
  );
};

SportFilter.propTypes = {
  onSelect: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  updateSearchUrls: PropTypes.func.isRequired,
  cityName: PropTypes.string,
  sportNames: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  className: PropTypes.string,
  miscStyles: PropTypes.shape({ option: PropTypes.shape({}) }),
  hideIcon: PropTypes.bool,
  hideIndicator: PropTypes.bool,
  showValues: PropTypes.bool,
  showAllOptions: PropTypes.bool,
  skip: PropTypes.bool,
  hidePopular: PropTypes.bool,
  isHost: PropTypes.bool,
};

SportFilter.defaultProps = {
  onSelect: false,
  cityName: 'Baltimore',
  disabled: false,
  isMulti: false,
  sportNames: [],
  className: '',
  miscStyles: {},
  hideIcon: false,
  hideIndicator: false,
  showValues: false,
  showAllOptions: false,
  skip: false,
  hidePopular: false,
  isHost: false,
};

export default withPropsFromUrl()(SportFilter);
