export default Object.freeze({
  ADMIN: 'ADMIN', // super admin
  OWNER: 'OWNER', // city admin
  STAFF: 'STAFF', // host
  PLAYER: 'PLAYER', // player
});

export enum RoleEnum {
  ADMIN = 'ADMIN', // super admin
  OWNER = 'OWNER', // city admin
  STAFF = 'STAFF', // host
  PLAYER = 'PLAYER', // player
}
