import PropTypes from 'prop-types';
import { CYCLIC_PROP } from '../common-props';

const WeekProps = PropTypes.shape({
  _id: PropTypes.string,
  scheduleId: PropTypes.string,
  schedule: CYCLIC_PROP,
  week_num: PropTypes.number,
  games: CYCLIC_PROP,
  days: PropTypes.arrayOf(CYCLIC_PROP),
  notes: PropTypes.string,
  weekTag: PropTypes.string,
  byes: PropTypes.arrayOf(PropTypes.string),
});

export default WeekProps;
