import formatDateRangeForQuery from './formatDateRangeForQuery';
import formatTimeRangeForQuery from './formatTimeRangeForQuery';
import checkIfFiltersAreApplied from './checkIfFiltersAreApplied';
import applyViewFilters, { applySportsFilter } from './applyViewFilters';

const discoverHelpers = {
  formatDateRangeForQuery,
  formatTimeRangeForQuery,
  checkIfFiltersAreApplied,
  applyViewFilters,
  applySportsFilter,
};

export default discoverHelpers;
