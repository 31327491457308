import qs from 'qs';
import { Redirect, Route, type RouteComponentProps } from 'react-router';
import { ErrorPage } from '../../pages';

const RentalSportRedirect = ({ location }: RouteComponentProps) => {
  const search = qs.parse(location.search);
  const rentalId = search['?id'];
  const { reservationId } = search;

  if (!rentalId) {
    return <Route component={ErrorPage} />;
  }

  return (
    <Redirect
      to={{
        pathname: `/r/${rentalId}`,
        search: `?view=sportView${reservationId ? `&reservationId=${reservationId}` : ''}`,
      }}
    />
  );
};
export default RentalSportRedirect;
