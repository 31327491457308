import PropTypes from 'prop-types';

const DropInProps = PropTypes.shape({
  _id: PropTypes.string,
  isDropIn: PropTypes.bool,
  neighborhood: PropTypes.shape({
    _id: PropTypes.string,
  }),
  venue: PropTypes.shape({
    _id: PropTypes.string,
  }),
  spots: PropTypes.number,
  programType: PropTypes.string,
  sport: PropTypes.string,
});

export default DropInProps;
