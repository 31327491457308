// all discover tab constants
// main
const SPORTS = 'SPORTS';
const EVENTS = 'EVENTS';
const CLASSES = 'CLASSES';
const VOLUNTEER = 'VOLUNTEER';
const COURT_RENTALS = 'COURT RENTALS';
// sub
const DAILY = 'DAILY';
const LEAGUE = 'LEAGUE';
const TOURNAMENT = 'TOURNAMENT';
const SERIES = 'SERIES';
const BY_SPORT = 'BY SPORT';
const BY_VENUE = 'BY VENUE';

// main enum
export const MainTabEnum = Object.freeze({
  SPORTS,
  EVENTS,
  CLASSES,
  VOLUNTEER,
  COURT_RENTALS,
});

// sub enum
export const SubTabEnum = Object.freeze({
  DAILY,
  LEAGUE,
  TOURNAMENT,
  SERIES,
  BY_SPORT,
  BY_VENUE,
});

export const DOWSubTabs = [LEAGUE, SERIES];

const DiscoverTabs = Object.freeze({
  SPORTS: {
    tab: 'SPORTS',
    subTabs: [LEAGUE, DAILY, TOURNAMENT],
  },
  EVENTS: {
    tab: 'EVENTS',
    subTabs: [],
  },
  CLASSES: {
    tab: 'CLASSES',
    subTabs: [DAILY, SERIES],
  },
  VOLUNTEER: {
    tab: 'VOLUNTEER',
    subTabs: [],
  },
  'COURT RENTALS': {
    tab: 'COURT RENTALS',
    subTabs: [BY_SPORT, BY_VENUE],
  },
});

export const isSubTabValid = (tab, subtab) => {
  if (!tab || !subtab) return false;
  return DiscoverTabs[tab].subTabs.includes(subtab);
};

// all discover tabs
export default DiscoverTabs;
