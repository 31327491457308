import PropTypes from 'prop-types';
import { ColorProps, CYCLIC_PROP } from '../common-props';

/**
 * @deprecated
 * Consider a typescript refactoring instead!
 */
const TeamProps = PropTypes.shape({
  _id: PropTypes.string,
  name: PropTypes.string,
  color: ColorProps,
  groupIds: PropTypes.arrayOf(PropTypes.string),
  freeAgentIds: PropTypes.arrayOf(PropTypes.string),
  captainId: PropTypes.string,
  captain: CYCLIC_PROP,
  players: PropTypes.arrayOf(CYCLIC_PROP),
  hostNotes: PropTypes.string,
  coCaptain: PropTypes.string,
  isVoloPassTeam: PropTypes.bool,
});

export default TeamProps;
