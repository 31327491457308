import { useQuery, gql } from '@apollo/client';

const GET_DROP_IN_CAPACITY = gql`
  query game($_id: ID!) {
    game(_id: $_id) {
      _id
      dropInCapacity {
        f
        m
        a
      }
    }
  }
`;

const getCapacityTotal = (capacity = {}) =>
  Object.values(capacity)
    .filter(v => Number.isInteger(v))
    .reduce((acc, val) => acc + val, 0);

const useDropInCapacity = ({ gameId }) => {
  const { data, loading, error } = useQuery(GET_DROP_IN_CAPACITY, {
    skip: !gameId,
    fetchPolicy: 'cache-and-network',
    variables: {
      _id: gameId,
    },
  });
  const dropInCapacity = data?.game?.dropInCapacity || {};
  const { f = 0, m = 0, a = 0 } = dropInCapacity;
  const totalCapacity = getCapacityTotal(dropInCapacity);

  return {
    capacity: {
      female: f,
      male: m,
      any: a,
      total: totalCapacity,
    },
    loading,
    error,
  };
};

export default useDropInCapacity;
