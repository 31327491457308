import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import {
  DELETE_PAYMENT_SOURCE,
  SET_PAYMENT_SOURCE_DEFAULT,
} from '../../REGISTER_QUERIES_AND_MUTATIONS';
import withActionStates from '../../../../../hocs/withActionStates';
import { OverflowMenu } from '../../../../../components';
import classNames from 'classnames/bind';
import styles from '../../../../../styles/main.module.scss';

const cx = classNames.bind(styles);

const PaymentSources = ({ source, setError, setSuccess }) => {
  const [deletePayment] = useMutation(DELETE_PAYMENT_SOURCE);
  const [setDefaultPayment] = useMutation(SET_PAYMENT_SOURCE_DEFAULT);

  const { isDefault, isExpired, last4, id, exp_month, exp_year, brand } = source || {};

  const getSourceActions = useCallback(
    paymentSource => [
      ...(!paymentSource.isDefault
        ? [
            {
              label: 'Use this payment method',
              disabled: paymentSource.isExpired,
              onClick: async () => {
                try {
                  await setDefaultPayment({
                    variables: {
                      input: { paymentSourceId: paymentSource?.id },
                    },
                  });
                  setSuccess('Successfuly added payment method');
                } catch (e) {
                  setError(e);
                }
              },
            },
          ]
        : []),
      {
        label: 'Delete payment method',
        onClick: async () => {
          try {
            await deletePayment({
              variables: {
                input: { paymentSourceId: paymentSource?.id },
              },
            });
            setSuccess('Successfuly removed payment method');
          } catch (e) {
            setError(e);
          }
        },
      },
    ],
    [deletePayment, setDefaultPayment]
  );

  return (
    <div
      className={cx(
        'sourceItem',
        'd-flex',
        'justify-content-between',
        'align-items-center',
        'bg-light',
        'p-3',
        'mb-3',
        {
          notDefault: !isDefault,
          isExpired,
        }
      )}
    >
      <div
        className={cx('main', 'text-left')}
        onClick={() => (isDefault ? {} : setDefaultPayment({ paymentSourceId: id }))}
        role="button"
        tabIndex={0}
      >
        {isDefault && <p className={cx('current')}>Current</p>}
        <div className="d-flex align-items-center">
          <div className={cx('brand', brand?.toLowerCase())} />
          <div>
            <p>
              <b>**** **** **** {last4}</b>
            </p>
            <p className={cx('exp')}>
              <small>
                {exp_month}/{exp_year}
              </small>
            </p>
          </div>
        </div>
      </div>

      <OverflowMenu options={getSourceActions(source)} />
    </div>
  );
};

PaymentSources.propTypes = {
  source: PropTypes.shape({
    isDefault: PropTypes.bool,
    isExpired: PropTypes.bool,
    last4: PropTypes.number,
    id: PropTypes.string,
    exp_month: PropTypes.number,
    exp_year: PropTypes.number,
    brand: PropTypes.string,
  }),
  setError: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
};

PaymentSources.defaultProps = {
  source: {},
};

export default withActionStates({ withAlerts: true })(PaymentSources);
