import useDiscoverStore from '../DiscoverStore';

const updateCurrentPage = pageNum => {
  const get = useDiscoverStore.getState;
  const set = useDiscoverStore.setState;

  const { currentPage } = get();
  if (pageNum === currentPage) return;
  set({ currentPage: pageNum });
};

export default updateCurrentPage;
