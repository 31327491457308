import { gql } from '@apollo/client';

export const EMAIL_IS_VALID = gql`
  mutation emailIsValid($input: EmailIsValidInput!) {
    emailIsValid(input: $input) {
      isValid
      value
      message
    }
  }
`;

export const PHONE_NUMBER_IS_VALID = gql`
  mutation phoneNumberIsValid($input: PhoneNumberIsValidInput!) {
    phoneNumberIsValid(input: $input) {
      isValid
      value
      message
    }
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation updateUserData($input: UpdateUserDataInput!) {
    updateUserData(input: $input) {
      currentUser {
        _id
      }
    }
  }
`;

export const UPDATE_USER_HOME_ORG = gql`
  mutation updateUserHomeOrganization($input: UpdateUserHomeOrganizationInput!) {
    updateUserHomeOrganization(input: $input) {
      currentUser {
        _id
      }
    }
  }
`;

export const TOGGLE_USER_ACCOUNT_STATUS = gql`
  mutation toggleUserAccountStatus($input: ToggleAccountStatusInput!) {
    toggleUserAccountStatus(input: $input) {
      _id
      deactivated
    }
  }
`;

export const SEND_WAIVER_REMINDER_EMAIL = gql`
  mutation sendReminderEmail($input: ReminderEmailInput!) {
    sendReminderEmail(input: $input) {
      _id
      waiver {
        waiverId
        signed
        date_signed
        reminders_sent {
          date_sent
          sent_by
        }
      }
    }
  }
`;

export const SEND_RENTAL_WAIVER_REMINDER_EMAIL = gql`
  mutation sendReminderRentalEmail($input: ReminderEmailInput!) {
    sendReminderRentalEmail(input: $input) {
      _id
      waiver {
        waiverId
        signed
        date_signed
        reminders_sent {
          date_sent
          sent_by
        }
      }
    }
  }
`;
