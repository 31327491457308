import PropTypes from 'prop-types';
import { CoordinatesProps, CYCLIC_PROP, NameUrlProps } from '../common-props';

const ApprovedVenueProps = PropTypes.shape({
  _id: PropTypes.string,
  shorthandName: PropTypes.string,
  pdfUploads: PropTypes.arrayOf(PropTypes.string),
  sportDetails: PropTypes.arrayOf(
    PropTypes.shape({
      sportName: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  marginDetails: PropTypes.shape({
    permitCost: PropTypes.number,
    staffCost: PropTypes.number,
    shirtCost: PropTypes.number,
    insuranceCost: PropTypes.number,
    equipmentCost: PropTypes.number,
    marketingCost: PropTypes.number,
    misc: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        amount: PropTypes.number,
      })
    ),
  }),
  image: NameUrlProps,
  venueFeatures: PropTypes.arrayOf(PropTypes.string),
  place_id: PropTypes.string,
  location: CoordinatesProps,
  viewport: PropTypes.shape({
    northeast: CoordinatesProps,
    southwest: CoordinatesProps,
  }),
  neighborhood: PropTypes.string,
  city: PropTypes.string,
  zip: PropTypes.number,
  maps_url_query: PropTypes.string,
  formatted_address: PropTypes.string,
  timezone: PropTypes.string,
  disabled: PropTypes.bool,
  sublocations: PropTypes.arrayOf(PropTypes.string),
  neighborhoodId: PropTypes.string,
  venuePermits: PropTypes.arrayOf(CYCLIC_PROP),
  name: PropTypes.string,
});

export default ApprovedVenueProps;
