import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { formatFileSize, lightenDarkenColor, useCSVReader } from 'react-papaparse';
import styles from './csv-dropzone.module.scss';

const cx = classNames.bind(styles);

const uploaderStyles = {
  zone: {
    alignItems: 'center',
    border: '2px dashed #CCC',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    padding: 10,
    cursor: 'pointer',
  },
  file: {
    background: '#1e62b454',
    borderRadius: '20px',
    display: 'flex',
    width: '100%',
    position: 'relative',
    zIndex: 10,
    padding: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex',
  },
  name: {
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em',
  },
  zoneHover: {
    border: '2px dashed #686868',
  },
  default: {
    border: '2px dashed #CCC',
  },
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  },
};

const CSVDropzone = ({ onDrop, format, example }) => {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState('#A01919');

  return (
    <CSVReader
      onUploadAccepted={results => {
        onDrop(results);
        setZoneHover(false);
      }}
      onDragOver={event => {
        event.preventDefault();
        setZoneHover(true);
      }}
      onDragLeave={event => {
        event.preventDefault();
        setZoneHover(false);
      }}
    >
      {({ getRootProps, acceptedFile, getRemoveFileProps, Remove }) => (
        <div
          {...getRootProps()}
          style={{ ...uploaderStyles.zone, ...(zoneHover && uploaderStyles.zoneHover) }}
        >
          {acceptedFile ? (
            <div style={uploaderStyles.file}>
              <div style={uploaderStyles.info}>
                <span style={uploaderStyles.size}>{formatFileSize(acceptedFile.size)}</span>
                <span style={uploaderStyles.name}>{acceptedFile.name}</span>
              </div>
              <div
                {...getRemoveFileProps()}
                style={uploaderStyles.remove}
                onMouseOver={event => {
                  event.preventDefault();
                  setRemoveHoverColor(lightenDarkenColor('#A01919', 40));
                }}
                onMouseOut={event => {
                  event.preventDefault();
                  setRemoveHoverColor('#A01919');
                }}
                onFocus={() => true}
                onBlur={() => true}
              >
                <Remove color={removeHoverColor} />
              </div>
            </div>
          ) : (
            <div className="w-100 text-center">
              <h2>Drop CSV file here or click to upload</h2>
              {format && (
                <div className={cx('format-table')}>
                  <b>REQUIRED FORMAT:</b>
                  <table>
                    <tbody>
                      <tr>
                        {format.map(f => (
                          <th key={f}>{f}</th>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
              {example && (
                <div className={cx('format-table')}>
                  <b>EXAMPLE FORMAT:</b>
                  <table>
                    <tbody>
                      <tr>
                        {example.map((f, i) => (
                          <th key={f + String(i)}>{f}</th>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </CSVReader>
  );
};

CSVDropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  format: PropTypes.arrayOf(PropTypes.string),
  example: PropTypes.arrayOf(PropTypes.string),
};

CSVDropzone.defaultProps = {
  format: null,
  example: null,
};

export default CSVDropzone;
