import clearFilters from './clearFilters';
import updateSubView from './updateSubView';
import updateMainView from './updateMainView';
import updateCityFilter from './updateCityFilter';
import updateCurrentPage from './updateCurrentPage';
import updateQueryStatus from './updateQueryStatus';
import toggleShowMobileFilters from './toggleShowMobileFilters';
import updateStoreWithUrlParams from './updateStoreWithUrlParams';
import updateFilterCollapsed from './updateFilterCollapsed';
import updateFilters from '../utils/updateFilters';

const discoverActions = {
  updateSubView,
  updateMainView,
  updateCurrentPage,
  updateQueryStatus,
  updateStoreWithUrlParams,
  toggleShowMobileFilters,
  updateFilterCollapsed,

  /* FILTER UPDATE FUNCTIONS */
  clearFilters,
  updateFilters,
  updateCityFilter,
};

export default discoverActions;
