/* eslint-disable linebreak-style */
// Graph API call to force metadata scraping
import axios from 'axios';
import { siteUrl, fbAppId, fbAppSecret } from '../../../environment';

export const metadataAPI = async id => {
  const graphAPI = 'https://graph.facebook.com';
  const url = encodeURIComponent(`${siteUrl}/l/${id}`);
  await axios.post(`${graphAPI}/?id=${url}&scrape=true&access_token=${fbAppId}|${fbAppSecret}`);
};

export default metadataAPI;
