import { useState, type FC } from 'react';
import classNames from 'classnames/bind';
import moment from 'moment-timezone';
import type { GenderEnum_TS } from '@rivallapp/volosports-components';
import styles from '../../player-details.module.scss';
import { Button } from '../../../../components';
import type { GetUserByInfoData } from '../../PLAYER_DETAILS_QUERIES';
import { MembershipInterval, MembershipStatus } from '../../../../shared/membership-enums';
// @ts-expect-error
import chevronDown from '../../../../images/home/chevron-down.png?as=webp';
// @ts-expect-error
import chevronLeft from '../../../../images/home/chevron-left.png?as=webp';

const cx = classNames.bind(styles);

type PlayerInfoDisplayProps = {
  _created?: string;
  birthday?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  gender?: GenderEnum_TS;
  phone_number?: string;
  username?: string;
  homeOrganizationObject?: GetUserByInfoData['getUserByInfo']['homeOrganizationObject'];
  activeMembershipForUser?: GetUserByInfoData['activeMembershipForUser'];
  setUpdatingInfo: (isUpdating: boolean) => void;
};

const PlayerInfoDisplay: FC<PlayerInfoDisplayProps> = props => {
  const {
    _created = '',
    birthday = '',
    email = '',
    firstName = '',
    gender = '',
    lastName = '',
    phone_number = '',
    username = '',
    homeOrganizationObject,
    setUpdatingInfo,
    activeMembershipForUser,
  } = props;

  const [collapsed, setCollapsed] = useState(true);

  return (
    <div className={cx('noEdit')}>
      <label>First Name</label>
      <div>{firstName}</div>
      <label>Last Name</label>
      <div>{lastName}</div>
      <label>Email</label>
      <div>{email}</div>
      <label>Username</label>
      <div>{username}</div>
      <label>Phone</label>
      <div>{phone_number}</div>
      <label>Birthday</label>
      <div>{birthday}</div>
      <label>Gender</label>
      <div>{gender?.toUpperCase()}</div>
      <label>Registration Date</label>
      <div>{moment(+_created).format('MM/DD/YYYY h:mm a')}</div>
      <label style={{ display: 'flex', alignItems: 'center' }}>
        Volo Pass Status{' '}
        <img
          onClick={() => setCollapsed(!collapsed)}
          onKeyDown={() => setCollapsed(!collapsed)}
          height="35px"
          src={!collapsed ? chevronDown : chevronLeft}
          alt="toggle collapse Volo Pass details"
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
          role="button"
        />
      </label>
      <div>
        {activeMembershipForUser?.status
          ? MembershipStatus[activeMembershipForUser.status].label
          : 'None'}
      </div>
      {!collapsed && (
        <>
          <label>Start Date</label>
          <div>
            {activeMembershipForUser?.planStartsAt
              ? moment(activeMembershipForUser.planStartsAt).format('MM/DD/YYYY h:mm a')
              : '-'}
          </div>
          <label>End Date</label>
          <div>
            {activeMembershipForUser?.planExpiresAt
              ? moment(activeMembershipForUser.planExpiresAt).format('MM/DD/YYYY h:mm a')
              : '-'}
          </div>
          <label>Subscription Type</label>
          <div>
            {activeMembershipForUser?.plan?.interval
              ? MembershipInterval[activeMembershipForUser.plan.interval]!.label
              : 'None'}
          </div>
          <label>Membership City</label>
          <div>
            {activeMembershipForUser?.metadata?.organization ??
              homeOrganizationObject?.name ??
              'No metadata or home city found'}
          </div>
        </>
      )}
      {/* @ts-expect-error This button doesn't have proper typing :( */}
      <Button primary square className={cx('actionButton')} onClick={() => setUpdatingInfo(true)}>
        Edit Player Details
      </Button>
    </div>
  );
};

export default PlayerInfoDisplay;
