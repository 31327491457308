import { useCallback, useEffect } from 'react';

import { sessionAPI } from '../utils/api';
import { useAuthStore } from '../zustand-stores';
import { Auth } from '../zustand-stores/AuthStore';

/**
 * This currently runs one time when the `App` component mounts.
 *
 * If the user was previously authenticated, it will call the
 * sessions API to silently reauthenticate.
 *
 * If the user's authentication is still valid, it updates the auth state
 * and provides their user context to Braze and Sentry.
 *
 * If it fails, the auth state will be reset and the backend will clear
 * their authentication (no need for a subsequent logout call)
 */
const useSilentLogin = () => {
  const silentLogin = useCallback(async () => {
    if (Auth.getIsAuthenticated()) {
      try {
        const { data } = await sessionAPI.getLoggedInUser();
        useAuthStore.setState({
          userId: data?.user_id,
          roles: data?.roles,
          isAuthenticated: true,
        });
        Auth.syncUserWithServices({
          email: data?.email,
          userId: data?.user_id,
        });
      } catch (err) {
        console.error('Could not load user...');
        Auth.resetState();
      }
    }
  }, []);

  useEffect(() => {
    silentLogin();
  }, [silentLogin]);
};

export default useSilentLogin;
