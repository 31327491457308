/* eslint-disable new-cap */
import tinycolor from 'tinycolor2';

export const isDark = hex => {
  const color = new tinycolor(hex);
  return color.getBrightness() < 100;
};

export const isSuperLight = hex => {
  const color = new tinycolor(hex);
  return color.getBrightness() > 230;
};

export const getColor = (item, options) => {
  const ops = options || {};
  if (!item || !item.color || !item.color.hex) {
    return ops.backup ? ops.backup : '#dddddd';
  }
  const color = new tinycolor(item.color.hex);
  const b = color.getBrightness();
  // const l = color.isLight();

  if (ops.lighten) {
    if (b < 60) {
      return color.lighten(25).toString();
    }
  }

  if (ops.darken) {
    if (b > 200) {
      return color.darken(25).toString();
    }
  }

  if (ops.slightDarken) {
    if (b > 240) {
      return color.darken(25).toString();
    }
  }

  return color.toString();
};

export const getTeamColors = team => {
  let color;

  if (!team.color || !team.color.hex) {
    color = 'grey';
  } else {
    color = team.color.hex;
  }

  const tinyColor = new tinycolor(color);
  const brightness = tinyColor.getBrightness();
  const isLight = brightness > 200 ? 0 : 10;
  const isKindaLight = brightness > 150;
  const isKindaDark = brightness < 150 ? 25 : isLight;
  let light;
  let lighter;

  const lpct = brightness < 50 ? 50 : isKindaDark;
  const dpct = isKindaLight ? 30 : 10;

  lighter = tinycolor(color)
    .lighten(lpct + 15)
    .toString();
  light = tinycolor(color).lighten(lpct).toString();
  const dark = tinycolor(color).darken(dpct).toString();
  const darker = tinycolor(color)
    .darken(dpct + 20)
    .toString();

  if (brightness > 220) {
    lighter = tinycolor(color).lighten(10).toString();
    light = tinycolor(color).darken(10).toString();
  }

  return {
    color,
    isDark: brightness < 80,
    isLight: brightness > 200,
    lighter,
    light,
    dark,
    darker,
  };
};

export default getColor;
