export const isHorizontalTableDataValid = (arrays = []) => {
  if (!arrays || !arrays?.length) return true;
  const arrayLengths = arrays.map(collection => collection?.length || 0) || [];
  return [...new Set(arrayLengths)].length === 1;
};

export const isVerticalTableDataValid = (array = []) => {
  if (!array || !array?.length) return false;
  let arrayIsValid = true;
  for (let i = 0, cache = array?.length; i < cache; i += 1) {
    const { values, header } = array[i] || {};
    if (!values || !header) {
      arrayIsValid = false;
    }
  }
  return arrayIsValid;
};
