import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import InputSlider from './InputSlider';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

class TimeIntervalPicker extends Component {
  constructor(props) {
    super(props);
    this.changeStartHours = this.changeStartHours.bind(this);
    this.changeStartMinutes = this.changeStartMinutes.bind(this);
    this.changeEndHours = this.changeEndHours.bind(this);
    this.changeEndMinutes = this.changeEndMinutes.bind(this);

    this.interval = this.props.interval || 5;
  }

  changeStartHours(pos) {
    const { start } = this.props.moment;
    start.hours(parseInt(pos.x, 10));
    this.props.onChange(start);
  }

  changeStartMinutes(pos) {
    const { start } = this.props.moment;
    const rounded = Math.round(pos.x / this.interval) * this.interval;
    const adj = Math.min(rounded, 59);
    start.minutes(parseInt(adj, 10));
    this.props.onChange(start);
  }

  changeEndHours(pos) {
    const { end } = this.props.moment;
    end.hours(parseInt(pos.x, 10));
    this.props.onChange(end);
  }

  changeEndMinutes(pos) {
    const { end } = this.props.moment;
    const adjusted = Math.min(Math.round(parseInt(pos.x, 10) / this.interval) * this.interval, 59);
    end.minutes(adjusted);
    this.props.onChange(end);
  }

  render() {
    const { start, end } = this.props.moment;

    return (
      <div className={cx('m-time', 'time-interval', this.props.className)}>
        <div className={cx('time-display-wrap')}>
          <div className={cx('showtime', 'start')}>
            <span className={cx('time')}>{start.format('hh')}</span>
            <span className={cx('separater')}>:</span>
            <span className={cx('time')}>{start.format('mm')}</span>
            <span className={cx('ampm')}>{start.format('a')}</span>
          </div>

          <span className={cx('dash')}>-</span>

          <div className={cx('showtime', 'end')}>
            <span className={cx('time')}>{end.format('hh')}</span>
            <span className={cx('separater')}>:</span>
            <span className={cx('time')}>{end.format('mm')}</span>
            <span className={cx('ampm')}>{end.format('a')}</span>
          </div>
        </div>

        <div className={cx('sliders', 'start')} style={{ paddingRight: '10px' }}>
          <p className={cx('time-text')}>Start hours:</p>
          <InputSlider
            className={cx('u-slider-time')}
            xmin={0}
            xmax={23}
            x={start.hour()}
            onChange={this.changeStartHours}
          />
          <p className={cx('time-text')}>Start minutes:</p>
          <InputSlider
            className={cx('u-slider-time')}
            xmin={0}
            xmax={59}
            x={start.minute()}
            onChange={this.changeStartMinutes}
          />
        </div>

        <div className={cx('sliders', 'end')} style={{ paddingLeft: '10px' }}>
          <p className={cx('time-text')}>End hours:</p>
          <InputSlider
            className={cx('u-slider-time')}
            xmin={0}
            xmax={23}
            x={end.hour()}
            onChange={this.changeEndHours}
          />
          <p className={cx('time-text')}>End minutes:</p>
          <InputSlider
            className={cx('u-slider-time')}
            xmin={0}
            xmax={59}
            x={end.minute()}
            onChange={this.changeEndMinutes}
          />
        </div>
      </div>
    );
  }
}

export default TimeIntervalPicker;
