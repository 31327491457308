// all of the statuses that a league doc could be in the db
export enum LeagueStatusEnum {
  DRAFT = 'draft', // Temporary status used to save data in the create flow.  Gets deleted by a job after 24 hours
  STAGED = 'staged', // Program has been 'created' through csv upload but 'create' button has not been clicked yet
  PENDING = 'pending', // league registration has not opened yet
  REGISTRATION_OPEN = 'registration_open', // league is open for registration
  READY = 'ready', // registration has closed but league has not started
  ACTIVE = 'active', // league has started
  NEEDS_APPROVAL = 'needs_approval', // teams or schedules are not complete
  COMPLETED = 'completed', // league was complete and archived
  CANCELLED = 'cancelled', // league was not complete and archived
  ERROR = 'error',
}

export enum LeagueStageEnum {
  UPCOMING = 'upcoming',
  ACTIVE = 'active',
  ARCHIVED = 'archived',
  STAGED = 'staged',
}

// translates a league status to its stage counterpart
export const LeagueStatusToStageKey = Object.freeze({
  [LeagueStatusEnum.PENDING]: LeagueStageEnum.UPCOMING,
  [LeagueStatusEnum.REGISTRATION_OPEN]: LeagueStageEnum.UPCOMING,
  [LeagueStatusEnum.NEEDS_APPROVAL]: LeagueStageEnum.UPCOMING,
  [LeagueStatusEnum.READY]: LeagueStageEnum.UPCOMING,
  [LeagueStatusEnum.ACTIVE]: LeagueStageEnum.ACTIVE,
  [LeagueStatusEnum.COMPLETED]: LeagueStageEnum.ARCHIVED,
  [LeagueStatusEnum.CANCELLED]: LeagueStageEnum.ARCHIVED,
  [LeagueStatusEnum.ERROR]: LeagueStageEnum.ARCHIVED,
  [LeagueStatusEnum.STAGED]: LeagueStageEnum.STAGED,
});

export default LeagueStatusEnum;
