import { type ITextProps, formatCents, Text } from '@rivallapp/volosports-components';

import styles from './styles';

interface Props extends ITextProps {
  value: number;
  subtract?: boolean;
  strikethrough?: boolean;
}

// Copied nearly 1:1 from mobile
const PriceText = ({ value, style, subtract, strikethrough, ...textProps }: Props) => {
  return (
    <Text {...textProps} style={[strikethrough && styles.strikethrough, style]}>
      {subtract ? '-' : ''}
      {formatCents(value)}
    </Text>
  );
};

export default PriceText;
