/* eslint-disable react/jsx-props-no-spreading */
import React, { type ComponentProps } from 'react';
import { Button, Spinner } from '@rivallapp/volosports-components';
import { usePrintWaiver, type UsePrintWaiverArgs } from './usePrintWaiver';

type ButtonProps = ComponentProps<typeof Button>;

const defaultProps: ButtonProps = {
  variant: 'link',
  _text: {
    color: 'primary.400',
    fontSize: 'xs',
    fontWeight: 'bold',
  },
};

type Props = Omit<ButtonProps, 'onPress'> & UsePrintWaiverArgs;

const PrintLeagueWaiverButton: React.FC<Props> = ({ waiverId, fullName, dateSigned, ...props }) => {
  const [NODE, print, printing] = usePrintWaiver({ waiverId, fullName, dateSigned });

  return (
    <>
      <Button
        endIcon={printing ? <Spinner /> : undefined}
        {...defaultProps}
        {...props}
        onPress={print}
      />
      {NODE}
    </>
  );
};

export default PrintLeagueWaiverButton;
