import { useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { OrganizationsContext } from '../contexts';
import { getAppName, getPageName, noHeader } from '../pages/page-options';

const usePageOptions = () => {
  const { pathname } = useLocation();
  const organizationsList = useContext(OrganizationsContext);

  return useMemo(() => {
    const pageName = getPageName({ pathname, organizationsList });
    return {
      pageName,
      noHeader: noHeader(pageName),
      isRfo: pathname.includes('rfo'),
      appName: getAppName({ pathname, organizationsList }),
    };
  }, [pathname, organizationsList]);
};

export default usePageOptions;
