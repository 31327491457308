import { type FC } from 'react';
import { Button, View, Heading, Text } from '@rivallapp/volosports-components';
import { type PaymentInterval, type VoloPassPlanOptionsProps } from './types';

const VoloPassPlanOptions: FC<VoloPassPlanOptionsProps> = ({
  isMonthlyVoloPassMember,
  monthlyDollarPrice,
  annualDollarPrice,
  monthlyPlanId,
  annualPlanId,
  planDetails,
  updatePlanDetails,
}) => {
  const { planId } = planDetails;

  const annualSavings =
    (monthlyDollarPrice && annualDollarPrice && monthlyDollarPrice * 12 - annualDollarPrice) || 0;

  const annualBillingDate = new Date();
  annualBillingDate.setFullYear(annualBillingDate.getFullYear() + 1);

  const formattedAnnualBillingDate = annualBillingDate.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  const onSelectPlan = (buttonPlanId: string, variant: PaymentInterval) => () => {
    updatePlanDetails({ planId: buttonPlanId, variant });
  };

  return (
    <View marginTop={4}>
      <Heading fontSize={16} color="primary.600">
        Choose a plan
      </Heading>
      <View marginTop={2}>
        {!isMonthlyVoloPassMember && (
          <Button
            type={planId === monthlyPlanId ? 'primary' : 'white'}
            borderRadius={50}
            marginY={1}
            onPress={onSelectPlan(monthlyPlanId!, 'monthly')}
          >
            {`Monthly $${monthlyDollarPrice}/month`}
          </Button>
        )}
        <Button
          type={planId === annualPlanId ? 'primary' : 'white'}
          borderRadius={50}
          marginY={1}
          onPress={onSelectPlan(annualPlanId!, 'annual')}
        >
          {`Annual $${annualDollarPrice}/year (Save $${annualSavings}!)`}
        </Button>
      </View>
      {isMonthlyVoloPassMember ? (
        <Text fontSize={12} marginTop={4} marginX={3}>
          Billing will be applied immediately. Annual membership will renew on{' '}
          <Text bold fontSize={12}>
            {formattedAnnualBillingDate}
          </Text>
          .
        </Text>
      ) : (
        <Text fontSize={9} color="primary.600" fontWeight={700}>
          ***3 month minimum required for monthly
        </Text>
      )}
    </View>
  );
};

export default VoloPassPlanOptions;
