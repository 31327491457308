import { gql } from '@apollo/client';

export const REGISTER_FOR_PROGRAM = gql`
  mutation currentUserRegisterForProgram($input: CurrentUserRegisterForProgramInput!) {
    currentUserRegisterForProgram(input: $input) {
      programRegistration {
        registrant {
          _id
        }
      }
    }
  }
`;

export const JOIN_PREPAID_TEAM = gql`
  mutation currentUserJoinPrepaidTeam($input: CurrentUserJoinPrepaidTeamInput!) {
    currentUserJoinPrepaidTeam(input: $input) {
      registrant {
        _id
      }
    }
  }
`;

export const PaymentSourceFragment = gql`
  fragment paymentSourceFragment on PaymentSource {
    last4
    isDefault
    isExpired
    exp_month
    exp_year
    brand
    id
  }
`;

export const ADD_PAYMENT_SOURCE = gql`
  mutation currentUserAddPaymentSource($input: CurrentUserAddPaymentSourceInput!) {
    currentUserAddPaymentSource(input: $input) {
      currentUser {
        _id
        hasStripeId
        paymentSources {
          ...paymentSourceFragment
        }
      }
    }
  }
  ${PaymentSourceFragment}
`;

export const SET_PAYMENT_SOURCE_DEFAULT = gql`
  mutation currentUserSetPaymentSourceDefault($input: CurrentUserSetPaymentSourceDefaultInput!) {
    currentUserSetPaymentSourceDefault(input: $input) {
      currentUser {
        _id
        paymentSources {
          ...paymentSourceFragment
        }
      }
    }
  }
  ${PaymentSourceFragment}
`;

export const CHECK_PASSWORD = gql`
  query checkGroupPassword($input: CheckGroupPasswordInput!) {
    checkGroupPassword(input: $input)
  }
`;

export const CONFIRMATION_QUERY = gql`
  query registrant($registrantId: ID!) {
    registrant(_id: $registrantId) {
      _id
      kind
      isDropin
      fullStripeCharge {
        id
        amount
        source {
          last4
        }
      }
      charge_data {
        before_fees
        promo_code
        promo_discount
      }
      group {
        _id
        name
        prepaid
        deepLink
      }
    }
  }
`;

export const DELETE_PAYMENT_SOURCE = gql`
  mutation currentUserDeletePaymentSource($input: CurrentUserDeletePaymentSourceInput!) {
    currentUserDeletePaymentSource(input: $input) {
      currentUser {
        _id
        paymentSources {
          ...paymentSourceFragment
        }
      }
    }
  }
  ${PaymentSourceFragment}
`;

export const WAIVER_QUERY = gql`
  query waiver($_id: ID!) {
    waiver(_id: $_id) {
      name
      content
    }
  }
`;
