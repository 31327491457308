import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './fancy-boolean.module.scss';
import { getLabel } from '../fancy-helpers.js';

const cx = classnames.bind(styles);

class FancyBoolean extends Component {
  constructor(props) {
    super(props);
    this.isRadio = props.type === 'radio';
  }

  onChange = e => {
    const { passRawEvent, onChange, id } = this.props;
    if (passRawEvent) {
      return onChange(e);
    }
    const { checked } = e.target;
    const value = this.isRadio ? id : checked;
    return onChange(value);
  };

  renderToggle = () => {
    const { toggle } = this.props;
    if (!toggle) return null;
    return (
      <div className={cx(styles.toggleWrap)}>
        <div className={cx(styles.toggler)} />
      </div>
    );
  };

  renderSwitch = () => {
    const { switch: isSwitch, trueLabel, falseLabel, wide } = this.props;
    if (!isSwitch) return null;
    return (
      <div className={cx(styles.switch, { [styles.wide]: wide })}>
        <div className={cx(styles.switchOption, styles.true)}>{trueLabel}</div>
        {/*
         */}
        <div className={cx(styles.switchOption, styles.false)}>{falseLabel}</div>
      </div>
    );
  };

  render() {
    const {
      className,
      disabled,
      error,
      style,
      styleName,
      type,
      saving,
      readOnly,
      labelStyle,
      name,
      value,
      autocomplete,
      toggle,
      id,
      small,
      switch: isSwitch,
      noLabel,
    } = this.props;

    const label = getLabel(this.props);

    return (
      <label
        className={cx(styles.fancyBool, className, styles[styleName], {
          [styles.error]: error,
          [styles.disabled]: disabled,
          [styles.readonly]: readOnly,
          [styles[type]]: !toggle,
          [styles.toggle]: toggle,
          [styles.switch]: isSwitch,
          [styles.small]: small,
        })}
        style={style}
        htmlFor={this.isRadio ? id : name}
      >
        <input
          type={type}
          checked={value}
          onChange={this.onChange}
          name={name}
          autoComplete={autocomplete}
          {...(disabled ? { disabled } : {})}
          {...(readOnly ? { readOnly } : {})}
          {...(this.isRadio
            ? {
                id,
                value: id,
              }
            : {})}
          onClick={e => e.stopPropagation()}
        />

        {this.renderToggle()}

        {this.renderSwitch()}

        {!noLabel && !isSwitch && (
          <span
            className={cx(styles.label, {
              'text-flash': saving,
            })}
            style={labelStyle}
          >
            {label}
          </span>
        )}
      </label>
    );
  }
}

FancyBoolean.propTypes = {
  autocomplete: PropTypes.string,
  type: PropTypes.oneOf(['checkbox', 'radio']),
  attrs: PropTypes.shape({}),
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  labelStyle: PropTypes.shape({}),
  name: PropTypes.string,
  onChange: PropTypes.func,
  /** when true, onChange will pass the event instead of transforming it into a key-value pair. */
  passRawEvent: PropTypes.bool,
  style: PropTypes.shape({}),
  styleName: PropTypes.string,
  defaultValue: PropTypes.bool,
  value: PropTypes.bool,
  saving: PropTypes.bool,
  readOnly: PropTypes.bool,
  /** required when type="radio" * */
  id: PropTypes.string,
  toggle: PropTypes.bool,
  small: PropTypes.bool,
  switch: PropTypes.bool,
  noLabel: PropTypes.bool,
  trueLabel: PropTypes.oneOfType([PropTypes.bool, PropTypes.node, PropTypes.string]),
  falseLabel: PropTypes.oneOfType([PropTypes.bool, PropTypes.node, PropTypes.string]),
  wide: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string),
};

FancyBoolean.defaultProps = {
  onChange: () => {},
  autocomplete: 'off',
  type: 'checkbox',
  attrs: {},
  className: '',
  disabled: false,
  error: false,
  labelStyle: {},
  name: undefined,
  passRawEvent: false,
  style: {},
  styleName: null,
  defaultValue: undefined,
  value: undefined,
  saving: false,
  readOnly: undefined,
  id: undefined,
  small: undefined,
  switch: undefined,
  toggle: undefined,
  noLabel: undefined,
  trueLabel: undefined,
  falseLabel: undefined,
  wide: undefined,
  options: undefined,
};

export default FancyBoolean;
export const Checkbox = props => <FancyBoolean type="checkbox" {...props} />;
export const Radio = props => <FancyBoolean type="radio" {...props} />;
export const Toggle = props => <FancyBoolean type="checkbox" toggle {...props} />;
export const Switch = props => <FancyBoolean type="checkbox" switch {...props} />;
export const RadioGroup = ({ options, value, ...props }) => (
  <span>
    {options.map(option => (
      <Radio id={option} value={value === option} {...props} />
    ))}
  </span>
);
