const getCityOptions = (organizationList = []) => {
  if (!organizationList || organizationList?.length < 1) return [];

  return organizationList?.map(({ name, _id }) => ({
    value: _id,
    label: name,
  }));
};

export default getCityOptions;
