import { compose, withStateHandlers, withProps } from 'recompose';
import * as _ from 'lodash-es';
import { formatCents, intFromFormattedPrice } from '../../utils';

export default WrappedComponent =>
  compose(
    withStateHandlers(
      ({ value }) => ({
        value: formatCents(value || 0),
      }),
      {
        onChange:
          (_state, { onChange }) =>
          value => {
            const formattedPrice = intFromFormattedPrice(value);
            onChange(formattedPrice);
            const formatError = _.isNumber(formattedPrice) || formattedPrice < 0;
            return {
              value: formattedPrice,
              formatError,
              formatErrorText: formatError ? 'Enter a valid price (e.g., 20.00)' : null,
            };
          },
      }
    ),
    withProps(props => {
      const { error, errorText, formatError, formatErrorText } = props;
      // override an error passed in via props with the format error,
      // otherwise pass that along.
      return {
        error: error || formatError,
        errorText: formatErrorText || errorText,
      };
    })
  )(WrappedComponent);
